import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useOptionsForSelects } from '../context'
import style from '../style.module.scss'

const CategorySelect = ({ formState, setValue }) => {
  const { t } = useTranslation()
  const { categories } = useOptionsForSelects()

  return (
    <div>
      <div className={style.optionsText}>{t('editPeriodsNew.category')}</div>
      <div className={cx(style.select, style.categorySelect)}>
        <DropdownSelect
          values={categories}
          selectedId={formState.category_id}
          initialId={formState.category_id}
          onSelect={(value) => setValue('category_id', value)}
          noMargin
        />
      </div>
    </div>
  )
}

export default CategorySelect
