import { BayOption, TrackingTech } from '@sweetspot/shared/types'
import ToptracerLogo from '@sweetspot/shared/assets/images/logos/bayToptracer.png'
import TrackmanLogo from '@sweetspot/shared/assets/images/logos/bayTrackman.png'
import InrangeLogo from '@sweetspot/shared/assets/images/logos/bayInrange.png'

export const BAY_TYPES: BayOption = {
  none: {
    name: TrackingTech.NONE,
  },
  toptracer: {
    name: TrackingTech.TOPTRACER,
    logo: ToptracerLogo,
  },
  trackman: {
    name: TrackingTech.TRACKMAN,
    logo: TrackmanLogo,
  },
  inrange: {
    name: TrackingTech.INRANGE,
    logo: InrangeLogo,
  },
}
