import { useTranslation } from 'react-i18next'

import { PricesTabs } from './PricesTabs'
import { useBallPricingContext } from '../../BallPricingProvider'

const PricesTab = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { bucketSizes },
  } = useBallPricingContext()

  return (
    <div className="flex h-full flex-1 flex-col gap-4">
      {bucketSizes.map((bucketSize) => (
        <div>
          <div className="px-md py-sm bg-background-mono-darker text-text-light">
            {bucketSize.nrOfBalls} {t('words.balls')}
          </div>
          <PricesTabs />
        </div>
      ))}
    </div>
  )
}

export default PricesTab
