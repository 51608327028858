import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export const migrateAutorenewalSettings = (id: number): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/memberships/${id}/autorenewal/migrate`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
