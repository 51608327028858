import { useMutation, useQuery } from 'react-query'
import { CLUB_QUERIES } from '../constants/queries'
import {
  postMembershipSignup,
  queryMemberships,
  queryMembershipsPlayer,
} from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'

/**
 * Custom hook for managing memberships.
 *
 * @param {Object} options - The options for the hook.
 * @param {number} options.clubId - The ID of the club.
 * @param {string} [options.state] - The state of the membership.
 * @param {number} [options.totalFee] - The total fee for the membership.
 * @param {number} [options.totalMonthlyFee] - The monthly fee for the membership.
 * @param {string} [options.uuid] - The UUID of the membership.
 * @param {string} [options.sortOrder] - The sort order for displaying memberships.
 * @param {boolean} [options.isActive] - Flag indicating if the membership is active.
 * @param {{limit: number}} [options.query] - Query for filtering memberships.
 * @param {boolean} [options.enabled] - Flag indicating if the membership is enabled.
 * @param {Object} [options.settings] - The settings related to the membership.
 * @param {string | null} [options.language=null] - The language for the membership interface.
 * @returns Return value of the hook (Specify the exact return type if known).
 */
const useMembership = ({
  clubId,
  state,
  totalFee,
  totalMonthlyFee,
  uuid,
  sortOrder,
  isActive,
  query,
  enabled,
  settings,
  language = null,
}) => {
  const { data, isFetching, isFetched, refetch } = useQuery(
    [
      CLUB_QUERIES.MEMBERSHIPS,
      clubId,
      state,
      uuid,
      totalFee,
      totalMonthlyFee,
      sortOrder,
      isActive,
      query,
      language,
    ],
    () =>
      queryMemberships({
        'club.id': clubId,
        state: state,
        total: totalFee,
        totalMonthly: totalMonthlyFee,
        isActive: isActive,
        'uuid[]': uuid,
        'order[sort.name]': sortOrder,
        ...query,
      }),
    { enabled: !!clubId && (enabled === undefined ? true : enabled), ...settings }
  )
  return { data, isFetching, isFetched, refetch }
}

const useMembershipPlayer = ({ clubUuid, isActive, options }) => {
  return useQuery(
    [CLUB_QUERIES.MEMBERSHIPS, clubUuid, isActive],
    () =>
      queryMembershipsPlayer({
        club: clubUuid,
        isActive: isActive,
      }),
    options
  )
}

const useMembershipSignup = () => {
  return useMutation(
    (
      membershipData = { courseUuid: '', membershipUuid: '', durationType: '', calendarYear: '' }
    ) => {
      return postMembershipSignup(membershipData)
    },
    {
      onError: (error) => {
        return error
      },
    }
  )
}

export { useMembership, useMembershipSignup, useMembershipPlayer }
