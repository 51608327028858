import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { createBayPayload, updateBayPayload } from './types'

export function createBay(payload: createBayPayload): Promise<void> {
  const { rangeId, bay } = payload

  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/bays`
  return request(URL, {
    method: 'POST',
    body: {
      floor: bay.floor,
      roof: bay.roof,
      tracking_technology: bay.tracking_technology,
    },
  })
}

export function updateBay(payload: updateBayPayload): Promise<void> {
  const { rangeId, bayId, bay } = payload

  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/bays/${bayId}`
  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: bay,
  })
}
