import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export function _getBookingPeriodsByGolfcourse(courseId) {
  const URL = `${getBaseUrlPlatform()}/periods?course.id=${courseId}&order[start_date]=DESC`

  return request(URL, {
    method: 'GET',
  })
}

export function _createBookingPeriod(courseId, payload) {
  const URL = `${getBaseUrlPlatform()}/crm/courses/${courseId}/periods`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _deleteBookingPeriod(bookingPeriodID, lang) {
  const URL = `${getBaseUrlPlatform()}/periods/${bookingPeriodID}`

  return request(URL, {
    method: 'DELETE',
    headers: {
      'Accept-Language': `${lang === 'se' ? 'sv' : lang}`,
    },
  })
}

export function _getPeriodOverrides(periodId, query) {
  const URL = `${getBaseUrlPlatform()}/period-overrides?period.id=${periodId}&${query}`

  return request(URL, {
    method: 'GET',
  })
}

export function _addPeriodOverride(periodId, payload) {
  const URL = `${getBaseUrlPlatform()}/crm/periods/${periodId}/overrides`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _deletePeriodOverride(periodOverrideId) {
  const URL = `${getBaseUrlPlatform()}/period-overrides/${periodOverrideId}`
  return request(URL, {
    method: 'DELETE',
  })
}
