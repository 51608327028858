const daysOptions = (t) => [
  { id: 0, name: t('editPeriodsNew.everyday'), value: 'every_day' },
  { id: 1, name: t('editPeriodsNew.weekends'), value: 'weekend' },
  { id: 2, name: t('editPeriodsNew.weekdays'), value: 'weekday' },
  { id: 3, name: t('dateTime.longDays.monday'), value: 'monday' },
  { id: 4, name: t('dateTime.longDays.tuesday'), value: 'tuesday' },
  { id: 5, name: t('dateTime.longDays.wednesday'), value: 'wednesday' },
  { id: 6, name: t('dateTime.longDays.thursday'), value: 'thursday' },
  { id: 7, name: t('dateTime.longDays.friday'), value: 'friday' },
  { id: 8, name: t('dateTime.longDays.saturday'), value: 'saturday' },
  { id: 9, name: t('dateTime.longDays.sunday'), value: 'sunday' },
]

export default daysOptions
