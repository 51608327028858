import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Query tee-times
 *
 * @returns
 */
export const queryTeeTimes = (
  query = {
    'course.uuid': null,
    'course.uuid[]': null,
    'space.uuid': null,
    'space.uuid[]': null,
    'from[before]': null,
    'from[strictly_before]': null,
    'from[after]': null,
    'from[strictly_after]': null,
    'order[from]': null,
    page: 1,
    limit: 50,
  },
  ...args
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/tee-times`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}

/**
 * Get's single teeTime by uuid
 *
 * @param {number|string} teeTimeUuid
 */
export const getTeeTimeByUuid = (teeTimeUuid) => {
  const url = `${getBaseUrlPlatform()}/tee-times/${teeTimeUuid}`
  return request(url)
}

/**
 * Get's single teeTime price by uuid
 *
 * @param {number|string} teeTimeUuid
 */
export const getTeeTimePriceByUUID = (teeTimeUUID) => {
  const url = `${getBaseUrlPlatform()}/tee-times/${teeTimeUUID}/price`
  return request(url)
}

/**
 * Get's tee time availability for day
 *
 * @param {string} uuid
 */
export function getTeeTimesAvailability(
  query = {
    'from[after]': null,
    'from[before]': null,
    'course[]': null,
    course: null,
    partnership: null,
    'available_slots[gte]': null,
    date: null,
    page: 1,
    limit: 50,
  },
  ...args
) {
  // export function getTeeTimesStatus(uuid, date, partnership = null) {
  // const formattedDate = DateHelpers.onlyDateString(date)
  // const query = { date: formattedDate, course: uuid, partnership: partnership }
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/tee-times/availability`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}

/**
 * Set Fixed price of booking.
 *
 * @param {number} teeTimeId
 * @param {object} priceObject
 */
export const setFixedPrice = (teeTimeId, priceObject) => {
  const url = `${getBaseUrlPlatform()}/tee-times/${teeTimeId}`
  return request(url, {
    method: 'PUT',
    body: JSON.stringify(priceObject),
    contentType: 'application/json',
  })
}

/**
 * Save tee time notes
 *
 * @param {string} teeTimeUuid
 * @returns
 */
export const saveNotes = (teeTimeUuid, payload = { notes: '' }) => {
  const url = `${getBaseUrlPlatform()}/tee-times/${teeTimeUuid}/notes`
  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Set tee time
 *
 * @param {number} periodId
 * @param {object} payload
 * @returns
 */
export function setTeeTimes(periodId, payload) {
  const URL = `${getBaseUrlPlatform()}/periods/${periodId}/tee-times`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Update tee times
 *
 * @param {object} payload
 * @returns
 */
export function updateTeeTimes(payload) {
  const URL = `${getBaseUrlPlatform()}/tee-times/pricing`
  return request(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Query dynamic prices
 *
 * @returns
 */
export const queryTeeTimeDynamicPrices = (
  query = {
    course: null,
    'from[after]': null,
    'from[before]': null,
    space: null,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/tee-times/dynamic-prices`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Gets next available tee time
 *
 * @param {object} payload
 * @returns
 */

export const getNextAvailableTeeTime = (
  query = {
    'course.uuid': null,
    'from[before]': null,
    'from[after]': null,
    page: 1,
    limit: 50,
  },
  ...args
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/tee-times/get-next-available`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}
