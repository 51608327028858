import TeetimeStatus from '@sweetspot/club-portal-legacy/components/TeetimeStatus'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { ReactComponent as MinusIcon } from '@sweetspot/club-portal-legacy/resources/images/red-stop.svg'
import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSelectedCourse } from '../../context'
import style from './style.module.scss'
import ToolTip from '@sweetspot/sweetspot-js/ui-kit/components/ToolTip/tooltip'
import cx from 'classnames'

const PeriodOverride = ({ isLoading, overrides, deleteOverride }) => {
  const [activeOverrideId, setActiveOverrideId] = useState(0)
  const { selectedCourse } = useSelectedCourse()
  const { t } = useTranslation()
  const hasGolfId = useSelector((state) => {
    const currentClub = state?.golfClub?.list?.find?.(
      (club) => club.id === state?.golfClub?.selectedId
    )
    return currentClub?.address?.country?.code === 'SE'
  })

  const handleDeleteOverride = () => {
    if (activeOverrideId) {
      deleteOverride(activeOverrideId)
    }
    toggleRemoveConfirm()
  }

  const toggleRemoveConfirm = (id = 0) => {
    setActiveOverrideId(id)
  }

  const PeriodHeader = ({ t, isLoading }) => (
    <div className={style.header}>
      <p className={style.title}>{t('editPeriodsNew.periodChanges')}</p>
      <PulseLoader showIf={isLoading} />
    </div>
  )

  const TableHeader = ({ t, selectedCourse }) => {
    const isCourse = selectedCourse.type === 'course'
    const classTableHeader = [style.header, isCourse ? style.course : ''].join(' ')
    return (
      <div className={classTableHeader}>
        <p>{t('editPeriodsNew.createdAt')}</p>
        <p>{t('editPeriodsNew.fromDate')}</p>
        <p>{t('editPeriodsNew.toDate')}</p>
        <p>{t('editPeriodsNew.createdBy')}</p>
        <p>{t('editPeriodsNew.scheduleTo')}</p>
        {!isCourse ? <p>{t('editPeriodsNew.space')}</p> : null}
        <p>{t('editPeriodsNew.tableHeaders.firstTimeStarts')}</p>
        <p>{t('editPeriodsNew.tableHeaders.lastTimeStarts')}</p>
        {<p>{t('editPeriodsNew.slots')}</p>}
        <p>{t('editPeriodsNew.days')}</p>
        <p>{t('editPeriodsNew.category')}</p>
        {hasGolfId && <p>{t('editPeriodsNew.golfID')}</p>}
        <p>{t('editPeriodsNew.primeTime')}</p>
        <div className={style.removeIcon}>
          <TrashIcon />
        </div>
      </div>
    )
  }

  return (
    <div className={style.container}>
      <PeriodHeader t={t} isLoading={isLoading} />
      <div className={style.tableContainer}>
        <TableHeader t={t} selectedCourse={selectedCourse} />
        {overrides.map(({ scheduled_to, ...override }) => {
          const isCourse = selectedCourse.type === 'course'
          const classTableRow = [style.row, isCourse ? style.course : ''].join(' ')
          return (
            <div key={override.id} className={classTableRow}>
              <p>{DateHelpers.toPHDateString(override.created_at)}</p>
              <p>{DateHelpers.toPODateString(override.start_date)}</p>
              <p>{DateHelpers.toPODateString(override.end_date)}</p>
              <ToolTip text={override?.updated_by} useOverflowCheck={true}>
                <div>{override?.updated_by}</div>
              </ToolTip>
              <p>{scheduled_to ? DateHelpers.toFullDateString(scheduled_to) : ''}</p>
              {!isCourse ? <p>{override?.space?.name || t('editPeriodsNew.all')}</p> : null}
              <p>{override.start_time_from.substr(11, 5)}</p>
              <p>{override.start_time_to.substr(11, 5)}</p>
              {<p>{override.slots}</p>}
              <p>{t(`editPeriodsNew.${override.repeat_on}`)}</p>
              {override.category ? (
                <TeetimeStatus color={override.category.color} name={override.category.name} />
              ) : (
                <p />
              )}
              {hasGolfId && (
                <p>
                  {typeof override.is_golf_id_required === 'boolean'
                    ? t(`editPeriodsNew.${override.is_golf_id_required ? 'required' : 'optional'}`)
                    : ''}
                </p>
              )}
              <p>
                {typeof override.is_prime_time === 'boolean'
                  ? t(`${override.is_prime_time ? 'yes' : 'no'}`)
                  : ''}
              </p>
              <div
                className={cx(
                  style.removeIcon,
                  !DateHelpers.checkValidPeriod(new Date(), new Date(scheduled_to)) &&
                    style.disabledIcon
                )}
                onClick={
                  DateHelpers.checkValidPeriod(new Date(), new Date(scheduled_to))
                    ? () => toggleRemoveConfirm(override.id)
                    : () => {}
                }
              >
                {<MinusIcon />}
              </div>
            </div>
          )
        })}
      </div>
      {!overrides?.length && (
        <div className={style.noScheduled}>
          {isLoading ? t('editPeriodsNew.loadingData') : t('editPeriodsNew.noLogged')}
        </div>
      )}
      <ConfirmPopup
        visible={!!activeOverrideId}
        acceptTheme="danger"
        titleIcon={TrashIcon}
        title={t('editPeriodsNew.deletePeriodChanges')}
        rejectTextKey="cancel"
        acceptTextKey="settings.delete"
        text={t('editPeriodsNew.confirmDeleteOverride')}
        onReject={() => toggleRemoveConfirm()}
        onClose={() => toggleRemoveConfirm()}
        onAccept={handleDeleteOverride}
      />
    </div>
  )
}

PeriodOverride.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  overrides: PropTypes.array.isRequired,
}

export default PeriodOverride
