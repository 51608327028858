import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectGolfClub } from '@sweetspot/club-portal-legacy/store/actions'

import ButtonGroup from './components/ButtonGroup'
import GolfClubSelect from './components/GolfClubSelect'

import { CacheBuster } from '@sweetspot/shared/util/cache-buster'
import { CacheInfo } from '@sweetspot/club-portal-legacy/components/CacheInfo/CacheInfo'
import { ReactComponent as SweetspotLogo } from '@sweetspot/club-portal-legacy/resources/images/sweetspot-logo.svg'

interface HeaderProps {
  updateMenu: () => void
}

const Header = ({ updateMenu }: HeaderProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const golfClubs = useSelector((state) => state.golfClub)

  const handleSelectGolfClub = (id: number, initial: boolean, redirect: boolean) => {
    dispatch(selectGolfClub(id, initial, redirect))
  }

  return (
    <div className="bg-background-sand-lighter border-bg-background-sand-lighter grid h-[76px] grid-cols-[200px_1fr_max-content_max-content] border-b-2">
      <SweetspotLogo
        className="h-[30px] cursor-pointer place-self-center"
        onClick={() => {
          history.push('/')
          updateMenu()
        }}
      />
      <GolfClubSelect golfClubs={golfClubs} selectGolfClub={handleSelectGolfClub} />
      <div>
        <CacheBuster>
          <CacheInfo />
        </CacheBuster>
      </div>
      <ButtonGroup />
    </div>
  )
}

export default Header
