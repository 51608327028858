import React from 'react'
import m from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField' // Test
import ButtonOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/ButtonOptionsSelect'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import styles from './styles.module.scss'

/**
 * Controlled wrapper
 */
const GeneralSettings = ({
  values,
  errors,
  onSetPartnershipValue,
  isCreated,
  disabled,
  onlyFields,
  onClearPartnershipvalue,
}) => {
  const { t } = useTranslation()

  const getError = (field) => {
    return errors && errors[field] ? errors[field] : ''
  }

  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const formatDateForSaving = (date, type) => {
    if (date && /([0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9])/g.test(date) && m(date).isValid()) {
      if (type === 'expires') {
        return m(date).hour(23).minute(59).second(59).utc().format(dateFormat)
      }
      if (type === 'starts') {
        return m(date).hour(0).minute(0).second(0).utc().format(dateFormat)
      }
    } else {
      return date
    }
  }

  const formatDateForDisplay = (date) => {
    if (date.length && date.length >= 10 && m(date).isValid()) {
      return m.utc(date).local().format('YYYY-MM-DD')
    }
    return date
  }

  const checkIfDateFull = (date) => {
    if (date.length && date.length >= 10 && m(date).isValid()) {
      return m.utc(date).local().format('YYYY-MM-DD')
    }
    return null
  }

  const renderFields = () => (
    <React.Fragment>
      <TextInputField
        label={t('wizard.partnershipName')}
        placeholder={t('name')}
        value={values.name || ''}
        error={getError('name')}
        onChange={(val) => onSetPartnershipValue('name', val)}
        type="text"
        disabled={disabled}
      />
      <ButtonOptionsSelect
        label={t('wizard.partnershipType')}
        disabled={disabled || isCreated}
        error={getError('type')}
        options={[
          {
            value: 'prepay',
            label: t('wizard.prepayOption'),
          },
          {
            value: 'postpay',
            label: t('wizard.postpayOption'),
          },
        ]}
        value={values.type}
        onSelect={(val) => onSetPartnershipValue('type', val)}
      />
      <div className={styles.periodContainer}>
        <TextInputField
          error={getError('starts_at')}
          value={formatDateForDisplay(values.starts_at)}
          type="calendar"
          disabled={disabled}
          containerWidth="half"
          placeholder="YYYY-MM-DD"
          label={t('wizard.bookingPeriod')}
          labelTwo={t('wizard.startDate')}
          onChange={(val) => onSetPartnershipValue('starts_at', formatDateForSaving(val, 'starts'))}
          calendarSettings={{
            selectedDate: checkIfDateFull(values.starts_at) || new Date().getTime(),
          }}
          CalendarComponent={MiniCalendar}
          maskOptions={{
            guide: false,
            mask: [
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              '-',
              /[0-1]/,
              /[0-9]/,
              '-',
              /[0-3]/,
              /[0-9]/,
            ],
          }}
        />
        <p>-</p>
        <TextInputField
          error={getError('expires_at')}
          type="calendar"
          disabled={disabled}
          value={formatDateForDisplay(values.expires_at)}
          containerWidth="half"
          placeholder="YYYY-MM-DD"
          labelTwo={t('wizard.endDate')}
          onChange={(val) =>
            onSetPartnershipValue('expires_at', formatDateForSaving(val, 'expires'))
          }
          calendarSettings={{
            selectedDate: checkIfDateFull(values.expires_at) || new Date(),
            startDate: DateHelpers.today(),
          }}
          CalendarComponent={MiniCalendar}
          maskOptions={{
            guide: false,
            mask: [
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              '-',
              /[0-1]/,
              /[0-9]/,
              '-',
              /[0-3]/,
              /[0-9]/,
            ],
          }}
        />
      </div>
      {values && values.type === 'prepay' ? (
        <>
          <TextInputField
            error={getError('price_excluded_vat')}
            type="number"
            disabled={disabled || isCreated}
            containerWidth="third"
            label={t('wizard.priceExcludingVAT')}
            value={parseFloat(values.price_excluded_vat / 100) || 0}
            onChange={(val) =>
              val === 0 || val
                ? onSetPartnershipValue('price_excluded_vat', parseFloat(val * 100))
                : onClearPartnershipvalue('price_excluded_vat')
            }
          />
          <TextInputField
            error={getError('price_included_vat')}
            type="number"
            containerWidth="third"
            disabled={disabled || isCreated}
            label={t('wizard.priceIncludingVAT')}
            value={parseFloat(values.price_included_vat / 100) || 0}
            onChange={(val) =>
              val === 0 || val
                ? onSetPartnershipValue('price_included_vat', parseFloat(val * 100))
                : onClearPartnershipvalue('price_included_vat')
            }
          />
        </>
      ) : null}
    </React.Fragment>
  )

  if (onlyFields === true) {
    return renderFields()
  }

  return <div className={cx(styles.container)}>{renderFields()}</div>
}

GeneralSettings.propTypes = {
  /** From Parent */
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onSetPartnershipValue: PropTypes.func,
  onClearPartnershipvalue: PropTypes.func,
  disabled: PropTypes.bool,
  isCreated: PropTypes.bool,
  onlyFields: PropTypes.bool,
}

GeneralSettings.defaulProps = {
  values: {},
  errors: {},
  onSetPartnershipValue: () => {},
  onClearPartnershipvalue: () => {},
  disabled: false,
  isCreated: false,
  onlyFields: false,
}

const mapStateToProps = (state) => ({
  lang: state.auth.me.lang,
})

export default connect(mapStateToProps)(GeneralSettings)
