import { useBaysContext } from '../BaysProvider'
import { BaySideBarMode } from '../types'
import CreateBay from './CreateBay'
import UpdateBay from './UpdateBay'
import BayDetails from './BayDetails'

const BaySideBar = () => {
  const { baySideBarMode } = useBaysContext()

  if (baySideBarMode === BaySideBarMode.VIEW) {
    return <BayDetails />
  }

  if (baySideBarMode === BaySideBarMode.CREATE) {
    return <CreateBay />
  }

  if (baySideBarMode === BaySideBarMode.EDIT) {
    return <UpdateBay />
  }

  return null
}

export default BaySideBar
