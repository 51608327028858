import React, { useCallback, useState } from 'react'
import HEADERS from './headers'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { BayType } from '@sweetspot/shared/types'
import { Button } from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { useTranslation } from 'react-i18next'
import Table from '@sweetspot/club-portal-legacy/components/Table'

type BaysTableProps = {
  bayData: BayType[]
}

const BaysTable = ({ bayData }: BaysTableProps) => {
  const { t } = useTranslation()
  const [selectedRows, setSelectedRows] = useState<BayType[]>([])
  const { setSelectedBays, toggleBaySideBar } = useBaysContext()

  const handleRowClick = useCallback(
    (selectedValue: BayType) => {
      setSelectedBays([selectedValue])
      toggleBaySideBar(BaySideBarMode.VIEW)
    },
    [setSelectedBays, toggleBaySideBar]
  )

  const handleEditClick = useCallback(() => {
    setSelectedBays(selectedRows)
    toggleBaySideBar(BaySideBarMode.EDIT)
  }, [selectedRows, setSelectedBays, toggleBaySideBar])

  return (
    <div className="h-full w-full">
      <div className="mb-lg flex justify-end">
        <Button
          variant={'clear-dark'}
          size={'small'}
          onClick={handleEditClick}
          disabled={!selectedRows?.length}
          className={'py-sm px-lg border-stroke-stone gap-2 text-sm font-medium'}
        >
          <i className="fa-regular fa-edit" />
          {t('words.edit')}
        </Button>
      </div>
      <Table
        headers={HEADERS}
        values={bayData}
        fullWidth
        fullHeight
        hasCheckbox
        hideScrollbar
        onRowClick={handleRowClick}
        onSelect={setSelectedRows}
      />
    </div>
  )
}

export default BaysTable
