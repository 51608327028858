import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Query third party applications
 *
 * @returns
 */

export const fetchApplicationsList = (query = { page: 1, limit: 50, club: null }) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/third-party-applications`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, {
    method: 'GET',
  })
}

/**
 * Create third party application
 *
 * @returns
 */
export const createApplication = (payload = { name: '', is_active: true }) => {
  const defaultPayload = { name: '', is_active: true }
  const URL = `${getBaseUrlPlatform()}/third-party-applications`

  return request(URL, {
    method: 'POST',
    body: { ...defaultPayload, ...payload },
  })
}

/**
 * Update third party application
 *
 * @param {Object} application
 * @param {Bool} value
 * @returns
 */
export const updateApplication = (application, value) => {
  const URL = `${getBaseUrlPlatform()}/third-party-applications/${application.id}`
  return request(URL, {
    method: 'PUT',
    body: {
      name: application.name,
      is_active: value,
    },
  })
}

/**
 * Remove third party application
 *
 * @param {number} applicationId
 * @returns
 */
export const removeApplication = (applicationId) => {
  const URL = `${getBaseUrlPlatform()}/third-party-applications/${applicationId}`
  return request(URL, {
    method: 'DELETE',
  })
}
