import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Update promotion
 *
 * @param {string} token
 * @param {string|number} promotionId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _updatePromotion = (promotionId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${promotionId}`
  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Deletes promotion
 *
 * @param {string} token
 * @param {string|number} promotionId
 * @returns {Promise}
 */
export const _deletePromotion = (promotionId) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${promotionId}`
  return request(URL, {
    method: 'DELETE',
  })
}

/**
 * Adds actions to promotion
 *
 * @param {string} token
 * @param {string|number} promotionId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addAction = (promotionId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${promotionId}/action/new`
  return request(URL, {
    method: 'PUT',

    body: payload,
  })
}

/**
 * Updates action configuration
 *
 * @param {string} token
 * @param {string|number} actionId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _updateAction = (actionId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/action/${actionId}`
  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Deletes action
 *
 * @param {string} token
 * @param {string|number} actionId
 * @returns {Promise}
 */
export const _deleteAction = (actionId) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/actions/${actionId}`
  return request(URL, {
    method: 'DELETE',
  })
}

/**
 * Adds rule to promotion
 *
 * @param {string} token
 * @param {string|number} promotionId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addRule = (promotionId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${promotionId}/rule/new`
  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Updates rule configuration
 *
 * @param {string} token
 * @param {string|number} ruleId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _updateRule = (ruleId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/rule/${ruleId}`
  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Deletes rule
 *
 * @param {string} token
 * @param {string|number} ruleId
 * @returns {Promise}
 */
export const _deleteRule = (ruleId) => {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/rules/${ruleId}`
  return request(URL, {
    method: 'DELETE',
  })
}
