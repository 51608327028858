import React, { useState, useMemo } from 'react'
import useTableHeader from '../hooks/useTableHeader'
import { generateStatusList, OPENING_HOURS, Status, statusColorMap } from '../mockData'
import { generateTimeSections } from '../utils'
import TableBodyRow from './TableBodyRow'
import {
  Divider,
  InputBase,
  InputContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'

type Props = {
  isSchemeEditMode: boolean
}

const SchemeTab = ({ isSchemeEditMode }: Props) => {
  const timeSections = generateTimeSections(OPENING_HOURS)
  const tableHeader = useTableHeader()

  const initialStatusMatrix = useMemo(() => {
    return timeSections.map(() => generateStatusList(7))
  }, [timeSections])

  const [statusMatrix, setStatusMatrix] = useState(initialStatusMatrix)

  const handleStatusChange = (rowIndex: number, colIndex: number, newStatus: Status) => {
    const updatedStatusMatrix = [...statusMatrix]
    updatedStatusMatrix[rowIndex] = [...updatedStatusMatrix[rowIndex]]
    updatedStatusMatrix[rowIndex][colIndex] = { status: newStatus }
    setStatusMatrix(updatedStatusMatrix)
  }

  return (
    <div className="flex h-full flex-1 flex-col">
      {tableHeader}
      <table>
        <tbody>
          {timeSections.map(([from, to], rowIndex) => {
            const isLastRow = rowIndex === timeSections.length - 1
            return (
              <TableBodyRow key={`${from}-${to}`} from={from} to={to} isLastRow={isLastRow}>
                {statusMatrix[rowIndex].map((statusObj, colIndex) => {
                  const isFirstColumn = colIndex === 0
                  const isLastColumn = colIndex === 6
                  const statusColorClass = statusColorMap[statusObj.status]
                  const borderRightClass = isLastColumn ? 'border-r' : ''
                  const borderBottomClass = isLastRow ? 'border-b' : ''
                  const roundedBottomRightClass = isLastColumn && isLastRow ? 'rounded-br-sm' : ''

                  return (
                    <td key={colIndex} className="h-4 !w-[114px]">
                      <div
                        className={`border-Borders-stroke-clean h-full w-full ${statusColorClass} ${borderRightClass} ${borderBottomClass} ${roundedBottomRightClass}`}
                      >
                        <div
                          className={`flex h-full w-full items-center justify-center ${
                            !isLastColumn ? 'border-background-mono-lighter' : ''
                          }`}
                        >
                          {!isFirstColumn && (
                            <div className="bg-background-mono-lighter h-full w-[2px]" />
                          )}
                          <InputSelect
                            onValueChange={(newStatus: Status) =>
                              handleStatusChange(rowIndex, colIndex, newStatus)
                            }
                            value={statusObj.status}
                            disabled={!isSchemeEditMode}
                          >
                            <InputBase className={`w-full ${statusColorClass}`}>
                              <InputSelectTrigger asChild>
                                <InputContainer>
                                  <InputSelectStatefulInput
                                    openClassName="rounded-none !border-border-stroke-light shadow-lg"
                                    className={`text-content-xs text-text-dark w-full border-2 border-transparent p-2 hover:${statusColorClass} ${statusColorClass}`}
                                    value={statusObj.status}
                                  />
                                  <InputSelectTrailingContainer />
                                </InputContainer>
                              </InputSelectTrigger>
                            </InputBase>

                            <InputSelectContent className="rounded-none !shadow-lg">
                              <InputSelectContentView className={'rounded-none border-0'}>
                                {Object.values(Status).map((status) => (
                                  <InputSelectItem
                                    key={status}
                                    value={status}
                                    className="rounded-none"
                                  >
                                    <ListItem
                                      className={`h-full justify-center gap-2 rounded-none pl-2 ${statusColorMap[status]}`}
                                    >
                                      <ListItemMainContent className="justify-center rounded-none">
                                        <ListItemParagraph className="text-content-xs text-text-dark">
                                          {status}
                                        </ListItemParagraph>
                                      </ListItemMainContent>
                                    </ListItem>
                                  </InputSelectItem>
                                ))}
                              </InputSelectContentView>
                            </InputSelectContent>
                          </InputSelect>
                          {!isLastColumn && (
                            <div className="bg-background-mono-lighter flex h-full w-[3px] items-center justify-end">
                              <Divider className="h-4" orientation="vertical" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  )
                })}
              </TableBodyRow>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SchemeTab
