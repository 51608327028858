import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'

export enum HeaderOption {
  DEFAULT = 0,
  EDIT_CATEGORY = 1,
  BOOK = 2,
}

export type HeaderActions = {
  setHeader: (header: HeaderOption) => void
  reset: () => void
  isOptionVisible: (header: HeaderOption) => boolean
}

export type HeaderSettingsOption = {
  key: string
  icon: IconDefinition
  label: string
  subLabel?: string
  onClick?: () => void
  copyText?: string
}
