// @ts-expect-error Imports from non-ts modules
import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { UploadMediaObjectPayload, UploadMediaObjectResponse } from './types'

/**
 * Upload media object to cloud
 */
export const uploadMediaObject = (
  payload: UploadMediaObjectPayload
): Promise<UploadMediaObjectResponse> => {
  const url = `${getBaseUrlPlatform()}/media-objects`

  return request(url, {
    method: 'post',
    contentType: 'multipart/form-data',
    body: payload,
  })
}
