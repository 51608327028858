import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

import CloseIcon from '@sweetspot/sweetspot-js/common/components/CloseIcon'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { ReactComponent as SearchIcon } from '@sweetspot/sweetspot-js/assets/svgs/search-icon.svg'
import { ReactComponent as ProfileIcon } from '@sweetspot/sweetspot-js/assets/svgs/profile-icon.svg'

import {
  importPlayerFromFederation,
  searchPlayers,
} from '@sweetspot/sweetspot-js/features/players/services/api-platform'

import {
  to,
  isBeginningOfGolfId,
  getElementTopLeft,
  getElementDimensions,
  isElement,
  isGolfId,
} from '@sweetspot/sweetspot-js/common/functions/utils'

import useSelectedClubId from '@sweetspot/sweetspot-js/common/hooks/useSelectedClubId'
import useButtonListener from '@sweetspot/sweetspot-js/common/hooks/useButtonListener'

import { getSessionStorage, setSessionStorage } from '@sweetspot/shared/util/session-storage'

import usePrev from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { REGEXP_PATTERNS } from '@sweetspot/shared/util/constants'

const tabs = {
  ALL: 'all',
  MEMBERS: 'members',
  MANUAL: 'manual',
}

const contexts = {
  BOOKING: '360BookingTabIndex',
  PARTNER_BOOKING: 'partnerBookingTabIndex',
  VOUCHER: 'voucherPlayerTabIndex',
}

const SelectPlayerPopup = ({
  availableTabs,
  context,
  onSelectPlayer,
  visible,
  isShowAdditionalFields,
  onRequestClose,
  className,
  attachToElementRef,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const currentClubId = useSelectedClubId()
  const currentClubsAndSelectedGolfClubId = useSelector((state) => state?.golfClub)
  const [currentTab, setCurrentTab] = useState(availableTabs[0])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [query, setQuery] = useState('')
  const prevQuery = usePrev(query)
  const [stubName, setStubName] = useState('')
  const [stubNameError, setStubNameError] = useState('')
  const [stubEmail, setStubEmail] = useState('')
  const [stubEmailError, setStubEmailError] = useState('')
  const [stubPhone, setStubPhone] = useState('')
  const [stubPhoneError, setStubPhoneError] = useState('')
  const [searching, setSearching] = useState(false)
  const [results, setResults] = useState([])

  const resultsRefs = useRef([])

  const currentGolfClubUUID = useMemo(() => {
    if (currentClubsAndSelectedGolfClubId?.selectedId) {
      const { list, selectedId } = currentClubsAndSelectedGolfClubId
      return list.find((golfClub) => golfClub?.id === selectedId)?.uuid
    }

    return null
  }, [currentClubsAndSelectedGolfClubId])

  useEffect(() => {
    if (context) {
      let key = contexts[context]
      const savedTabIndex = getSessionStorage(key)

      if (availableTabs[savedTabIndex]) {
        setCurrentTab(availableTabs[savedTabIndex])
      }
    }
  }, [context, availableTabs])

  useEffect(() => {
    setQuery('')
    setStubName('')
    setStubNameError('')
    setStubEmail('')
    setStubEmailError('')
    setStubPhone('')
    setStubPhoneError('')
    setSearching(false)
    setResults([])
    setSelectedIndex(0)
  }, [visible, attachToElementRef])

  const handleOnRequestClose = (event) => {
    if (visible) {
      setQuery('')
      setStubName('')
      setStubEmail('')
      setStubPhone('')
      setSelectedIndex(0)
      onRequestClose(event)
    }
  }
  const containerRef = useRef()

  useOnClickOutside(containerRef, () => handleOnRequestClose('manual'))
  useButtonListener(['Escape'], () => handleOnRequestClose('manual'), 'keydown', {
    preventDefault: true,
    stopPropagation: true,
    capture: true,
  })

  useEffect(() => {
    if (containerRef?.current?.querySelector && visible) {
      containerRef.current.querySelector('input').focus()
    }
  }, [currentTab, visible])

  // Handle nav with arrow keys
  useEffect(() => {
    setSelectedIndex(0)
    const handleKeyPresses = (e) => {
      if (!results?.length > 0) return

      if (
        e?.keyCode === 40 ||
        e?.which === 40 ||
        e?.key === 'ArrowDown' ||
        e?.code === 'ArrowDown'
      ) {
        setSelectedIndex((prev) => {
          const newIndex = prev + 1
          if (results[newIndex]) {
            if (resultsRefs?.current?.[newIndex]?.scrollIntoView) {
              resultsRefs.current[newIndex].scrollIntoView({ behavior: 'smooth' })
            }
            return newIndex
          }
          return prev
        })
        e.preventDefault()
        e.stopPropagation()
      }
      if (e?.keyCode === 38 || e?.which === 38 || e?.key === 'ArrowUp' || e?.code === 'ArrowUp') {
        setSelectedIndex((prev) => {
          const newIndex = prev - 1
          if (results[newIndex]) {
            if (resultsRefs?.current?.[newIndex]?.scrollIntoView) {
              resultsRefs.current[newIndex].scrollIntoView({ behavior: 'smooth' })
            }
            return newIndex
          }
          return prev
        })
        e.preventDefault()
        e.stopPropagation()
      }
    }

    window.addEventListener('keydown', handleKeyPresses)

    return () => window.removeEventListener('keydown', handleKeyPresses)
  }, [results])

  const checkSearchQuery = (inputValue) => {
    if (!inputValue || inputValue.length < 3) return
    // Regex patterns
    const emailCheckPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    const golfIdCheckPattern = /^\d{6}-\d{3}$/

    // Detect what user is searching for
    const isValidNumber = isValidPhoneNumber(inputValue)
    const isValidEmail = emailCheckPattern.test(inputValue)
    const isValidGolfId = golfIdCheckPattern.test(inputValue)

    // Return endpoint props
    if (isValidNumber) return { phone: inputValue, page: 1, limit: 1 }
    if (isValidEmail) return { email: inputValue, page: 1, limit: 1 }
    if (isValidGolfId) return { golfId: inputValue, page: 1, limit: 1 }

    return { search: inputValue, page: 1 }
  }

  // Do player search
  const doSearch = useCallback(
    async (query) => {
      const isGolfIdSearch = isGolfId(query)
      let searchPlayerEndpointProps = checkSearchQuery(query)
      let res, err

      if (currentTab === tabs.ALL && isGolfIdSearch && currentGolfClubUUID) {
        ;[res, err] = await to(
          importPlayerFromFederation({
            club: currentGolfClubUUID,
            federation: 'git',
            identifier: query,
          })
        )
      } else if (currentTab === tabs.MEMBERS || !isGolfIdSearch) {
        if (currentTab === tabs.MEMBERS) {
          searchPlayerEndpointProps = {
            ...searchPlayerEndpointProps,
            'type[members]': currentClubId,
            relatedGolfClub: currentClubId,
            golfClub: currentClubId,
          }
        }
        ;[res, err] = await to(searchPlayers(searchPlayerEndpointProps))
      }

      if (res) {
        setSearching(false)
        setResults(isGolfIdSearch && currentTab === tabs.ALL ? [res] : res)
      }

      if (err) {
        addToast(t('sentences.failedToSearch'), { appearance: 'error' })
      }
    },
    [currentTab, currentClubId, t, addToast, currentGolfClubUUID]
  )
  // Debounce search
  useEffect(() => {
    let searchTimeout = null
    if (query?.length > 0) {
      setSearching(true)
      searchTimeout = setTimeout(() => {
        doSearch(query)
      }, 400)
    }
    return () => clearTimeout(searchTimeout)
  }, [query, doSearch])

  // Autoformat golf id numbers
  useEffect(() => {
    if (isBeginningOfGolfId(query) && query?.length > prevQuery?.length) {
      setQuery((prev) => prev + '-')
    }
    setStubName(query)
  }, [query, prevQuery])

  // Clear error on tab change or input value update
  useEffect(() => {
    setStubNameError('')
  }, [currentTab, stubName])

  // Clear error on tab change or input value update
  useEffect(() => {
    setStubEmailError('')
  }, [currentTab, stubEmail])

  useEffect(() => {
    setStubPhoneError('')
  }, [currentTab, stubPhone])

  // Handle selecting player
  const handleOnSelectPlayer = (player) => {
    if (currentTab === tabs.ALL || currentTab === tabs.MEMBERS) {
      onSelectPlayer({
        type: 'player',
        player,
      })
    }
    if (currentTab === tabs.MANUAL) {
      let name = typeof player === 'string' ? player : stubName

      if (!name || typeof name !== 'string') {
        setStubNameError(t('errors.required'))
        return
      }
      if (REGEXP_PATTERNS.EMOJIS.test(name)) {
        setStubNameError(t('sentences.nameCanNotContainEmojis'))
        return
      }
      if (stubEmail && !REGEXP_PATTERNS.EMAIL.test(stubEmail)) {
        setStubEmailError(t('errors.invalidEmail'))
        return
      }

      if (stubPhone && !isValidPhoneNumber(stubPhone)) {
        setStubPhoneError(t('errors.invalidPhoneNumber'))
        return
      }

      onSelectPlayer({
        type: 'stub',
        player: {
          name: name,
          email: stubEmail,
          phone: stubPhone,
        },
      })
    }
    handleOnRequestClose('selected')
  }

  // Handle on enter press
  const handleOnEnterSelect = () => {
    if (searching || !results?.length > 0 || !results[selectedIndex]) return
    handleOnSelectPlayer(results[selectedIndex])
  }

  const handleChangeTab = (tab) => {
    if (context) {
      let key = contexts[context]
      setSessionStorage(key, availableTabs.indexOf(tab))
    }
    setCurrentTab(tab)
  }

  // Make styles
  const getStyles = () => {
    let stylesObject = {}

    if (isElement(attachToElementRef) || isElement(attachToElementRef?.current)) {
      const el = isElement(attachToElementRef)
        ? attachToElementRef
        : isElement(attachToElementRef.current)
        ? attachToElementRef.current
        : null
      const { top, left } = getElementTopLeft(el)
      const { height } = getElementDimensions(el)
      const { innerHeight: windowHeight } = window

      let topPx = 0
      let popupBottomBorder = 0
      let bottomPx = 0

      topPx = top + height + 3
      popupBottomBorder = topPx + 421

      if (popupBottomBorder > windowHeight) {
        topPx = topPx - (popupBottomBorder - windowHeight) - 5
      }

      stylesObject = {
        ...stylesObject,
        top: topPx || null,
        bottom: bottomPx || null,
        left: left || 0,
      }
    }

    return stylesObject
  }

  const renderPopup = () => (
    <div
      ref={containerRef}
      className={cx(
        styles.container,
        {
          [styles.active]: visible,
          [styles.isFixed]: !!attachToElementRef,
        },
        className
      )}
      style={getStyles()}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon className={cx(styles.closeIcon)} onClick={() => handleOnRequestClose('manual')} />
      <div className={cx(styles.header)}>
        {currentTab === tabs.ALL && (
          <React.Fragment>
            <div className={cx(styles.iconTitleContainer)}>
              <SearchIcon className={cx(styles.headerIcon)} />
              <p className={cx(styles.headerTitle)}>
                {t('sentences.searchPlayersByNamePhoneOrGolfId')}
              </p>
            </div>
            <div className={cx(styles.inputAndButtonWrapper)}>
              <TextInputField
                onChange={(value) => setQuery(value)}
                value={query}
                type="text"
                containerClassName={cx(styles.input)}
                placeholder={t('sentences.typeToSearch')}
                onEnter={!searching ? handleOnEnterSelect : null}
              />
            </div>
          </React.Fragment>
        )}
        {currentTab === tabs.MEMBERS && (
          <React.Fragment>
            <div className={cx(styles.iconTitleContainer)}>
              <SearchIcon className={cx(styles.headerIcon)} />
              <p className={cx(styles.headerTitle)}>
                {t('sentences.searchPlayersByNamePhoneOrGolfId')}
              </p>
            </div>
            <div className={cx(styles.inputAndButtonWrapper)}>
              <TextInputField
                onChange={(value) => setQuery(value)}
                value={query}
                type="text"
                containerClassName={cx(styles.input)}
                placeholder={t('sentences.typeToSearch')}
                onEnter={!searching ? handleOnEnterSelect : null}
              />
            </div>
          </React.Fragment>
        )}
        {currentTab === tabs.MANUAL && (
          <React.Fragment>
            <div className={cx(styles.iconTitleContainer)}>
              <ProfileIcon className={cx(styles.headerIcon)} />
              <p className={cx(styles.headerTitle)}>{t('sentences.typeNameOfPlayer')}</p>
            </div>
            <div className={cx(styles.inputAndButtonWrapper, styles.manual)}>
              <TextInputField
                onChange={(value) => setStubName(value)}
                value={stubName}
                type="text"
                containerClassName={cx(styles.input)}
                placeholder={t('words.name')}
                error={stubNameError}
                onEnter={(val) => handleOnSelectPlayer(val)}
              />
              {isShowAdditionalFields && (
                <TextInputField
                  onChange={(value) => setStubEmail(value)}
                  value={stubEmail}
                  type="text"
                  containerClassName={cx(styles.input)}
                  placeholder={t('words.email')}
                  error={stubEmailError}
                  onEnter={(val) => handleOnSelectPlayer(val)}
                />
              )}
              {isShowAdditionalFields && (
                <TextInputField
                  onChange={(value) => setStubPhone(value)}
                  value={stubPhone}
                  type="phone"
                  containerClassName={cx(styles.input)}
                  placeholder={t('words.phone')}
                  error={stubPhoneError}
                  onEnter={(val) => handleOnSelectPlayer(val)}
                />
              )}

              <Button
                width="25%"
                className={cx(styles.button)}
                size="larger"
                text={t('words.add')}
                theme="gray-outline"
                onClick={() => handleOnSelectPlayer(null)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={cx(styles.tabsContainer)}>
        {availableTabs.map((tab) => (
          <div
            key={tab}
            className={cx(styles.tab, { [styles.active]: tab === currentTab })}
            onClick={() => handleChangeTab(tab)}
          >
            <p className={cx(styles.tabTitle)}>
              {tab === tabs.ALL && t('sentences.allPlayers')}
              {tab === tabs.MEMBERS && t('words.members')}
              {tab === tabs.MANUAL && t('sentences.addManually')}
            </p>
            <div className={cx(styles.activeIndicator)}></div>
          </div>
        ))}
      </div>
      <div className={cx(styles.results)}>
        {currentTab === tabs.ALL || currentTab === tabs.MEMBERS
          ? (() => {
              if (!query) return null
              if (searching) {
                return (
                  <div className={cx(styles.searchingContainer)}>
                    <PulseLoader showIf={true} fillHeight={true} />
                  </div>
                )
              }

              if (results && results?.length <= 0) {
                return (
                  <div className={cx(styles.noResultsContainer)}>
                    <p>{t('sentences.noResults')}</p>
                  </div>
                )
              }

              if (results && results?.length > 0) {
                return (
                  <div className={cx(styles.resultsWrapper)}>
                    {results.map((player, index) => {
                      const { first_name: firstName, last_name: lastName, golf_id: golfId } = player
                      return (
                        <div
                          ref={(ref) => (resultsRefs.current[index] = ref)}
                          key={player.id + '-' + currentTab}
                          className={cx(styles.playerRow, {
                            [styles.selectedElement]: index === selectedIndex,
                          })}
                        >
                          <div className={cx(styles.infoWrapper)}>
                            <p className={cx(styles.firstLastName)}>
                              {firstName || ''} {lastName || ''}
                            </p>
                            <p className={cx(styles.golfId)}>{golfId || ''}</p>
                          </div>
                          <Button
                            width="quarter"
                            className={cx(styles.playerAddButton)}
                            text={t('words.add')}
                            onClick={() => handleOnSelectPlayer(player)}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              }

              return null
            })()
          : null}
      </div>
    </div>
  )

  if (attachToElementRef) {
    return <Portal id="select-player-popup">{renderPopup()}</Portal>
  }
  return renderPopup()
}

SelectPlayerPopup.propTypes = {
  availableTabs: PropTypes.arrayOf(PropTypes.oneOf(['all', 'members', 'manual'])),
  context: PropTypes.string,
  onSelectPlayer: PropTypes.func,
  visible: PropTypes.bool,
  isShowAdditionalFields: PropTypes.bool,
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  attachToElementRef: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}

SelectPlayerPopup.defaultProps = {
  availableTabs: ['all'],
  visible: true,
  isShowAdditionalFields: false,
  attachToElementRef: null,
}

export default SelectPlayerPopup
