import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'

import {
  createTeeTimeCategory,
  getTeeTimeCategory,
  updateTeeTimeCategory,
  getReservationPolicy,
  createReservationPolicy,
  updateReservationPolicy,
  removeReservationPolicy,
} from '@sweetspot/club-portal-legacy/store/actions'

import General from './components/General'
import BookingPolicies from './components/ReservationPolicy/BookingPolicies'

import { POLICY_CHECKERS, TABS } from './constants'

import style from './style.module.scss'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { queryMemberships } from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryClient } from '@sweetspot/club-portal-legacy/elements/Root/react-query-wrapper'

const defaultForm = {
  name: '',
  description: '',
  color: '',
  displaySetting: 'default',
  useSameName: true,
  displayName: '',
  displayDesc: '',
}

class CategoryModal extends Component {
  state = {
    categoryId: 0,
    isLoading: true,
    tab: TABS.GENERAL,
    category: null,
    form: defaultForm,
    creatorPolicies: [],
    participantPolicies: [],
  }

  TRANSLATIONS = {
    createCategory: GetTranslatedString(this.props.lang, 'teeTimeCategory.createCategory'),
    general: GetTranslatedString(this.props.lang, 'teeTimeCategory.general'),
    reservePolicy: GetTranslatedString(this.props.lang, 'teeTimeCategory.reservePolicy'),
    participantPolicy: GetTranslatedString(this.props.lang, 'teeTimeCategory.participantPolicy'),
  }

  TABS = [
    { id: TABS.GENERAL, label: this.TRANSLATIONS.general, stepNumber: TABS.GENERAL },
    {
      id: TABS.RESERVATION_POLICY,
      label: this.TRANSLATIONS.reservePolicy,
      stepNumber: TABS.RESERVATION_POLICY,
    },
    {
      id: TABS.PARTICIPANT_POLICY,
      label: this.TRANSLATIONS.participantPolicy,
      stepNumber: TABS.PARTICIPANT_POLICY,
    },
  ]

  componentDidMount() {
    let categoryId = this.props.match.params.id
    if (categoryId === 'new') categoryId = 0

    this.setState({ categoryId, isLoading: true })
    if (categoryId) this.getCategory(categoryId)
    else this.setState({ isLoading: false })
  }

  getCategory = (categoryId) => {
    const { token, dispatch } = this.props

    // this.setState({ isLoading: true });
    dispatch(getTeeTimeCategory(token, categoryId))
      .then((res) => {
        this.getPolicies(res?.booking_creator_reservation_policy.uuid, true)
        this.getPolicies(res?.participant_reservation_policy.uuid, false)

        this.setState({
          categoryId,
          isLoading: false,
          category: res,
          form: {
            name: res?.name || '',
            description: res?.description || '',
            color: res?.color || '',
            displaySetting: res?.display || 'default',
            useSameName: res?.is_use_same_name,
            displayName: res?.is_use_same_name ? res?.name : res?.custom_name,
            displayDesc: res?.is_use_same_name ? res?.description : res?.custom_description,
          },
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          form: defaultForm,
        })
      })
  }

  getPolicies = async (policyUuid, isBookingCreator) => {
    const { token, dispatch, clubId } = this.props

    const [memberships, membershipsErr] = await to(
      queryClient.fetchQuery(
        [CLUB_QUERIES.MEMBERSHIPS, { page: 1, limit: 199, 'club.id': clubId }],
        () => queryMemberships({ 'club.id': clubId, page: 1, limit: 199 })
      )
    )

    dispatch(getReservationPolicy(token, policyUuid))
      .then((res) => {
        if (!memberships || membershipsErr)
          throw new Error('Could not fetch memberships', { cause: membershipsErr })

        const policies = res.checkers.map((checker) => {
          if (checker.type === POLICY_CHECKERS.USER_TYPE) {
            return {
              id: checker.uuid,
              userTypes: Object.keys(checker.configuration).map((key) => ({
                type: key.toUpperCase(),
                value: checker.configuration[key]
                  .filter((item) => !!memberships.find((membership) => membership.uuid === item))
                  .map((item) => {
                    const membership = memberships.find((membership) => membership.uuid === item)

                    return { id: membership.id, label: membership.name, value: membership.uuid }
                  }),
              })),
            }
          } else if (checker.type === POLICY_CHECKERS.GENDER_TYPE) {
            return {
              id: checker.uuid,
              genderTypes: checker.configuration.genders,
            }
          } else if (checker.type === POLICY_CHECKERS.TOTAL_HCP) {
            return {
              id: checker.uuid,
              totalHcp: checker.configuration.total_hcp || '',
            }
          } else if (checker.type === POLICY_CHECKERS.PLAYER_HCP) {
            return {
              id: checker.uuid,
              playerHcp: checker.configuration.max_hcp_per_player || '',
            }
          } else if (checker.type === POLICY_CHECKERS.BOOKING_WINDOW) {
            return {
              id: checker.uuid,
              bookingWindow: checker.configuration || [],
            }
          }
        })

        this.setState({ isLoading: false })
        if (isBookingCreator) this.setState({ creatorPolicies: policies })
        else this.setState({ participantPolicies: policies })
      })
      .catch(() => {
        this.setState({ isLoading: false })

        if (isBookingCreator) this.setState({ creatorPolicies: [] })
        else this.setState({ participantPolicies: [] })
      })
  }

  handleClose = () => {
    this.props.history.push('/settings/tee-time-category', { shouldReload: true })
  }

  handleChange = ({ name, value }, isUpdate = false, loader = false) => {
    const { form, category } = this.state
    this.setState(
      {
        form: {
          ...form,
          name: category?.name || '',
          description: category?.description || '',
          displayName: category?.is_use_same_name ? category?.name : category?.custom_name,
          displayDesc: category?.is_use_same_name
            ? category?.description
            : category?.custom_description,
          [name]: value,
        },
      },
      () => {
        if (isUpdate) {
          if (loader) this.setState({ isLoading: true })
          this.handleSave()
        }
      }
    )
  }

  handleSave = _.debounce(() => {
    const { category, form } = this.state
    const payload = {
      id: category?.id,
      name: form.name,
      description: form.description,
      color: form.color,
      is_use_same_name: form.useSameName,
      custom_name: form.displayName,
      custom_description: form.displayDesc,
      display: form.displaySetting,
    }
    this.handleUpdate(payload)
  }, 200)

  handleCancel = () => {
    const { category } = this.state

    this.setState({
      form: {
        name: category?.name || '',
        description: category?.description || '',
        color: category?.color || '',
        displaySetting: category?.display || 'default',
        useSameName: category ? category.is_use_same_name : true,
        displayName: category?.is_use_same_name ? category?.name : category?.custom_name,
        displayDesc: category?.is_use_same_name
          ? category?.description
          : category?.custom_description,
      },
    })
  }

  handleCreate = () => {
    const { form } = this.state
    const { token, dispatch, clubId, history } = this.props

    const payload = {
      name: form.name,
      description: '',
      color: '#a7dec4',
      is_use_same_name: true,
      custom_name: '',
      custom_description: '',
      display: 'default',
    }

    this.setState({ isLoading: true })
    dispatch(createTeeTimeCategory(token, { ...payload, club_id: clubId }))
      .then((res) => {
        history.push(`/settings/tee-time-category/${res.id}`)
        this.getCategory(res.id)
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleUpdate = (payload, loader) => {
    const { token, dispatch, clubId } = this.props

    if (loader) {
      this.setState({ isLoading: true })
    }
    dispatch(updateTeeTimeCategory(token, { ...payload, club_id: clubId }))
      .then((res) => {
        this.setState({
          isLoading: false,
          category: res,
          form: {
            name: res?.name || '',
            description: res?.description || '',
            color: res?.color || '',
            displaySetting: res?.display || 'default',
            useSameName: res?.is_use_same_name,
            displayName: res?.is_use_same_name ? res?.name : res?.custom_name,
            displayDesc: res?.is_use_same_name ? res?.description : res?.custom_description,
          },
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleCreatePolicy = (payload, isParticipant = false) => {
    const { category } = this.state
    const { token, dispatch } = this.props

    let policyUuid = category?.booking_creator_reservation_policy?.uuid
    if (isParticipant) policyUuid = category?.participant_reservation_policy?.uuid
    if (!policyUuid || !payload) return

    this.setState({ isLoading: true })
    dispatch(createReservationPolicy(token, policyUuid, payload))
      .then(() => {
        if (isParticipant) policyUuid = category?.participant_reservation_policy?.uuid

        this.getPolicies(policyUuid, !isParticipant)
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleUpdatePolicy = (policyId, payload, isParticipant) => {
    const { category } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(updateReservationPolicy(token, policyId, payload))
      .then(() => {
        let policyUuid = category?.booking_creator_reservation_policy?.uuid
        if (isParticipant) policyUuid = category?.participant_reservation_policy?.uuid

        this.getPolicies(policyUuid, !isParticipant)
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleRemovePolicy = (id, isParticipant) => {
    const { category } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(removeReservationPolicy(token, id))
      .then(() => {
        let policyUuid = category?.booking_creator_reservation_policy?.uuid
        if (isParticipant) policyUuid = category?.participant_reservation_policy?.uuid

        this.getPolicies(policyUuid, !isParticipant)
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleTabChange = (tab) => {
    this.setState({ tab })
  }

  renderHeader = () => {
    const { form } = this.state

    if (!form?.name) {
      return <div className={style.header}>{this.TRANSLATIONS.createCategory}</div>
    }
    return <div className={style.header}>{form?.name}</div>
  }

  renderContent = () => {
    const { categoryId, tab, isLoading, category, form, creatorPolicies, participantPolicies } =
      this.state

    return (
      <div className={style.content}>
        <div className={style.tabs}>
          <SimpleTabs
            disabled={!category}
            items={this.TABS}
            currentItem={tab}
            onClick={this.handleTabChange}
          />
        </div>
        {tab === TABS.GENERAL && (
          <General
            isLoading={isLoading}
            category={category}
            form={form}
            onCancel={this.handleCancel}
            onChange={this.handleChange}
            onCreate={this.handleCreate}
            onUpdate={this.handleUpdate}
          />
        )}
        {tab === TABS.RESERVATION_POLICY && (
          <BookingPolicies
            isBookingCreator={true}
            categoryId={categoryId}
            policies={creatorPolicies}
            onCreate={this.handleCreatePolicy}
            onUpdate={this.handleUpdatePolicy}
            onRemove={this.handleRemovePolicy}
            loading={isLoading && tab !== TABS.GENERAL}
          />
        )}
        {tab === TABS.PARTICIPANT_POLICY && (
          <BookingPolicies
            isBookingCreator={false}
            categoryId={categoryId}
            policies={participantPolicies}
            onCreate={this.handleCreatePolicy}
            onUpdate={this.handleUpdatePolicy}
            onRemove={this.handleRemovePolicy}
            loading={isLoading && tab !== TABS.GENERAL}
          />
        )}
      </div>
    )
  }

  render = () => {
    const { tab, isLoading } = this.state

    return (
      <div className={style.container}>
        <ModalContainer
          theme="webApp"
          width="half"
          headerBackgroundColor="#4F5B6D"
          headerColor="#fff"
          headerChildren={this.renderHeader()}
          onClose={this.handleClose}
          loading={isLoading && tab === TABS.GENERAL}
        >
          {this.renderContent()}
        </ModalContainer>
      </div>
    )
  }
}

CategoryModal.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  clubId: PropTypes.number.isRequired,
  memberships: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  memberships: state.membership.list,
  lang: state.auth.me.lang,
  clubId: state.golfClub.selectedId,
})

export default connect(mapStateToProps)(CategoryModal)
