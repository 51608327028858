import {
  convertTimeToTimeZone,
  localizedDaysOfWeek,
} from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { leftpad } from '@sweetspot/club-portal-legacy/utils/date'
import { getHoursMinsFromISOString } from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { differenceInDays, format, isDate } from 'date-fns'
import { toDateObject } from './utils'

const DAYS_OF_WEEK = localizedDaysOfWeek('en', 0)
const MONDAY_TO_SUNDAY = localizedDaysOfWeek('en', 1)
const FULL_WEEK_LENGTH = 7

const incrementDateByOneDay = (date) => {
  const newDate = toDateObject(date)
  newDate.setDate(newDate.getDate() + 1)
  return newDate
}

const generateTableData = (options) => {
  const { timePeriod, selectedWeek, space, currency, amount = null } = options
  if (selectedWeek.id !== -1) {
    return generateTableDataFromDB(options)
  }
  const daysMap = generateDaysMap(options)
  const timeSlots = generateTimeSlots(timePeriod)

  const ALL_TABLE_DATA = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')

  if (ALL_TABLE_DATA[timePeriod.id]?.[-1]?.[space]) {
    const data = ALL_TABLE_DATA[timePeriod.id][-1][space]
    return { data, timeSlots, daysMap }
  }

  let startDate = new Date(selectedWeek.fromDate)
  let endDate = new Date(selectedWeek.toDate)

  /* Defining variables for full Week */
  const monday = new Date(startDate)
  monday.setDate(monday.getDate() - monday.getDay() + 1)
  const sunday = new Date(endDate)
  sunday.setDate(sunday.getDate() + (FULL_WEEK_LENGTH - (sunday.getDay() % FULL_WEEK_LENGTH)))

  if (selectedWeek.id === -1) {
    startDate = monday
    endDate = sunday
  }

  const data = {}

  const [startHours, startMins] = timePeriod.start_time_from.split('T')[1].split(':')

  const currentDate = new Date(startDate)
  currentDate.setHours(+startHours)
  currentDate.setMinutes(+startMins)

  const { interval } = timePeriod
  while (!data.Monday || currentDate.getDay() !== 1) {
    const dayOfWeek = DAYS_OF_WEEK[currentDate.getDay()]
    data[dayOfWeek] = data[dayOfWeek] || []
    while (data[dayOfWeek].length < timeSlots.length) {
      let hours = leftpad(currentDate.getHours())
      let mins = leftpad(currentDate.getMinutes())
      const from = `${hours}:${mins}`
      currentDate.setMinutes(+mins + interval)
      hours = leftpad(currentDate.getHours())
      mins = leftpad(currentDate.getMinutes())
      const to = `${hours}:${mins}`

      const timeSlot = {
        from,
        to,
        price: {
          amount,
          currency,
        },
        price_per_extra_player: 0,
      }

      data[dayOfWeek].push(timeSlot)
    }
    currentDate.setDate(currentDate.getDate() + 1)
    currentDate.setHours(+startHours)
    currentDate.setMinutes(+startMins)
  }
  return { timeSlots, data, daysMap }
}

export default generateTableData

const generateTimeSlots = (timePeriod) => {
  const { start_time_from, start_time_to, interval } = timePeriod

  const startTimeFrom = new Date(start_time_from)
  const startTimeTo = new Date(start_time_to)
  const timeSlots = []

  const currentDate = new Date(startTimeFrom)
  while (currentDate <= startTimeTo) {
    const [hours, mins] = getHoursMinsFromISOString(currentDate.toISOString())
    const slot = `${hours}:${mins}`
    timeSlots.push(slot)
    currentDate.setMinutes(currentDate.getMinutes() + interval)
  }
  return timeSlots
}

const generateTableDataFromDB = (options) => {
  const ALL_TABLE_DATA = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')
  const { timePeriod, teeTimes, space, selectedWeek, timezone } = options
  const { id } = timePeriod

  const daysMap = generateDaysMap(options)
  const timeSlots = generateTimeSlots(timePeriod)

  if (ALL_TABLE_DATA[id]?.[selectedWeek.id]?.[space]) {
    const data = ALL_TABLE_DATA[id][selectedWeek.id][space]
    return { timeSlots, daysMap, data }
  }

  const data = {}
  const teeTimesCopy = [...teeTimes]
  let currentIndex = 0

  for (let i = 0; i < FULL_WEEK_LENGTH; i++) {
    const dayOfWeek = MONDAY_TO_SUNDAY[i]
    if (!(dayOfWeek in daysMap)) {
      continue
    }

    const teeTimesWithNoOffset = teeTimesCopy.map((teeTime) => {
      const elementWithNoOffSet = { ...teeTime }
      elementWithNoOffSet.from = convertTimeToTimeZone(teeTime.from, timezone)
      elementWithNoOffSet.to = convertTimeToTimeZone(teeTime.to, timezone)
      return elementWithNoOffSet
    })

    let dayTimeSlots = teeTimesWithNoOffset.slice(currentIndex, currentIndex + timeSlots.length)
    currentIndex += timeSlots.length

    if (dayTimeSlots.length > 0) {
      data[dayOfWeek] = dayTimeSlots
    }

    dayTimeSlots = []
  }
  return { data, timeSlots, daysMap }
}

const generateDaysMap = ({ selectedWeek }) => {
  const daysMap = {}
  const { fromDate: startDate, toDate: finalDate } = selectedWeek
  const startWeekDay = isDate(startDate) ? startDate.getDay() : new Date(startDate).getDay()
  const finalWeekDay = isDate(finalDate) ? finalDate.getDay() : new Date(finalDate).getDay()

  if (differenceInDays(finalDate, startDate) < FULL_WEEK_LENGTH - 1) {
    //WEEKLY_PERIOD_SET for a partial week
    //Populating the object daysMap with key-value pairs regarding a non-complete week, starting on "${startWeekDay}" and ending on a "${finalWeekDay}"
    if (startWeekDay <= finalWeekDay) {
      for (let i = startWeekDay; i <= finalWeekDay; i++) {
        daysMap[DAYS_OF_WEEK[toDateObject(startDate).getDay()]] = format(
          toDateObject(startDate),
          'yyyy-MM-dd'
        )
        incrementDateByOneDay(startDate)
      }
    } else {
      //Handle the case where the week starts on Saturday and ends on Sunday
      for (let i = startWeekDay - 1; i <= FULL_WEEK_LENGTH - 1; i++) {
        daysMap[DAYS_OF_WEEK[toDateObject(startDate).getDay()]] = format(
          toDateObject(startDate),
          'yyyy-MM-dd'
        )
        incrementDateByOneDay(startDate)
      }
    }
  } else {
    //Independent if it is an initial ALL_PERIOD_SET or if it is just a WEEKLY_PERIOD_SET for a complete week (not partial week)
    //Populating the object daysMap with key-value pairs regarding a complete week, starting on a Monday and ending on a Sunday
    for (let i = 0; i <= FULL_WEEK_LENGTH - 1; i++) {
      daysMap[DAYS_OF_WEEK[toDateObject(startDate).getDay()]] = format(
        toDateObject(startDate),
        'yyyy-MM-dd'
      )
      incrementDateByOneDay(startDate)
    }
  }
  return daysMap
}
