// Function to find the earliest open hour and the latest close hour
function findTimeRange(openingHours) {
  let earliestOpen = '23:59'
  let latestClose = '00:00'

  for (const day in openingHours) {
    if (openingHours[day].open < earliestOpen) {
      earliestOpen = openingHours[day].open
    }
    if (openingHours[day].close > latestClose) {
      latestClose = openingHours[day].close
    }
  }

  return { earliestOpen, latestClose }
}

// Function to format time in HH:MM format
function formatTime(hour: number, minute: number) {
  return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

// Function to generate one-hour sections between the earliest open and latest close hours
export function generateTimeSections(openingHours) {
  const { earliestOpen, latestClose } = findTimeRange(openingHours)

  const startHour = parseInt(earliestOpen.split(':')[0])
  const endHour = parseInt(latestClose.split(':')[0])

  const timeSections = []

  for (let hour = startHour; hour < endHour; hour++) {
    const start = formatTime(hour, 0)
    const end = formatTime(hour, 59)
    timeSections.push([start, end])
  }

  return timeSections
}
