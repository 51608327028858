import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import style from './style.module.scss'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'

import headers from './headers.json'
import { getMemberships } from '@sweetspot/club-portal-legacy/store/actions'
import MembershipWizardModal from '@sweetspot/club-portal-legacy/modals/MembershipWizardModal'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

const actions = [
  {
    id: 1,
    label: 'membership.addMembership',
    action: 'createNewMembership',
  },
]

const filters = [
  {
    id: 1,
    name: '.status',
    options: [
      { id: 1, name: '.active', filter: 'ACTIVE' },
      { id: 2, name: '.inActive', filter: 'INACTIVE' },
      { id: 3, name: '.draft', filter: 'DRAFT' },
    ],
  },
]

class MembershipGrid extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeFilters: ['ACTIVE'],
      sortItems: [],
      isLoading: false,
      searchString: '',
      currentPage: null,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleActionConfirm = this.handleActionConfirm.bind(this)
    this.getMembershipList = this.getMembershipList.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.props.dispatch({
      type: CONSTANTS.MEMBERSHIP.SET_LIST,
      payload: [],
    })
    this.props.dispatch({
      type: CONSTANTS.MEMBERSHIP.SET_TOTAL_LIST,
      payload: [],
    })
    this.props.dispatch({
      type: CONSTANTS.MEMBERSHIP.SET_TOTAL_PAGES,
      payload: { totalItems: 0, itemsPerPage: 50 },
    })
    this.getMembershipList()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { state } = prevProps.location

    if (this.props.golfClubs.selectedId !== prevProps.golfClubs.selectedId) {
      this.getMembershipList(this.state.currentPage || 1)
    } else if (location?.state?.shouldReload && !state) {
      this.getMembershipList(this.state.currentPage || 1)
    }
  }

  handleSearch(value = '') {
    this.setState({ searchString: value })
  }

  handleActionConfirm(action) {
    if (action.id === 1) this.onCreateNewMembership()
  }

  getMembershipList(pageNum = 1, searchQuery) {
    const { searchString, sortItems, activeFilters } = this.state
    const {
      golfClubs: { selectedId },
      getMemberships,
    } = this.props
    let query = `?page=${pageNum}&club.id=${selectedId}`
    sortItems.forEach((sort) => {
      query += `&order[${sort.name === 'name' ? 'name.sort' : sort.name}]=${sort.type}`
    })
    if (searchString) query += `&name=${searchString}`
    query += this.getFilterQuery(activeFilters)

    if (searchQuery) query += searchQuery

    getMemberships(query).then(() => {
      this.setState({ isLoading: false, currentPage: pageNum })
    })
  }

  onCreateNewMembership = () => {
    this.props.history.push('/memberships/new')
  }

  onEditMembership = (row) => {
    const { _id } = row
    this.props.history.push(`/memberships/${_id}`)
  }

  handleSort = (sortItems) => {
    this.setState({ sortItems }, () => this.getMembershipList(this.state.currentPage))
  }

  handleFilters = (filter) => {
    if (!filter) {
      this.setState({ activeFilters: [], searchString: '' }, () => this.getMembershipList(1))
      return null
    }
    let activeFilters = [...this.state.activeFilters]

    if (!activeFilters.includes(filter)) {
      activeFilters.push(filter)
    } else activeFilters.splice(activeFilters.indexOf(filter), 1)
    this.setState({ activeFilters }, () => {
      this.getMembershipList(1)
    })
  }

  getFilterQuery = (filter) => {
    if (!filter) {
      return
    }
    let queryString = ''
    filter.forEach((filter) => {
      switch (filter) {
        case 'ACTIVE':
          queryString += `&isActive[]=true&state[]=published`
          break
        case 'INACTIVE':
          queryString += `&isActive[]=false&state[]=published`
          break
        case 'DRAFT':
          queryString += `&state[]=draft`
          break
        default:
          break
      }
    })
    return queryString
  }

  render() {
    const { isLoading } = this.state
    const { memberships, token, totalPages, itemsPerPage, totalItems } = this.props
    const { path } = this.props.match

    return (
      <div className={style.content}>
        <Grid
          filterStyles={'w-auto'}
          activeFilters={this.state.activeFilters}
          handleFilters={this.handleFilters}
          filters={filters}
          filtersEnabled
          filtersTitle={'membership.membershipFilter'}
          actions={actions}
          actionsEnabled
          isLoading={isLoading}
          values={memberships}
          pageTitle={'membership.memberships'}
          headers={headers}
          searchEnabled
          searchPlaceholder={'.membership.search'}
          pagination
          token={token}
          totalPages={totalPages}
          rowsPerPage={itemsPerPage}
          totalRows={totalItems}
          displayRangeOfRows
          onRowClick={this.onEditMembership}
          setCurrentPage={this.getMembershipList}
          onActionConfirm={this.handleActionConfirm}
          onSearchChange={this.handleSearch}
          onSearch={() => this.getMembershipList()}
          onHeaderClick={this.handleSort}
        />
        <Route path={`${path}/:membershipId`} component={MembershipWizardModal} />
      </div>
    )
  }
}

MembershipGrid.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
  memberships: PropTypes.array.isRequired,
  totalPages: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  getMemberships: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  golfClubs: state.golfClub,
  memberships: state.membership.list,
  totalPages: state.membership.totalPages,
  itemsPerPage: state.membership.itemsPerPage,
  totalItems: state.membership.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
  getMemberships: (query) => dispatch(getMemberships(query)),
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipGrid)
