import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export function getCouponList(voucherID, query = '') {
  const URL = `${getBaseUrlPlatform()}/promotions/${voucherID}/coupons${query}`

  return request(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
    },
  })
}

export function getVoucherByID(voucherID) {
  const URL = `${getBaseUrlPlatform()}/promotions/${voucherID}`

  return request(URL, {
    method: 'GET',
  })
}

export function createVoucher(clubID, payload) {
  const URL = `${getBaseUrlPlatform()}/club/${clubID}/promotion/voucher/new`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function updateVoucher(voucherID, payload) {
  const URL = `${getBaseUrlPlatform()}/promotions/${voucherID}`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function getVouchers(query) {
  const URL = `${getBaseUrlPlatform()}/promotions${query}&coupon_based=true`

  return request(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
    },
  })
}

export function deleteVoucher(voucherID) {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${voucherID}`

  return request(URL, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
    },
  })
}

export function invalidateVoucher(voucherID) {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${voucherID}/cancel`

  return request(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
    },
    body: {},
  })
}

export function getPlayerPromotionCoupon(query) {
  const URL = `${getBaseUrlPlatform()}/player-promotion-coupons${query}`

  return request(URL, {
    method: 'GET',
  })
}

export function assignCodeToPlayer(playerID, payload) {
  const URL = `${getBaseUrlPlatform()}/crm/player/${playerID}/coupon/assign`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function cancelUniqueVoucher(voucherID) {
  const URL = `${getBaseUrlPlatform()}/crm/promotion-coupons/${voucherID}/cancel`

  return request(URL, {
    method: 'PUT',
    body: {},
  })
}

export function removeCode(codeID) {
  const URL = `${getBaseUrlPlatform()}/promotion-coupons/${codeID}`

  return request(URL, {
    method: 'DELETE',
  })
}

export function generateCode(clubID) {
  const URL = `${getBaseUrlPlatform()}/club/${clubID}/coupon/code/generate`

  return request(URL, {
    method: 'PUT',
    body: {},
  })
}

export function generateBulkCode(voucherID, number) {
  const URL = `${getBaseUrlPlatform()}/crm/promotions/${voucherID}/coupon/new`

  return request(URL, {
    method: 'PUT',
    body: { number },
  })
}
