import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-row border-b-[1px] px-6 py-2'}>
      <p className={'flex-1 text-lg font-bold'}>
        {t('words.settings')}: {t('sentences.ballPricing')}
      </p>
    </div>
  )
}

export default Header
