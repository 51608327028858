import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import * as API_COOPERATIONS from '@sweetspot/sweetspot-js/features/cooperations/services/api-platform'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { parseError } from '@sweetspot/sweetspot-js/common/functions/parse'
import { filterCooperationsByID } from '@sweetspot/sweetspot-js/features/cooperations/tools/data'

export const ACTIONS = {
  TOGGLE_ALL_MEMBERS: 'TOGGLE_ALL_MEMBERS',
  ADD_MEMBERSHIPS: 'ADD_MEMBERSHIPS',
  REMOVE_MEMBERSHIPS: 'REMOVE_MEMBERSHIPS',
  CONFIRM_REQUEST: 'CONFIRM_REQUEST',
  DENY_REQUEST: 'DENY_REQUEST',
  CANCEL_COOPERATION: 'CANCEL_COOPERATION',
}

export const STATE = {
  NEW: 'new',
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  CONFIRMED: 'confirmed',
  CANCELED: 'canceled',
}

// Initially fetches cooperations for ALL clubs, because /api/cooperations/ only filters by host_club.id
const useCooperations = (
  currentClubId,
  selectedStates = [STATE.NEW, STATE.AWAITING_CONFIRMATION, STATE.CONFIRMED],
  withMembershipData = false,
  currentPage = null,
  pageLimit = 999
) => {
  const { t } = useTranslation()
  const [isCreatingCooperation, setIsCreatingCooperation] = useState(false)
  const [isUpdatingCooperation, setIsUpdatingCooperation] = useState(false)
  const [error, setError] = useState(null)

  const {
    data: cooperations,
    isFetching: isFetchingCooperations,
    isFetchingNextPage,
    isFetchingPreviousPage,
    error: queryError,
    refetch,
  } = useInfiniteQuery(
    [
      CLUB_QUERIES.COOPERATIONS,
      {
        club: currentClubId,
        'state[]': selectedStates,
        withMembershipsData: withMembershipData,
        page: currentPage,
        limit: pageLimit,
      },
    ],
    () =>
      API_COOPERATIONS.fetchCooperationsList({
        'state[]': selectedStates,
        page: currentPage,
        limit: pageLimit,
      }).then((data) => {
        return withMembershipData
          ? Promise.all(data.map((i) => API_COOPERATIONS.fetchCooperation(i.uuid)))
          : data
      }),
    {
      enabled: !!currentClubId,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length < pageLimit) return undefined
        return pages?.length + 1
      },
      select: ({ pages }) => {
        return filterCooperationsByID(pages[currentPage ? currentPage - 1 : 0], currentClubId).map(
          (item) => {
            const isHost = item.host_club.id === currentClubId
            // Set manually status NEW for incoming requests
            item = {
              ...item,
              state: item.state === STATE.AWAITING_CONFIRMATION && !isHost ? STATE.NEW : item.state,
            }
            return {
              ...item,
              name: item[`${isHost ? 'partner' : 'host'}_club`].name,
              status: {
                text: t(`cooperations.state_${item.state}`),
                state: item.state === 'new' ? 'new' : null,
              },
              _isHost: isHost,
            }
          }
        )
      },
    }
  )

  const createCooperation = async (partnerClubID) => {
    try {
      setIsCreatingCooperation(true)
      const cooperation = await API_COOPERATIONS.createCooperation(currentClubId, partnerClubID)
      refetch().then()
      return { ...cooperation, _isHost: true }
    } catch (e) {
      setError(parseError(e))
    } finally {
      setIsCreatingCooperation(false)
    }
  }

  const fetchCooperation = async (cooperationRow) => {
    try {
      return await API_COOPERATIONS.fetchCooperation(cooperationRow.uuid)
    } catch (e) {
      setError(parseError(e))
    }
  }

  const updateCooperation = async (cooperation, actionName, value) => {
    setIsUpdatingCooperation(true)
    try {
      if (actionName === ACTIONS.TOGGLE_ALL_MEMBERS) {
        return await API_COOPERATIONS.toggleAllMembersOption(cooperation, cooperation._isHost)
      }
      if (actionName === ACTIONS.ADD_MEMBERSHIPS) {
        return await API_COOPERATIONS.addMemberships(cooperation, value, cooperation._isHost)
      }
      if (actionName === ACTIONS.REMOVE_MEMBERSHIPS) {
        return await API_COOPERATIONS.removeMemberships(cooperation, value, cooperation._isHost)
      }
      if (actionName === ACTIONS.CONFIRM_REQUEST) {
        return await API_COOPERATIONS.confirmCooperationRequest(cooperation.uuid)
      }
      if (actionName === ACTIONS.DENY_REQUEST) {
        return await API_COOPERATIONS.denyCooperationRequest(cooperation.uuid)
      }
      if (actionName === ACTIONS.CANCEL_COOPERATION) {
        return await API_COOPERATIONS.cancelCooperation(cooperation.uuid)
      }
    } catch (e) {
      setError(parseError(e))
    } finally {
      setIsUpdatingCooperation(false)
    }
  }

  return {
    cooperations: cooperations || [],
    refetch,
    error: error || queryError,
    createCooperation,
    fetchCooperation,
    updateCooperation,
    isLoading: {
      createCooperation: isCreatingCooperation,
      fetchCooperations: isFetchingCooperations || isFetchingPreviousPage || isFetchingNextPage,
      updateCooperation: isUpdatingCooperation,
    },
  }
}

export default useCooperations
