import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import style from './style.module.scss'

import hash from 'object-hash'

import SortingArrows from './components/SortingArrows'
import Utilities from '../../helpers/Utilities'
import SelectCell from './components/SelectCell'
import TimeCell from './components/TimeCell'
import InputCell from './components/InputCell'
import Checkbox from '../Checkbox'
import OrderCell from './components/OrderCell'
import Text from '../Text'
import SpaceWrapper from '../SpaceWrapper'
import Scrollbar from '../Scrollbar'

/**
 * This component creates a table with editing, sorting, filtering and both vertical and horizontal
 * scrolling
 */
export default class DropdownTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      values: this.getValuesWithUniqueID(),
      selectedRows: [],
      showVerticalScrollbar: false,
      showHorizontalScrollbar: false,
    }

    this.isAltPressed = false

    this.handleOverflow = this.handleOverflow.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckHeader = this.handleCheckHeader.bind(this)
    this.handleCheckRow = this.handleCheckRow.bind(this)
    this.scrollToVertically = this.scrollToVertically.bind(this)
    this.scrollToHorizontally = this.scrollToHorizontally.bind(this)
    this.handleWheelScroll = this.handleWheelScroll.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  /**
   * Set event listeners
   */
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    window.addEventListener('resize', this.handleOverflow)

    if (
      this.props.defaultSort &&
      Object.prototype.hasOwnProperty.call(this.props.headers, this.props.defaultSort)
    ) {
      // Sort values if has defaultSort
      this.handleSort(this.props.defaultSort, 'asc')
    }
  }

  /**
   * If values or filters change, filter and sort values
   * If selectedValues change, call onSelect prop
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !Utilities.arraysAreEqual(this.props.values, prevProps.values) ||
      !Utilities.arraysAreEqual(this.props.filters, prevProps.filters) ||
      this.props.values.length !== prevProps.values.length
    ) {
      // Get values with IDs and filter
      let newValues = this.getValuesWithUniqueID(true)
      // Get all unique IDs
      let uniqueIDs = Object.keys(newValues)
      // Only include selected rows that haven't been removed
      let newSelected = Utilities.deepClone(this.state.selectedRows).filter((id) =>
        uniqueIDs.includes(id)
      )

      this.setState(
        {
          values: newValues,
          selectedRows: newSelected,
        },
        () => {
          if (
            this.props.defaultSort &&
            Object.proptotype.hasOwnProperty.call(this.props.headers, this.props.defaultSort)
          ) {
            // Sort values if has defaultSort
            this.handleSort(this.props.defaultSort, 'up')
          }
        }
      )
    }

    if (!Utilities.arraysAreEqual(this.state.selectedRows, prevState.selectedRows)) {
      if (this.props.onSelect) this.props.onSelect(this.getSelectedValues())
    }

    this.handleOverflow()
  }

  /**
   * Remove event listeners
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
    window.removeEventListener('resize', this.handleOverflow)
  }

  /**
   * Show or hide scrollbars if needed when resizing the window
   */
  handleOverflow() {
    if (this.hasXOverflow()) {
      if (!this.state.showHorizontalScrollbar) this.setState({ showHorizontalScrollbar: true })
    } else {
      if (this.state.showHorizontalScrollbar) this.setState({ showHorizontalScrollbar: false })
    }

    if (this.hasYOverflow()) {
      if (!this.state.showVerticalScrollbar) this.setState({ showVerticalScrollbar: true })
    } else {
      if (this.state.showVerticalScrollbar) this.setState({ showVerticalScrollbar: false })
    }
  }

  /**
   * Create a unique hash for every row and save them in an object with the hash as key
   *
   * @param {Boolean} filter determines if the values should be filtered
   */
  getValuesWithUniqueID(filter = false) {
    let values = {}
    this.props.values.forEach((value) => {
      let valueHash = hash(value, { excludeKeys: (key) => key.substring(0, 1) === '_' })

      if (filter && this.props.filters) {
        for (let filter of this.props.filters) {
          if (filter && !filter(value)) return
        }
      }

      values[valueHash] = Utilities.deepClone(value)
    })

    return values
  }

  /**
   * Returns all rows
   *
   * @returns {Array} all **DropdownTable** rows
   * @public
   */
  getValues() {
    return Object.values(this.state.values)
  }

  /**
   * Returns selected rows
   *
   * @returns {Array} selected **DropdownTable** rows
   * @public
   */
  getSelectedValues() {
    return this.state.selectedRows.map((uniqueID) => {
      return this.state.values[uniqueID]
    })
  }

  handleSort = (name, sortType) => {
    const { sortItems } = this.state
    const { headers } = this.props

    const values = this.getValuesWithUniqueID(true)

    let temp = []
    if (sortItems.filter((item) => item.name === name).length) {
      if (sortType) {
        temp = sortItems.map((item) => {
          if (item.name === name) return { ...item, type: sortType }
          return item
        })
      } else {
        temp = sortItems.filter((item) => item.name !== name)
      }
    } else {
      temp = sortItems.concat({ name, type: sortType })
    }

    const tempSort = []
    Object.keys(headers).forEach((header) => {
      if (temp.some((item) => item.name === header)) {
        tempSort.push(temp.find((item) => item.name === header))
      }
    })

    this.setState({ sortItems: tempSort })

    const uniqueIDs = Object.keys(values)

    const tempValues = _.orderBy(
      Object.values(values).map((item, index) => ({
        ...item,
        uniqueID: uniqueIDs[index],
      })),
      tempSort.map((item) => item.name),
      tempSort.map((item) => item.type)
    )

    let newValues = {}
    tempValues.forEach((value) => {
      newValues[value.uniqueID] = value
      delete newValues[value.uniqueID].uniqueID
    })

    this.setState({ values: newValues })
  }

  findValueByProperty(key, value) {
    let uniqueIDs = Object.keys(this.state.values)
    let values = Object.values(this.state.values)

    let found = values.findIndex((val) => val[key] === value)
    if (found === -1) return null

    return uniqueIDs[found]
  }

  handleChange(uniqueId, key, value) {
    let type = this.props.headers[key].type

    if (type === 'order') {
      this.handleChangeOrder(uniqueId, key, value)
    } else {
      if (!value) return
      let copy = Utilities.deepClone(this.state.values)
      copy[uniqueId][key] = value
      this.setState(
        {
          values: copy,
        },
        () => {
          if (this.props.onSelect) this.props.onSelect(this.getSelectedValues())
        }
      )
    }
  }

  handleChangeOrder(uniqueId, key, value) {
    let originalValue = this.state.values[uniqueId][key]
    let valueToSwitch = this.findValueByProperty(key, value)

    if (valueToSwitch) {
      let copy = Utilities.deepClone(this.state.values)
      copy[uniqueId][key] = value
      copy[valueToSwitch][key] = originalValue
      this.setState({
        values: copy,
      })
    }
  }

  handleCheckHeader() {
    let uniqueIDs = Object.keys(this.state.values)

    if (this.state.selectedRows.length !== uniqueIDs.length) {
      this.setState({
        selectedRows: uniqueIDs,
      })
    } else {
      this.setState({
        selectedRows: [],
      })
    }
    if (this.props.onSelect) this.props.onSelect(this.getSelectedValues())
  }

  handleCheckRow(uniqueID) {
    let selectedRows = Utilities.deepClone(this.state.selectedRows)
    let index = selectedRows.indexOf(uniqueID)

    if (index === -1) {
      selectedRows.push(uniqueID)
    } else {
      selectedRows.splice(index, 1)
    }

    this.setState({
      selectedRows: selectedRows,
    })
    if (this.props.onSelect) this.props.onSelect(this.getSelectedValues())
  }

  getObjectKeys() {
    let keys = []
    Object.keys(this.props.headers).forEach((key) => {
      if (key.substring(0, 1) !== '_') {
        keys.push(key)
      }
    })

    return keys
  }

  getObjectValues(row) {
    let keys = this.getObjectKeys(row)
    return keys.map((key) => {
      return row[key]
    })
  }

  hasXOverflow() {
    return this._table.scrollWidth > this._table.offsetWidth
  }

  hasYOverflow() {
    return this._body.scrollHeight > this._body.offsetHeight
  }

  scrollToVertically(ratio) {
    let height = this._body.scrollHeight - this._body.offsetHeight
    this._body.scrollTo(0, height * ratio)
    if (this._checkboxContainer) {
      this._checkboxContainer.scrollTo(0, height * ratio)
    }
  }

  scrollToHorizontally(ratio) {
    let width = this._table.scrollWidth - this._table.offsetWidth
    this._table.scrollTo(width * ratio, 0)
  }

  scrollByVertically(pixels) {
    this._body.scrollBy(0, pixels)
    if (this._checkboxContainer) {
      this._checkboxContainer.scrollBy(0, pixels)
    }

    let height = this._body.scrollHeight - this._body.offsetHeight
    let scroll = this._body.scrollTop
    let ratio = height !== 0 ? scroll / height : 0

    if (this._verticalScroll) this._verticalScroll.scrollTo(0, ratio)
  }

  scrollByHorizontally(pixels) {
    this._table.scrollBy(pixels, 0)

    let width = this._table.scrollWidth - this._table.offsetWidth
    let scroll = this._table.scrollLeft
    let ratio = width !== 0 ? scroll / width : 0

    if (this._horizontalScroll) this._horizontalScroll.scrollTo(ratio, 0)
  }

  handleWheelScroll(event) {
    let delta = event.deltaY

    if (this.isAltPressed) {
      this.scrollByHorizontally(delta)
    } else {
      this.scrollByVertically(delta)
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Alt') this.isAltPressed = true
  }

  handleKeyUp(event) {
    if (event.key === 'Alt') this.isAltPressed = false
  }

  renderCheckboxes() {
    if (!this.props.hasCheckbox) return <div />

    let uniqueIDs = Object.keys(this.state.values)

    let bodyClassNames = [style.checkboxBody]
    if (this.props.fullHeight) bodyClassNames.push(style.fullHeight)

    return (
      <div className={style.checkboxes}>
        <div className={style.checkboxHeader}>
          <div>
            <Checkbox
              checked={
                this.state.selectedRows.length !== 0 &&
                this.state.selectedRows.length === Object.keys(this.state.values).length
              }
              onChange={this.handleCheckHeader}
            />
          </div>
        </div>
        <SpaceWrapper>
          <div
            className={bodyClassNames.join(' ')}
            ref={(container) => (this._checkboxContainer = container)}
          >
            {uniqueIDs.map((id, index) => this.renderCheckboxBody(id, index))}
          </div>
        </SpaceWrapper>
      </div>
    )
  }

  renderCheckboxBody(uniqueID, index) {
    return (
      <div
        className={style.checkboxRow}
        ref={(row) => (this[`_checkbox_${uniqueID}`] = row)}
        onMouseEnter={() => {
          this[`_row_${uniqueID}`].classList.add(style.hover)
        }}
        onMouseLeave={() => {
          this[`_row_${uniqueID}`].classList.remove(style.hover)
        }}
        key={index}
      >
        <div>
          <Checkbox
            checked={this.state.selectedRows.includes(uniqueID)}
            onChange={() => {
              this.handleCheckRow(uniqueID)
            }}
          />
        </div>
      </div>
    )
  }

  renderHeader() {
    let headerValues = Object.values(this.props.headers)
    let headerKeys = Object.keys(this.props.headers)

    let colTemplate = []
    let headerStyle
    let headerClassName = [style.headerContent]
    if (this.props.hideHeader) headerClassName.push(style.hideHeader)
    if (this.props.hideArrows) headerClassName.push(style.hideArrows)

    let headers = Object.values(this.props.headers)
    for (let i = 0; i < headers.length; i++) {
      let width = 'minmax(100px, 1fr)'
      if (headers[i].width && headers[i].width !== '') {
        width = `minmax(${headers[i].width}, 1fr)`
      }
      colTemplate.push(width)
    }

    headerStyle = { gridTemplateColumns: colTemplate.join(' ') }

    return (
      <div className={style.header}>
        <div className={headerClassName.join(' ')} style={headerStyle}>
          {headerValues.map((header, index) => {
            let content = header.name
            if (header.name && header.name.toString().substring(0, 1) === '.') {
              content = <Text textId={header.name} />
            }

            return (
              <div className={style.headerElement} key={index}>
                {content}
                <div className={style.arrowContainer}>
                  <SortingArrows
                    onClick={(sortType) => this.handleSort(headerKeys[index], sortType)}
                  />
                </div>
              </div>
            )
          })}
          <ul />
        </div>
      </div>
    )
  }

  renderBody() {
    let bodyClassNames = [style.body]
    if (this.props.fullHeight) {
      bodyClassNames.push(style.fullHeight)
    }

    let uniqueIDs = Object.keys(this.state.values)
    let trStyle = {}

    let colTemplate = []

    let headers = Object.values(this.props.headers)
    for (let i = 0; i < headers.length; i++) {
      let width = 'minmax(100px, 1fr)'
      if (headers[i].width && headers[i].width !== '') {
        width = `minmax(${headers[i].width}, 1fr)`
      }
      colTemplate.push(width)
    }

    trStyle = { gridTemplateColumns: colTemplate.join(' ') }

    let values = Object.values(this.state.values)

    return (
      <SpaceWrapper disabled={!this.props.fullHeight}>
        <div className={bodyClassNames.join(' ')} ref={(body) => (this._body = body)}>
          {values.map((row, key) => {
            return (
              <div
                className={style.row}
                key={key}
                ref={(row) => (this[`_row_${uniqueIDs[key]}`] = row)}
                onMouseEnter={() => {
                  if (this[`_checkbox_${uniqueIDs[key]}`]) {
                    this[`_checkbox_${uniqueIDs[key]}`].classList.add(style.hover)
                  }
                }}
                onMouseLeave={() => {
                  if (this[`_checkbox_${uniqueIDs[key]}`]) {
                    this[`_checkbox_${uniqueIDs[key]}`].classList.remove(style.hover)
                  }
                }}
                onClick={() => this.props.onRowClick && this.props.onRowClick(row)}
              >
                <div className={style.rowContent} style={trStyle}>
                  {this.renderCells(row, uniqueIDs[key])}
                </div>
              </div>
            )
          })}
        </div>
      </SpaceWrapper>
    )
  }

  renderCells(row, uniqueId) {
    let keys = this.getObjectKeys(row)

    return this.getObjectValues(row).map((col, index) => {
      return (
        <div className={style.cell} key={index}>
          {this.renderCell(keys[index], col, uniqueId)}
        </div>
      )
    })
  }

  renderCell(key, value, uniqueId) {
    let cellType = this.props.headers[key].type
    let cellValueType = this.props.headers[key].valueType
    let min = this.props.headers[key].min
    let displayFunc = this.props.headers[key].display

    if (Utilities.isSet(displayFunc)) {
      value = displayFunc(value)
    }

    switch (cellType) {
      case 'input':
        return (
          <InputCell
            initialValue={value}
            type={cellValueType}
            min={min}
            onChange={(value) => {
              this.handleChange(uniqueId, key, value)
            }}
          />
        )
      case 'select':
        return (
          <SelectCell
            options={this.props.headers[key].options}
            initialId={value}
            onChange={(id) => {
              this.handleChange(uniqueId, key, id)
            }}
          />
        )
      case 'time':
        return (
          <TimeCell
            initialTime={value}
            onChange={(minutes) => {
              this.handleChange(uniqueId, key, minutes)
            }}
          />
        )
      case 'order':
        return (
          <OrderCell
            initialValue={value}
            nonEditable={this.props.nonEditable}
            onChange={(value) => {
              this.handleChange(uniqueId, key, value)
            }}
          />
        )
      default:
        if (value && value.toString().substring(0, 1) === '.') {
          return <Text textId={value} />
        } else {
          return <div className={style.label}>{value ? value : '-'}</div>
        }
    }
  }

  renderFooter() {
    if (!this.props.hasFooter) return null
    return (
      <div className={style.footer}>
        <div className={style.footerElement}>
          <Text textId="totalRows" append={Object.values(this.state.values).length} />
        </div>
        <div className={style.footerElement}>
          <Text textId="selectedRows" append={this.state.selectedRows.length} />
        </div>
      </div>
    )
  }

  render() {
    let containerClassName = [style.container]
    if (this.props.nonEditable) containerClassName.push(style.nonEditable)
    if (this.props.fullHeight) containerClassName.push(style.fullHeight)
    if (this.props.hideShadow) containerClassName.push(style.hideShadow)

    return (
      <div className={containerClassName.join(' ')} onWheel={this.handleWheelScroll}>
        {this.renderCheckboxes()}
        <SpaceWrapper disabled={!this.props.fullHeight} style={{ overflow: 'auto' }}>
          <div className={style.table} ref={(table) => (this._table = table)}>
            {this.renderHeader()}
            {this.renderBody()}
          </div>
        </SpaceWrapper>
        {this.state.showVerticalScrollbar && (
          <Scrollbar
            onScroll={(x, y) => this.scrollToVertically(y)}
            ref={(scroll) => (this._verticalScroll = scroll)}
            className={style.verticalScrollbar}
          />
        )}
        {this.renderFooter()}
        {this.state.showHorizontalScrollbar && !this.props.hideScrollbar && (
          <Scrollbar
            onScroll={(x) => this.scrollToHorizontally(x)}
            ref={(scroll) => (this._horizontalScroll = scroll)}
            className={style.horizontalScrollbar}
            horizontal
          />
        )}
      </div>
    )
  }
}

DropdownTable.propTypes = {
  /**
   * Object containing headers for the table in the following format
   *
   * ```
   * {
   *   headerName: {
   *      name: required,
   *      type: required,
   *      valueType: required,
   *      width: optional,
   *      options: optional,
   *      min: optional
   *    },
   *    ...
   * }
   * ```
   *
   * @param {String} headerName arbitrary name for the header
   *
   * @param {String} name name of the header. If the name starts with a dot (.)
   * it will be used as a *textId* in a **Text** component
   *
   * @param {String} type type of the cell used to render this value<br />
   * &nbsp;&nbsp; • **label:** non editable text field<br />
   * &nbsp;&nbsp; • **select:** dropdown list of selectable options<br />
   * &nbsp;&nbsp; • **input:** editable input<br />
   * &nbsp;&nbsp; • **time:** editable time<br />
   * &nbsp;&nbsp; • **order:** editable order<br />
   *
   * @param {String} valueType type of the value<br />
   * &nbsp;&nbsp; • **string:** text<br />
   * &nbsp;&nbsp; • **number:** number<br />
   * &nbsp;&nbsp; • **time:** time in minutes<br />
   * &nbsp;&nbsp; • **day:** day as a numerical value<br />
   *
   * @param {String} width minimum width for the cell
   *
   * @param {Array} options array of options for **select** cell type in the format
   * `[{ name: String, id: Number }, ...]` <br />
   * &nbsp;&nbsp;&nbsp;&nbsp;If the name starts with a dot (.) it will be used as a *textId* in a
   * **Text** component
   *
   * @param {Number} min minimum value for **input** cell type with **number** value type
   */
  headers: PropTypes.object.isRequired,
  /**
   * Array containing the values for each row of the table in the following format
   *
   * ```
   * [
   *   {
   *     _id: 156325
   *     headerName1: value1,
   *     headerName2: value2,
   *     ...
   *   },
   *   ...
   * ]
   * ```
   *
   * Any value starting by an underscore (_) will be ignored by the **DropdownTable**, it serves only as a
   * reference when retrieving data from the **DropdownTable**<br />
   *
   * The header names must match the names given in the headers
   */
  values: PropTypes.array.isRequired,
  /**
   * Determines if the **DropdownTable** should fill available height
   */
  fullHeight: PropTypes.bool,
  /**
   * Determines if the **DropdownTable** should show horizontal scrollbar or not
   */
  hideScrollbar: PropTypes.bool,
  /**
   * Determines if the **DropdownTable** should have a box shadow or not
   */
  hideShadow: PropTypes.bool,
  /**
   * Determines if Arrow icons should be shown in header
   */
  hideArrows: PropTypes.bool,
  /**
   * Determines if the **DropdownTable** should have a column with checkboxes used to select rows
   */
  hasCheckbox: PropTypes.bool,

  /**
   * Determines if the **DropdownTable** should have a footer with information about the rows
   */
  hasFooter: PropTypes.bool,
  /**
   * Determines if the **DropdownTable** should have hidden headers
   */
  hideHeader: PropTypes.bool,
  /**
   * Determines the default value to sort by, needs to match the header name
   */
  defaultSort: PropTypes.string,
  /**
   * Determines if the **DropdownTable** should be readonly
   */
  nonEditable: PropTypes.bool,
  /**
   * Array of filter functions to apply to the **DropdownTable** data
   */
  filters: PropTypes.array,
  /**
   * Function to run when selecting or deselecting rows
   *
   * @param {Array} rows selected rows
   */
  onSelect: PropTypes.func,
  /**
   * Function to run when clicking a row
   *
   * @param {Array} rows selected rows
   */
  onRowClick: PropTypes.func,
  /**
   * Integer that set's the initial id of dropdown select
   *
   */
  initialId: PropTypes.number,
}
