import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useOptionsForSelects } from '../'
import generateTableData from './generate-table-data'
import getTeeTimes from './getTeeTimes'

const useWeekPeriod = (timePeriod, formState, selectedCourse, setValue) => {
  const { week, space, hasUpdated } = formState
  const { spacesOptions, weekPeriodOptions } = useOptionsForSelects(timePeriod)
  const [tableData, setTableData] = useState(null)
  const selectedWeek =
    weekPeriodOptions.find((option) => option.id === week) || weekPeriodOptions[0]

  const [currency] = useClubCurrency()

  const { data: teeTimes, isLoading } = useQuery(
    ['TEE_TIMES', timePeriod.id, selectedWeek.id, space],
    () => {
      setValue('hasUpdated', true)
      return getTeeTimes(selectedCourse, selectedWeek, space)
    }
  )

  useEffect(() => {
    if ((teeTimes && !tableData) || (hasUpdated && teeTimes)) {
      setValue('hasUpdated', false)
      setTableData(
        generateTableData({
          timePeriod,
          teeTimes,
          selectedWeek,
          space,
          currency,
          timezone: selectedCourse?.timezone,
        })
      )
    }
  }, [
    timePeriod,
    teeTimes,
    selectedWeek,
    space,
    hasUpdated,
    tableData,
    setValue,
    currency,
    selectedCourse?.timezone,
  ])

  useEffect(() => {
    if (week === -1 && weekPeriodOptions[0].id !== -1) {
      setValue('week', 1)
      setValue('space', spacesOptions?.[0]?.id)
    }
  }, [week, spacesOptions, timePeriod, setValue, weekPeriodOptions])

  const { data, timeSlots = [], daysMap } = tableData || {}

  return {
    tableData: data,
    setTableData,
    weekPeriodOptions,
    timeSlots,
    daysMap,
    spacesOptions,
    isLoading,
  }
}

export default useWeekPeriod
