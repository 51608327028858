import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { Membership } from '@sweetspot/shared/types'

/**
 * Toggle if git club can be selected or not
 */
export const toggleGitClubSelectable = (id: number): Promise<Membership> => {
  const URL = `${getBaseUrlPlatform()}/memberships/${id}/toggle-git-club-selectable`

  return request(URL, {
    method: 'PUT',
  })
}
