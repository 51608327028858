import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import { ReactComponent as ExpandIcon } from '@sweetspot/sweetspot-js/assets/svgs/expand.svg'
import { ReactComponent as InexpandIcon } from '@sweetspot/sweetspot-js/assets/svgs/minimize.svg'

import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'

import styles from './styles.module.scss'
import useButtonListener from '@sweetspot/sweetspot-js/common/hooks/useButtonListener'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

const ModalContainer = (props) => {
  const [isExpanded, setExpanded] = useState(false)

  const container = useRef()

  useButtonListener(['Escape'], () => {
    if (props.onClose && props.closeOnEscape === true && props.visible) props.onClose()
  })

  useOnClickOutside(container, () => {
    if (props.onClose && props.closeOnClickOutside === true && props.visible) props.onClose()
  })

  const themeStyle = useMemo(() => {
    if (props.theme === 'webApp') return styles.webApp
    else if (props.theme === 'webAppLogin') return styles.webAppLogin
    else if (props.theme === 'wizard') return styles.wizard
    return ''
  }, [props.theme])

  const headerTitleElement = useMemo(() => {
    if (!props.headerTitle) return <div />

    if (typeof props.headerTitle === 'string') {
      return (
        <span className={styles.headerTitle} style={{ color: props.headerColor }}>
          {props.headerTitle || ''}
        </span>
      )
    }

    return props.headerTitle
  }, [props.headerColor, props.headerTitle])

  return (
    <OverlayContainer
      className={cx(props?.className, styles.parentContainer, {
        [styles.visible]: props.visible,
      })}
    >
      <div
        ref={container}
        className={cx(
          styles.container,
          {
            [styles.visible]: props.visible,
          },
          {
            [styles.widthThreeQuarter]: props.width === 'threeQuarter',
            [styles.widthHalf]: props.width === 'half',
            [styles.widthThird]: props.width === 'third',
            [styles.widthQuarter]: props.width === 'quarter',
            [styles.widthAuto]: props.width === 'auto',
          },
          {
            [styles.heightDefault]: props.height === 'default',
            [styles.heightAuto]: props.height === 'auto',
            [styles.heightFullOr75]: props.height === 'fullOr75',
            [styles.heightFull]: props.expandable && isExpanded,
            [styles.fixed]: props.fixed,
          },
          {
            [styles.defaultLayout]: props.layout === 'default',
            [styles.sidebarLayout]: props.layout === 'sidebar',
          },
          {
            [styles.webApp]: props.theme === 'webApp',
          }
        )}
      >
        {props.layout === 'sidebar' && props.sidebarChildren ? (
          <div className={cx(styles.sidebarContainer, props.sidebarClassName)}>
            <div className={cx(styles.scrollableInner)}>{props.sidebarChildren}</div>
          </div>
        ) : null}

        <div
          className={cx(styles.headerContainer, themeStyle)}
          style={{
            backgroundColor: props.headerBackgroundColor,
            color: props.headerColor,
          }}
        >
          {props.onClose ? (
            <div
              className={cx(styles.closeButtonRow, themeStyle)}
              style={{ backgroundColor: props.headerBackgroundColor, color: props.headerColor }}
            >
              {headerTitleElement}

              <div className={styles.rightSide}>
                {props.headerButtons && (
                  <div className={styles.headerButtons}>{props.headerButtons}</div>
                )}
                {props.expandable && (
                  <div style={{ display: 'flex' }}>
                    {isExpanded ? (
                      <InexpandIcon onClick={() => setExpanded(false)} />
                    ) : (
                      <ExpandIcon onClick={() => setExpanded(true)} />
                    )}
                  </div>
                )}
                <CloseIcon onClick={props.onClose} className={cx(styles.closeButton)} />
              </div>
            </div>
          ) : null}
          {props.headerChildren || null}
        </div>

        <div className={cx(styles.innerContainer, props.scrollable && styles.scroll)}>
          <div className={cx(styles.scrollableInner, props.scrollable && styles.scroll)}>
            {props.children || null}
          </div>
          {props.loading && props.loading === true ? (
            <div className={cx(styles.loaderContainer, styles.loaderContainerLoading)}>
              <PulseLoader showIf={true} />
            </div>
          ) : null}
        </div>

        {props.footerChildren ? (
          <div className={cx(styles.footerContainer)}>{props.footerChildren}</div>
        ) : null}
      </div>
    </OverlayContainer>
  )
}

ModalContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOf(['threeQuarter', 'half', 'third', 'quarter', 'auto']),
  height: PropTypes.oneOf(['default', 'auto', 'fullOr75']),
  expandable: PropTypes.bool,
  fixed: PropTypes.bool,
  children: PropTypes.any,
  headerColor: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  headerChildren: PropTypes.any,
  headerTitle: PropTypes.any,
  headerButtons: PropTypes.any,
  footerChildren: PropTypes.any,
  sidebarChildren: PropTypes.any,
  sidebarClassName: PropTypes.string,
  loading: PropTypes.bool,
  layout: PropTypes.oneOf(['default', 'sidebar']),
  theme: PropTypes.oneOf(['default', 'webApp', 'webAppLogin', 'wizard']),
  closeOnEscape: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  isFullsize: PropTypes.bool,
  className: PropTypes.string,
}
ModalContainer.defaultProps = {
  visible: true,
  onClose: undefined,
  width: 'half',
  height: 'default',
  expandable: false,
  fixed: false,
  children: null,
  loading: false,
  layout: 'default',
  sidebarClassName: '',
  theme: 'default',
  closeOnEscape: true,
  closeOnClickOutside: false,
  isFullsize: true,
  className: '',
}

export default ModalContainer
