import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Creates new partnership beloning to passed club ID
 *
 * @param {string|number} id
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addPartnership = (clubId, payload) => {
  const URL = `${getBaseUrlPlatform()}/clubs/${clubId}/partnerships/new`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Get's single partnership by ID
 *
 * @param {string|number} partnershipId
 * @returns {Promise}
 */
export const _getPartnershipById = (partnershipId) => {
  const URL = `${getBaseUrlPlatform()}/partnerships/${partnershipId}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Updates partnership
 *
 * @param {string|number} partnership
 * @param {Object} payload
 * @returns {Promise}
 */
export const _updatePartnership = (partnership, payload) => {
  const URL = `${getBaseUrlPlatform()}/partnerships/${partnership}/published`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Delete partnership. Only allowed on inactive partnerships. This is a hard delete.
 *
 * @param {string|number} partnershipId
 * @returns {Promise}
 */
export const _deletePartnership = (partnershipId) => {
  const URL = `${getBaseUrlPlatform()}/crm/partnerships/${partnershipId}`
  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Cancels partnership. This is done on activated partnerships. This is a soft delete
 *
 * @param {string|number} partnershipId
 */
export const _cancelPartnership = (partnershipId) => {
  const URL = `${getBaseUrlPlatform()}/crm/partnerships/${partnershipId}/cancel`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}

/**
 * Activates a membership
 *
 * @param {string|number} partnershipId
 */
export const _activatePartnership = (partnershipId) => {
  const URL = `${getBaseUrlPlatform()}/crm/partnerships/${partnershipId}/activate`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}

/**
 * Adds promotion to membership
 *
 * @param {string|number} partnershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addPromotion = (partnershipId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/partnerships/${partnershipId}/promotion/new`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Get's partnerships by admin id
 *
 * @param {string|number} adminId
 * @returns {Promise}
 */
export const _getAdminsPartnershipList = (adminId) => {
  const URL = `${getBaseUrlPlatform()}/crm/partnerships?bookers.id=${adminId}&order%5Bname%5D=asc&order%5Bclub.name%5D=asc`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
