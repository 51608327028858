import React, { useMemo, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { isBookingConsideredPaid } from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { SpacesAccordion } from '../SpaceAccordion'
import { TotalCalculation } from '../TotalCalcutlation/TotalCalculation'

const SummaryOrderRows = ({
  orderBooking,
  selectedSpaces,
  orderBookingRefetch,
  venue,
  extraPlayerState,
  hasOwner,
  isPaid,
  isPartiallyPaid,
  isConfirmed,
}) => {
  const roles = useRoles()
  const accessTable = useMemo(() => {
    const role = getFirstMatchingRole(roles)
    if (!role)
      return {
        PRICE_ADJUSTMENT: false,
      }
    return {
      PRICE_ADJUSTMENT: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT, role?.value),
    }
  }, [roles])

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  const isConsideredPaid = useMemo(() => {
    if (orderBooking) {
      return isBookingConsideredPaid(orderBooking)
    }
    return false
  }, [orderBooking])

  const renderSpace = useCallback(
    (space, isLastSpace) => {
      const items = orderBooking.items.filter(
        (item) => item?.slot?.tee_time?.space?.id === space.id
      )
      return (
        <div className="w-full" key={space.id}>
          <SpacesAccordion
            hasOwner={hasOwner}
            disableAdjustment={isConsideredPaid || !accessTable.PRICE_ADJUSTMENT}
            space={space}
            items={items}
            timezone={venueTimeZone}
            orderBookingRefetch={orderBookingRefetch}
            currencyCode={orderBooking.currency_code}
            isPaid={isPaid || isPartiallyPaid}
            isConfirmed={isConfirmed}
          />
          {!isLastSpace && <hr className={'mt-2.5'} />}
        </div>
      )
    },
    [
      accessTable.PRICE_ADJUSTMENT,
      isConsideredPaid,
      orderBooking.currency_code,
      orderBooking.items,
      orderBookingRefetch,
      venueTimeZone,
    ]
  )

  return (
    <>
      <div className={cx(styles.container)}>
        {orderBooking?.spaces.map((space, index) =>
          renderSpace(space, index === orderBooking.spaces.length - 1)
        )}
      </div>
      <TotalCalculation booking={orderBooking} extraPlayerState={extraPlayerState} />
    </>
  )
}

SummaryOrderRows.propTypes = {
  orderBooking: PropTypes.object.isRequired,
  selectedSpaces: PropTypes.array,
  orderBookingRefetch: PropTypes.func,
}

SummaryOrderRows.defaultProps = {
  orderBooking: null,
  selectedSpaces: [],
  orderBookingRefetch: () => {},
}

export default SummaryOrderRows
