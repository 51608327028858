import { MembershipState } from '@sweetspot/club-portal-legacy/store/types'

export const initialState: MembershipState = {
  list: [],
  totalList: [],
  totalPages: 1,
  rowsPerPage: 50,
  totalItems: 1,
  gitMemberships: [],
}
