import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import {
  createSpace,
  deleteSpace,
  getSpaces,
  updateSpace,
} from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { useQuery } from 'react-query'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import Spinner from '@sweetspot/sweetspot-js/common/components/Spinner'
import { useToasts } from 'react-toast-notifications'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'

const defaultErrors = {
  course: null,
  name: null,
  discription: null,
  floor: null,
  general: null,
}

const ManageSpaces = () => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const currentClubId = useSelector((state) => state?.golfClub?.selectedId)

  const { data: golfCourses, isFetching: golfCoursesIsFetching } = useQuery(
    [CLUB_QUERIES.COURSES, { 'club.id': currentClubId, 'order[name]': 'asc' }],
    () => queryCourses({ 'club.id': currentClubId, 'order[name]': 'asc' }),
    {
      enabled: !!currentClubId,
      select: (data) => {
        return data.map((course) => ({ ...course, label: course.name }))
      },
    }
  )

  const [state, setState] = useMergeState({
    selectedSpace: null,

    course: null,
    name: '',
    description: '',
    floor: '',

    saving: false,

    confirmDelete: false,
    confirmDeleteValue: '',
    confirmDeleteError: null,
  })
  const [errors, setErrors] = useMergeState(defaultErrors)

  const {
    data: spaces,
    isFetching: spacesIsFetching,
    refetch: refetchSpaces,
  } = useQuery([CLUB_QUERIES.SPACES, currentClubId], () => getSpaces({ club: currentClubId }), {
    enabled: !!currentClubId,
    select: (data) => {
      return data.map((item) => ({ ...item, label: item.name }))
    },
  })

  const selectSpace = (space) => {
    if (space?.id) {
      setState({
        selectedSpace: space,
        course: golfCourses.find((x) => x.id === space.course.id),
        name: space.name,
        description: space.description,
        floor: space.floor,
      })
      setErrors(defaultErrors)
    } else {
      setState({
        selectedSpace: null,
        course: null,
        name: '',
        description: '',
        floor: '',
      })
      setErrors(defaultErrors)
    }
  }

  const selectCourse = (course) => {
    setState({
      course: course,
    })
  }

  const submit = async (e) => {
    e.preventDefault()
    setState({ saving: true })

    const selectedSpace = state?.selectedSpace

    const course = state?.course
    const name = state?.name
    const description = state?.description
    const floor = parseInt(state?.floor) || null

    let errors = {
      course: null,
      name: null,
      description: null,
      floor: null,
    }
    let hasErrors = false

    if (!course?.uuid) {
      errors.course = t('sentences.courseMustBeSelected')
      hasErrors = true
    }

    if (!name || typeof name !== 'string' || name.length < 2 || name.length > 150) {
      errors.name = t('errors.stringBetween', { min: 2, max: 150 })
      hasErrors = true
    }

    if (description && (typeof description !== 'string' || description.length > 150)) {
      errors.description = t('errors.maxString_plural', { count: 150 })
      hasErrors = true
    }

    if (floor && typeof floor !== 'number') {
      errors.floor = t('errors.fieldMustBeANumber')
      hasErrors = true
    }

    if (hasErrors) {
      setState({ saving: false })
      setErrors(errors)
      return
    }

    let res, err

    if (!selectedSpace) {
      ;[res, err] = await to(
        createSpace(currentClubId, {
          name,
          course: course.uuid,
          ...(description ? { description } : {}),
          ...(floor ? { floor } : {}),
        })
      )
    } else {
      ;[res, err] = await to(
        updateSpace(selectedSpace.uuid, {
          name,
          course: course.uuid,
          ...(description ? { description } : {}),
          ...(floor ? { floor } : {}),
        })
      )
    }

    if (err) {
      setState({ saving: false })
      if (err?.violations) {
        err.violations.forEach((violation) => {
          const prop = violation.propertyPath
          if (prop === 'course') {
            setErrors({ course: t('sentences.courseMustBeSelected') })
          }
          if (prop === 'name') {
            setErrors({ name: t('errors.stringBetween', { min: 2, max: 150 }) })
          }
          if (prop === 'description') {
            setErrors({ description: t('errors.maxString_plural', { count: 150 }) })
          }
          if (prop === 'floor') {
            setErrors({ floor: t('errors.valueMustBeBetweenThese', { this: 0, that: 255 }) })
          }
        })
      } else {
        setErrors({ general: t('errors.somethingWentWrong', { id: 2526 }) })
      }
    }

    if (res) {
      setState({ saving: false, selectedSpace: res })
      refetchSpaces()

      if (selectedSpace) {
        addToast(t('sentences.spaceUpdated'), { appearance: 'success' })
      } else {
        addToast(t('sentences.spaceCreated'), { appearance: 'success' })
      }
    } else {
      setState({ saving: false })
    }
  }

  const removeSpace = async (confirmed = false) => {
    setState({ confirmDeleteValue: '', confirmDeleteError: null })
    if (!confirmed) {
      setState({ confirmDelete: true })
      return
    } else {
      setState({ confirmDelete: false })
    }

    setState({ saving: true })

    const [res] = await to(deleteSpace(state.selectedSpace.uuid))

    if (res) {
      setState({
        saving: false,
        selectedSpace: null,
        course: null,
        name: '',
        description: '',
        floor: '',
      })
      refetchSpaces()
      addToast(t('sentences.spaceDeleted'), { appearance: 'warning' })
    } else {
      setState({ saving: false })
      setErrors({ general: t('errors.somethingWentWrong', { id: 2527 }) })
    }
  }

  const validateConfirmDelete = () => {
    const { confirmDeleteValue } = state

    if (confirmDeleteValue === 'Delete' || confirmDeleteValue === 'Ta bort') {
      removeSpace(true)
    } else {
      setState({ confirmDeleteError: t('errors.fieldInvalid') })
    }
  }

  const cancelConfirmDelete = () => {
    setState({ confirmDeleteValue: '', confirmDeleteError: null, confirmDelete: false })
  }

  return (
    <div className={cx(styles.container)}>
      <h2 className={cx(styles.title)}>{t('words.spaces')}</h2>

      <form onSubmit={submit} className={cx(styles.form)}>
        <TextInputOptionsSelect
          label={t('words.spaces')}
          placeholder={t('sentences.createNewSpace')}
          options={[{ label: t('sentences.createNewSpace') }, ...(spaces || [])]}
          onOptionSelect={(space) => selectSpace(space)}
          disabled={spacesIsFetching || state.saving}
          value={state?.selectedSpace?.name || ''}
        />

        <hr />
        <TextInputOptionsSelect
          label={t('words.course')}
          placeholder={t('sentences.selectCourse')}
          options={golfCourses}
          onOptionSelect={(course) => selectCourse(course)}
          value={state?.course?.name || ''}
          disabled={state.saving || golfCoursesIsFetching}
          error={errors.course}
          onInputFocus={() => setErrors(defaultErrors)}
        />
        <TextInputField
          label={t('words.name')}
          labelTwo="Min 2"
          value={state.name}
          placeholder={t('words.name')}
          onChange={(value) => setState({ name: value })}
          error={errors.name}
          onInputFocus={() => setErrors({ name: null })}
        />
        <TextInputField
          labelTwo="Max 150"
          type="text-area"
          label={t('words.description')}
          value={state.description}
          onChange={(value) => setState({ description: value })}
          rows={5}
          disabled={state.saving}
          error={errors.description}
          onInputFocus={() => setErrors({ description: null })}
        />
        <TextInputField
          type="number"
          label={t('words.floor')}
          placeholder={t('words.floor')}
          value={state.floor}
          onChange={(value) => setState({ floor: value })}
          disabled={state.saving}
          error={errors.floor}
          onInputFocus={() => setErrors({ floor: null })}
        />

        <div className={cx(styles.buttonsRow)}>
          <Button
            onClick={submit}
            text={state?.selectedSpace ? t('sentences.updateSpace') : t('sentences.createSpace')}
            type="submit"
            width="auto"
            size="medium"
            className={cx(styles.button)}
            disabled={state.saving}
          />
          <Button
            text={t('sentences.deleteSpace')}
            disabled={(state?.selectedSpace ? false : true) || state.saving}
            size="medium"
            width="auto"
            theme="danger"
            className={cx(styles.button)}
            onClick={() => removeSpace()}
          />
          <Spinner
            visible={spacesIsFetching || state.saving || golfCoursesIsFetching}
            color="blue"
            className={styles.spinner}
          />
        </div>

        <p className={cx(styles.generalError)}>{errors.general}</p>
      </form>

      <ConfirmPopup
        visible={state.confirmDelete}
        title={t('sentences.confirmSpaceDeletion')}
        onClose={cancelConfirmDelete}
        rejectText={t('words.cancel')}
        onReject={cancelConfirmDelete}
        acceptText={t('words.delete')}
        onAccept={validateConfirmDelete}
      >
        <TextInputField
          label={t('sentences.typeDeleteToConfirm')}
          value={state.confirmDeleteValue}
          onChange={(value) => setState({ confirmDeleteValue: value })}
          error={state.confirmDeleteError}
          onEnter={validateConfirmDelete}
        />
      </ConfirmPopup>
    </div>
  )
}

ManageSpaces.propTypes = {}

ManageSpaces.defaultProps = {}

export default ManageSpaces
