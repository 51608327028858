import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import {
  setTeeTimes,
  updateTeeTimes,
} from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import { useContext, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import TimePeriodsContext, { useActivePeriod, usePeriodsList } from '../'

const useTableSelectorMutations = (period, course, setValue, setUpdating) => {
  const dispatch = useDispatch()
  const formStateRef = useRef()
  const queryClient = useQueryClient()

  const [globalState] = useContext(TimePeriodsContext)
  const [, setActivePeriod] = useActivePeriod()
  const { periodsList, setPeriodsList } = usePeriodsList()
  const { spaces } = globalState

  const onSuccess = () => {
    const allTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')

    const newPeriod = { ...period }
    newPeriod.period_price_status = newPeriod.period_price_status || 'all_period_price_set'
    setActivePeriod(newPeriod)
    const newPeriodsList = [...periodsList]
    const index = newPeriodsList.findIndex((p) => p.id === newPeriod.id)
    newPeriodsList[index] = newPeriod
    setPeriodsList(newPeriodsList)

    if (formStateRef.current) {
      const { week, space, isAllChanges } = formStateRef.current
      formStateRef.current = null
      delete allTableData[period.id]?.[week]?.[space]
      if (isAllChanges || Object.keys(allTableData[period.id][week]).length === 0) {
        delete allTableData[period.id][week]
      }
      if (Object.keys(allTableData[period.id]).length === 0) {
        delete allTableData[period.id]
      }
      dispatch(addToast('updateTeeTimesSuccess', 'success'))
    } else {
      delete allTableData[period.id]
      setValue('week', 1)
      setValue('space', spaces?.[0]?.id)
      setValue('hasUpdated', true)
      dispatch(addToast('setTeeTimesSuccess', 'success'))
    }
    queryClient.resetQueries('TEE_TIMES')
    localStorage.setItem('ALL_TABLE_DATA', JSON.stringify(allTableData))
    setUpdating(false)
  }

  const onError = () => {
    if (formStateRef.current) {
      dispatch(addToast('updateTeeTimesError', 'error'))
    } else {
      dispatch(addToast('setTeeTimesError', 'error'))
    }
    setUpdating(false)
  }

  const updateTeeTimesMutation = useMutation((data) => updateTeeTimes({ data }), {
    onSuccess,
    onError,
  })

  const setTeeTimesMutation = useMutation((payload) => setTeeTimes(period.uuid, payload), {
    onSuccess,
  })

  const updateTeeTimesHandler = async (formState, slots, isAllChanges) => {
    setUpdating(true)
    formStateRef.current = { ...formState, isAllChanges }
    updateTeeTimesMutation.mutate(slots, {
      onSuccess: () => {
        slots.splice(0)
      },
    })
  }

  const setTeeTimesHandler = async (tableData) => {
    setUpdating(true)
    const data = JSON.parse(JSON.stringify(tableData))
    delete data.hasChanged

    const payload = {
      club: course.club.uuid,
      data,
    }
    setTeeTimesMutation.mutate(payload)
  }

  return { updateTeeTimes: updateTeeTimesHandler, setTeeTimes: setTeeTimesHandler }
}

export default useTableSelectorMutations
