import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import style from './style.module.scss'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import GolfCartModal from '@sweetspot/club-portal-legacy/modals/GolfCartModal'

import headers from './headers.json'

import { getGolfCartFleets } from '@sweetspot/club-portal-legacy/store/actions'

import { ReactComponent as NoGolfCardIcon } from '@sweetspot/club-portal-legacy/resources/images/golf-cart.svg'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'
import { withClubCurrency } from '../../../../hoc/withClubCurrency'

const actions = [
  {
    id: 1,
    label: 'membership.addMembership',
    action: 'createNewAdmin',
  },
]

class GolfCarts extends Component {
  state = {
    isLoading: true,
    fleets: [],
    totalPages: 0,
    rowsPerPage: 0,
    totalRows: 0,
  }

  componentDidMount = () => {
    this.getGolfCartsFleets()
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props
    const { state } = prevProps.location

    if (location && location.state && location.state.shouldReload && !state) {
      this.getGolfCartsFleets()
    }

    if (!_.isEqual(this.props.golfCourses, prevProps.golfCourses)) {
      this.getGolfCartsFleets()
    }
  }

  handleActionConfirm = (action) => {
    if (action.id === 1) {
      this.handleCreate()
    }
  }

  getGolfCartsFleets = (pageNum = 1) => {
    const {
      token,
      golfCourses,
      getGolfCartFleets,
      golfClubs: { selectedId },
      currentClubCurrency,
    } = this.props

    let query = `?golf_club_id=${selectedId}&page=${pageNum}`

    this.setState({ isLoading: true })
    getGolfCartFleets(token, query)
      .then((response) => {
        const rowsPerPage = response['hydra:itemsPerPage']
        const totalRows = response['hydra:totalItems']
        const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
        const golfCartList = response['hydra:member']

        try {
          const fleets = golfCartList
            ? golfCartList.map((item) => {
                const inventory = item.product_variants[0]
                const numberOfGC = item.product_variants?.length || '-'
                const fee = inventory?.fees[0]?.price

                return {
                  id: item.id,
                  name: item.name,
                  uuid: item.uuid,
                  courses: item.courses.map((course) =>
                    golfCourses.find((courseItem) => courseItem.id === course.id)
                  ),
                  number_of_golf_carts: numberOfGC,
                  fee: fee ? priceToLocal(fee, currentClubCurrency, true) : '-',
                  is_active: {
                    state: item.is_active ? 'active' : 'inActive',
                    text: item.is_active ? '.active' : '.inActive',
                  },
                }
              })
            : []

          this.setState({
            isLoading: false,
            fleets,
            totalPages,
            rowsPerPage,
            totalRows,
          })
        } catch (error) {
          this.setState({
            isLoading: false,
            fleets: [],
            totalPages: 1,
            rowsPerPage: 100,
            totalRows: 0,
          })
        }
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleCreate = () => {
    this.props.history.push('/settings/golf-carts/new')
  }

  handleEdit = (row) => {
    this.props.history.push(`/settings/golf-carts/${row.uuid}`)
  }

  render() {
    const { isLoading, fleets, totalPages, totalRows, rowsPerPage } = this.state

    return (
      <div className={style.content}>
        <Grid
          actions={actions}
          actionsEnabled
          isLoading={isLoading}
          values={fleets}
          pageTitle={'golfCarts.fleet'}
          headers={headers}
          hideArrows
          shouldHideColumnEdit
          onRowClick={(row) => this.handleEdit(row)}
          onActionConfirm={this.handleActionConfirm}
          pagination
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setCurrentPage={this.getGolfCartsFleets}
          displayRangeOfRows
        />
        {!fleets ||
          (!fleets.length && (
            <div className={style.container}>
              {isLoading ? (
                <div className={style.loading}>
                  <PulseLoader showIf={isLoading} />
                  <Text textId="golfCarts.loadingGolfCarts" />
                </div>
              ) : (
                <div>
                  <NoGolfCardIcon className={style.noFleetImg} />
                  <Text textId="golfCarts.noGolfCartFleet" className={style.noFleetText} />
                </div>
              )}
            </div>
          ))}

        <Switch>
          <Route exact path="/settings/golf-carts/:id" component={GolfCartModal} />
        </Switch>
      </div>
    )
  }
}

GolfCarts.propTypes = {
  getGolfCartFleets: PropTypes.func.isRequired,
  golfClubs: PropTypes.object.isRequired,
  golfCourses: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  history: PropTypes.object,
  token: PropTypes.string.isRequired,
  currentClubCurrency: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  golfClubs: state.golfClub,
  golfCourses: state.golfCourse.list,
  lang: state.auth.me.lang,
  token: state.auth.token,
})

const mapDispatchToProps = (dispatch) => ({
  getGolfCartFleets: (role, query) => dispatch(getGolfCartFleets(role, query)),
})

export default withClubCurrency(connect(mapStateToProps, mapDispatchToProps)(GolfCarts))
