import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Creates new membership beloning to passed club ID
 *
 * @param {string|number} id
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addMembership = (clubId, payload) => {
  const URL = `${getBaseUrlPlatform()}/clubs/${clubId}/memberships/new`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Updates membership
 *
 * @param {string|number} membershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _updateMembership = (membershipId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Deletes membership
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const _deleteDraftMembership = (membershipId) => {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}`
  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Publishes membership
 *
 * @param {string|numver} membershipId
 */
export const _publishMembership = (membershipId) => {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}/publish`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}

/**
 * Adds promotion to membership
 *
 * @param {string|number} membershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addPromotion = (membershipId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}/promotion/new`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Get's single membership by ID
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const _getMembershipById = (membershipId) => {
  const URL = `${getBaseUrlPlatform()}/memberships/${membershipId}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Get's predefined fee-units
 *
 * @returns {Promise}
 */
export const _getFeeUnits = (lang) => {
  const URL = `${getBaseUrlPlatform()}/fee-units`
  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${lang}`,
    },
  })
}

/**
 * Adds fee to membership
 *
 * @param {string|number} membershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _addFeeToMembership = (membershipId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}/fees/new`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Updates a fee entity
 *
 * @param {string|number} feeId
 * @param {Object} payload
 * @returns {Promise}
 */
export const _updateFee = (feeId, payload) => {
  const URL = `${getBaseUrlPlatform()}/crm/fees/${feeId}`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

/**
 * Deletes a fee
 *
 * @param {string|number} feeId
 * @returns {Promise}
 */
export const _deleteFee = (feeId) => {
  const URL = `${getBaseUrlPlatform()}/crm/fees/${feeId}`
  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Delete membership
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const _deleteMembership = (membershipId) => {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}`
  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _addPlayerToMembership(membershipId, payload) {
  const URL = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}/card/new`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

export function _markMembershipAsPaid(id) {
  const URL = `${getBaseUrlPlatform()}/crm/membership-cards/${id}/mark-as-paid`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}

export function _unmarkMembershipAsPaid(id) {
  const URL = `${getBaseUrlPlatform()}/crm/membership-cards/${id}/cancel`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}

export function _removeMembershipCard(id) {
  const URL = `${getBaseUrlPlatform()}/crm/membership-cards/${id}`

  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _sendPaymentLink(orderId) {
  const URL = `${getBaseUrlPlatform()}/bookings/memberships/${orderId}/payment/send`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}

export function _lockUnlockMembership(uuid) {
  const URL = `${getBaseUrlPlatform()}/membership-cards/${uuid}/lock`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
  })
}
