import { useEffect, useState } from 'react'
import { TimezoneInfo } from '@sweetspot/club-portal-legacy/components/TimezoneInfo'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'

import style from './style.module.scss'
import {
  InputBase,
  InputContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'

interface GolfCourseSelectProps {
  courses: GolfCourseItem[]
  selectedCourse: GolfCourseItem
  onSelectCourse: (id: string) => void
  newDesignBtn?: boolean
}

const GolfCourseSelect = ({
  courses,
  selectedCourse,
  onSelectCourse,
  newDesignBtn = false,
}: GolfCourseSelectProps) => {
  const [course, setCourse] = useState<GolfCourseItem | null>(null)
  const [courseNameLength, setCourseNameLength] = useState<number>(0)

  const { t } = useTranslation()

  useEffect(() => {
    if (selectedCourse) {
      setCourse(selectedCourse)
      setCourseNameLength(selectedCourse.name?.length)
    }
  }, [selectedCourse])

  if (!courses || courses.length === 0) return null

  return (
    <div className={style.container}>
      <div className={style.nameContainer}>
        <div className={style.courseText}>
          {!newDesignBtn && (
            <div className={style.textContainer}>
              <DropdownSelect
                hideArrow
                values={courses}
                initialId={course?.id}
                onSelect={(id) => onSelectCourse(id)}
                width={courseNameLength ? 100 + courseNameLength * 11 : 300}
                largeText
              />
            </div>
          )}
          {newDesignBtn && (
            <InputSelect value={course?.id} onValueChange={(id) => onSelectCourse(id)}>
              <InputBase>
                <InputSelectTrigger asChild className="w-[300px]">
                  <InputContainer className="ring-border-stroke-subtle">
                    <InputSelectStatefulInput
                      placeholder={t('sentences.selectCourse')}
                      className="text-content-base text-ellipsis pl-4 pr-8"
                      value={course?.name || ''}
                    />
                    <InputSelectTrailingContainer />
                  </InputContainer>
                </InputSelectTrigger>
              </InputBase>
              <InputSelectContent className="max-h-72">
                <InputSelectContentView>
                  {courses.map((option) => {
                    return (
                      <InputSelectItem value={option.id} key={option.id}>
                        <ListItem className="ml-4 h-full w-[260px] min-w-[260px] justify-center gap-2 ">
                          <ListItemMainContent className="justify-center py-1">
                            <ListItemParagraph className="text-content-base text-ellipsis">
                              {option?.name}
                            </ListItemParagraph>
                          </ListItemMainContent>
                          {course?.id === option.id && (
                            <ListItemTrailing>
                              <i className="fa-regular fa-check h-4 w-4" />
                            </ListItemTrailing>
                          )}
                        </ListItem>
                      </InputSelectItem>
                    )
                  })}
                </InputSelectContentView>
              </InputSelectContent>
            </InputSelect>
          )}
          <TimezoneInfo
            styles={
              newDesignBtn
                ? {
                    container: style.timezone,
                  }
                : {}
            }
            timezone={selectedCourse?.timezone ?? 'Europe/Stockholm'}
          />
        </div>
      </div>
    </div>
  )
}

export default GolfCourseSelect
