import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Get's single promotion by id
 *
 * @param {number|string} promotionId
 */
export const getSinglePromotion = (promotionId) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}`
  return request(url)
}

/**
 * Query promotions
 *
 */
export const queryPromotions = (
  query = {
    club: null,
    'club[]': null,
    'membership.id': null,
    'membership.id[]': null,
    name: null,
    context: null,
    'context[]': null,
    coupon_based: null,
    'order[priority]': null,
    'state[]': null,
    'groups[]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/promotions`,
      query,
    },
    {
      skipNull: true,
    }
  )

  return request(url)
}

/**
 * Create single promotion in club
 *
 * @param {string|number} clubId
 */
export const createClubPromotion = (
  clubId,
  payload = {
    name: '',
    priority: 0,
    status: 'draft',
  }
) => {
  const url = `${getBaseUrlPlatform()}/clubs/${clubId}/promotion`

  return request(url, { method: 'PUT', body: payload })
}

/**
 * Create single promotion in membership
 *
 * @param {string|number} membershipId
 */
export const createMembershipPromotion = (membershipId, payload = { name: '' }) => {
  const url = `${getBaseUrlPlatform()}/crm/memberships/${membershipId}/promotion/new`

  return request(url, { method: 'PUT', body: payload })
}

/**
 * Update promotion
 *
 * @param {*} promotionId
 */
export const updatePromotion = (
  promotionId,
  payload = {
    name: null,
    priority: null,
  }
) => {
  let body = {}

  Object.keys(payload).forEach((key) => {
    if (payload[key] !== null) {
      body[key] = payload[key]
    }
  })

  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}`
  return request(url, { method: 'PUT', body })
}

/**
 * Activate promotion
 *
 * @param {string|number} promotionId
 */
export const activatePromotion = (promotionId) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/activate`

  return request(url, { method: 'PUT' })
}

/**
 * Deactivate promotion
 *
 * @param {string|number} promotionId
 */
export const deactivatePromotion = (promotionId) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/deactivate`

  return request(url, { method: 'PUT' })
}

/**
 * Archive promotion
 *
 * @param {string|number} promotionId
 */
export const archivePromotion = (promotionId) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/archive`

  return request(url, { method: 'PUT' })
}

/**
 * Create new action in promotion by id
 *
 * @param {string|number} promotionId
 */
export const createNewAction = (
  promotionId,
  body = {
    type: null,
    configuration: {},
  }
) => {
  const url = `${getBaseUrlPlatform()}/crm/promotions/${promotionId}/action/new`
  return request(url, { method: 'PUT', body })
}

/**
 * Deletes an action
 *
 * @param {string|number} actionId
 */
export const deleteAction = (actionId) => {
  const url = `${getBaseUrlPlatform()}/crm/promotions/actions/${actionId}`
  return request(url, { method: 'DELETE' })
}

/**
 * Update an promotion action
 *
 * @param {string|number} actionId
 */
export const updateAction = (actionId, body = {}) => {
  const url = `${getBaseUrlPlatform()}/crm/promotions/action/${actionId}`
  return request(url, { method: 'PUT', body })
}

/**
 * Create new rule in promotion by id
 *
 * @param {string|number} promotionId
 */
export const createNewRule = (
  promotionId,
  body = {
    type: null,
    configuration: {},
  }
) => {
  const url = `${getBaseUrlPlatform()}/crm/promotions/${promotionId}/rule/new`
  return request(url, { method: 'PUT', body })
}

/**
 * Update an promotion rule
 *
 * @param {string|number} ruleId
 */
export const updateRule = (ruleId, body = {}) => {
  const url = `${getBaseUrlPlatform()}/crm/promotions/rule/${ruleId}`
  return request(url, { method: 'PUT', body })
}

/**
 * Delete rule
 *
 * @param {string|number} ruleId
 */
export const deleteRule = (ruleId) => {
  const url = `${getBaseUrlPlatform()}/crm/promotions/rules/${ruleId}`
  return request(url, { method: 'DELETE' })
}

/**
 * Put promotion above other promotion in priority
 *
 * @param {string|number} promotionId
 * @param {string|number} putAbovePromotionUuid
 */
export const updatePromotionPriority = (promotionId, putAbovePromotionUuid) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/priority`
  return request(url, {
    method: 'PUT',
    body: {
      put_above: putAbovePromotionUuid,
    },
  })
}

/**
 * Get promotion incompatible promotions
 *
 * @param {string|number} promotionId
 */
export const getIncompatiblePromotions = (
  promotionId,
  query = {
    club: null,
    'club[]': null,
    'membership.id': null,
    'membership.id[]': null,
    name: null,
    context: null,
    'context[]': null,
    coupon_based: null,
    'order[priority]': null,
    'state[]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/promotions/${promotionId}/incompatible-promotions`,
      query,
    },
    {
      skipNull: true,
    }
  )

  return request(url)
}

/**
 * Add incompatible promotion to promotion
 *
 * @param {string|number} promotionId
 * @param {string} incompatiblePromotionUuid
 */
export const addIncompatiblePromotion = (promotionId, incompatiblePromotionUuid) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/incompatible/add`
  return request(url, {
    method: 'PUT',
    body: {
      incompatible_promotion: incompatiblePromotionUuid,
    },
  })
}

/**
 * Remove incompatible promotion from promotion
 *
 * @param {string|number} promotionId
 * @param {string} incompatiblePromotionUuid
 */
export const removeIncompatiblePromotion = (promotionId, incompatiblePromotionUuid) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/incompatible/remove`
  return request(url, {
    method: 'PUT',
    body: {
      incompatible_promotion: incompatiblePromotionUuid,
    },
  })
}

/**
 * Import coupon emails to a bulk codes voucher/promotion
 *
 * @param {string|number} promotionId
 * @param {string[]} emails
 */
export const importCouponEmails = (promotionId, emails) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/coupon/emails/import`
  return request(url, {
    method: 'PUT',
    body: {
      emails,
    },
  })
}

/**
 * Bulk export voucher coupons
 *
 * @param {string|number} promotionId
 */
export const bulkExportVoucherCoupons = (promotionId) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/coupons/bulk/export`
  return request(url, { method: 'PUT' })
}

/**
 * Delete unassigned bulk codes
 *
 * @param {string|number} promotionId
 */
export const deleteBulkCodesWithoutHolder = (promotionId) => {
  const url = `${getBaseUrlPlatform()}/promotions/${promotionId}/coupons/bulk/unassigned/remove`
  return request(url, { method: 'PUT' })
}
