import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import {
  IEnableOpeningHours,
  IGetOpeningHours,
  IOpeningHours,
  IUpdateOpeningHour,
  OpeningHours,
} from './types'

/**
 * Update an opening hour item
 *
 * @param {IUpdateOpeningHour} payload
 * @return {*}  {IOpeningHours}
 */
export const updateOpeningHour = (payload: IUpdateOpeningHour): Promise<IOpeningHours> => {
  const URL = `${getBaseUrlPlatform()}/warehouses/${payload.warehouseId}/opening-hours`

  return request(URL, {
    method: 'PUT',

    body: payload.body,
  })
}

/**
 * Enables (creates) opening hours for warehouse
 *
 * @param {IEnableOpeningHours} payload
 * @return {*}  {IOpeningHours}
 */
export const enableOpeningHours = (payload: IEnableOpeningHours): Promise<IOpeningHours> => {
  const URL = `${getBaseUrlPlatform()}/warehouses/${payload.warehouseId}/opening-hours/enable`

  return request(URL, {
    method: 'PUT',
  })
}

/**
 * Get all opening hours for a specific warehouse. Will be null if not enabled (created)
 *
 * @param {IGetOpeningHours} payload
 * @return {*}  {IOpeningHours}
 */
export const getOpeningHours = (payload: IGetOpeningHours): Promise<OpeningHours> => {
  const URL = `${getBaseUrlPlatform()}/warehouses/${payload.warehouseId}/opening-hours`

  return request(URL, {
    method: 'GET',
  })
}
