import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import styles from './styles.module.scss'
import SingleRadioOption from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/SingleRadioOption'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Checkbox from '@sweetspot/club-portal-legacy/components/Checkbox'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { SETTINGS } from '@sweetspot/sweetspot-js/features/vouchers/hooks/useVoucher'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import { currencyCodeInCurrentLanguage } from '@sweetspot/sweetspot-js/common/functions/utils'

const ActionsTab = ({ discount, voucherActions, isActive, editField, errors, isLoading }) => {
  const { t } = useTranslation()
  const discountSelected = discount[SETTINGS.IS_USES_TYPE] || discount[SETTINGS.IS_VALUE_TYPE]
  const [currencyCode] = useClubCurrency()
  return (
    <div className={cx(styles.container, !isActive && styles.inactive)}>
      {/*<div className={styles.actionsArea}>*/}
      {/*  <span className={styles.label}>{t('vouchers.label_ApplyDiscount')}</span>*/}
      {/*  <div className={styles.row}>*/}
      {/*    <SingleRadioOption*/}
      {/*      containerClassName={cx(styles.option)}*/}
      {/*      value={''}*/}
      {/*      checked={true}*/}
      {/*      label={t('vouchers.option_Items')}*/}
      {/*    />*/}
      {/*    <SingleRadioOption*/}
      {/*      containerClassName={cx(styles.option)}*/}
      {/*      value={''}*/}
      {/*      checked={true}*/}
      {/*      label={t('vouchers.option_BookingTotal')}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={styles.actionsArea}>
        <span className={styles.label}>{t('vouchers.label_DiscountType')}</span>
        <div className={styles.row}>
          <SingleRadioOption
            containerClassName={cx(styles.option)}
            value={1}
            checked={discount[SETTINGS.IS_USES_TYPE]}
            label={t('vouchers.option_Uses')}
            onChange={() => voucherActions.editDiscount(SETTINGS.IS_USES_TYPE)}
          />
          <SingleRadioOption
            containerClassName={cx(styles.option)}
            value={1}
            checked={discount[SETTINGS.IS_VALUE_TYPE]}
            label={t('vouchers.option_DiscountValue')}
            onChange={() => voucherActions.editDiscount(SETTINGS.IS_VALUE_TYPE)}
          />
        </div>
      </div>
      {discountSelected && (
        <div className={styles.inputArea}>
          <div className={styles.input}>
            <span className={styles.label}>{t('vouchers.label_Discount')}</span>
            <TextInputField
              theme="voucherB"
              suffix="%"
              type={'number'}
              inputProps={{
                min: 0,
              }}
              value={discount[SETTINGS.PERCENTAGE]}
              error={errors[SETTINGS.PERCENTAGE]}
              onChange={(value = '') =>
                voucherActions.editDiscount(SETTINGS.PERCENTAGE, value.trim())
              }
            />
          </div>
          <div className={cx(styles.input, discount[SETTINGS.IS_VALUE_TYPE] && styles.value)}>
            <span className={styles.label}>
              {t(`vouchers.option_${discount[SETTINGS.IS_USES_TYPE] ? 'Uses' : 'DiscountValue'}`)}
            </span>
            <TextInputField
              value={discount[SETTINGS.USES_VALUE]}
              error={errors[SETTINGS.USES_VALUE]}
              type={'number'}
              inputProps={{
                min: 0,
              }}
              theme="voucherB"
              onChange={(value = '') =>
                voucherActions.editDiscount(SETTINGS.USES_VALUE, value.trim())
              }
              suffix={
                discount[SETTINGS.IS_VALUE_TYPE]
                  ? currencyCodeInCurrentLanguage(currencyCode)
                  : undefined
              }
            />
          </div>
          <div className={cx(styles.input, discount[SETTINGS.IS_VALUE_TYPE] && styles.value)}>
            <span className={styles.label}>{t(`vouchers.option_MinimumPrice`)}</span>
            <TextInputField
              value={discount[SETTINGS.MIN_PRICE] || 0}
              error={errors[SETTINGS.MIN_PRICE]}
              type={'number'}
              inputProps={{
                min: 0,
              }}
              theme="voucherB"
              onChange={(value = '') =>
                voucherActions.editDiscount(SETTINGS.MIN_PRICE, value.trim())
              }
              suffix={currencyCodeInCurrentLanguage(currencyCode)}
            />
          </div>
        </div>
      )}
      {discountSelected && (
        <div className={styles.actionsArea}>
          <span className={styles.label}>{t('vouchers.label_ApplyDiscount')}</span>
          <div className={cx(styles.row, styles.target)}>
            <Checkbox
              containerClassName={styles.checkbox}
              append={t('vouchers.option_GolfCarts')}
              appendClassName={styles.checkboxLabel}
              checked={discount[SETTINGS.APPLY_GOLF_CART]}
              onChange={(value) => voucherActions.editDiscount(SETTINGS.APPLY_GOLF_CART, value)}
            />
            <Checkbox
              containerClassName={styles.checkbox}
              append={t('vouchers.option_CodeHolder')}
              appendClassName={styles.checkboxLabel}
              checked={discount[SETTINGS.APPLY_HOLDER]}
              onChange={(value) => voucherActions.editDiscount(SETTINGS.APPLY_HOLDER, value)}
            />
            <Checkbox
              containerClassName={styles.checkbox}
              append={t('vouchers.option_GuestHolder')}
              appendClassName={styles.checkboxLabel}
              checked={discount[SETTINGS.APPLY_GUEST]}
              onChange={(value) => voucherActions.editDiscount(SETTINGS.APPLY_GUEST, value)}
            />
          </div>
        </div>
      )}
      {editField === 'discount' && (
        <div className={styles.saveArea}>
          {errors[SETTINGS.APPLY_GOLF_CART] ? (
            <p className={styles.error}>{errors[SETTINGS.APPLY_GOLF_CART]}</p>
          ) : (
            <Button size="small" width="third" onClick={() => voucherActions.saveDiscount()}>
              {isLoading ? (
                <PulseLoader
                  color="#FFF"
                  dotStyles={{ width: 10, height: 10, marginTop: '7px' }}
                  showIf={true}
                />
              ) : (
                t('vouchers.button_Save')
              )}
            </Button>
          )}
          <Button
            size="small"
            width="third"
            theme="default-outline"
            text={t('vouchers.button_Cancel')}
            onClick={() => voucherActions.cancelEdit('discount')}
          />
        </div>
      )}
    </div>
  )
}

ActionsTab.propTypes = {}

ActionsTab.defaulProps = {}

export default ActionsTab
