import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import style from './style.module.scss'
import moment from 'moment'
import i18n from 'i18next'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import BookingModal from '@sweetspot/club-portal-legacy/modals/BookingModal'
import EditTeeTime from '../../components/EditTeeTime'
import PlayerCardModal from '@sweetspot/club-portal-legacy/modals/PlayerCard'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

import headers from './headers.json'

import { getTeeTimesForDayFromGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { getNoShowSlots } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import { ReactComponent as GpsIcon } from '@sweetspot/sweetspot-js/assets/svgs/gps-icon.svg'
import {
  getStartAndEndOfDateLocal,
  toISOStringWithOffset,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'

const bookingStatus = ['pay on site', 'paid', 'waiting for payment']

class NoShow extends Component {
  state = {
    isLoading: false,
    noShows: [],
    currentPage: 1,
    totalPages: 0,
    rowsPerPage: 0,
    totalRows: 0,
    showBookingPopup: false,
    searchString: '',
    noSearchResult: false,
  }

  componentDidMount = () => {
    this.getNoShows()
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props
    const { state } = prevProps.location

    if (location && location.state && location.state.shouldReload && !state) {
      this.getNoShows()
    }

    if (!_.isEqual(this.props.golfCourses, prevProps.golfCourses)) {
      this.getNoShows()
    }
  }

  parseNoShows = (fetchedNoShows) => {
    return fetchedNoShows.map((x) => {
      const player = x?.player?.first_name
        ? `${x?.player?.first_name} ${x?.player?.last_name}`
        : x?.stub_player?.name || '-'
      return {
        id: x.id,
        teeTime: DateHelpers.dateStringwithMinutes(moment(x.booking?.start_time)),
        from: x.booking?.start_time,
        course: x.booking?.course?.name,
        courseId: x.booking?.course?.id,
        courseUuid: x.booking?.course?.uuid,
        player,
        playerId: x?.player?.id || '-',
        golfId: x?.player?.golf_id || '-',
        bookingOwner: x?.is_owner ? i18n.t('words.yes') : i18n.t('words.no'),
        email: x?.player?.email || '-',
        phone: x?.player?.phone || '-',
      }
    })
  }

  getNoShows = async (pageNum = 1) => {
    const { searchString } = this.state
    const {
      golfClubs: { selectedId },
    } = this.props

    this.setState({ isLoading: true })

    const currentTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss')
    let query = `?isArrivalRegistration=false&course_type=course&booking.git_booking.id%5Bexists%5D=false%5Bexists%5D&booking.startTime%5Blt%5D=${currentTime}&order%5Bbooking.startTime%5D=desc&page=${pageNum}`
    if (selectedId) query += `&club=${selectedId}`
    if (searchString) query += `&search=${encodeURIComponent(searchString)}`
    bookingStatus.forEach((status) => {
      query += `&${encodeURIComponent('booking.status[]')}=${encodeURIComponent(status)}`
    })
    const [res, err] = await to(getNoShowSlots(selectedId, query))

    if (res) {
      const rowsPerPage = res['hydra:itemsPerPage']
      const totalRows = res['hydra:totalItems']
      const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
      const noShowList = res['hydra:member']
      const parsedNoShows = this.parseNoShows(noShowList)
      if (!totalRows && this.state.currentPage > 1) {
        this.setState({ currentPage: pageNum - 1 }, () => this.getNoShows(pageNum - 1))
        return
      }
      this.setState({
        noShows: parsedNoShows,
        noSearchResult: !!searchString && !parsedNoShows.length,
        totalPages,
        rowsPerPage,
        totalRows,
        isLoading: false,
      })
    } else if (err) {
      this.setState({
        isLoading: false,
        noShows: [],
        noSearchResult: false,
        totalPages: 1,
        rowsPerPage: 100,
        totalRows: 0,
      })
    }
  }

  handleCloseBookingModal = () => {
    this.setState({ showBookingPopup: false }, () => this.getNoShows(this.state.currentPage || 1))
  }

  handleSearchChange = (value) => {
    this.setState({ searchString: value })
  }

  setPageNum = (page) => {
    this.setState({ currentPage: page })
    this.getNoShows(page)
  }

  openTeeTimeModal = (row) => {
    const { selectedGolfCourse, getTeeTimesForDayFromGolfCourse } = this.props

    if (selectedGolfCourse === 0) return
    this.setState({ isLoading: true }, () => {
      const dailyTimeStamp = new Date(row.from)
      const [startDay, endDay] = getStartAndEndOfDateLocal(dailyTimeStamp)
      getTeeTimesForDayFromGolfCourse(
        row.courseUuid ? row.courseUuid : selectedGolfCourse,
        toISOStringWithOffset(startDay),
        toISOStringWithOffset(endDay)
      )
        .then((teeTimes) => {
          const timestamp = moment.utc(row?.from).unix()
          const teeTime = teeTimes.find((time) => moment.utc(time.from).unix() === timestamp)
          if (!teeTime) return
          this.setState({ isLoading: false, selectedTeeTime: teeTime, showBookingPopup: true })
        })
        .catch(() =>
          this.setState({ isLoading: false, selectedTeeTime: null, showBookingPopup: false })
        )
    })
  }

  openPlayerCard = (row) => {
    this.props.history.push(`no-show/player/${row.playerId}`)
  }

  render() {
    const {
      isLoading,
      noShows,
      totalPages,
      totalRows,
      rowsPerPage,
      selectedTeeTime,
      showBookingPopup,
      noSearchResult,
    } = this.state

    return (
      <div className={style.content}>
        <Grid
          values={noShows}
          pageTitle={'noShow'}
          headers={headers}
          hideArrows
          shouldHideColumnEdit
          onRowClick={(row) => this.openTeeTimeModal(row)}
          rightClickOptions={[
            {
              label: i18n.t('openBooking'),
              onClick: (row) => this.openTeeTimeModal(row),
              disabled: false,
            },
            {
              label: i18n.t('openPlayerCard'),
              onClick: (row) => this.openPlayerCard(row),
              disabled: false,
            },
          ]}
          pagination
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setCurrentPage={(page) => this.setPageNum(page)}
          displayRangeOfRows
          searchEnabled
          searchPlaceholder={'.players.searchPlayer'}
          onSearchChange={this.handleSearchChange}
          onSearch={() => this.getNoShows()}
        />
        {!noShows ||
          (!noShows.length && (
            <div className={style.container}>
              {isLoading ? (
                <div className={style.loading}>
                  <PulseLoader showIf={isLoading} />
                  <Text textId="loadingData" />
                </div>
              ) : (
                <div>
                  <GpsIcon />
                  <Text
                    textId={noSearchResult ? 'noSearchResults' : 'allPlayersHasBeenAR'}
                    className={style.emptyState}
                  />
                </div>
              )}
            </div>
          ))}

        {showBookingPopup && (
          <div className={style.bookingModalContainer}>
            <BookingModal
              teeTimeId={selectedTeeTime.uuid}
              onClose={() => this.handleCloseBookingModal()}
              type="clubPortal"
              EditTeeTimeComponent={EditTeeTime}
            />
          </div>
        )}
        <Switch>
          <Route exact path="/no-show/player/:id" component={PlayerCardModal} />
        </Switch>
      </div>
    )
  }
}

NoShow.propTypes = {
  golfClubs: PropTypes.object.isRequired,
  golfCourses: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  history: PropTypes.object,
  token: PropTypes.string.isRequired,
  selectedGolfCourse: PropTypes.number,
  getTeeTimesForDayFromGolfCourse: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubs: state.golfClub,
    golfClub: state.golfClub.selectedId,
    golfCourses: state.golfCourse.list,
    selectedGolfCourse: state.golfCourse.selectedId,
    isSuperAdmin: state.auth.isSuperAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTeeTimesForDayFromGolfCourse: (courseUuid, startIso, endIso) =>
      dispatch(getTeeTimesForDayFromGolfCourse(courseUuid, startIso, endIso)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoShow)
