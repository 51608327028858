import React, { useState } from 'react'
import style from './style.module.scss'
import PropTypes from 'prop-types'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import { GOLF_ID_OPTIONS } from '@sweetspot/shared/util/constants'

const EditSideMenu = ({
  handleEditSave,
  disabledButtons,
  isLoading,
  teeTimeCategories,
  handleEditCancel,
  handleEditClear,
}) => {
  const [form, setForm] = useState({
    status: null,
    golfId: null,
    slots: '',
    primeTime: null,
  })
  const { t } = useTranslation()
  const { status, golfId, slots, primeTime } = form
  const golfIdValues = [{ id: -1, name: '-', value: null }, ...GOLF_ID_OPTIONS]
  const teeTimeValues = [
    { id: -1, name: '-', value: null },
    ...teeTimeCategories.map((item) => ({
      id: item.id,
      color: item.color,
      name: item.name,
      value: item.id,
    })),
  ]
  const primeTimeValues = [
    { id: -1, name: '-', value: null },
    { id: 0, name: t('yes'), value: true },
    { id: 1, name: t('no'), value: false },
  ]

  const handleChange = ({ name, value }) => {
    let parsedValue

    if (name === 'slots') {
      if (value <= 0 || value > 10) parsedValue = 0
      else parsedValue = parseInt(value) > 0 ? parseInt(value) : null
    } else parsedValue = value === -1 ? null : value

    setForm({ ...form, [name]: parsedValue })
  }

  const handleSubmit = () => {
    const payload = {
      status: status || null,
      slots: typeof slots === 'number' ? slots : null,
      is_golf_id_required: golfId === null || golfId === -1 ? null : !!golfId,
      is_prime_time:
        primeTime !== null ? primeTimeValues.find((x) => x.id === primeTime)?.value : primeTime,
    }
    handleEditSave(payload)
  }

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.mainLabel}>
          <Text textId="editPeriodsNew.createOverride" />
        </div>
        <div className={style.label}>
          <Text textId="golf.selectTimePanels" />
        </div>
        <div className={style.settings}>
          <div className={style.status}>
            <span className={style.label}>{t('category')}</span>
            <DropdownSelect
              readOnly
              showBorder
              hideSelf
              initialId={-1}
              selectedId={status}
              disabled={disabledButtons}
              values={teeTimeValues.map((item) => ({
                id: item.id,
                color: item.color,
                name: item.name,
                value: item.id,
              }))}
              onSelect={(value) => handleChange({ name: 'status', value })}
            />
          </div>
          <div className={style.golfId}>
            <span className={style.label}>{t('golf_id')}</span>
            <DropdownSelect
              readOnly
              showBorder
              hideSelf
              disabled={disabledButtons}
              initialId={-1}
              selectedId={golfId}
              values={golfIdValues.map((item) => ({
                ...item,
                name: item.value ? t(`teeSheet.${item.value}`) : '-',
              }))}
              onSelect={(value) => handleChange({ name: 'golfId', value })}
            />
          </div>
          <div className={style.slots}>
            <span className={style.label}>{t('slots')}</span>
            <TextInputField
              type="number"
              disabled={disabledButtons}
              value={slots}
              placeholder={'-'}
              onChange={(value) => handleChange({ name: 'slots', value })}
            />
          </div>
          <div className={style.primeTime}>
            <span className={style.label}>{t('primeTime')}</span>
            <DropdownSelect
              readOnly
              showBorder
              hideSelf
              disabled={disabledButtons}
              initialId={-1}
              selectedId={primeTime}
              values={primeTimeValues}
              onSelect={(value) => handleChange({ name: 'primeTime', value })}
            />
          </div>
        </div>
        <Button
          className={style.clearButton}
          theme="danger-outline"
          disabled={disabledButtons}
          onClick={handleEditClear}
          width="auto"
          size="medium"
        >
          {t('clearSelection')}
        </Button>
        <div className={style.buttonRow}>
          <Button
            className={style.cancelButton}
            theme="none"
            width="third"
            size="medium"
            onClick={handleEditCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            theme="default"
            loading={isLoading}
            disabled={disabledButtons || Object.values(form).filter((x) => x !== null).length === 0}
            width="third"
            size="medium"
            onClick={() => handleSubmit()}
          >
            {t('save')}
          </Button>
        </div>
        <div className={style.tip}>
          <Text textId="teeSheet.selectTeeTimeTip" />
        </div>
      </div>
    </div>
  )
}

EditSideMenu.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  teeTimeCategories: PropTypes.array.isRequired,
  handleEditCancel: PropTypes.func.isRequired,
  handleEditSave: PropTypes.func.isRequired,
  handleEditClear: PropTypes.func.isRequired,
  disabledButtons: PropTypes.bool.isRequired,
}

export default EditSideMenu
