import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { FeatureFlag } from './types'

export const getAllFeatureFlags = (): Promise<FeatureFlag> => {
  const url = `${getBaseUrlPlatform()}/features`
  return request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
