import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'

import {
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
} from '@sweetspot/shared/util/session-storage'

import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import styles from './styles.module.scss'
import { STORAGE_KEYS } from '@sweetspot/shared/util/constants'

const PlayersControls = ({
  onAddCopiedPlayers,
  keyModifier,
  showCopyPlayers,
  players,
  className,
  itemWrapperClassName,
  loading,
  theme,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [showInsert, setShowInsert] = useState(false)
  const [showCopy, setShowCopy] = useState(false)

  const KEY = `${STORAGE_KEYS.COPIED_PLAYERS_KEY}-${keyModifier || ''}`

  useEffect(() => {
    if (players?.length > 0) {
      setShowCopy(true)
    } else {
      setShowCopy(false)
    }
  }, [players])

  useEffect(() => {
    const func = () => {
      if (getSessionStorage(KEY)) {
        setShowInsert(true)
      } else {
        setShowInsert(false)
      }
    }
    const interval = setInterval(func, 2000)
    func()
    return () => clearInterval(interval)
  }, [KEY])

  const handleCopyPlayers = () => {
    setSessionStorage(KEY, JSON.stringify(players))
    setShowInsert(true)
    addToast(t('sentences.playersCopied'), { appearance: 'success' })
  }

  const handleAddCopiedPlayers = () => {
    const importedPlayers = JSON.parse(getSessionStorage(KEY))
    removeSessionStorage(KEY)
    setShowInsert(false)
    onAddCopiedPlayers(importedPlayers)
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        },
        className
      )}
    >
      {loading ? (
        <Skeleton width="100%" height={30} />
      ) : (
        <React.Fragment>
          {showCopyPlayers && showCopy && players && (
            <Button
              className={cx(styles.itemWrapper, itemWrapperClassName)}
              size="default"
              theme="gray"
              width="auto"
              loading={loading}
              loaderStyle="pulse"
              onClick={handleCopyPlayers}
            >
              {t('words.copy')}
            </Button>
          )}
          {onAddCopiedPlayers && showInsert && (
            <Button
              className={cx(styles.itemWrapper, itemWrapperClassName)}
              onClick={handleAddCopiedPlayers}
              size="default"
              width="auto"
              theme="gray"
              loading={loading}
              loaderStyle="pulse"
            >
              <AddIcon className={cx(styles.icon, styles.addIcon)} />
              {t('sentences.insertCopiedPlayers')}
            </Button>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

PlayersControls.propTypes = {
  onAddCopiedPlayers: PropTypes.func,
  showCopyPlayers: PropTypes.bool,
  players: PropTypes.array,
  className: PropTypes.string,
  itemWrapperClassName: PropTypes.string,
  loading: PropTypes.bool,
  keyModifier: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  availableSlots: PropTypes.number,
}

PlayersControls.defaultProps = {
  onAddCopiedPlayers: undefined,
  showCopyPlayers: true,
  players: [],
  loading: false,
  keyModifier: '',
  theme: 'dark',
  availableSlots: 1,
}

export default PlayersControls
