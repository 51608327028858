import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { Payment, PaymentRequestPayload } from './types'
import { Hydra } from '@sweetspot/shared/types'
import queryString from 'query-string'
import { QueryRecord } from '@sweetspot/shared/types'

/**
 * Get all failed payments
 */
export const getFailedMembershipPayments = (
  query: PaymentRequestPayload
): Promise<Hydra<Payment>> => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/membership-payments?type=installment_loan&payment_provider_state=refused&state=awaiting_payment`,
      query: query as QueryRecord<typeof query>,
    },
    {
      arrayFormat: 'bracket',
    }
  )

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'Application/ld+json',
    },
  })
}
