import { Range, BayType } from '@sweetspot/shared/types'

export enum BaySideBarMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export type BaysContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  bays: BayType[]
  areBaysLoading: boolean
  selectedBays?: BayType[]
  setSelectedBays: (bays: BayType[]) => void
  openBaySideBar: boolean
  toggleBaySideBar: (mode?: BaySideBarMode) => void
  changeBaySideBarMode: (mode: BaySideBarMode) => void
  reFetchBays: () => void
  baySideBarMode?: BaySideBarMode
}
