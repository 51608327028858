import { useState, useMemo, useCallback } from 'react'
import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import Summary from './Summary'
import Details from './Details'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { TrackingTech } from '@sweetspot/shared/types'
import { useTranslation } from 'react-i18next'
import { updateBay } from '@sweetspot/shared/data-access/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useToasts } from 'react-toast-notifications'

const UpdateBay = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { selectedRange, openBaySideBar, selectedBays, toggleBaySideBar, reFetchBays } =
    useBaysContext()
  const [numberOfBays] = useState<number>(selectedBays?.length || 1)

  const isBulkEdit = useMemo(() => numberOfBays > 1, [numberOfBays])

  const [floor, setFloor] = useState<number>(
    !isBulkEdit && selectedBays && selectedBays[0].floor !== undefined ? selectedBays[0].floor : 1
  )
  const [roof, setRoof] = useState<boolean>(
    !isBulkEdit && selectedBays ? selectedBays[0].roof : true
  )
  const [trackingTech, setTrackingTech] = useState<TrackingTech>(
    !isBulkEdit && selectedBays ? selectedBays[0].tracking_technology : TrackingTech.NONE
  )

  const { title, updateButtonTitle } = useMemo(() => {
    if (numberOfBays === 1) {
      return {
        title: `${t('settings.bays.bay')} ${t('words.edit')}`,
        updateButtonTitle: `${t('update')} ${t('settings.bays.bay')}`,
      }
    }

    return {
      title: `${t('settings.bays.bay')} ${t('words.edit')} ${t('words.bulk')} (${numberOfBays})`,
      updateButtonTitle: `${t('update')} ${numberOfBays} ${t('settings.bays.bays')} ${t(
        'communication.in'
      )} ${t('words.bulk')}`,
    }
  }, [numberOfBays, t])

  const handleUpdateBay = useCallback(async () => {
    if (!selectedRange?.id || !selectedBays?.length) {
      return
    }

    try {
      const [, error] = await to(
        updateBay({
          rangeId: selectedRange?.id,
          bayId: selectedBays[0].id,
          bay: {
            floor,
            roof,
            tracking_technology: trackingTech,
          },
        })
      )

      if (error?.errors?.length) {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
        return
      }
      await reFetchBays()
      toggleBaySideBar()
    } catch (error) {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    }
  }, [
    selectedRange?.id,
    selectedBays,
    floor,
    roof,
    trackingTech,
    reFetchBays,
    toggleBaySideBar,
    addToast,
    t,
  ])

  return (
    <Sheet open={openBaySideBar} onOpenChange={(open) => toggleBaySideBar(BaySideBarMode.EDIT)}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={() => toggleBaySideBar()}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Summary
            numberOfBays={numberOfBays}
            bayNumber={isBulkEdit ? undefined : selectedBays && selectedBays[0].bay_number}
          />
          <Details
            floor={floor}
            setFloor={setFloor}
            roof={roof}
            setRoof={setRoof}
            trackingTech={trackingTech}
            setTrackingTech={setTrackingTech}
          />
        </SheetCustomContent>
        {isBulkEdit && (
          <p className="text-background-mono-dark text-center">Bulk edit is coming soon...</p>
        )}
        <SheetFooter>
          <Button
            disabled={isBulkEdit}
            onClick={handleUpdateBay}
            className="min-h-touch-height-lg w-full"
          >
            {updateButtonTitle}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default UpdateBay
