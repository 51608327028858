import React, { Component } from 'react'
import ReactTable from 'react-table'
import ReactToPrint from 'react-to-print'
import { format } from 'date-fns'

import { getTeeSheetData as getTeeSheetDataService } from '@sweetspot/shared/data-access/api-platform'
import { getLang } from '@sweetspot/club-portal-legacy/languages'
import { queryTeeTimes } from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import {
  fetchBookingItems,
  fetchBookings,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { BOOKING_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'

import styles from './style.module.scss'

const WIDTH = 20
const WIDTH2 = 40

const PLAYER_TYPES = {
  STUB: 'AG',
  MEMBER: 'M',
  GUEST: 'G',
}

class TeeSheetShare extends Component {
  constructor() {
    super()

    this.state = {
      isLoading: false,
      tableData: [],
      locale: 'sv',
    }

    this.setColumns()
  }

  componentDidMount() {
    this.getTeeSheetData()
  }

  setColumns() {
    const { locale } = this.state

    this.columns = [
      {
        Header: () => (
          <ReactToPrint
            trigger={() => (
              <button className={`system-button info-outline md-32`}>
                {getLang(locale)['print']}
              </button>
            )}
            content={() => this._teeSheetShare}
          />
        ),
        accessor: 'start_time',
        Cell: ({ row }) => (
          <span className={row.start_time.substr(3, 2) === '00' ? styles.bold : ''}>
            {row.start_time}
          </span>
        ),
        width: 80,
        style: { textAlign: 'center', height: '30px' },
      },
      {
        Header: () => <div>{getLang(locale)['player']} 1</div>,
        accessor: 'player1',
        headerClassName: styles.header1,
        className: styles.cell1,
      },
      {
        Header: () => <div>{getLang(locale)['category']}</div>,
        accessor: 'category1',
        headerClassName: styles.header2,
        className: styles.cellCategory,
        width: WIDTH,
      },
      {
        Header: () => <div>{getLang(locale)['hcp']}</div>,
        accessor: 'hcp1',
        headerClassName: styles.header2,
        className: styles.cell2,
        width: WIDTH2,
      },
      {
        Header: () => <div>{getLang(locale)['player']} 2</div>,
        accessor: 'player2',
        headerClassName: styles.header1,
        className: styles.cell1,
      },
      {
        Header: () => <div>{getLang(locale)['category']}</div>,
        accessor: 'category2',
        headerClassName: styles.header2,
        className: styles.cellCategory,
        width: WIDTH,
      },
      {
        Header: () => <div>{getLang(locale)['hcp']}</div>,
        accessor: 'hcp2',
        headerClassName: styles.header2,
        className: styles.cell2,
        width: WIDTH2,
      },
      {
        Header: () => <div>{getLang(locale)['player']} 3</div>,
        accessor: 'player3',
        headerClassName: styles.header1,
        className: styles.cell1,
      },
      {
        Header: () => <div>{getLang(locale)['category']}</div>,
        accessor: 'category3',
        headerClassName: styles.header2,
        className: styles.cellCategory,
        width: WIDTH,
      },
      {
        Header: () => <div>{getLang(locale)['hcp']}</div>,
        accessor: 'hcp3',
        headerClassName: styles.header2,
        className: styles.cell2,
        width: WIDTH2,
      },
      {
        Header: () => <div>{getLang(locale)['player']} 4</div>,
        accessor: 'player4',
        headerClassName: styles.header1,
        className: styles.cell1,
      },
      {
        Header: () => <div>{getLang(locale)['category']}</div>,
        accessor: 'category4',
        headerClassName: styles.header2,
        className: styles.cellCategory,
        width: WIDTH,
      },
      {
        Header: () => <div>{getLang(locale)['hcp']}</div>,
        accessor: 'hcp4',
        headerClassName: styles.header2,
        className: styles.cell2,
        width: WIDTH2,
      },
      {
        Header: () => <div>{getLang(locale)['notes']}</div>,
        accessor: 'notes',
        headerClassName: styles.header1,
        className: styles.cell1,
        Cell: ({ row }) => (
          <span className="flex flex-col">
            {row.notes?.map((note) => (
              <span className="overflow-wrap-break-word max-w-full whitespace-pre-line">
                &#x2022; {note}
              </span>
            ))}
          </span>
        ),
      },
    ]
  }

  async getTeeSheetData() {
    // eslint-disable-next-line
    const hash = this.props.match.params.id

    this.setState({ isLoading: true })

    const teeSheetData = await getTeeSheetDataService(hash)
    this.setState(
      {
        isLoading: false,
        locale: teeSheetData.locale,
      },
      this.setColumns
    )

    const startTime = format(new Date(teeSheetData.available_date), "yyyy-MM-dd'T00:00:00'xxx")
    const endTime = format(new Date(teeSheetData.available_date), "yyyy-MM-dd'T23:59:59'xxx")

    const bookings = await fetchBookings({
      'booking.start_time[after]': startTime,
      'booking.start_time[before]': endTime,
      'course.uuid': teeSheetData.course?.uuid,
      'groups[]': [BOOKING_GROUPS.LEGACY_BOOKING, BOOKING_GROUPS.PARTNERSHIP],
      'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
      limit: 200,
      page: 1,
    })

    const bookingItemsByTeeTime = []
    for (const booking of bookings) {
      const bookingItems = await fetchBookingItems(booking.uuid, {
        'groups[]': [ITEM_GROUPS.SLOT, ITEM_GROUPS.PRODUCT_VARIANT],
      })

      if (bookingItems && bookingItems.length > 0) {
        const teeTimeUuid = bookingItems[0].slot.tee_time.uuid
        const existingBookingGroup = bookingItemsByTeeTime.find((group) => group.id === teeTimeUuid)

        if (existingBookingGroup) {
          existingBookingGroup.bookingItems.push(...bookingItems)
        } else {
          bookingItemsByTeeTime.push({ id: teeTimeUuid, bookingItems })
        }
      }
    }

    const teeTimes = await queryTeeTimes({
      'course.uuid': teeSheetData.course?.uuid,
      'from[after]': startTime,
      'from[before]': endTime,
      'order[from]': 'asc',
      limit: 100,
      page: 1,
    })

    const getPlayerData = (item) => {
      const { is_member, player, stub_player } = item.slot
      if (stub_player?.name) {
        return {
          category: PLAYER_TYPES.STUB,
          playerName: stub_player.name,
          playerHcp: '',
        }
      }

      const playerName = player ? `${player.first_name || ''} ${player.last_name || ''}` : ''
      const playerCategory = is_member ? PLAYER_TYPES.MEMBER : PLAYER_TYPES.GUEST
      const playerHcp = player?.hcp

      return { category: playerCategory, playerName, playerHcp }
    }

    const updateRowWithPlayerData = (row, item, rowIndex) => {
      if (!item.slot) return false
      const { category, playerName, playerHcp } = getPlayerData(item)
      row[`category${rowIndex}`] = category
      row[`player${rowIndex}`] = playerName
      row[`hcp${rowIndex}`] = playerHcp
      return true
    }

    const tableData = teeTimes.map((teeTime) => {
      const row = {
        start_time: format(new Date(teeTime.from), 'HH:mm'),
        notes: JSON.parse(teeTime?.notes)?.map((note) => note?.text),
      }

      const items =
        bookingItemsByTeeTime.find((items) => items.id === teeTime.uuid)?.bookingItems || []

      let rowIndex = 1
      items.forEach((item) => {
        const updated = updateRowWithPlayerData(row, item, rowIndex)
        if (updated) rowIndex++
      })

      return row
    })

    this.setState({ tableData })
  }

  render() {
    const { tableData } = this.state

    return (
      <div className={styles.container}>
        <ReactTable
          ref={(el) => (this._teeSheetShare = el)}
          data={tableData}
          columns={this.columns}
          showPagination={false}
          resizable={false}
          sortable={false}
          pageSize={tableData.length}
          NoDataComponent={() => null}
        />
      </div>
    )
  }
}

export default TeeSheetShare
