import { useRef, useMemo } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

const AddGolfCartModal = ({ golfCarts, booking, handleAddGolfCart }) => {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => handleAddGolfCart(null, null))

  const availableCarts = useMemo(() => {
    if (golfCarts.variants && golfCarts.product.product_variants) {
      return golfCarts.variants.map((variant) => {
        return golfCarts.product.product_variants.find((product) => product.uuid === variant.uuid)
      })
    } else {
      return []
    }
  }, [golfCarts])

  return (
    <div ref={wrapperRef} className={cx(styles.golfCartVariantsContainer)}>
      {availableCarts.map((el) => (
        <div
          key={el.uuid}
          onClick={() => handleAddGolfCart(booking, el.uuid)}
          className={cx(styles.innerContainer, {
            [styles.disabledColfCart]: el.disabled,
          })}
        >
          <span className={cx(styles.golfCartNameContainer)} title={el.name}>
            {el.name}
          </span>
          <span className={cx(styles.golfCartPriceContainer)}>
            {priceToLocal(el.fees[0].price, booking.currency_code, true)}
          </span>
        </div>
      ))}
    </div>
  )
}

export default AddGolfCartModal
