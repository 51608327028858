import { ReactComponent as InfoSymbol } from '@sweetspot/sweetspot-js/assets/svgs/info-symbol.svg'
import HoverDiv from '@sweetspot/sweetspot-js/common/components/HoverDiv'
import cx from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PeriodRule from '../../components/PeriodRule'
import style from '../../style.module.scss'
import ManageTab from './ManageTab'
import PriceListTab from './PriceListTab'

const TimePeriodDetails = (props) => {
  const { hash: windowHash } = window.location

  const { t } = useTranslation()
  const { activePeriod, selectedCourse } = props
  const [activeTab, setActiveTab] = useState(
    ['#price-list', '#manage'].includes(windowHash) ? windowHash : '#manage'
  )

  const changeTab = (tabName) => {
    window.location.hash = tabName
    setActiveTab(tabName)
  }

  return (
    <>
      <PeriodRule period={activePeriod} isLoading={false} />

      <div className={style.timePeriodDetailsContainer}>
        <div className={style.tabsContainer}>
          <ul className={cx(style.toggleMenu, style.container)}>
            <li className={activeTab === '#manage' ? style.active : ''}>
              <span onClick={() => changeTab('#manage')} className="cursor-pointer">
                {t('editPeriodsNew.manageTab')}
              </span>
            </li>
            <li className={activeTab === '#price-list' ? style.active : ''}>
              <span onClick={() => changeTab('#price-list')} className="cursor-pointer">
                {t('editPeriodsNew.priceListTab')}
              </span>
            </li>
          </ul>
        </div>

        {activeTab === '#price-list' && selectedCourse?.is_use_dynamic_pricing ? (
          <div className={style.teeTimePeriodsMainContainer}>
            <div className={style.teeTimePeriodOptions}>
              <div className={style.activatePriceList}>
                <h6 className={style.letterStyle}>
                  <span>{t('editPeriodsNew.activateThisPriceList')}</span>
                  <div className={style.infoHover}>
                    <HoverDiv direction="left" hoverText={t('editPeriodsNew.infoHoverNewPricing')}>
                      <InfoSymbol />
                    </HoverDiv>
                  </div>
                </h6>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {activeTab === '#price-list' ? (
        <PriceListTab period={activePeriod} isCourse={selectedCourse.type === 'course'} />
      ) : null}
      {activeTab === '#manage' ? <ManageTab period={activePeriod} /> : null}
    </>
  )
}

export default TimePeriodDetails
