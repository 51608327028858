import { FederationPlayer } from '@sweetspot/shared/types'
import { InformationField, SidebarItem } from '../types'

export const generateSidebarItems = (
  sidebar: SidebarItem,
  gitInfo: FederationPlayer | null
): InformationField[] => {
  const baseMenuItems = [
    {
      id: 1,
      text: 'partnerAdmin.information',
      disabled: false,
      isActive: sidebar === SidebarItem.INFORMATION,
    },
    {
      id: 2,
      text: 'membership.memberships',
      disabled: false,
      isActive: sidebar === SidebarItem.MEMBERSHIP,
    },
    {
      id: 3,
      text: 'words.vouchers',
      disabled: false,
      isActive: sidebar === SidebarItem.VOUCHER,
    },
    {
      id: 4,
      text: 'bookings',
      disabled: false,
      isActive: sidebar === SidebarItem.BOOKING,
    },
  ]

  if (gitInfo) {
    baseMenuItems.push({
      id: 6,
      text: 'settings.playerCard.gitInfo',
      disabled: false,
      isActive: sidebar === SidebarItem.GIT_INFO,
    })
  }

  return baseMenuItems
}
