import { useMemo } from 'react'
import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import Summary from './Summary'
import Details from './Details'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { useTranslation } from 'react-i18next'

const BayDetails = () => {
  const { t } = useTranslation()
  const { openBaySideBar, selectedBays, toggleBaySideBar, changeBaySideBarMode } = useBaysContext()

  const selectedBay = useMemo(() => selectedBays && selectedBays[0], [selectedBays])

  const { title, submitButtonTitle } = useMemo(() => {
    return {
      title: t('settings.bays.bayDetails'),
      submitButtonTitle: `${t('settings.bays.editBay')} ${selectedBay?.bay_number}`,
    }
  }, [selectedBay?.bay_number, t])

  if (!selectedBay) {
    return null
  }

  return (
    <Sheet open={openBaySideBar} onOpenChange={(open) => toggleBaySideBar(BaySideBarMode.VIEW)}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={() => toggleBaySideBar()}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Summary bayNumber={selectedBay.bay_number} />
          <Details
            floor={selectedBay.floor}
            roof={selectedBay.roof}
            trackingTech={selectedBay.tracking_technology}
          />
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={() => changeBaySideBarMode(BaySideBarMode.EDIT)} className="w-full">
            {submitButtonTitle}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default BayDetails
