import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({
  component: Component,
  loggedIn,
  auth = true,
  notFound = false,
  courseType,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn) {
          if (auth) {
            return <Component courseType={courseType} {...props} />
          } else {
            return <Redirect to={{ pathname: '/unauthorized' }} />
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { pathname: notFound || !rest.computedMatch ? '/' : rest.computedMatch.url },
              }}
            />
          )
        }
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  notFound: PropTypes.bool,
  auth: PropTypes.bool,
  courseType: PropTypes.string,
}

export default PrivateRoute
