const headers = {
  bookingId: {
    name: '.bookingId',
    type: 'button',
    valueType: 'array',
    width: '90px',
    selected: false,
    isSortable: false,
    style: { color: '#489EF4' },
  },
  course: {
    name: '.course',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  date: {
    name: '.bookingDate',
    type: 'label',
    display: (date: string) => date && date.split(' ')[0],
    valueType: 'string',
    width: '100px',
    selected: true,
    isSortable: true,
  },
  startTime: {
    name: '.startTime',
    type: 'label',
    valueType: 'string',
    width: '80px',
    selected: true,
    isSortable: false,
  },
  noOfPlayers: {
    name: '.playersText',
    type: 'label',
    valueType: 'number',
    width: '65px',
    selected: true,
    isSortable: false,
  },
  price: {
    name: '.price',
    type: 'label',
    valueType: 'number',
    width: '100px',
    selected: true,
    isSortable: false,
  },
  status: {
    name: '.status',
    type: 'label',
    valueType: 'string',
    width: '150px',
    selected: true,
    isSortable: false,
  },
}

export default headers
