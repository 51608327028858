import { Divider } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'

type Props = {
  numberOfBays?: number | string
  bayNumber?: number
}

const Summary = ({ bayNumber, numberOfBays }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col">
      <p className="text-content-base font-bold">{t('words.summary')}</p>
      <div className="pt-md pb-xl">
        <p className="text-content-sm font-bold">{t('settings.bays.bay')} #</p>
        <p className="text-content-base font-regular uppercase">
          {bayNumber || (numberOfBays && +numberOfBays > 1 && t('words.bulk'))}
        </p>
      </div>
      <Divider />
    </div>
  )
}

export default Summary
