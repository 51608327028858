import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useState } from 'react'
import { Range } from '@sweetspot/shared/types'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'

const useRanges = () => {
  const currentClubUuid = useSelector((state: RootState) => {
    if (state?.golfClub?.selectedId && state?.golfClub?.list?.length) {
      const selectedGolfClub = state.golfClub.list.find(
        (club) => club.id === state.golfClub.selectedId
      )
      if (selectedGolfClub) {
        return selectedGolfClub.uuid
      }
    }
  })
  const [selectedRange, setSelectedRange] = useState<Range | undefined>()

  const { data, isLoading: areRangesLoading } = useQuery(
    [CLUB_QUERIES.RANGES, currentClubUuid],
    () => queryRanges(currentClubUuid),
    {
      enabled: !!currentClubUuid,
    }
  )

  return {
    ranges: (data?.ranges as Range[]) || [],
    areRangesLoading,
    selectedRange,
    setSelectedRange,
  }
}

export default useRanges
