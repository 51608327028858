import { getCourseById } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { queryTeeTimes } from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import { format, toDate } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

const LIMIT = 500

const getTeeTimes = async (course, week, space) => {
  if (!week || week.id === -1) {
    return []
  }

  const startDateWithTimezone = (date, tz) => {
    const formattedDate = format(new Date(date), "yyyy-MM-dd'T'00:00:00'Z'")
    const convertToDateFomat = toDate(new Date(formattedDate))

    return zonedTimeToUtc(convertToDateFomat, tz)
  }
  const endDateWithTimezone = (date, tz) => {
    const formattedDate = format(new Date(date), "yyyy-MM-dd'T'23:59:59'Z'")
    const convertToDateFomat = toDate(new Date(formattedDate))

    return zonedTimeToUtc(convertToDateFomat, tz)
  }

  const from = startDateWithTimezone(week.fromDate, course.timezone).toISOString()
  const to = endDateWithTimezone(week.toDate, course.timezone).toISOString()

  if (!course?.uuid && course?.id) {
    course = getCourseById(course.id)
  }

  const query = {
    limit: LIMIT,
    'course.uuid': course.uuid,
    'from[before]': to,
    'from[after]': from,
    'order[from]': 'asc',
  }

  if (space && space !== -1) {
    query['space.uuid'] = space
  }

  async function loop(page, results = []) {
    query.page = page
    const res = await queryTeeTimes(query)
    results.push(...res)
    if (res.length < LIMIT) {
      return results
    }
    return loop(++page, results)
  }

  const teeTimes = await loop(1)

  return teeTimes
}

export default getTeeTimes
