import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'
import {
  addItem,
  replaceOrRemove,
  sortBy,
} from '@sweetspot/club-portal-legacy/store/reducers/helpers'
import {
  GolfClubActionTypes,
  GolfClubItem,
  GolfClubState,
} from '@sweetspot/club-portal-legacy/store/types'

export default function golfClub(state = initialState, action: GolfClubActionTypes): GolfClubState {
  let golfClubList: GolfClubItem[]

  switch (action.type) {
    case CONSTANTS.GOLF_CLUB.GET_LIST:
      golfClubList = action.payload as GolfClubItem[]
      golfClubList = sortBy(golfClubList, 'name')

      return Object.assign({}, state, {
        list: golfClubList,
      })
    case CONSTANTS.GOLF_CLUB.GET_SPECIFIC:
      golfClubList = replaceOrRemove(
        state.list,
        (action.payload as GolfClubItem).id,
        action.payload as GolfClubItem
      )
      golfClubList = sortBy(golfClubList, 'name')

      return Object.assign({}, state, {
        list: golfClubList,
      })
    case CONSTANTS.GOLF_CLUB.FETCH_FROM_STORAGE:
      golfClubList = action.payload as GolfClubItem[]

      return Object.assign({}, state, {
        list: golfClubList,
      })
    case CONSTANTS.GOLF_CLUB.CREATE:
      golfClubList = addItem<GolfClubItem>(state.list, action.payload as GolfClubItem)
      golfClubList = sortBy(golfClubList, 'name')

      return Object.assign({}, state, {
        list: golfClubList,
      })
    case CONSTANTS.GOLF_CLUB.UPDATE:
      golfClubList = replaceOrRemove<GolfClubItem>(
        state.list,
        (action.payload as GolfClubItem).id,
        action.payload as GolfClubItem
      )
      golfClubList = sortBy(golfClubList, 'name')

      return Object.assign({}, state, {
        list: golfClubList,
      })
    case CONSTANTS.GOLF_CLUB.DELETE:
      golfClubList = replaceOrRemove<GolfClubItem>(
        state.list,
        (action.payload as GolfClubItem).id,
        action.payload as GolfClubItem
      )

      return Object.assign({}, state, {
        list: golfClubList,
      })
    case CONSTANTS.GOLF_CLUB.SELECT:
      return Object.assign({}, state, {
        selectedId: action.payload,
      })
    default:
      return state
  }
}
