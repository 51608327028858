import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import usePlayerCard from '@sweetspot/club-portal-legacy/modals/PlayerCard/hooks/usePlayerCard'
import useMembershipCard from '@sweetspot/club-portal-legacy/modals/PlayerCard/hooks/useMembershipCard'

/**
 * `usePlayerCardLogic` is a custom hook that combines the logic from both the PlayerCard
 * and Membership hooks to provide a unified set of methods and properties that are required
 * for managing the PlayerCard component. This separation ensures that the component remains clean
 * and is only responsible for rendering, while the business logic is handled here.
 */
const usePlayerCardLogic = (playerId?: string) => {
  // Extracts the selected golf club ID from the Redux store
  const selectedGolfClubId = useSelector((state: RootState) => state.golfClub?.selectedId)

  // Extracts the necessary methods and properties for updating and querying player data
  const {
    handleUpdateCard,
    handleUpdatePlayRight,
    isPlayerDataUpdating,
    isPlayerDataLoading,
    gitInfo,
    isPlayerCardUpdated,
    cardInfo,
  } = usePlayerCard(playerId)

  // Extracts the necessary methods and properties for managing membership cards, based on the selected player and golf club
  const {
    membershipCards,
    isLoading: isMembershipDataLoading,
    handleMarkAsPaid,
    handleUnmarkAsPaid,
    handleRemove,
    addMembership,
    handleSendLink,
    handleRefund,
    handleCancelMembershipAutoRenewal,
    handleEnableMembershipAutoRenewal,
    handleCancelUpcomingPayments,
    handleLockOrUnlockMembership,
  } = useMembershipCard({
    playerId: cardInfo?.id,
    selectedId: selectedGolfClubId,
  })

  // Computes a loading state based on both player data and membership data loading states
  const isLoading = useMemo(
    () => isPlayerDataLoading || isPlayerDataUpdating || isMembershipDataLoading,
    [isPlayerDataUpdating, isPlayerDataLoading, isMembershipDataLoading]
  )

  // Returns the unified set of methods and properties for managing the PlayerCard
  return {
    handleUpdateCard,
    handleUpdatePlayRight,
    gitInfo,
    isPlayerCardUpdated,
    isLoading,
    membershipCards,
    handleMarkAsPaid,
    handleUnmarkAsPaid,
    handleRemove,
    addMembership,
    handleSendLink,
    handleCancelMembershipAutoRenewal,
    handleEnableMembershipAutoRenewal,
    handleCancelUpcomingPayments,
    selectedGolfClubId,
    cardInfo,
    handleRefund,
    handleLockOrUnlockMembership,
  }
}

export default usePlayerCardLogic
