import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import produce from 'immer'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import {
  addClubUserHoldReservation,
  getAvailableGolfCartsList,
  getClubUserHoldReservations,
  removeClubUserHoldReservation,
} from '@sweetspot/sweetspot-js/features/golfCarts/services/api-platform'
import {
  isDateBeforeNow,
  isDateBeforeToday,
  isValidDate,
  toISOStringWithOffset,
  isDateBeforeOtherDate,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import SingleReservation from './components/SingleReservation'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import moment from 'moment-timezone'

const DEFAULT_FORM = {
  date: '',
  fromTime: '',
  toTime: '',
  quantity: 1,
  note: '',
}

const ReservedCarts = ({ warehouseUuid, timezone }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState(DEFAULT_FORM)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRemovingReservation, setIsRemovingReservation] = useState(false)
  const [error, setError] = useState(null)

  const fromDate = useMemo(() => {
    if (!form.date || !form.fromTime) return null
    const date = new Date(`${form.date}T${form.fromTime}`)
    return isValidDate(date) ? date : null
  }, [form.date, form.fromTime])

  const toDate = useMemo(() => {
    if (!form.date || !form.toTime) return null
    const date = new Date(`${form.date}T${form.toTime}`)
    return isValidDate(date) ? date : null
  }, [form.date, form.toTime])

  const updateFormValue = (newValue, key) => {
    setForm(
      produce((draft) => {
        draft[key] = newValue
      })
    )
  }

  const { data: availableCarts, isFetching: isFetchingAvailableData } = useQuery(
    [CLUB_QUERIES.CART_AVAILABILITY, warehouseUuid, fromDate, toDate],
    () =>
      getAvailableGolfCartsList(warehouseUuid, {
        holdFrom: toISOStringWithOffset(fromDate),
        holdTo: toISOStringWithOffset(toDate),
        productCode: 'golf_cart',
      }),
    {
      enabled: !!warehouseUuid && !!fromDate && !!toDate,
      select: (data) => {
        if (!data?.filter) data = Object.keys(data).map((key) => data[key])
        return data.filter((cart) => cart?.on_hand >= 1 && cart?.state === 'active')
      },
    }
  )

  const {
    data: reservedCarts,
    isFetching: isFetchingReservedCarts,
    refetch: refetchReservedCarts,
  } = useQuery(
    [CLUB_QUERIES.RESERVED_CARTS, warehouseUuid],
    () => getClubUserHoldReservations(warehouseUuid, { page: 1, limit: 999 }),
    {
      enabled: !!warehouseUuid,
      select: (data) => {
        if (Array.isArray(data)) {
          return data
            .filter((item) => !isDateBeforeToday(new Date(item?.hold_from)))
            .sort((a, b) => {
              return new Date(a.hold_from) - new Date(b.hold_from)
            })
        } else {
          return data
        }
      },
    }
  )

  const availableAmount = useMemo(() => {
    if (!availableCarts?.length) return null
    return availableCarts?.length
  }, [availableCarts])

  const formIsReadyForSubmit = useMemo(() => {
    if (
      !isFetchingAvailableData &&
      fromDate &&
      toDate &&
      form.quantity &&
      availableAmount >= form.quantity
    ) {
      return true
    }
    return false
  }, [form, availableAmount, isFetchingAvailableData, fromDate, toDate])

  const handleSubmitForm = async () => {
    setError(null)
    if (!fromDate || !toDate || !form.quantity || !availableCarts) return

    if (isDateBeforeNow(fromDate)) {
      setError(t('sentences.dateAndTimeMustNotHavePassed'))
      return
    }

    if (form.quantity <= 0) {
      setError(t('sentences.quantityHasToBeGraterThanZero'))
      return
    }

    if (isDateBeforeOtherDate(toDate, fromDate)) {
      setError(t('sentences.toDateMustBeAfterFromDate'))
      return
    }

    if (form?.note && form.note.length > 45) {
      setError(t('errors.notesTooLong'))
      return
    }

    setIsSubmitting(true)

    const quantityToReserve = form.quantity
    let cartUuids = []
    for (let index = 0; index < quantityToReserve; index++) {
      cartUuids.push(availableCarts[index].uuid)
    }

    const [res, err] = await to(
      addClubUserHoldReservation(warehouseUuid, {
        hold_from: moment(fromDate).utc(),
        hold_to: moment(toDate).utc(),
        product_variants: cartUuids,
        ...(form?.note && { note: form.note }),
      })
    )

    if (res && !err) {
      setForm(DEFAULT_FORM)
    } else {
      if (err?.violations?.length) {
        err.violations.forEach((e) => {
          const { propertyPath, errorName } = e
          if (propertyPath === 'hold_from' && errorName === 'TOO_LOW_ERROR') {
            setError(t('sentences.dateAndTimeMustNotHavePassed'))
          }
        })
      }
      setError(t('sentences.numberOfGolfCartsNotAvailable'))
    }
    refetchReservedCarts()
    setIsSubmitting(false)
  }

  const handleRemoveReservation = async (reservation) => {
    if (!reservation?.uuid) return

    setIsRemovingReservation(true)
    const [res, err] = await to(removeClubUserHoldReservation(warehouseUuid, reservation.uuid))

    if (res && !err) {
      refetchReservedCarts()
    }
    setIsRemovingReservation(false)
  }

  return (
    <div className={cx(styles.container)}>
      <h2 className={cx(styles.title)}>{t('sentences.addNew')}</h2>
      <div className={cx(styles.form)}>
        <TextInputField
          placeholder="yyyy-mm-dd"
          containerWidth="third"
          type="calendar"
          label={t('words.date')}
          value={form.date}
          onChange={(newValue) => updateFormValue(newValue, 'date')}
          lightMainLabelColor={true}
          calendarSettings={{ startDate: new Date() }}
          CalendarComponent={MiniCalendar}
        />
        <TextInputField
          placeholder="hh:mm"
          containerWidth="17%"
          type="time"
          label={t('words.from')}
          value={form.fromTime}
          onChange={(newValue) => updateFormValue(newValue, 'fromTime')}
          lightMainLabelColor={true}
        />
        <TextInputField
          placeholder="hh:mm"
          containerWidth="17%"
          type="time"
          label={t('words.to')}
          value={form.toTime}
          onChange={(newValue) => updateFormValue(newValue, 'toTime')}
          lightMainLabelColor={true}
        />
        <div className={cx(styles.availableContainer)}>
          <Label label={t('words.available')} lightColor={true} />
          {isFetchingAvailableData ? (
            <PulseLoader
              showIf={true}
              className={cx(styles.loader)}
              dotStyles={{ width: '7px', height: '7px' }}
            />
          ) : !availableAmount ? (
            <p className={cx(styles.availableText)}>-</p>
          ) : (
            <p className={cx(styles.availableText)}>{availableAmount}</p>
          )}
        </div>
        <TextInputField
          containerWidth="10%"
          type="number"
          label={t('words.quantity')}
          value={form.quantity}
          inputProps={{
            min: 1,
          }}
          onChange={(newValue) => updateFormValue(newValue, 'quantity')}
          lightMainLabelColor={true}
        />
      </div>
      <div className={cx(styles.note)}>
        <TextInputField
          placeholder={t('sentences.typeHere')}
          containerWidth="full"
          type="text"
          label={t('words.note')}
          value={form.note}
          onChange={(newValue) => updateFormValue(newValue, 'note')}
          lightMainLabelColor={true}
        />
      </div>
      <div className={cx(styles.actions)}>
        <button
          onClick={() => handleSubmitForm()}
          className={cx('system-button primary md-32 mr-2.5')}
          disabled={!formIsReadyForSubmit || isSubmitting}
        >
          {isSubmitting ? <PulseLoader showIf={true} /> : t('words.add')}
        </button>
        {error && (
          <div className={cx(styles.errorWrapper)}>
            <p>{error}</p>
          </div>
        )}
      </div>
      <h2 className={cx(styles.title)}>{t('words.reserved_plural')}</h2>
      <div className={cx(styles.reservedContainer)}>
        {isFetchingReservedCarts || isRemovingReservation ? (
          <PulseLoader showIf={true} />
        ) : !reservedCarts?.length ? (
          <p className={cx(styles.noCartsText)}>{t('sentences.noCartsReserved')}</p>
        ) : (
          reservedCarts.map((reservation) => {
            return (
              <SingleReservation
                key={reservation.uuid}
                reservation={reservation}
                onRemoveReservation={handleRemoveReservation}
                timezone={timezone}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

ReservedCarts.propTypes = {
  warehouseUuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

ReservedCarts.defaultProps = {}

export default ReservedCarts
