import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { getCountries } from '@sweetspot/club-portal-legacy/constants/country'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { useTranslation } from 'react-i18next'
import { Player } from '@sweetspot/shared/types'

const usePlayerInformationLogic = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const {
    options,
    genderOptions,
    memberTypeOptions,
    membershipCategoryOptions,
    membershipTypeOptions,
    countries,
  } = useMemo(() => {
    const options = [
      { id: 0, name: t('yes') },
      { id: 1, name: t('no') },
    ]

    const memberTypeOptions = [
      { id: 'adult_male', name: t('settings.playerCard.adult_male') },
      { id: 'adult_female', name: t('settings.playerCard.adult_female') },
      { id: 'junior_male', name: t('settings.playerCard.junior_male') },
      { id: 'junior_female', name: t('settings.playerCard.junior_female') },
    ]

    const membershipCategoryOptions = [
      { id: 'annual_duration', name: t('settings.playerCard.annual_duration') },
      { id: '12_month', name: t('settings.playerCard.12_month') },
    ]

    const membershipTypeOptions = [
      { id: '9_hole', name: t('settings.playerCard.9_hole') },
      { id: '18_hole', name: t('settings.playerCard.18_hole') },
    ]

    const genderOptions = [
      { id: 'man', name: t('settings.playerCard.man') },
      { id: 'woman', name: t('settings.playerCard.woman') },
      { id: 'other', name: t('settings.playerCard.other') },
    ]

    const countries = _.orderBy(getCountries(language), ['name'], ['asc']).map((item, index) => ({
      ...item,
      id: index + 1,
      label: item.name,
    }))

    return {
      options,
      memberTypeOptions,
      membershipCategoryOptions,
      membershipTypeOptions,
      genderOptions,
      countries,
    }
  }, [t, language])

  const isValidEmail = (email: string) => Utilities.validateEmail(email)
  const isValidPhoneNumber = (phone: string) => Utilities.isAPhoneNumber(phone)
  const isValidDateString = (date: string) => DateHelpers.isValidDateString(date)

  const getErrorMessage = useCallback(
    (name: string, form: Player) => {
      if (name === 'email' && !isValidEmail(form[name])) return t('players.invalidEmail')
      if (name === 'phone' && !isValidPhoneNumber(form[name])) return t('players.invalidPhone')
      return null
    },
    [t]
  )

  return {
    options,
    genderOptions,
    memberTypeOptions,
    membershipCategoryOptions,
    membershipTypeOptions,
    countries,
    isValidEmail,
    isValidPhoneNumber,
    isValidDateString,
    getErrorMessage,
  }
}

export default usePlayerInformationLogic
