import * as React from 'react'
import { cn } from '../../../utils'
import { Button } from '../../atoms'
import { Input, InputBase } from '../InputBase'

interface StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string
  increment: () => void
  decrement: () => void
  readOnly?: boolean
}

const Stepper = React.forwardRef<HTMLDivElement, StepperProps>(
  ({ className, children, value = 1, increment, decrement, onChange, readOnly, ...props }, ref) => {
    return (
      <div
        className={cn('relative w-full', className)}
        {...props}
        ref={ref}
        data-testid="input-container"
      >
        <InputBase className="flex-row">
          <Button
            className="hover:text-text-dark absolute top-1/2 -translate-y-1/2 transform shadow-none hover:bg-transparent focus:bg-transparent focus:ring-0"
            variant="clear-dark"
            onClick={decrement}
            data-testid="decrement-button"
          >
            <i className="fa-regular fa-minus" />
          </Button>
          <Input
            type="text"
            onChange={onChange}
            readOnly={readOnly}
            rounding="rectangular"
            value={value}
            className="focus:border-border-stroke-clean h-touch-height-md text-center text-sm font-semibold"
          />
          <Button
            className="hover:text-text-dark absolute right-0 top-1/2 -translate-y-1/2 transform shadow-none hover:bg-transparent focus:bg-transparent focus:ring-0"
            variant="clear-dark"
            onClick={increment}
            data-testid="increment-button"
          >
            <i className="fa-regular fa-plus" />
          </Button>
        </InputBase>
      </div>
    )
  }
)
Stepper.displayName = 'Stepper'

export { Stepper }
