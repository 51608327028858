import { useMemo } from 'react'

import { generateTimeSections } from '../../utils'
import { OPENING_HOURS, generatePriceList, statusColorMap } from '../../mockData'
import useTableHeader from '../../hooks/useTableHeader'
import TableBodyRow from '../TableBodyRow'
import { Divider } from '@sweetspot/scramble-ds'

export const PricesTable = () => {
  const timeSections = generateTimeSections(OPENING_HOURS)
  const tableHeader = useTableHeader()

  const priceMatrix = useMemo(() => {
    return timeSections.map(() => generatePriceList(7))
  }, [timeSections])

  return (
    <div className="flex h-full flex-1 flex-col">
      {tableHeader}
      <table>
        <tbody>
          {timeSections.map(([from, to], rowIndex) => {
            const isLastRow = rowIndex === timeSections.length - 1
            return (
              <TableBodyRow key={`${from}-${to}`} from={from} to={to} isLastRow={isLastRow}>
                {priceMatrix[rowIndex].map((priceObj, colIndex) => {
                  const isFirstColumn = colIndex === 0
                  const isLastColumn = colIndex === 6
                  const statusColorClass = statusColorMap[priceObj.status]
                  const borderRightClass = isLastColumn ? 'border-r' : ''
                  const borderBottomClass = isLastRow ? 'border-b' : ''
                  const roundedBottomRightClass = isLastColumn && isLastRow ? 'rounded-br-sm' : ''

                  return (
                    <td key={colIndex} className="h-8 !w-[114px]">
                      <div
                        className={`border-Borders-stroke-clean h-full w-full ${statusColorClass} ${borderRightClass} ${borderBottomClass} ${roundedBottomRightClass}`}
                      >
                        <div
                          className={`flex h-full w-full items-center justify-center ${
                            !isLastColumn ? 'border-background-mono-lighter' : ''
                          }`}
                        >
                          {!isFirstColumn && (
                            <div className="bg-background-mono-lighter h-full w-[2px]" />
                          )}
                          <div className="text-text-dark text-content-xs w-full text-center">
                            {priceObj.price}
                          </div>
                          {!isLastColumn && (
                            <div className="bg-background-mono-lighter flex h-full w-[3px] items-center justify-end">
                              <Divider className="h-4" orientation="vertical" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  )
                })}
              </TableBodyRow>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
