import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Query booking emails
 *
 * @param {*} query
 * @param  {...any} args
 * @returns
 */
export const queryBookingEmails = (
  query = {
    golf_club: undefined,
    status: undefined,
    title: undefined,
    limit: 100,
    page: 1,
  },
  ...args
) => {
  const url = queryString.stringifyUrl({
    url: `${getBaseUrlPlatform()}/bookings/emails`,
    query,
  })
  return request(url, ...args)
}
