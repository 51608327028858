// @ts-expect-error Imports from non-ts modules
import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import type {
  GetHomeClubPostsQuery,
  GetHomeClubPostsResponse,
  GetHomeClubSettingsResponse,
  PutHomeClubPostsPayload,
  PutHomeClubPostsResponse,
  PutHomeClubSettingsPayload,
  PutHomeClubSettingsResponse,
  PutSingleHomeClubPostPayload,
} from './types'
import queryString from 'query-string'
import { HomeClubPost, Hydra, QueryRecord } from '@sweetspot/shared/types'

export const getHomeClubSettings = (clubId: number): Promise<GetHomeClubSettingsResponse> => {
  const url = `${getBaseUrlPlatform()}/clubs/${clubId}/home-club-settings`
  return request(url)
}

export const putHomeClubSettings = (
  clubId: number,
  payload: PutHomeClubSettingsPayload
): Promise<PutHomeClubSettingsResponse> => {
  const url = `${getBaseUrlPlatform()}/clubs/${clubId}/home-club-settings`
  return request(url, {
    method: 'put',
    body: payload,
  })
}

export const getHomeClubPosts = (
  query: GetHomeClubPostsQuery
): Promise<GetHomeClubPostsResponse> => {
  const url = queryString.stringifyUrl({
    url: `${getBaseUrlPlatform()}/home-club-posts`,
    query: query as QueryRecord<typeof query>,
  })
  return request(url)
}

export const getHomeClubPostHydra = (
  query: GetHomeClubPostsQuery
): Promise<Hydra<GetHomeClubPostsResponse>> => {
  const url = queryString.stringifyUrl({
    url: `${getBaseUrlPlatform()}/home-club-posts`,
    query: query as QueryRecord<typeof query>,
  })
  return request(url, {
    accept: 'application/ld+json',
  })
}

export const putHomeClubPosts = (
  clubId: number,
  payload: PutHomeClubPostsPayload
): Promise<PutHomeClubPostsResponse> => {
  const url = `${getBaseUrlPlatform()}/clubs/${clubId}/home-club-posts`

  return request(url, {
    method: 'put',
    body: payload,
  })
}

export const putSingleHomeClubPost = (
  postId: number,
  payload: PutSingleHomeClubPostPayload
): Promise<HomeClubPost> => {
  const url = `${getBaseUrlPlatform()}/home-club-posts/${postId}`

  return request(url, {
    method: 'put',
    body: payload,
  })
}

export const deleteSingleHomeClubPost = (postId: number): Promise<void> => {
  const url = `${getBaseUrlPlatform()}/home-club-posts/${postId}`

  return request(url, {
    method: 'delete',
  })
}
