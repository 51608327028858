import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import InnerBoxWrapper from '../InnerBoxWrapper'
import DarkBlueSubtitle from '../DarkBlueSubtitle'
import ValueText from '../ValueText'
import { SLOT_TYPES } from '@sweetspot/sweetspot-js/features/promotions/constants/actions'
import { useQueries } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryClubs } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

const SlotFilterPresentation = ({ filter }) => {
  const { t } = useTranslation()

  const gitHomeClubsResults = useQueries(
    filter?.git_home_club_query?.map((uuid) => ({
      queryKey: [CLUB_QUERIES.CLUBS, uuid],
      queryFn: () => queryClubs({ uuid: uuid }),
      enable: !!filter?.git_home_club_query,
    })) || []
  )

  const gitHomeClubs = useMemo(() => {
    if (!gitHomeClubsResults?.length) return []

    let clubs = []
    let loading = false

    gitHomeClubsResults.forEach((result) => {
      const { isFetching, isLoading, isIdle, data } = result
      if (isFetching || isLoading || isIdle) loading = true
      else {
        clubs = [...clubs, ...data]
      }
    })

    if (loading) return []
    else return clubs
  }, [gitHomeClubsResults])

  return (
    <InnerBoxWrapper>
      {filter?.slot_type_query && (
        <React.Fragment>
          <DarkBlueSubtitle>{t('sentences.playerType')}</DarkBlueSubtitle>
          <div className={cx(styles.valuesWrapper)}>
            {filter.slot_type_query?.length ? (
              filter.slot_type_query.map((slotType) => (
                <ValueText key={slotType}>
                  {t(SLOT_TYPES.find((x) => x.value === slotType).label)}
                </ValueText>
              ))
            ) : (
              <ValueText>{t('sentences.doesNotApplyToAnyPlayer')}</ValueText>
            )}
          </div>
        </React.Fragment>
      )}

      {filter?.age_query && (
        <React.Fragment>
          <DarkBlueSubtitle>{t('words.age')}</DarkBlueSubtitle>
          <div className={cx(styles.valuesWrapper)}>
            <ValueText
              suffixText={
                filter.age_query?.check_by_year === true
                  ? `(${t('sentences.calculatedByYear')})`
                  : ''
              }
            >
              {filter.age_query.from || filter.age_query.to ? (
                <>
                  {filter.age_query.from && !filter.age_query.to ? (
                    <>
                      {t('words.from')} {filter.age_query.from}
                    </>
                  ) : (
                    ''
                  )}
                  {!filter.age_query.from && filter.age_query.to ? (
                    <>
                      {t('sentences.upTo')} {filter.age_query.to}
                    </>
                  ) : (
                    ''
                  )}
                  {filter.age_query.from && filter.age_query.to ? (
                    <>{`${filter.age_query.from} - ${filter.age_query.to}`}</>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                t('sentences.doesNotApplyToAnyAge')
              )}
            </ValueText>
          </div>
        </React.Fragment>
      )}

      {filter?.git_home_club_query && (
        <React.Fragment>
          <DarkBlueSubtitle>{t('words.gitHomeClub')}</DarkBlueSubtitle>
          {filter?.git_home_club_query?.length > 0 ? (
            gitHomeClubs?.length > 0 ? (
              <div className={cx(styles.valuesWrapper)}>
                {gitHomeClubs.map((club) => (
                  <ValueText key={club.id}>{club.name}</ValueText>
                ))}
              </div>
            ) : (
              <PulseLoader showIf={true} />
            )
          ) : (
            <ValueText>{t('sentences.doesNotApplyToAnyGitHomeClub')}</ValueText>
          )}
        </React.Fragment>
      )}
    </InnerBoxWrapper>
  )
}

SlotFilterPresentation.propTypes = {
  filter: PropTypes.object,
}

SlotFilterPresentation.defaultProps = {}

export default SlotFilterPresentation
