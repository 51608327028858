import { getLocalStorage, clearLocalStorage } from '@sweetspot/shared/util/local-storage'
import { APP_NAMES, STORAGE_KEYS } from '@sweetspot/shared/util/constants'
import { isJson } from '@sweetspot/sweetspot-js/common/functions/utils'
import i18n from 'i18next'
import {
  clearAuthToken,
  clearRefreshToken,
  getAuthToken,
  getNewTokens,
  getRefreshRequestStatus,
  REFRESH_REQUEST_STATUSES,
  setRefreshRequestStatus,
} from '../services/token-service'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

/**
 *
 * @param {*} resHeaders
 * @returns
 */
const getDelayedMessageHeaders = (resHeaders) => {
  const xDelay = resHeaders.get('X-Delay')
  const xDelayRenewalEndpoint = resHeaders.get('X-Delay-Renewal-Endpoint')
  const xDelayRenewalToken = resHeaders.get('X-Delay-Renewal-Token')
  const xDelayTo = resHeaders.get('X-Delay-To')

  if (xDelay && xDelayRenewalEndpoint && xDelayRenewalToken && xDelayTo) {
    return {
      xDelay,
      xDelayRenewalEndpoint,
      xDelayRenewalToken,
      xDelayTo,
    }
  }
  return false
}

export const handleSunset = (res) => {
  const sunsetDate = res.headers.get('Sunset')
  const sunsetReason = res.headers.get('Deprecated-Reason')

  if (sunsetDate) {
    console.log(
      '%c Sunset Notice for endpoint: %s \n Sunset Date: %s \n Sunset Reason: %s',
      'color: black; background-color: yellow; padding: 2px; display: block; float: left',
      res.url.replace('http:', '').replace('https:', ''),
      sunsetDate,
      sunsetReason || 'Unknown'
    )
  }
}

let attempts = {}
let cleanAttemptsTimeout = null

/**
 *
 * @param {string} url
 * @param {Object} [options]
 * @param {('GET'|'POST'|'PUT'|'DELETE'|'get'|'post'|'put'|'delete')} [options.method=GET]
 * @param {string} [options.contentType=application/json]
 * @param {Object} [options.body=null]
 * @param {string} [options.accept='*\/*']
 * @param {string} [options.acceptLanguage=en]
 * @param {boolean} [options.retryConflict=true]
 * @param {function} [delayHeadersHandler]
 * @param {string} [id]
 * @returns {Promise<unknown>}
 */
const request = (url, options, delayHeadersHandler, id) => {
  return new Promise((resolve, reject) => {
    const requestId = id || crypto.randomUUID()

    attempts = {
      ...attempts,
      [requestId]: attempts[requestId] ? attempts[requestId] + 1 : 1,
    }
    clearTimeout(cleanAttemptsTimeout)
    cleanAttemptsTimeout = setTimeout(() => {
      attempts = {}
    }, 10000)

    const { method, contentType, body, accept, acceptLanguage, headers, isLogin, retryConflict } = {
      method: options?.method?.toUpperCase() || 'GET',
      contentType: options?.contentType || 'application/json',
      body: options?.body || null,
      accept: options?.accept || '*/*',
      acceptLanguage: options?.acceptLanguage || i18n?.language || 'en',
      headers: options?.headers || {},
      isLogin: options?.isLogin || false,
      retryConflict: options?.retryConflict ?? true,
    }

    let requestOptions = {
      url,
      method: method,
      headers: {
        Accept: accept,
        'Accept-Language': acceptLanguage,
        ...headers,
      },
    }
    if (contentType !== 'multipart/form-data') {
      requestOptions.headers['Content-Type'] = contentType
    }

    let authToken = null
    if (isLogin) {
      clearAuthToken()
      clearRefreshToken()
      setRefreshRequestStatus(REFRESH_REQUEST_STATUSES.NOT_IN_PROGRESS)
    } else {
      authToken = getAuthToken()
    }

    if (authToken) {
      requestOptions.headers['Authorization'] = `Bearer ${authToken}`
    }

    if (body) {
      if (isJson(body) || body instanceof FormData) {
        requestOptions.body = body
      } else {
        requestOptions.body = JSON.stringify(body)
      }
    }

    if (!body && (method === 'POST' || method === 'PUT') && url.includes(getBaseUrlPlatform())) {
      requestOptions.body = JSON.stringify({})
    }

    const retry = async (timeoutTime = 1000) => {
      await new Promise(() => {
        setTimeout(() => {
          request(url, options, delayHeadersHandler, requestId)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
        }, timeoutTime)
      })
    }

    fetch(url, requestOptions)
      .then(async (res) => {
        handleSunset(res)
        if (res.ok) {
          if (options?.rawResponse) {
            return resolve(res)
          } else {
            let delayedHeaders = getDelayedMessageHeaders(res.headers)
            res
              .json()
              .then((data) => {
                if (delayedHeaders && delayHeadersHandler) {
                  delayHeadersHandler(delayedHeaders, data)
                }
                resolve(data)
              })
              .catch(() => {
                if (delayedHeaders && delayHeadersHandler) {
                  delayHeadersHandler(delayedHeaders, null)
                }
                resolve(true)
              })
          }
        } else {
          if ((res?.status_code === 401 || res?.code === 401 || res?.status === 401) && !isLogin) {
            if (
              getRefreshRequestStatus() === REFRESH_REQUEST_STATUSES.IN_PROGRESS &&
              attempts?.[requestId] <= 5
            ) {
              await retry(2000)
            } else if (getRefreshRequestStatus() === REFRESH_REQUEST_STATUSES.NOT_IN_PROGRESS) {
              await getNewTokens()
              await retry()
            } else if (getRefreshRequestStatus() === REFRESH_REQUEST_STATUSES.FAILED) {
              throw res
            }
          }

          res
            .json()
            .then(async (data) => {
              if (
                retryConflict === true &&
                (res.status === 409 ||
                  data?.detail === 'The optimistic lock on an entity failed.') &&
                attempts?.[requestId] <= 5
              ) {
                await retry()
              }

              reject({ ...data, status: res.status })
            })
            .catch(() => reject('Response is not JSON'))
        }
      })
      .catch((err) => {
        handleAppStateErrors(err)
        reject(err)
      })
  })
}
export default request

/**
 * Returns api env variable from REACT_APP_API_ENV key.
 *
 * @returns {PROD|SANDBOX|DEV}
 */
export const getApiEnv = () => {
  return process.env.REACT_APP_API_ENV
}

/**
 * Returns api platform env var from REACT_APP_API_PLATFORM_URL key.
 *
 * @returns {String}
 */
export const getBaseUrlPlatformVar = () => {
  return process.env.REACT_APP_API_PLATFORM_URL
}

export const envIsProdOrSandbox = () => {
  const ENV = getApiEnv()
  return ['PROD', 'SANDBOX'].includes(ENV)
}

/**
 * @returns {string}
 */
export const getBaseUrlPlatform = () => {
  const API_URL = getBaseUrlPlatformVar()

  if (envIsProdOrSandbox()) return API_URL
  else {
    return getLocalStorage(STORAGE_KEYS.API_PLATFORM_URL_KEY) || API_URL
  }
}

const handleAppStateErrors = (error) => {
  if (getCurrentAppName() === APP_NAMES.CLUB_PORTAL) {
    if (error.status_code === 401 || error.code === 401 || error.status === 401) {
      clearLocalStorage()
    } else {
      const ERROR_CODES = {
        EXPIRED_FIREBASE_TOKEN: 'Token has expired',
      }
      switch (error.message) {
        case ERROR_CODES.EXPIRED_FIREBASE_TOKEN:
          console.log('Expired token')
          clearLocalStorage()
          break
        default:
        // Skip
      }
    }
    return
  }

  if (error.status_code === 401 || error.code === 401 || error.status === 401) {
    console.log('Expired token')
  }
}
