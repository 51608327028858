import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { Me } from './types'

export const getMe = (): Promise<Me> => {
  const URL = `${getBaseUrlPlatform()}/auth/club-users/me`

  return request(URL)
}

export const getMyRoles = (): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/club-users/my-roles`

  return request(URL)
}
