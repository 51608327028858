import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as CaretDown } from '@sweetspot/club-portal-legacy/resources/images/caret-down.svg'
import { ReactComponent as ClearIcon } from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'

import styles from './styles.module.scss'

class Accordion extends Component {
  state = {
    open: this.props.initialOpen || false,
  }

  handleOnClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }))
  }

  handleOnDelete = (e) => {
    e.stopPropagation()
    if (this.props.onDelete) this.props.onDelete()
  }

  render = () => {
    const { open } = this.state
    const { children, title, level, hasSubAccordions, onDelete, hasError } = this.props

    return (
      <div
        className={cx(styles.container, {
          [styles.containerLevelTwo]: level === 2,
        })}
      >
        <div
          className={cx(styles.header, {
            [styles.headerError]: hasError,
          })}
          onClick={this.handleOnClick}
        >
          <CaretDown
            className={cx(styles.caretIcon, {
              [styles.caretIconActive]: open,
            })}
          />
          {title ? <p className={cx(styles.headerTitle)}>{title}</p> : null}
          {onDelete ? (
            <div className={cx(styles.deleteContainer)} onClick={this.handleOnDelete}>
              <ClearIcon />
            </div>
          ) : null}
        </div>
        <div
          className={cx(
            styles.innerContainer,
            {
              [styles.innerContainerLevelOne]: level === 1,
              [styles.innerContainerLevelTwo]: level === 2,
            },
            {
              [styles.innerContainerOpen]: open,
              [styles.innerContainerOpenLevelOne]: open && level === 1,
              [styles.innerContainerOpenLevelTwo]: open && level === 2,
            },
            {
              [styles.innerContainerNoPadding]: hasSubAccordions,
            }
          )}
        >
          {children || null}
        </div>
      </div>
    )
  }
}

Accordion.propTypes = {
  chilren: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.string,
  level: PropTypes.number,
  hasSubAccordions: PropTypes.bool,
  initialOpen: PropTypes.bool,
  onDelete: PropTypes.func,
  hasError: PropTypes.bool,
}
Accordion.defaultProps = {
  chilren: '',
  title: '',
  level: 1,
  initialOpen: false,
  hasSubAccordions: false,
  onDelete: undefined,
  hasError: false,
}

export default Accordion
