export const CHECKERS_TYPES = [
  {
    name: 'simultaneous-bookings',
    id: 1,
    label: 'sentences.simultaneousBookings',
    value: 'simultaneously_booked_slots_checker',
    type: 'simultaneously_booked_slots_checker',
  },
  {
    name: 'days-before',
    id: 2,
    label: 'sentences.timePeriodBefore',
    value: 'number_of_days_checker',
    type: 'number_of_days_checker',
  },
  {
    name: 'booked-slots',
    id: 3,
    label: 'sentences.numberOfBookedSlots',
    value: 'booked_slots_per_time_range_checker',
    type: 'booked_slots_per_time_range_checker',
    infoTextKey: 'sentences.numberOfBookedSlotsAllowed',
  },
]

export const BOOKED_SLOTS_UNITS = [
  {
    value: 'day',
    id: 1,
    label: 'dateTime.words.day',
    secondaryLabel: 'sentences.numberOfBookedSlotsAllowedPerDay',
  },
  {
    value: 'week',
    id: 2,
    label: 'dateTime.words.week',
    secondaryLabel: 'sentences.numberOfBookedSlotsAllowedPerWeek',
  },
  {
    value: 'month',
    id: 3,
    label: 'dateTime.words.month',
    secondaryLabel: 'sentences.numberOfBookedSlotsAllowedPerMonth',
  },
  {
    value: 'year',
    id: 4,
    label: 'dateTime.words.year',
    secondaryLabel: 'sentences.numberOfBookedSlotsAllowedPerYear',
  },
  {
    value: 'total',
    id: 5,
    label: 'words.total',
    secondaryLabel: 'sentences.totalNumberOfBookedSlotsAllowed',
  },
]

export const TYPES_OBJ = {
  SIMUL_SLOTS: 'simultaneously_booked_slots_checker',
  DAYS_BEFORE: 'number_of_days_checker',
  BOOKED_SLOTS: 'booked_slots_per_time_range_checker',
}
