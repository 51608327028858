import moment from 'moment'

export const HEADERS = {
  email: {
    name: '.words.email',
    type: 'label',
    width: '250px',
    selected: true,
    isSortable: true,
  },
  'membership.name': {
    name: '.words.membership',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  expiration_date: {
    name: '.words.endDate',
    type: 'label',
    valueType: 'string',
    width: '300px',
    selected: true,
    display: (val, row) => moment(val).format('L'),
  },
  import_date: {
    name: '.sentences.importDate',
    type: 'label',
    valueType: 'string',
    width: '340px',
    selected: true,
    display: (val, row) => moment(val).format('L'),
    isSortable: true,
  },
}
