import React from 'react'
import { ReactComponent as DefaultSimulatorIcon } from '@sweetspot/sweetspot-js/assets/svgs/simulator-default.svg'
import { ReactComponent as DefaultRangesIcon } from '@sweetspot/sweetspot-js/assets/svgs/driving-range-default.svg'
import { ReactComponent as DefaultProIcon } from '@sweetspot/sweetspot-js/assets/svgs/pro-default.svg'
import { ReactComponent as DefaultOtherIcon } from '@sweetspot/sweetspot-js/assets/svgs/other-default.svg'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'

export const EmptyVenuIcon = ({ courseType, customStyle }) => {
  switch (courseType) {
    case COURSE_TYPES.SIMULATOR.value:
      return <DefaultSimulatorIcon className={customStyle} />
    case COURSE_TYPES.DRIVING_RANGE.value:
      return <DefaultRangesIcon className={customStyle} />
    case COURSE_TYPES.PRO.value:
      return <DefaultProIcon className={customStyle} />
    default:
      return <DefaultOtherIcon className={customStyle} />
  }
}
