import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'

import Button from '@sweetspot/sweetspot-js/common/components/Button'

import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const AddPlayerButton = ({
  onAddPlayer,
  className,
  itemWrapperClassName,
  iconClassName,
  loading,
  canEdit,
  theme,
  availableSlots,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        },
        className
      )}
    >
      {onAddPlayer && (
        <Button
          className={cx(styles.itemWrapper, itemWrapperClassName)}
          size="default"
          theme="gray"
          disabled={availableSlots <= 0 || !canEdit}
          loading={loading}
          loaderStyle="pulse"
          onClick={availableSlots > 0 && canEdit ? onAddPlayer : () => {}}
        >
          <AddIcon className={cx(styles.icon, styles.addIcon, iconClassName)} />
          {t('words.player')}
        </Button>
      )}
    </div>
  )
}

AddPlayerButton.propTypes = {
  onAddPlayer: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  itemWrapperClassName: PropTypes.string,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
  availableSlots: PropTypes.number,
}

AddPlayerButton.defaultProps = {
  onAddPlayer: undefined,
  loading: false,
  canEdit: true,
  theme: 'dark',
  availableSlots: 0,
}

export default AddPlayerButton
