import { useMemo } from 'react'
import PopupWrapper from '@sweetspot/sweetspot-js/common/components/PopupWrapper'
import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds'
import { formatCurrency } from '@sweetspot/shared/util/number'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import cx from 'classnames'
import styles from './styles.module.scss'
import { useBookingPaymentCalculations } from '@sweetspot/sweetspot-js/features/bookings/hooks/useBookingPaymentCalculations'
import { Booking } from '@sweetspot/shared/types'

interface ConfirmChangeModalProps {
  onConfirm(): void
  onCancel(): void
  isPaid: boolean
  isPartiallyPaid: boolean
  isConfirmed: boolean
  booking: Booking
}

export const ConfirmChangeModal = ({
  onConfirm,
  onCancel,
  isPaid,
  isPartiallyPaid,
  isConfirmed,
  booking,
}: ConfirmChangeModalProps) => {
  const { t } = useTranslation()
  const [currencyCode] = useClubCurrency()

  const { calculatedTotal, priceDifference, toPayTotal, toRefundTotal } =
    useBookingPaymentCalculations({
      isPaid,
      isPartiallyPaid,
      isConfirmed,
      booking,
    })

  const [
    title,
    newTotalPriceTitle,
    amountToBeRefundedTitle,
    leftToPayTitle,
    submitButtonTitle,
    paymentAndRefundInfo,
  ] = useMemo(() => {
    if (priceDifference > 0) {
      return [
        t('extraPlayer.header_attention'),
        t('extraPlayer.label_newTotalPrice'),
        '',
        t('extraPlayer.label_leftTOPay'),
        t('words.confirm'),
        t('extraPlayer.message_paymentRequireInfo'),
      ]
    }

    if (priceDifference < 0) {
      return [
        t('extraPlayer.header_attention_refund'),
        t('extraPlayer.label_newTotalPrice'),
        t('extraPlayer.label_amountToRefund'),
        '',
        t('words.refund_verb'),
        t('extraPlayer.message_refundRequireInfo'),
      ]
    }

    if (toPayTotal > 0 && toRefundTotal > 0) {
      return [
        t('extraPlayer.header_attention_payment_and_refund'),
        t('extraPlayer.label_newTotalPrice'),
        t('extraPlayer.label_amountToRefund'),
        t('extraPlayer.label_leftTOPay'),
        t('words.refund_verb'),
        t('extraPlayer.message_paymentAndRefundInfo'),
      ]
    }
    return []
  }, [priceDifference, toPayTotal, toRefundTotal, t])

  return (
    <PopupWrapper className={cx(styles.confirmChangeModal)} onClose={onCancel} title={title}>
      <div>
        <p className={'mb-2'}>{paymentAndRefundInfo}</p>
        {newTotalPriceTitle && (
          <div className={'flex gap-1'}>
            {newTotalPriceTitle}{' '}
            <p className={'font-bold'}>
              {formatCurrency(Math.round(calculatedTotal / 100), {
                currencyCode,
              })}
            </p>
          </div>
        )}
        {amountToBeRefundedTitle && (
          <div className={'flex gap-1'}>
            {amountToBeRefundedTitle}{' '}
            <p className={'font-bold'}>
              {formatCurrency(Math.round(toRefundTotal / 100), {
                currencyCode,
              })}
            </p>
          </div>
        )}
        {leftToPayTitle && (
          <div className={'flex gap-1'}>
            {leftToPayTitle}{' '}
            <p className={'font-bold'}>
              {formatCurrency(Math.round(toPayTotal / 100), {
                currencyCode,
              })}
            </p>
          </div>
        )}
        <div className={'flex w-full justify-end gap-4'}>
          <Button variant={'line-dark'} onClick={onCancel}>
            {t('words.cancel')}
          </Button>
          <Button onClick={onConfirm}>{submitButtonTitle}</Button>
        </div>
      </div>
    </PopupWrapper>
  )
}
