import { useTranslation } from 'react-i18next'

import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import BucketConfigurationSelect from '../BucketConfigurationSelect/BucketConfigurationSelect'

const BallPrice = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { ballPrice, ballPriceData, setBallPrice },
  } = useBallPricingContext()
  const [currency] = useClubCurrency()

  return (
    <div className="text-content-base text-text-dark font-bold">
      <span>{t('sentences.ballPriceCurrency', { currency: `(${currency})` })}</span>
      {ballPriceData?.technologies?.map((tech) => (
        <div className="bg-background-mono-lighter mt-2 rounded-md border p-4 pt-2">
          <span className="capitalize">{tech}</span>
          <div className="mb-[2px] mt-1 flex gap-1">
            <span className="flex-1 px-2">{t('words.low')}</span>
            <span className="flex-1 px-2">{t('words.medium')}</span>
            <span className="flex-1 px-2">{t('words.high')}</span>
          </div>
          <div className="flex items-center gap-1">
            <BucketConfigurationSelect
              value={ballPrice[tech]?.low}
              options={ballPriceData.prices}
              onChange={(value) => setBallPrice?.({ [tech]: { low: parseFloat(value) } })}
              renderValue={(value) => parseFloat(value as string).toFixed(2)}
            />
            <BucketConfigurationSelect
              value={ballPrice[tech]?.medium}
              options={ballPriceData.prices}
              onChange={(value) => setBallPrice?.({ [tech]: { medium: parseFloat(value) } })}
              renderValue={(value) => parseFloat(value as string).toFixed(2)}
            />
            <BucketConfigurationSelect
              value={ballPrice[tech]?.high}
              options={ballPriceData.prices}
              onChange={(value) => setBallPrice?.({ [tech]: { high: parseFloat(value) } })}
              renderValue={(value) => parseFloat(value as string).toFixed(2)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default BallPrice
