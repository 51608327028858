import React from 'react'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { setLocalStorage } from '@sweetspot/shared/util/local-storage'

import styles from './styles.module.scss'
import { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { STORAGE_KEYS } from '@sweetspot/shared/util/constants'

export const DEV_ACCOUNTS = {
  sadmin: 'Super admin',
  owner: 'Owner',
  admin: 'Admin',
  cs: 'Customer service',
  co: 'Course operator',
  booker: 'Booker (with all courses included)',
}

const EnvSelector = (props) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [platformENV, setPlatformENV] = React.useState('')

  const onChangeENV = (target, value = '') => {
    value = value.trim()
    if (target === 'platform') setPlatformENV(value)
  }

  const copyToInput = (target) => {
    if (target === 'platform' && !platformENV) {
      setPlatformENV(getBaseUrlPlatform())
    }
  }

  const onSave = () => {
    if (!platformENV) {
      addToast(t('envSelector.error_Empty'), { appearance: 'error' })
      return
    }

    setLocalStorage(STORAGE_KEYS.API_PLATFORM_URL_KEY, platformENV)
    addToast(t('envSelector.message_Success'), { appearance: 'success' })
    setPlatformENV('')
  }
  return (
    <div className={cx(styles.container)}>
      <p className={cx(styles.title)}>{t('envSelector.title')}</p>
      <div className={cx(styles.header)}>
        <p className={cx(styles.label, styles.fastCopy)} onClick={() => copyToInput('platform')}>
          {getBaseUrlPlatform()}
        </p>
      </div>
      <div className={cx(styles.updateArea)}>
        <div className={cx(styles.labelArea)}>
          <p className={cx(styles.label)}>{t('envSelector.label_Platform')}</p>
        </div>
        <div className={cx(styles.inputArea)}>
          <input
            onChange={(e) => onChangeENV('platform', e.target.value)}
            value={platformENV}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
        <div className={cx(styles.saveArea)}>
          <button className={cx('system-button primary md-36')} onClick={onSave}>
            {t('words.save')}
          </button>
        </div>
      </div>
    </div>
  )
}

EnvSelector.propTypes = {}

export default EnvSelector
