import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import Table from '@sweetspot/club-portal-legacy/components/Table'

import style from './style.module.scss'

class ExternalPlayerModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }

    this.headers = {
      name: {
        name: '.name',
        type: 'label',
        valueType: 'string',
        width: '250px',
        selected: true,
        disabled: true,
      },
      age: {
        name: '.age',
        type: 'label',
        valueType: 'number',
        width: '50px',
        selected: true,
      },
      email: {
        name: '.email',
        type: 'label',
        valueType: 'string',
        width: '250px',
        selected: true,
      },
      phone: {
        name: '.phone',
        type: 'label',
        valueType: 'string',
        width: '180px',
        selected: true,
      },
      SSUser: {
        name: '.SSUser',
        type: 'label',
        valueType: 'string',
        width: '200px',
        selected: true,
      },
      golfId: {
        name: '.golfId',
        type: 'label',
        valueType: 'string',
        width: '150px',
        selected: true,
      },
      hcp: {
        name: '.hcp',
        type: 'label',
        valueType: 'number',
        display: (hcp) => ExternalPlayerModal.displayHCP(hcp),
        selected: true,
      },
      homeClub: {
        name: '.homeClub',
        type: 'label',
        valueType: 'string',
        width: '250px',
        selected: true,
      },
    }

    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  static displayHCP(hcp) {
    if (hcp <= 0) {
      return Math.abs(hcp).toFixed(1)
    } else {
      return '+' + hcp.toFixed(1)
    }
  }

  handleKeyPress(e) {
    if (e.charCode === 13) this.props.onSearch(1)
  }

  renderSearchResult() {
    const { players, onRowClick } = this.props
    const emptySearchResult = players.filter((player) => player._id === -1).length

    return (
      <div className={style.searchResulTable}>
        <Table
          headers={this.headers}
          values={players}
          hideScrollbar
          hideArrows
          onRowClick={emptySearchResult ? null : onRowClick}
        />
      </div>
    )
  }

  renderContent() {
    const { onClose, onSearchChange, isLoading } = this.props
    return (
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="players.addExternalPlayer" />
          </div>
          <div className={style.closeBtn} onClick={onClose}>
            &times;
          </div>
        </div>
        <div className={style.searchInput}>
          <SearchInput
            placeholder=".players.searchPlayer"
            onKeyPress={this.handleKeyPress}
            onChange={(value) => onSearchChange(value)}
            isBordered
          />
          <PulseLoader showIf={isLoading} />
        </div>
        {this.renderSearchResult()}
      </div>
    )
  }

  render() {
    return <OverlayContainer>{this.renderContent()}</OverlayContainer>
  }
}

ExternalPlayerModal.propTypes = {
  lang: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  players: PropTypes.array,
  token: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubs: state.golfClub,
  }
}

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ExternalPlayerModal)
