import { SimulatorBooking } from '@sweetspot/shared/types'
import { FC, useMemo } from 'react'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  isBookingConfirmed,
  isBookingConsideredPaid,
  isBookingPartiallyPaid,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import { useTranslation } from 'react-i18next'
import { ExtraPlayerState } from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'
import { useBookingPaymentCalculations } from '@sweetspot/sweetspot-js/features/bookings/hooks/useBookingPaymentCalculations'
interface TotalCalculation {
  booking: SimulatorBooking
  extraPlayerState: ExtraPlayerState
}

export const TotalCalculation: FC<TotalCalculation> = ({ booking, extraPlayerState }) => {
  const isPaid = useMemo(() => isBookingConsideredPaid(booking), [booking])
  const isPartiallyPaid = useMemo(() => isBookingPartiallyPaid(booking), [booking])
  const isConfirmed = useMemo(() => isBookingConfirmed(booking), [booking])

  const {
    calculatedTotal,
    priceDifference,
    toPayTotal,
    toRefundTotal,
    areTeeTimesWithPPPModifiedResult,
    totalAdjustments,
    showPriceDifference,
    showAmountToPay,
    showDiscount,
    showPartiallyPaid,
    showAmountPaid,
  } = useBookingPaymentCalculations({
    isPaid,
    isPartiallyPaid,
    isConfirmed,
    booking,
  })

  const { t } = useTranslation()

  const symbolText = useMemo(() => (priceDifference > 0 ? '+' : ''), [priceDifference])
  return (
    <div
      className={'flex w-full flex-col gap-2 rounded-b-xl bg-gray-100 px-[31px] py-3 font-semibold'}
    >
      {showDiscount && (
        <>
          <div className={'flex justify-between'}>
            <p>{t('words.subtotal')}</p>
            <p>{priceToLocal(calculatedTotal + totalAdjustments, booking.currency_code, true)}</p>
          </div>
          <div className={'flex justify-between'}>
            <p>{t('words.discount')}</p>
            <p>{priceToLocal(totalAdjustments, booking.currency_code, true)}</p>
          </div>
        </>
      )}
      {showAmountPaid && (
        <div className={'flex justify-between'}>
          <p>{t('sentences.totalPaid')}: </p>
          <p>{priceToLocal(calculatedTotal, booking.currency_code, true)}</p>
        </div>
      )}
      {showPartiallyPaid && (
        <>
          <div className={'flex justify-between'}>
            <p>{t('extraPlayer.label_newTotalPrice')}</p>
            <p>{priceToLocal(calculatedTotal, booking.currency_code, true)}</p>
          </div>
          <div className={'flex justify-between'}>
            <p>{t('sentences.leftToPay')}:</p>
            <p>{priceToLocal(toPayTotal, booking.currency_code, true)}</p>
          </div>
        </>
      )}
      {showPriceDifference && (
        <>
          <div className={'flex justify-between text-blue-500'}>
            <p>{t('sentences.priceDifference')}:</p>
            <p>
              {symbolText}
              {priceToLocal(priceDifference, booking.currency_code, true)}
            </p>
          </div>
          <div className={'flex justify-between'}>
            <p>{t('extraPlayer.label_newTotalPrice')}</p>
            <p>{priceToLocal(Math.abs(calculatedTotal), booking.currency_code, true)}</p>
          </div>
          {areTeeTimesWithPPPModifiedResult && (isPaid || isPartiallyPaid) && (
            <>
              {toRefundTotal > 0 && (
                <div className={'flex justify-between'}>
                  <p>{t('sentences.toBeRefunded')}:</p>
                  <p>{priceToLocal(toRefundTotal, booking.currency_code, true)}</p>
                </div>
              )}
              {toPayTotal > 0 && (
                <div className={'flex justify-between'}>
                  <p>{t('sentences.leftToPay')}:</p>
                  <p>{priceToLocal(toPayTotal, booking.currency_code, true)}</p>
                </div>
              )}
            </>
          )}
        </>
      )}
      {showAmountToPay && (
        <div className={'flex justify-between'}>
          <p>{t('sentences.totalToPay')}</p>
          <p>{priceToLocal(calculatedTotal, booking.currency_code, true)}</p>
        </div>
      )}
    </div>
  )
}
