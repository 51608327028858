import { useQuery } from 'react-query'
import { _getBookingPeriodsByGolfcourse } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'

import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'
import { createContext, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

const TimePeriodsContext = createContext({
  selectedCourse: null,
  isLoading: true,
  periodsList: null,
  periodOverrides: null,
  activePeriod: null,
  categories: [],
  spaces: [],
  lang: null,
  token: null,
})

const notSelectedTeeTimeCategories = { id: null, name: '-', color: 'white' }

export const useTimePeriodsInit = () => {
  const [globalState, setGlobalState] = useContext(TimePeriodsContext)
  const reduxState = useSelector(mapStateToProps)
  const { clubId, selectedCourse, lang, token } = reduxState

  useQuery(['TEE-TIME-CATEGORIES', clubId], () =>
    getTranslatedTeeTimeCategories({ locale: lang, allPages: true, clubId }).then((categories) => {
      setGlobalState((state) => ({
        ...state,
        categories: [...categories, notSelectedTeeTimeCategories],
      }))
    })
  )

  useQuery(['SPACES', selectedCourse?.id], async () => {
    if (selectedCourse && selectedCourse.id) {
      const spaces = await getSpaces({ course: selectedCourse.id })
      return setGlobalState((state) => ({
        ...state,
        spaces: spaces.map((s) => ({ ...s, id: s.uuid })),
      }))
    }
  })

  useQuery(
    ['PERIODS', selectedCourse?.id],
    () => {
      if (selectedCourse && selectedCourse.id) {
        return _getBookingPeriodsByGolfcourse(selectedCourse.id)
      }
    },
    {
      onSuccess: (periods) => {
        setGlobalState((state) => ({
          ...state,
          periodsList: periods || [],
          activePeriod: periods ? periods[0] : null,
        }))
      },
    }
  )

  useEffect(() => {
    setGlobalState((state) => ({ ...state, lang, token, selectedCourse }))
  }, [lang, selectedCourse, selectedCourse?.id, setGlobalState, token])

  return globalState
}

export default TimePeriodsContext

const mapStateToProps = (state) => {
  return {
    golfClub: state.golfClub.list.find((club) => club.id === state.golfClub.selectedId),
    clubId: state.golfClub.selectedId,
    golfCourses: state.golfCourse.list,
    selectedCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
    token: state.auth.token,
    lang: state.auth.me.lang,
  }
}
