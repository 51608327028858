import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import TimePicker from '@sweetspot/club-portal-legacy/components/TimePicker'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { useTranslation } from 'react-i18next'
import style from '../style.module.scss'
import { ReactComponent as CalendarIcon } from './calendar-icon.svg'

const DateTimeRangeInput = ({ errors, triedToSubmit, formState, setValue }) => {
  const { t } = useTranslation()
  const { start_date, end_date, start_time_from, start_time_to } = formState

  return (
    <>
      <div className={errors.start_date && triedToSubmit ? style.errored : ''}>
        <span className={style.optionsText}>{t('editPeriodsNew.fromDate')}</span>
        <TextInputField
          containerClassName={style.inputOptions}
          type="calendar"
          value={start_date}
          calendarSettings={{
            selectedDate: start_date,
            returnDateFormat: 'YYYY-MM-DD',
          }}
          onChange={(value) => setValue('start_date', value)}
          CalendarComponent={MiniCalendar}
          prefix={<CalendarIcon />}
          calendarPrefix={true}
          noMargin={true}
          noMarginPrefix={true}
        />
        {triedToSubmit && <div className={style.error}>{errors.start_date}</div>}
      </div>
      <div className={errors.end_date && triedToSubmit ? style.errored : ''}>
        <span className={style.optionsText}>{t('editPeriodsNew.toDate')}</span>
        <TextInputField
          containerClassName={style.inputOptions}
          type="calendar"
          value={end_date}
          calendarSettings={{
            selectedDate: end_date,
            returnDateFormat: 'YYYY-MM-DD',
          }}
          onChange={(value) => setValue('end_date', value)}
          CalendarComponent={MiniCalendar}
          prefix={<CalendarIcon />}
          calendarPrefix={true}
        />

        {triedToSubmit && <div className={style.error}>{errors.end_date}</div>}
      </div>
      <div className={errors.start_time_from && triedToSubmit ? style.errored : ''}>
        <span className={style.optionsText}>{t('editPeriodsNew.firstTimeStarts')}</span>
        <TimePicker
          value={start_time_from}
          onChange={(event) => setValue('start_time_from', event.target.value)}
          className={`ss-input ${style.inputOptions}`}
          type="time"
          iconLeft
        />
        {triedToSubmit && <div className={style.error}>{errors.start_time_from}</div>}
      </div>
      <div className={errors.start_time_to && triedToSubmit ? style.errored : ''}>
        <span className={style.optionsText}>{t('editPeriodsNew.lastTimeStarts')}</span>
        <TimePicker
          value={start_time_to}
          onChange={(event) => setValue('start_time_to', event.target.value)}
          className={`ss-input ${style.inputOptions}`}
          type="time"
          iconLeft
        />
        {triedToSubmit && <div className={style.error}>{errors.start_time_to}</div>}
      </div>
    </>
  )
}

export default DateTimeRangeInput
