import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { withTranslation } from 'react-i18next'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import PAManagerModal from '@sweetspot/club-portal-legacy/modals/PAManagerModal'

import headers from './headers.json'
import AdminHelpers from '@sweetspot/club-portal-legacy/helpers/AdminHelpers'

import { getListOfAdmins } from '@sweetspot/club-portal-legacy/store/actions'

const actions = [
  {
    id: 1,
    label: 'membership.addMembership',
    action: 'createNewAdmin',
  },
]

class PartnerAdmins extends Component {
  state = {
    currentPage: 1,
    isLoading: true,
    searchString: '',
    admins: [],
    totalPages: 1,
    totalRows: 0,
    rowsPerPage: 0,
  }

  componentDidMount = () => {
    this.getAdminsList()
    this.props.i18n.on('languageChanged', this.handleLanguageChange)
  }

  componentWillUnmount = () => {
    this.props.i18n.off('languageChanged', this.handleLanguageChange)
  }

  handleLanguageChange = () => {
    this.getAdminsList()
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props
    if (!prevProps.location) return
    const { state } = prevProps.location

    if (location && location.state && location.state.shouldReload && !state) {
      this.getAdminsList(this.state.currentPage)
    }
  }

  handleSearch = (value = '') => {
    this.setState({ searchString: value })
  }

  handleActionConfirm = (action) => {
    if (action.id === 1) {
      this.onCreateNewAdmin()
    }
  }

  getAdminsList = (pageNum = 1) => {
    const { searchString } = this.state
    const { getListOfAdmins } = this.props
    this.setState({ isLoading: true })

    let query = `&page=${pageNum}`
    if (searchString) query += `&search=${searchString}`

    getListOfAdmins('ROLE_PARTNER,ROLE_PARTNER_BOOKER', query).then((response) => {
      const rowsPerPage = response['hydra:itemsPerPage']
      const totalRows = response['hydra:totalItems']
      const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
      const adminList = response['hydra:member']
      if (!adminList) {
        this.setState({ isLoading: false, totalPages: 0, itemsPerPage: 0, totalItems: 0 })
      } else {
        let admins = AdminHelpers.formatPartnerAdminList(adminList)
        this.setState({ admins, isLoading: false, totalPages, rowsPerPage, totalRows })
      }
    })
  }

  onCreateNewAdmin = () => {
    this.props.history.push('/settings/partner-admins/new')
  }

  onEditAdmin = (row) => {
    const { _id } = row
    this.props.history.push(`/settings/partner-admins/${_id}`)
  }

  render() {
    const { isLoading, admins, totalPages, rowsPerPage, totalRows } = this.state

    return (
      <div className={style.content}>
        <Grid
          actions={actions}
          actionsEnabled
          isLoading={isLoading}
          values={admins}
          pageTitle={'partnerRoles'}
          headers={headers}
          hideArrows
          searchEnabled
          searchPlaceholder={'.settings.partnerAdmins.searchPlaceholder'}
          pagination
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          displayRangeOfRows
          onRowClick={(row) => this.onEditAdmin(row)}
          setCurrentPage={(currentPage) =>
            this.setState({ currentPage }, () => this.getAdminsList(currentPage))
          }
          onActionConfirm={this.handleActionConfirm}
          onSearchChange={this.handleSearch}
          onSearch={() => this.getAdminsList()}
        />
        <Switch>
          <Route exact path="/settings/partner-admins/:id" component={PAManagerModal} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  golfClubs: state.golfClub,
  lang: state.auth.me.lang,
  token: state.auth.token,
})

const mapDispatchToProps = (dispatch) => ({
  getListOfAdmins: (role, query) => dispatch(getListOfAdmins(role, query)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PartnerAdmins))

PartnerAdmins.propTypes = {
  getListOfAdmins: PropTypes.func.isRequired,
  golfClubs: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string.isRequired,
}
