import React, { useState, useCallback } from 'react'

// Component and utility imports
import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import HeaderInformation from './components/Header/HeaderInformation'
import HeaderTitle from './components/Header/HeaderTitle'
import Sidebar from './components/Sidebar'
import Information from './components/Information'
import GitInfo from './components/GitInfo'
import MembershipWrapper from './components/MembershipWrapper'
import style from './style.module.scss'

// Hook imports
import usePlayerCardLogic from './hooks/usePlayerCardLogic'
import usePlayerCardNavigationLogic from './hooks/usePlayerCardNavigationLogic'

// Types and Context Providers
import { SidebarItem } from './types'
import { PlayerCardProvider } from '@sweetspot/club-portal/data-access/context-providers'
import Bookings from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Bookings'
import VoucherPlayerCard from './components/Voucher'

type Props = {
  playerId?: string
  onClose?: VoidFunction
}

/**
 * The PlayerCard component renders details and operations related to a particular player.
 * It provides functionalities like viewing/updating player info, managing memberships, etc.
 */
const PlayerCard: React.FC<Props> = ({ playerId, onClose }) => {
  // State for controlling the currently active sidebar item
  const [sidebar, setSidebar] = useState<SidebarItem>(SidebarItem.INFORMATION)

  // Business logic for player card management
  const {
    handleUpdateCard,
    handleUpdatePlayRight,
    gitInfo,
    isPlayerCardUpdated,
    isLoading,
    membershipCards,
    handleMarkAsPaid,
    handleUnmarkAsPaid,
    handleRemove,
    addMembership,
    handleSendLink,
    handleCancelMembershipAutoRenewal,
    handleEnableMembershipAutoRenewal,
    handleCancelUpcomingPayments,
    selectedGolfClubId,
    cardInfo,
    handleRefund,
    handleLockOrUnlockMembership,
  } = usePlayerCardLogic(playerId)

  // Logic for navigation (especially for the closing action)
  const { handleClose } = usePlayerCardNavigationLogic(isPlayerCardUpdated, onClose)

  const changeSidebar = useCallback((id: SidebarItem) => {
    setSidebar(id)
  }, [])

  // Function to render the main content based on the selected sidebar item
  const renderMain = useCallback(() => {
    switch (sidebar) {
      case SidebarItem.INFORMATION:
        return cardInfo ? (
          <Information
            isLoading={isLoading}
            playerInfo={cardInfo}
            onUpdate={handleUpdateCard}
            onChangePlayRight={handleUpdatePlayRight}
          />
        ) : null
      case SidebarItem.MEMBERSHIP:
        return (
          <MembershipWrapper
            isLoading={isLoading}
            player={cardInfo}
            membershipCards={membershipCards}
            markAsPaid={handleMarkAsPaid}
            removeMembership={handleRemove}
            sendPaymentLink={handleSendLink}
            unMarkAsPaid={handleUnmarkAsPaid}
            onAddMembership={addMembership}
            cancelMembershipAutoRenewal={handleCancelMembershipAutoRenewal}
            enableMembershipAutoRenewal={handleEnableMembershipAutoRenewal}
            handleCancelUpcomingPayments={handleCancelUpcomingPayments}
            refundMembershipCard={handleRefund}
            handleLockOrUnlockMembership={handleLockOrUnlockMembership}
            clubId={selectedGolfClubId}
          />
        )
      case SidebarItem.BOOKING:
        return <Bookings playerId={cardInfo?.id} />
      case SidebarItem.VOUCHER:
        return cardInfo ? (
          <VoucherPlayerCard playerInfo={cardInfo} selectedGolfClubId={selectedGolfClubId} />
        ) : null
      case SidebarItem.GIT_INFO:
        return gitInfo ? <GitInfo gitInfo={gitInfo} /> : null
      default:
        return <div />
    }
  }, [
    addMembership,
    cardInfo,
    gitInfo,
    selectedGolfClubId,
    handleMarkAsPaid,
    handleRemove,
    handleSendLink,
    handleUnmarkAsPaid,
    handleUpdateCard,
    handleUpdatePlayRight,
    isLoading,
    membershipCards,
    sidebar,
  ])

  return (
    <ModalContainer
      className={style.container}
      isFullsize={false}
      layout="sidebar"
      loading={isLoading}
      width={'threeQuarter'}
      onClose={handleClose}
      sidebarChildren={
        <Sidebar sidebar={sidebar} info={cardInfo} gitInfo={gitInfo} onChange={changeSidebar} />
      }
      headerChildren={<HeaderInformation player={cardInfo} />}
      headerTitle={<HeaderTitle membershipCards={membershipCards} player={cardInfo} />}
    >
      {renderMain()}
    </ModalContainer>
  )
}

/**
 * The PlayerCardWrapper provides the necessary context for the PlayerCard component.
 * This wrapper ensures that any required contexts are available to the PlayerCard.
 */
const PlayerCardWrapper: React.FC<Props> = (props) => (
  <PlayerCardProvider>
    <PlayerCard {...props} />
  </PlayerCardProvider>
)

export default PlayerCardWrapper
