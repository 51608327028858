import React, { useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import SweetspotLogo from '@sweetspot/club-portal-legacy/resources/images/SS_logo_white.png'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { useHistory, useParams } from 'react-router'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  confirmClubUserInvite,
  getClubUserInvite,
} from '@sweetspot/sweetspot-js/features/clubUsers/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import { LanguageKeys } from '@sweetspot/shared/translations'

const DEFAULT_ERRORS = {
  first_name: false,
  last_name: false,
  password: false,
  password_repeat: false,
  text: '',
}

const ClubUserAcceptInvite = () => {
  const { t, i18n } = useTranslation()
  const { token } = useParams()
  const { addToast } = useToasts()
  let history = useHistory()

  const [form, setForm] = useMergeState({
    first_name: '',
    last_name: '',
    password: '',
    password_repeat: '',
  })
  const [tokenInvalid, setTokenInvalid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useMergeState(DEFAULT_ERRORS)

  const { isFetchingInviteData } = useQuery(
    [CLUB_QUERIES.CLUB_USER_INVITE],
    () => getClubUserInvite(token),
    {
      enabled: !!token,
      onSuccess: (data) => {
        if (data?.status !== 'not_active') setTokenInvalid(true)
        if (data?.club_user?.id) {
          onSubmitListener(undefined, true)
        }
      },
      onError: (err) => {
        setTokenInvalid(true)
      },
    }
  )

  const onSubmitListener = async (e, emptyPayload = false) => {
    if (e) e.preventDefault()

    setFormErrors(DEFAULT_ERRORS)
    setIsSubmitting(true)

    const { first_name, last_name, password, password_repeat } = form

    if (!emptyPayload) {
      if (password !== password_repeat) {
        setFormErrors({
          password: true,
          password_repeat: true,
          text: t('sentences.passwordsDontMatch'),
        })
        setIsSubmitting(false)
        return
      }

      if (password?.length < 8) {
        setFormErrors({
          password: true,
          password_repeat: true,
          text: t('sentences.passwordTooShort'),
        })
        setIsSubmitting(false)
        return
      }

      if (!first_name?.length) {
        setFormErrors({
          first_name: true,
          text: t('errors.fieldRequired'),
        })
        setIsSubmitting(false)
        return
      }

      if (!last_name?.length) {
        setFormErrors({
          last_name: true,
          text: t('errors.fieldRequired'),
        })
        setIsSubmitting(false)
        return
      }
    }

    const [res, err] = await to(
      confirmClubUserInvite(
        token,
        emptyPayload
          ? {}
          : {
              first_name,
              last_name,
              password,
            }
      )
    )

    if (!res || err) {
      if (err?.violations) {
        err.violations.forEach((error) => {
          const { errorName, propertyPath } = error
          if (propertyPath === 'last_name' && errorName === 'TOO_SHORT_ERROR') {
            setFormErrors({
              last_name: true,
              text: t('errors.fieldTooShort'),
            })
          }
          if (propertyPath === 'first_name' && errorName === 'TOO_SHORT_ERROR') {
            setFormErrors({
              last_name: true,
              text: t('errors.fieldTooShort'),
            })
          }
          if (propertyPath === 'password' && errorName === 'TOO_SHORT_ERROR') {
            setFormErrors({
              last_name: true,
              text: t('errors.fieldTooShort'),
            })
          }
        })
      } else {
        addToast(t('sentences.couldNotConfirmInvite'), { appearance: 'error' })
        setFormErrors({
          text: t('errors.somethingWentWrong', { id: 'verify-invite-1' }),
        })
      }
      setIsSubmitting(false)
    } else {
      addToast(t('sentences.inviteAccepted'), { appearance: 'success' })
      history.push('/')
    }
  }

  const onChangeInputListener = (e) => {
    e.preventDefault()
    const { value, name } = e.target
    setFormErrors({ [name]: false })
    setForm({ [name]: value })
  }

  const changeLanguage = (KEY) => {
    i18n.changeLanguage(KEY)
  }

  return (
    <div className={cx(styles.container)}>
      <img className={cx(styles.logo, 'mb-12')} alt="sweetspot logo" src={SweetspotLogo} />
      <div className={cx(styles.formWrapper)}>
        <p className={cx(styles.languageSwitch)}>
          <span
            className={cx(i18n.language === LanguageKeys.EN && styles.underline)}
            onClick={() => changeLanguage(LanguageKeys.EN)}
          >
            English
          </span>{' '}
          <span
            className={cx(i18n.language === LanguageKeys.SV && styles.underline)}
            onClick={() => changeLanguage(LanguageKeys.SV)}
          >
            Svenska
          </span>
        </p>
        {tokenInvalid && <p>{t('sentences.tokenInvalidOrExpired')}</p>}
        {!isFetchingInviteData && !tokenInvalid && (
          <form onSubmit={onSubmitListener}>
            <div className={cx('ss-form-wrapper')}>
              <div className={cx('ss-form-row mb-5 mt-2')}>
                <div className={cx('ss-form-wrapper w-50p w-flex flex-0-1 mr-3.5')}>
                  <label className={cx('ss-label')}>{t('words.firstName')}</label>
                  <div className={cx('ss-input-row w-100p')}>
                    <input
                      name="first_name"
                      value={form.first_name}
                      onChange={onChangeInputListener}
                      placeholder={t('sentences.enterFirstName')}
                      className={cx('ss-input', formErrors.first_name && 'error')}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div className={cx('ss-form-wrapper w-50p w-flex')}>
                  <label className={cx('ss-label')}>{t('words.lastName')}</label>
                  <div className={cx('ss-input-row w-100p')}>
                    <input
                      name="last_name"
                      value={form.last_name}
                      onChange={onChangeInputListener}
                      placeholder={t('sentences.enterLastName')}
                      className={cx('ss-input', formErrors.last_name && 'error')}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
              <div className={cx('ss-form-wrapper w-100p mb-5')}>
                <label className={cx('ss-label')}>{t('words.password')}</label>
                <input
                  name="password"
                  type="password"
                  value={form.password}
                  onChange={onChangeInputListener}
                  placeholder={t('words.password')}
                  className={cx('ss-input w-100p', formErrors.password && 'error')}
                  disabled={isSubmitting}
                />
              </div>
              <div className={cx('ss-form-wrapper w-100p mb-5')}>
                <label className={cx('ss-label')}>{t('sentences.confirmPassword')}</label>
                <input
                  name="password_repeat"
                  type="password"
                  value={form.password_repeat}
                  onChange={onChangeInputListener}
                  placeholder={t('words.password')}
                  className={cx('ss-input w-100p', formErrors.password_repeat && 'error')}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className={cx(styles.footer)}>
              <p className={cx(styles.errorText)}>{formErrors.text || ''}</p>
              <PulseLoader showIf={isSubmitting} />
              <button
                type="submit"
                className={cx('system-button primary md-32')}
                disabled={isSubmitting}
              >
                {t('words.confirm')}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

ClubUserAcceptInvite.propTypes = {}

ClubUserAcceptInvite.defaultProps = {}

export default ClubUserAcceptInvite
