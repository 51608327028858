import { ReactNode } from 'react'
import TimePicker from 'rc-time-picker'
import moment from 'moment'

import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'

interface OpeningHoursTimePickerProps {
  day: string
  disabled?: boolean
  time?: string
  type: string
}

const OpeningHoursTimePicker = ({ day, disabled, time, type }: OpeningHoursTimePickerProps) => {
  const {
    bucketConfiguration: { setOpeningHours },
  } = useBallPricingContext()

  return (
    <TimePicker
      className="text-content-base [&_input]:text-content-base [&_input]:text-text-dark w-20 font-normal [&_input]:h-8 [&_input]:rounded-md [&_input]:px-4"
      showSecond={false}
      value={!disabled ? moment(time, 'HH:mm') : undefined}
      onChange={(newTime) => setOpeningHours?.({ [day]: { [type]: newTime.format('HH:mm') } })}
      minuteStep={1}
      popupClassName="pointer-events-auto [&>div]:text-content-base"
      clearIcon={<div></div>}
      disabled={disabled}
      placeholder="--:--"
      // getPopupContainer is missing from TimePickerProps, thus throwing a ts error
      // BUT we need it to enable scrolling of the timepicker if inside the Sheet:
      // https://github.com/react-component/time-picker/issues/64#issuecomment-343506191
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getPopupContainer={(triggerNode: { parentNode: ReactNode }) => triggerNode.parentNode}
    />
  )
}

export default OpeningHoursTimePicker
