import { FC, useMemo, useState } from 'react'
import { Space, Item } from '@sweetspot/shared/types'

import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'

import { DetailsSummary } from '../DetailsSummary/DetailsSummary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useExtraPlayerState } from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'

interface SpaceAccordionProps {
  space: Space
  timezone: string
  orderBookingRefetch: () => Promise<unknown>
  disableAdjustment: boolean
  currencyCode: string
  hasOwner: boolean
  items: Item[]
  isPaid: boolean
  isConfirmed: boolean
}

export const SpacesAccordion: FC<SpaceAccordionProps> = ({
  space,
  timezone,
  orderBookingRefetch,
  disableAdjustment,
  currencyCode,
  items,
  hasOwner,
  isPaid,
  isConfirmed,
}) => {
  const [isDetailsOpen, setDetailsState] = useState<boolean>(false)
  const extraPlayerState = useExtraPlayerState()

  const groupedItems = useMemo(() => {
    const groupedByTeeTimeUUID = items.reduce((acc: { [key: string]: Item[] }, item) => {
      const teeTimeUUID = item.slot.tee_time.uuid
      if (!acc[teeTimeUUID]) {
        acc[teeTimeUUID] = []
      }
      acc[teeTimeUUID].push(item)
      return acc
    }, {})

    Object.keys(groupedByTeeTimeUUID).forEach((teeTimeUUID) => {
      groupedByTeeTimeUUID[teeTimeUUID].sort((a, b) => {
        // Check if extraPlayer exists for both items
        const aExists = !!a.slot.extra_player
        const bExists = !!b.slot.extra_player

        // If neither or both exist, compare their IDs if they exist, otherwise consider them equal
        if (aExists && bExists) {
          return a.slot.extra_player!.id - b.slot.extra_player!.id
        } else if (!aExists && !bExists) {
          return 0 // Keep original order if both do not have extraPlayer
        } else if (!aExists) {
          return -1 // a comes before b
        } else {
          return 1 // b comes before a
        }
      })
    })

    return Object.entries(groupedByTeeTimeUUID)
  }, [items])

  const spaceTotal = useMemo(() => {
    let total = 0
    extraPlayerState?.calculatedPriceResult?.order_items
      .filter((orderItem) => orderItem.space_uuid === space.uuid)
      .forEach((orderItem) => (total += orderItem.total))
    return total
  }, [extraPlayerState?.calculatedPriceResult?.order_items, space.uuid])

  const handlePriceClick = () => {
    setDetailsState(!isDetailsOpen)
  }
  return (
    <div className={'flex w-full flex-col'}>
      <div
        onClick={handlePriceClick}
        className={'flex cursor-pointer select-none flex-row justify-between '}
      >
        <div className="text-brand-black font-semibold">{space.name}</div>
        <div className={'text-brand-black flex flex-row items-center '}>
          {!isDetailsOpen && (
            <span className="text-brand-black mr-2 font-semibold ">
              {priceToLocal(spaceTotal, currencyCode, true)}
            </span>
          )}
          <FontAwesomeIcon
            className={` transition-transform ${isDetailsOpen ? 'rotate-180' : 'rotate-0'}`}
            icon={faCaretDown}
            color="#000"
          ></FontAwesomeIcon>
        </div>
      </div>
      {isDetailsOpen && (
        <div className="overflow-auto">
          {groupedItems.map(([teeTimeUuid, items], index) => {
            return (
              <div key={index} className="flex flex-col">
                <DetailsSummary
                  hasOwner={hasOwner}
                  disableAdjustment={disableAdjustment}
                  key={index}
                  items={items}
                  teeTimeUuid={teeTimeUuid}
                  timezone={timezone}
                  currency_code={currencyCode}
                  orderBookingRefetch={orderBookingRefetch}
                  isLastItem={index === groupedItems.length - 1}
                  isConfirmed={isConfirmed}
                  isPaid={isPaid}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
