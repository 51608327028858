import { useMemo, useRef, useState } from 'react'
import _isEqual from 'lodash/isEqual'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import { RULE_TYPES, TYPES_OBJ } from '../../constants/rules'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { createNewRule, deleteRule, updateRule } from '../../services/api-platform'
import { useToasts } from 'react-toast-notifications'

import BookingSource from './Rules/BookingSource'
import DaysAndTimesChecker from './Rules/DaysAndTimesChecker'
import PrimeTimeChecker from './Rules/PrimeTimeChecker'

import CoursesAndSpacesIncludedRule from '@sweetspot/sweetspot-js/features/promotionsReservationsCommon/Rules/CoursesAndSpacesIncludedRule'
import AnimatedLine from '@sweetspot/sweetspot-js/common/components/AnimatedLine'

import BookingPeriod from './Rules/BookingPeriod'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'

const SingleRule = ({
  rule: ruleProp,
  promotionId,
  onRemoveRule,
  index,
  disabled,
  golfClubID,
  voucherDisabled = false,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const saveTimeout = useRef(null)

  // States
  const [isLoading, setIsLoading] = useState(false)
  const [rule, setRule] = useMergeState(ruleProp)
  const prevRule = usePrevious(rule)

  // Memos
  const isNewRule = useMemo(
    () => (typeof rule?.id === 'string' && rule.id?.includes('new') ? true : false),
    [rule]
  )

  const currentRuleType = useMemo(() => {
    return RULE_TYPES.find((item) => item.value === rule.type) || null
  }, [rule])

  // Functions
  const saveRule = async (updatedRule) => {
    const newRule = updatedRule || rule
    const newPrevRule = updatedRule ? rule : prevRule

    clearTimeout(saveTimeout.current)

    const shouldSave = () => {
      if (_isEqual(newRule, newPrevRule)) {
        return false
      } else {
        return true
      }
    }

    const canSave = () => {
      if (newRule?.type) {
        return true
      }
      return false
    }

    if (isNewRule && canSave()) {
      setIsLoading(true)
      saveTimeout.current = setTimeout(async () => {
        const [res, err] = await to(createNewRule(promotionId, newRule))

        if (!res || err) {
          addToast(t('sentences.couldNotSaveRule'), { appearance: 'error' })
        } else {
          setRule({ id: res.id, uuid: res.uuid })
        }
        setIsLoading(false)
      }, 500)
    }

    if (!isNewRule && canSave() && shouldSave()) {
      setIsLoading(true)
      saveTimeout.current = setTimeout(async () => {
        const [res, err] = await to(updateRule(newRule.id, newRule))

        if (!res || err) {
          addToast(t('sentences.couldNotSaveRule'), { appearance: 'error' })
        }
        setIsLoading(false)
      }, 500)
    }
  }

  const removeRule = () => {
    if (disabled) return

    if (!isNewRule) {
      deleteRule(rule.id)
    }
    onRemoveRule()
  }

  return (
    <div className={cx(styles.container)} style={{ zIndex: 100 - index }}>
      <AnimatedLine show={isLoading} className={cx(styles.pulse)} />
      <div className={cx(styles.header)}>
        <div className={cx(styles.leftSide)}>
          <p className={cx(styles.headerTitle)}>
            {currentRuleType ? t(currentRuleType.label) : t('sentences.newRule')}
          </p>
          {currentRuleType?.hint ? (
            <InfoHover textId={currentRuleType?.hint} containerClassName={styles.headerHint} />
          ) : null}
        </div>
        {!voucherDisabled && (
          <div className={cx(styles.rightSide)}>
            <div
              className={cx(styles.removeIconContainer, disabled && styles.disabled)}
              onClick={removeRule}
            >
              <RemoveIcon className={cx(styles.removeIcon)} />
            </div>
          </div>
        )}
      </div>
      {
        <div className={cx(styles.foldContainer, styles.isOpen)}>
          <div
            className={cx(styles.innerContainer, voucherDisabled && styles.innerContainerDisabled)}
          >
            {/* Booking source */}
            {currentRuleType?.value === TYPES_OBJ.SOURCE && (
              <BookingSource
                rule={rule}
                onSetRule={(value) => setRule(value)}
                onSaveRule={(newRule) => saveRule(newRule)}
                disabled={disabled}
                loading={isNewRule}
                voucherDisabled={voucherDisabled}
              />
            )}
            {/* Courses checker */}
            {currentRuleType?.value === TYPES_OBJ.COURSESANDSPACES && (
              <CoursesAndSpacesIncludedRule
                rule={rule}
                onSetRule={(value) => setRule(value)}
                onSaveRule={(newRule) => saveRule(newRule)}
                disabled={disabled}
                loading={isNewRule}
                context="promotion"
                golfClubID={golfClubID}
                voucherDisabled={voucherDisabled}
              />
            )}
            {/* Days and times checker */}
            {currentRuleType?.value === TYPES_OBJ.DAYSANDTIMES && (
              <DaysAndTimesChecker
                rule={rule}
                onSetRule={(value) => setRule(value)}
                onSaveRule={(newRule) => saveRule(newRule)}
                disabled={disabled}
                loading={isNewRule}
                voucherDisabled={voucherDisabled}
              />
            )}
            {/* Prime time rule checker */}
            {currentRuleType?.value === TYPES_OBJ.PRIMETIME && (
              <PrimeTimeChecker
                rule={rule}
                onSetRule={(value) => setRule(value)}
                onSaveRule={(newRule) => saveRule(newRule)}
                disabled={disabled}
                loading={isNewRule}
                voucherDisabled={voucherDisabled}
              />
            )}
            {/* Booking period */}
            {currentRuleType?.value === TYPES_OBJ.PERIOD && (
              <BookingPeriod
                rule={rule}
                onSetRule={(value) => setRule(value)}
                onSaveRule={(newRule) => saveRule(newRule)}
                disabled={disabled}
                loading={isNewRule}
                voucherDisabled={voucherDisabled}
                readOnly={voucherDisabled}
              />
            )}
          </div>
        </div>
      }
    </div>
  )
}

SingleRule.propTypes = {
  index: PropTypes.number,
  rule: PropTypes.object,
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemoveRule: PropTypes.func,
  disabled: PropTypes.bool,
}

SingleRule.defaultProps = {}

export default SingleRule
