import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './styles.module.scss'

import FeedbackContainer from '../Partials/FeedbackContainer'
import Label from '../Partials/Label'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import DotMarker from '@sweetspot/sweetspot-js/common/components/DotMarker'
import HoverDiv from '@sweetspot/sweetspot-js/common/components/HoverDiv'

const ToggleButton = ({
  onChange,
  value,
  disabled,
  containerClassName,
  containerWidth,
  label,
  lightLabel,
  secondaryLabel,
  hoverTextKey,
  error,
  success,
  feedback,
  noMargin,
  toggleLeft,
  dotEnabled,
  dotText,
  hintClassName,
}) => {
  const valueChanged = () => {
    if (disabled) return
    onChange(!value)
  }

  const toggleClasses = cx(
    {
      [styles.checked]: value,
      [styles.disabled]: disabled,
      'w-full basis-full grow-0 shrink-0': containerWidth === 'full',
      'w-1/2 basis-1/2 grow-0 shrink-0': containerWidth === 'half',
      'w-1/3 basis-1/3 grow-0 shrink-0': containerWidth === 'third',
      'w-1/4 basis-1/4 grow-0 shrink-0': containerWidth === 'quarter',
    },
    styles.container,
    {
      [styles.noMargin]: noMargin,
    },
    containerClassName
  )

  return (
    <div className={toggleClasses}>
      <div className={styles.labelRow}>
        <Label label={label} lightColor />
        {hoverTextKey && <InfoHover textId={hoverTextKey} containerClassName={hintClassName} />}
      </div>
      <div className={styles.toggleRow} onClick={valueChanged}>
        <div
          className={cx(styles.wrapper, {
            'mr-0': !label && !secondaryLabel,
          })}
        >
          <div className={styles.check}>
            <span></span>
          </div>
          <div className={styles.uncheck}>
            <span></span>
          </div>
        </div>
        <div className={styles.toggleCircle} />
        {dotEnabled && (
          <HoverDiv direction={'left'} hoverText={dotText} className={cx(styles.hint)}>
            <DotMarker color={'red'} className={cx(styles.dot)} />
          </HoverDiv>
        )}
        {toggleLeft && (
          <input type="checkbox" aria-label="Toggle Button" className={styles.toggleInput} />
        )}
        <Label
          label={secondaryLabel}
          defaultMargin={false}
          secondaryDark
          normalWeight={lightLabel}
          disabled={disabled}
        />
        {!toggleLeft && (
          <input type="checkbox" aria-label="Toggle Button" className={styles.toggleInput} />
        )}
      </div>
      {/* Feedback container */}
      {feedback ? <FeedbackContainer error={error} success={success} /> : null}
    </div>
  )
}

ToggleButton.defaultProps = {
  value: false,
  onChange: () => {},
  lightLabel: false,
  label: '',
  secondaryLabel: '',
  success: '',
  error: '',
  disabled: false,
  containerClassName: '',
  containerWidth: 'full',
  infoTextKey: '',
  feedback: true,
  noMargin: false,
  hoverTextKey: '',
  toggleLeft: false,
  strictTrigger: false,
}

ToggleButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  lightLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  containerWidth: PropTypes.string,
  success: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  feedback: PropTypes.bool,
  noMargin: PropTypes.bool,
  hoverTextKey: PropTypes.string,
  toggleLeft: PropTypes.bool,
  dotEnabled: PropTypes.bool,
  dotText: PropTypes.string,
  hintClassName: PropTypes.string,
}

export default ToggleButton
