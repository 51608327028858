import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Create a new scorecard
 *
 * @param {object} [payload={
 *     tee_time: ''
 *   }]
 * @return {*}
 */
export const createScoreCard = (
  payload = {
    tee_time: '',
  }
) => {
  const url = `${getBaseUrlPlatform()}/scorecards`
  return request(url, { method: 'post', body: payload })
}

/**
 * Get scorecard based on teetimeUuid
 *
 * @param {*} teetimeUuid
 * @return {*}
 */
export const getScoreCard = (teetimeUuid) => {
  if (!teetimeUuid) throw new Error('TeetimeUuid can not be undefined/null')
  const query = {
    page: 1,
    limit: 50,
    'order.token': null,
    'tee_time.uuid': teetimeUuid,
  }
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/scorecards`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, { method: 'get' })
}

/**
 * Get updated scorecard
 *
 * @param {number} scorecardId
 * @return {*} The scorecard
 */
export const updateScoreCard = (scorecardId) => {
  const url = `${getBaseUrlPlatform()}/scorecards/${scorecardId}/actualize`
  return request(url, { method: 'put' })
}

/**
 * Get info about the club from onTag (printers etc)
 *
 * @param {*} gitId
 * @return {object}
 */
export const getClubInfo = (gitId) => {
  const query = {
    git_id: gitId,
  }
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/ontag/clubinfo`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, { method: 'get' })
}

/**
 * Send a scorecard for printing
 *
 * @param {object} [payload={
 *     scorecard (string) // Uuid
 *     players (array)    // array of string uuids
 *     printer_server_guid (string)
 *     desired_score_card_type (number)
 *   }]
 **/
export const printScoreCard = (
  payload = {
    scorecard: 'scorecard_uuid',
    players: ['player_uuid'],
    printer_server_guid: 'print_server_uuid',
    desired_score_card_type: 1,
  }
) => {
  const url = `${getBaseUrlPlatform()}/scorecards/ontag/send`
  return request(url, { method: 'post', body: payload })
}

export const updatePlayerInScorecard = (
  teeTimeUuid,
  payload = {
    player: '',
    last_name: '',
    first_name: '',
    golf_id: '',
    hcp: '',
    age: 18,
    start_tee_id: '',
    start_order: 1,
    gender: '',
  }
) => {
  const url = `${getBaseUrlPlatform()}/scorecards/${teeTimeUuid}/player`
  return request(url, { method: 'PUT', body: payload })
}

export const getScorecardByID = (scorecardUuid) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/scorecards/${scorecardUuid}`,
    },
    {
      skipNull: true,
    }
  )
  return request(url, { method: 'get' })
}
