import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import { hexToRgba } from '@sweetspot/sweetspot-js/common/functions/utils'

const SelectableTeeTime = ({
  isSelected,
  isNextSelected,
  isPreviousSelected,
  isSuggested,
  isFirstInSelectedGroup,
  height,
  teeTime,
  onSelect,
  onDeselect,
  isInBooking,
  isDisabled,
}) => {
  return (
    <div
      className={cx(styles.selectableTeeTime, {
        [styles.selected]: isSelected,
        [styles.topSelected]: isFirstInSelectedGroup,
        [styles.previousSelected]: isPreviousSelected,
        [styles.nextSelected]: isNextSelected,
        [styles.suggested]: isSuggested,
        [styles.isInBooking]: isInBooking,
        [styles.disabled]: isDisabled,
      })}
      style={{
        height: height,
        backgroundColor: `rgba(${hexToRgba(teeTime.category.color, isSuggested ? 0 : 0.5)})`,
        borderColor: teeTime.category.color,
      }}
      onClick={() => (isSelected ? onDeselect(teeTime) : onSelect(teeTime))}
    >
      <div className={cx(styles.plusSignContainer)}>
        <span
          className={cx('material-icons', 'md-light', 'md-18')}
          style={{ color: teeTime.category.color }}
        >
          add_circle_outline
        </span>
      </div>
    </div>
  )
}

SelectableTeeTime.propTypes = {
  isSelected: PropTypes.bool,
  isNextSelected: PropTypes.bool,
  isPreviousSelected: PropTypes.bool,
  isSuggested: PropTypes.bool,
  isFirstInSelectedGroup: PropTypes.bool,
  height: PropTypes.number,
  teeTime: PropTypes.object,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  isInBooking: PropTypes.bool,
}

SelectableTeeTime.defaultProps = {}

export default SelectableTeeTime
