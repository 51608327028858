import React from 'react'
import PropTypes from 'prop-types'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'
import { withClubCurrency } from '../../../../hoc/withClubCurrency'

const PriceForm = (props) => {
  const {
    minPriceWeek,
    minPriceWork,
    basePriceWeek,
    basePriceWork,
    demandWork,
    demandWeek,
    supplyWeek,
    supplyWork,
    avgPriceWork,
    isWeekend,
    maxPriceWork,
    maxPriceWeek,
    avgPrice,
    activePeriodId,
    currentClubCurrency,
  } = props

  return (
    <div className={style.priceListing}>
      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className="col-span-2" />
        <div>
          <Text className={style.text} textId="date.weekday" />
        </div>
        <div>
          <Text className={style.text} textId="date.weekend" />
        </div>
      </div>

      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className={`col-span-2 ${style.firstCol}`}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.minimumPrice" />
        </div>
        <div>
          <input
            className="ss-input"
            name="minPriceWork"
            value={minPriceWork}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
        </div>
        <div>
          <input
            className="ss-input"
            name="minPriceWeek"
            value={minPriceWeek}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
        </div>
      </div>

      <hr />
      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className={`col-span-2 ${style.firstCol}`}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.basePrice" />
        </div>
        <div>
          <input
            className="ss-input"
            name="basePriceWork"
            value={basePriceWork}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
          <span className={style.symbol}>+</span>
        </div>
        <div>
          <input
            className="ss-input"
            name="basePriceWeek"
            value={basePriceWeek}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
          <span className={style.symbol}>+</span>
        </div>
      </div>

      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className={`col-span-2 ${style.firstCol}`}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.demand" />
        </div>
        <div>
          <input
            className="ss-input"
            name="demandWork"
            value={demandWork}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
          <span className={style.symbol}>+</span>
        </div>
        <div>
          <input
            className="ss-input"
            name="demandWeek"
            value={demandWeek}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
          <span className={style.symbol}>+</span>
        </div>
      </div>

      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className={`col-span-2 ${style.firstCol}`}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.supply" />
        </div>
        <div>
          <input
            className="ss-input"
            name="supplyWork"
            value={supplyWork}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
          {!isWeekend && <span className={style.symbol}>=</span>}
        </div>
        <div>
          <input
            className="ss-input"
            name="supplyWeek"
            value={supplyWeek}
            disabled={!activePeriodId}
            onChange={props.handleChange}
          />
          {isWeekend && <span className={style.symbol}>=</span>}
        </div>
      </div>

      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className={`col-span-2 ${style.firstCol}`}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.maxPrice" />
        </div>
        <div>
          <b>{!isWeekend && priceToLocal(maxPriceWork * 100, currentClubCurrency, true)}</b>
        </div>
        <div>
          <b>{isWeekend && priceToLocal(maxPriceWeek * 100, currentClubCurrency, true)}</b>
        </div>
      </div>

      <div className={`grid grid-cols-4 ${style.row}`}>
        <div className={`col-span-2 ${style.firstCol}`}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.averagePrice" />
        </div>
        <div>
          <b>{!isWeekend && priceToLocal(avgPrice * 100, currentClubCurrency, true)}</b>
        </div>
        <div>
          <b>{isWeekend && priceToLocal(avgPrice * 100, currentClubCurrency, true)}</b>
        </div>
      </div>

      <div className={`grid grid-cols-1 ${style.row}`}>
        <div className={style.firstCol}>
          <label className={style.infoIcon}>i</label>
          <Text textId="pricing.priceRounding" />
          <br />
        </div>

        <div className={style.priceRounding}>
          <input
            className="ss-input"
            name="avgPriceWork"
            value={avgPriceWork}
            disabled={!activePeriodId}
            type="number"
            onChange={props.handleChange}
          />
        </div>
      </div>
    </div>
  )
}

PriceForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  minPriceWeek: PropTypes.number,
  minPriceWork: PropTypes.number,
  basePriceWeek: PropTypes.number,
  basePriceWork: PropTypes.number,
  demandWork: PropTypes.number,
  demandWeek: PropTypes.number,
  supplyWeek: PropTypes.number,
  supplyWork: PropTypes.number,
  avgPriceWork: PropTypes.number,
  maxPrice: PropTypes.number,
  maxPriceWork: PropTypes.number,
  maxPriceWeek: PropTypes.number,
  avgPrice: PropTypes.number,
  isWeekend: PropTypes.bool,
  activePeriodId: PropTypes.number,
  currentClubCurrency: PropTypes.string.isRequired,
}

export default withClubCurrency(PriceForm)
