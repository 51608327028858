import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonAndDropdownSelectWrapper from '@sweetspot/sweetspot-js/common/components/ButtonAndDropdownSelectWrapper'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'
import NestedRowWrapper from '@sweetspot/sweetspot-js/common/components/NestedRowWrapper'

import produce from 'immer'
import { useInfiniteQuery, useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import PromotionsContext from '@sweetspot/sweetspot-js/features/promotions/containers/PromotionsManager/PromotionsContext'
import PoliciesContext from '@sweetspot/sweetspot-js/features/reservationPolicies/containers/ReservationPolicyManager/PoliciesContext'
import { queryClubCourses } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'

let searchDebounce

const formatOptions = (array) => {
  return array.map((course) => ({
    id: course.id,
    label: course.name,
    name: course.name,
    uuid: course.uuid,
    gitId: course.git_id,
  }))
}

const CoursesAndSpacesIncludedRule = ({
  rule,
  onSetRule,
  onSaveRule,
  disabled,
  loading,
  context,
  golfClubID,
  voucherDisabled,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const { t } = useTranslation()
  const { values } = useContext(
    context === 'promotion'
      ? PromotionsContext
      : context === 'reservationPolicy'
      ? PoliciesContext
      : {}
  )
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  const clubId = useMemo(() => {
    return golfClubID || values.clubId
  }, [golfClubID, values.clubId])

  const config = useMemo(() => {
    return rule.configuration.venues || {}
  }, [rule])

  const {
    data: courses,
    fetchNextPage: loadMoreCourses,
    isFetching: isFetchingCourses,
    isFetchingNextPage: isFetchingNextPageOfCourses,
    status: coursesStatus,
  } = useInfiniteQuery(
    [CLUB_QUERIES.COURSES, { clubId, page: 1, limit: 50, 'order[name]': 'asc' }],
    ({ pageParam = 1 }) =>
      queryClubCourses(clubId, {
        page: pageParam,
        limit: 50,
        'order[name]': 'asc',
      }),
    {
      enabled: !!clubId,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length < 50) return undefined
        return pages?.length + 1
      },
      select: (data) => {
        return formatOptions(data.pages.flat())
      },
    }
  )

  const { data: spaces, isFetching: isFetchingSpaces } = useQuery(
    [CLUB_QUERIES.SPACES, { club: clubId, page: 1, limit: 200, 'order[name]': 'asc' }],
    ({ pageParam = 1 }) =>
      getSpaces({
        club: clubId,
        page: pageParam,
        limit: 200,
        'order[name]': 'asc',
      }),
    {
      enabled: !!clubId,
    }
  )

  const searchCourses = async (value) => {
    clearTimeout(searchDebounce)

    return new Promise((resolve) => {
      searchDebounce = setTimeout(async () => {
        const [res] = await to(
          queryCourses({
            'order[name]': 'asc',
            offset: 0,
            limit: 200,
            'name.search': value,
            'club.id': clubId,
          })
        )

        if (!isMounted.current) return

        if (res) {
          resolve(formatOptions(res))
        } else {
          resolve([])
        }
      }, 500)
    })
  }

  const updateSelectedCourses = (newSelected) => {
    const newCourseUuids = newSelected.map((x) => x.uuid)

    const updatedRule = produce(rule, (draft) => {
      if (!Object.keys(config)?.length) draft.configuration.venues = {}

      const prevCourseUuids = Object.keys(draft.configuration.venues)
      prevCourseUuids.forEach((x) => {
        if (!newCourseUuids.includes(x)) {
          delete draft.configuration.venues[x]
        }
      })

      newSelected.forEach((x) => {
        if (!prevCourseUuids.includes(x.uuid)) {
          draft.configuration.venues[x.uuid] = { spaces: [] }
        }
      })
    })

    setShowDropdown(false)
    onSetRule(updatedRule)
    onSaveRule(updatedRule)
  }

  const updateSelectedSpaces = (uuid, newSelected) => {
    const updatedRule = produce(rule, (draft) => {
      draft.configuration.venues[uuid] = { spaces: newSelected.map((x) => x?.uuid) }
    })

    onSetRule(updatedRule)
    onSaveRule(updatedRule)
  }

  const deselectCourse = (id) => {
    const updatedRule = produce(rule, (draft) => {
      delete draft.configuration.venues[id]
    })

    onSetRule(updatedRule)
    onSaveRule(updatedRule)
  }

  return (
    <div className={cx(styles.container)}>
      {courses &&
        courses
          .filter((course) => Object.keys(config).includes(course.uuid))
          .map((x) => (
            <div className={styles.spacesContainer} key={x.id}>
              <NestedRowWrapper
                voucherDisabled={voucherDisabled}
                onRemove={() => deselectCourse(x.uuid)}
                title={x.name}
              >
                <MultiSelect
                  noSelectedLabel={
                    isFetchingSpaces
                      ? t('sentences.loadingSpaces')
                      : t('sentences.noSpacesSelected')
                  }
                  width="full"
                  buttonLabel={t('words.spaces')}
                  options={
                    spaces
                      ? spaces
                          .filter((s) => s?.course?.id && s.course.id === x.id)
                          .map((s) => ({ ...s, label: s?.name }))
                      : []
                  }
                  selectedOptions={
                    spaces
                      ? config[x.uuid]?.spaces
                          .map((s) => spaces?.find((x) => x.uuid === s))
                          .map((y) => ({ ...y, label: y?.name })) || []
                      : []
                  }
                  handleChangeSelected={(s) => updateSelectedSpaces(x.uuid, s)}
                  dropdownProps={{
                    loading:
                      coursesStatus === 'loading' ||
                      isFetchingCourses ||
                      isFetchingNextPageOfCourses,
                    loadMorePages: () => loadMoreCourses(),
                  }}
                  disabled={disabled}
                  loading={loading || isFetchingCourses}
                  readOnly={voucherDisabled}
                  voucherDisabled={voucherDisabled}
                />
              </NestedRowWrapper>
            </div>
          ))}
      {!voucherDisabled && (
        <ButtonAndDropdownSelectWrapper
          id="course-checker-dropdown"
          buttonProps={{
            className: cx(styles.addButton),
            icon: 'plus',
            onClick: () => setShowDropdown(true),
            text: t('words.courses'),
            disabled: disabled,
            loading:
              coursesStatus === 'loading' || isFetchingCourses || isFetchingNextPageOfCourses,
          }}
          dropdownProps={{
            isVisible: showDropdown,
            options: courses || [],
            preselectedOptions: courses
              ? courses.filter((course) => Object.keys(config).includes(course.uuid))
              : [],
            searchEnabled: true,
            onSave: updateSelectedCourses,
            onCancel: () => setShowDropdown(false),
            searchPlaceholder: t('sentences.searchCourse'),
            loading:
              coursesStatus === 'loading' || isFetchingCourses || isFetchingNextPageOfCourses,
            loadMorePages: () => loadMoreCourses(),
            onSearch: searchCourses,
          }}
        />
      )}
    </div>
  )
}

CoursesAndSpacesIncludedRule.propTypes = {
  rule: PropTypes.object,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  context: PropTypes.oneOf(['promotion', 'reservationPolicy']),
}

CoursesAndSpacesIncludedRule.defaultProps = {
  loading: false,
}

export default CoursesAndSpacesIncludedRule
