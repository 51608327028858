import { useTranslation } from 'react-i18next'
import style from '../../style.module.scss'
import NumberInput from '@sweetspot/sweetspot-js/common/components/NumberInput'

const IntervalField = ({ errors, triedToSubmit, formState, setValue }) => {
  const { t } = useTranslation()

  return (
    <div className={triedToSubmit && errors.interval ? style.errored : ''}>
      <span className={style.optionsText}> {t('editPeriodsNew.interval')}</span>
      <div className={style.numberInput}>
        <NumberInput
          value={formState.interval}
          onChange={(event) => setValue('interval', +event.target.value)}
          className={`ss-input ${style.inputOptions}`}
          min={5}
          max={60}
        />
      </div>
    </div>
  )
}

export default IntervalField
