import AES from 'crypto-js/aes'
import ENC from 'crypto-js/enc-utf8'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@sweetspot/shared/util/local-storage'

import { getSessionStorage, removeSessionStorage } from '@sweetspot/shared/util/session-storage'
import { getBaseUrlPlatform } from '../functions/request'
import { to } from '../functions/utils'
import { SALTS, STORAGE_KEYS } from '@sweetspot/shared/util/constants'

export const REFRESH_REQUEST_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_IN_PROGRESS: 'NOT_IN_PROGRESS',
  FAILED: 'FAILED',
}

/**
 * In memory vars
 */
let authToken = null
let refreshToken = null
let REFRESH_REQUEST_STATUS = REFRESH_REQUEST_STATUSES.NOT_IN_PROGRESS

/**
 * Sets token to whatever passed value;
 *
 * @param {string} token
 */
export const setAuthToken = (token) => {
  if (token && token.includes('"')) {
    // Removes boundary double quotes
    token = token.replace(/^"|"$/g, '')
  }
  authToken = token
  const cipherToken = AES.encrypt(token, SALTS.TOKEN_SALT).toString()
  setLocalStorage(STORAGE_KEYS.TOKEN_KEY, cipherToken)
}

/**
 * Sets token to whatever passed value;
 *
 * @param {string} token
 */
export const setRefreshToken = (token) => {
  if (token && token.includes('"')) {
    // Removes boundary double quotes
    token = token.replace(/^"|"$/g, '')
  }
  refreshToken = token
  const ciptherRefreshToken = AES.encrypt(token, SALTS.REFRESH_TOKEN_SALT).toString()
  setLocalStorage(STORAGE_KEYS.REFRESH_TOKEN_KEY, ciptherRefreshToken)
}

/**
 * Returns auth token
 *
 * @returns
 */
export const getAuthToken = () => {
  if (!authToken) {
    const authCipher =
      getLocalStorage(STORAGE_KEYS.TOKEN_KEY) || getSessionStorage(STORAGE_KEYS.TOKEN_KEY) || null

    if (authCipher) {
      try {
        authToken = AES.decrypt(authCipher, SALTS.TOKEN_SALT).toString(ENC)
      } catch (error) {
        authToken = null
      }
    }
  }
  return authToken
}

/**
 * Returns refresh token
 *
 * @returns
 */
export const getRefreshToken = () => {
  if (!refreshToken) {
    const refreshCipher =
      getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN_KEY) ||
      getSessionStorage(STORAGE_KEYS.REFRESH_TOKEN_KEY) ||
      null

    if (refreshCipher) {
      try {
        refreshToken = AES.decrypt(refreshCipher, SALTS.REFRESH_TOKEN_SALT).toString(ENC)
      } catch (error) {
        refreshToken = null
      }
    }
  }
  return refreshToken
}

/**
 * Clears auth token from local and memory
 */
export const clearAuthToken = () => {
  authToken = null
  removeLocalStorage(STORAGE_KEYS.TOKEN_KEY)
  removeSessionStorage(STORAGE_KEYS.TOKEN_KEY)
}

/**
 * Clears refresh token from local and memory
 */
export const clearRefreshToken = () => {
  refreshToken = null
  removeLocalStorage(STORAGE_KEYS.REFRESH_TOKEN_KEY)
  removeSessionStorage(STORAGE_KEYS.REFRESH_TOKEN_KEY)
}

/**
 * Sets refresh request status
 *
 * @param {String} value
 */
export const setRefreshRequestStatus = (value) => {
  REFRESH_REQUEST_STATUS = value
}

/**
 * Gets refresh request status
 *
 * @returns
 */
export const getRefreshRequestStatus = () => {
  return REFRESH_REQUEST_STATUS
}

/**
 * Refresh tokens
 */
export const getNewTokens = async () => {
  setRefreshRequestStatus(REFRESH_REQUEST_STATUSES.IN_PROGRESS)

  const tempRefreshToken = getRefreshToken()
  const tempAuthToken = getAuthToken()
  clearAuthToken()
  clearRefreshToken()

  const [res, err] = await to(
    fetch(`${getBaseUrlPlatform()}/auth/token/refresh`, {
      body: JSON.stringify({
        refresh_token: tempRefreshToken,
      }),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tempAuthToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Accept-Language': 'en',
      },
    })
  )

  try {
    if (err) throw err
    if (!res) throw res
    if (!res.ok) throw res

    const [data, dataErr] = await to(res.json())
    if (dataErr) throw dataErr
    if (!data) throw data

    setAuthToken(data.token)
    setRefreshToken(data.refresh_token)
    setRefreshRequestStatus(REFRESH_REQUEST_STATUSES.NOT_IN_PROGRESS)
  } catch (error) {
    setRefreshRequestStatus(REFRESH_REQUEST_STATUSES.FAILED)
    throw error
  }

  return
}
