import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryClubCourses } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { useSelector } from 'react-redux'

import ValueText from '../../ValueText'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import DarkBlueSubtitle from '../../DarkBlueSubtitle'
import InnerBoxWrapper from '../../InnerBoxWrapper'

const CoursesAndSpaces = ({ rule }) => {
  const { t } = useTranslation()
  const clubId = useSelector((state) => state?.golfClub?.selectedId)

  const config = rule?.configuration?.venues

  const { data: courses, isFetching: isFetchingCourses } = useQuery(
    [CLUB_QUERIES.COURSES, { club: clubId, page: 1, limit: 50, 'order[name]': 'asc' }],
    ({ pageParam = 1 }) =>
      queryClubCourses(clubId, {
        page: pageParam,
        limit: 50,
        'order[name]': 'asc',
      }),
    {
      enabled: !!clubId && !!config,
    }
  )

  const { data: spaces, isFetching: isFetchingSpaces } = useQuery(
    [CLUB_QUERIES.SPACES, { club: clubId, page: 1, limit: 50, 'order[name]': 'asc' }],
    ({ pageParam = 1 }) =>
      getSpaces(clubId, {
        page: pageParam,
        limit: 50,
        'order[name]': 'asc',
      }),
    {
      enabled: !!clubId && !!config,
    }
  )

  const getCourseName = (uuid) => {
    return courses.find((x) => x.uuid === uuid)?.name || ''
  }
  const getSpaceName = (uuid) => {
    return spaces.find((x) => x.uuid === uuid)?.name || ''
  }

  return (
    <div className={cx(styles.container)}>
      <DarkBlueSubtitle>{t('sentences.coursesAndSpaces')}</DarkBlueSubtitle>
      {config ? (
        <React.Fragment>
          {!isFetchingCourses && !isFetchingSpaces ? (
            Object.keys(config).map((courseUuid) => (
              <InnerBoxWrapper key={courseUuid} className={cx(styles.row)}>
                <ValueText>{getCourseName(courseUuid)}</ValueText>
                <div className={cx(styles.spacesWrapper)}>
                  {config[courseUuid]?.spaces.map((spaceUuid) => (
                    <ValueText
                      key={spaceUuid}
                      className={cx(styles.robotoFont)}
                      fontWeight="medium"
                      fontSize="13"
                    >
                      {getSpaceName(spaceUuid)}
                    </ValueText>
                  ))}
                </div>
              </InnerBoxWrapper>
            ))
          ) : (
            <PulseLoader showIf={true} />
          )}
        </React.Fragment>
      ) : (
        <ValueText>{t('sentences.noCoursesOrSpacesAllowed')}</ValueText>
      )}
    </div>
  )
}

CoursesAndSpaces.propTypes = {
  rule: PropTypes.object,
}

CoursesAndSpaces.defaultProps = {}

export default CoursesAndSpaces
