import * as React from 'react'
import { useStore } from 'zustand'

import { UniversalProps } from '../types'
import { Hint, HintProps } from '../../atoms'
import { InputBaseContext, createInputBaseStore, useInputBaseStoreContext } from './store'
import { cn } from '../../../utils'

interface InputBaseProps extends UniversalProps {
  error?: boolean
  disabled?: boolean
  required?: boolean
}

const InputBase = React.forwardRef<HTMLDivElement, InputBaseProps>(
  ({ className, error, disabled, required, children, ...props }, ref) => {
    const [store, setStore] = React.useState(createInputBaseStore({ error, disabled, required }))

    React.useEffect(() => {
      setStore(createInputBaseStore({ error, disabled, required }))
    }, [error, disabled, required])

    return (
      <InputBaseContext.Provider value={store}>
        <div className={cn('flex w-full flex-col items-start', className)} {...props} ref={ref}>
          {children}
        </div>
      </InputBaseContext.Provider>
    )
  }
)

InputBase.displayName = 'InputBase'

export interface InputContainerProps extends UniversalProps {
  disabled?: boolean
}

const InputContainer = React.forwardRef<HTMLDivElement, InputContainerProps>(
  ({ className, disabled: propDisabled, ...props }, ref) => {
    const store = useInputBaseStoreContext()
    const { disabled: stateDisabled } = useStore(store, (inputBaseState) => inputBaseState)
    const disabled = propDisabled !== undefined ? propDisabled : stateDisabled

    return (
      <div
        className={cn('relative w-full', { 'opacity-30': disabled }, className)}
        {...props}
        ref={ref}
        data-testid="input-container"
      />
    )
  }
)

InputContainer.displayName = 'InputContainer'

const InputLeadingContainer = React.forwardRef<HTMLDivElement, UniversalProps>(
  ({ className, ...props }, ref) => (
    <div
      className={cn('absolute left-4 top-1/2 -translate-y-1/2 transform', className)}
      {...props}
      ref={ref}
      data-testid="input-leading-container"
    />
  )
)

InputLeadingContainer.displayName = 'InputLeadingContainer'

const InputHint = React.forwardRef<HTMLDivElement, HintProps>(({ variant, ...props }, ref) => {
  const store = useInputBaseStoreContext()
  const { error, disabled } = useStore(store, (inputBaseState) => inputBaseState)

  const getVariant = () => {
    if (variant) return variant
    if (error) return 'error'
    if (disabled) return 'disabled'
    return 'default'
  }

  return <Hint variant={getVariant()} ref={ref} {...props} />
})

InputHint.displayName = 'InputHint'

export interface InputLabelContainerProps extends UniversalProps {
  disabled?: boolean
}

const InputLabelContainer = React.forwardRef<HTMLDivElement, InputLabelContainerProps>(
  ({ className, disabled: propDisabled, children, ...props }, ref) => {
    const store = useInputBaseStoreContext()
    const { disabled: stateDisabled, required } = useStore(
      store,
      (inputBaseState) => inputBaseState
    )
    const disabled = propDisabled !== undefined ? propDisabled : stateDisabled

    return (
      <div
        className={cn(
          'text-content-sm flex min-h-5 items-end self-stretch text-ellipsis px-2 font-bold',
          { 'text-text-pale': disabled },
          className
        )}
        {...props}
        ref={ref}
      >
        {children}
        {required && <span className="text-text-danger ml-1">*</span>}
      </div>
    )
  }
)

InputLabelContainer.displayName = 'InputLabelContainer'

const InputTrailingContainer = React.forwardRef<HTMLDivElement, UniversalProps>(
  ({ className, ...props }, ref) => (
    <div
      className={cn('absolute right-4 top-1/2 -translate-y-1/2 transform', className)}
      {...props}
      ref={ref}
      data-testid="input-trailing-container"
    />
  )
)

InputTrailingContainer.displayName = 'InputTrailingContainer'

export {
  InputLeadingContainer,
  InputContainer,
  InputHint,
  InputBase,
  InputLabelContainer,
  InputTrailingContainer,
}
