import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Set QT api key for integration
 */
export const setQTApiKey = (
  id,
  payload = {
    api_key: '',
  }
) => {
  const url = `${getBaseUrlPlatform()}/courses/${id}/integrations/qt/key`
  return request(url, {
    method: 'put',
    body: payload,
  })
}

/**
 * Get course configured QT api key
 */
export const getQTApiConfig = (id) => {
  const url = `${getBaseUrlPlatform()}/courses/${id}/integrations/qt`
  return request(url)
}

/**
 * Get course integrated QT version
 */
export const getQTVersion = (id) => {
  const url = `${getBaseUrlPlatform()}/courses/${id}/integrations/qt/version`
  return request(url)
}

/**
 * Get course QT objects
 */
export const getQTObjects = (id) => {
  const url = `${getBaseUrlPlatform()}/courses/${id}/integrations/qt/objects`
  return request(url)
}

/**
 * Set the QT config for the course (whole config object/payload)
 */
export const setQTConfig = (id, data) => {
  const url = `${getBaseUrlPlatform()}/courses/${id}/integrations/qt/config`
  return request(url, {
    method: 'put',
    body: data,
  })
}
