import React, { useState, useEffect, useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'

import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'
import { ReactComponent as EditPen } from '@sweetspot/sweetspot-js/assets/svgs/edit-pen.svg'
import { ReactComponent as DangerRed } from '@sweetspot/sweetspot-js/assets/svgs/danger-red.svg'

import { useTranslation } from 'react-i18next'
import useStatusArray from '@sweetspot/sweetspot-js/common/hooks/useStatusArray'
import { useToasts } from 'react-toast-notifications'

import { priceToLocal, to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  getAdjustmentLabel,
  isBookingItemPaid,
  isBookingPaid,
  isBookingPartiallyRefunded,
  isBookingItemRefunded,
  isBookingPaidByRefundableMethod,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'

import {
  deleteAdjustmentFromOrderItem,
  setItemPrice,
  addRefundToItem,
  removeCouponFromOrder,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

import styles from './styles.module.scss'
import useIsClubPortal from '@sweetspot/sweetspot-js/common/hooks/useIsClubPortal'
import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'

const BookingItemsTable = ({
  booking,
  item,
  className,
  onRequestBookingUpdate,
  onRequestOpenPaymentFold,
  canEdit,
  focusEndPrice,
  refunds,
  refundsLoading,
  isCancelled,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [showRefundInput, setShowRefundInput] = useState(false)
  const [refundInputValue, setRefundInputValue] = useState('')
  const [refundInputError, setRefundInputError] = useState('')
  const [showConfirmRefund, setShowConfirmRefund] = useState(false)

  const [editStartPrice, setEditStartPrice] = useState(false)
  const [editStartPriceValue, setEditStartPriceValue] = useState('')
  const [editStartPriceError, setEditStartPriceError] = useState(null)

  const [editEndPrice, setEditEndPrice] = useState(false)
  const [editEndPriceValue, setEditEndPriceValue] = useState('')
  const [editEndPriceError, setEditEndPriceError] = useState(null)
  const prevFocusEndPrice = usePrevious(focusEndPrice)

  const isClubPortal = useIsClubPortal()

  const [addingAdjustment, setAddingAdjustment] = useState(false)
  const [savingPriceAdjustment, setSavingPriceAdjustment] = useState(false)

  const roles = useRoles()
  const accessTable = useMemo(() => {
    const role = getFirstMatchingRole(roles)
    if (!role)
      return {
        PRICE_ADJUSTMENT: false,
      }
    return {
      PRICE_ADJUSTMENT: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT, role?.value),
    }
  }, [roles])

  const hasPriceAdjustment = useMemo(
    () => !!item.adjustments.find((x) => x?.type === 'order_item_new_price_adjustment'),
    [item]
  )

  const itemRefunds = useMemo(() => {
    if (!refundsLoading && refunds?.length > 0) {
      return refunds.filter((refund) => refund?.order_item?.id === item.id)
    }
    return null
  }, [refunds, refundsLoading])

  const {
    card: isPaidByCard,
    googlePay: isPaidByGooglePay,
    applePay: isPaidByApplePay,
  } = useMemo(() => isBookingPaidByRefundableMethod(booking), [booking])

  const {
    add: addRemovingAdjustments,
    array: removingAdjustments,
    remove: removeRemovingAdjustments,
  } = useStatusArray()

  useEffect(() => {
    if ((prevFocusEndPrice === false || !prevFocusEndPrice) && focusEndPrice === true) {
      setEditEndPrice(true)
      setEditEndPriceValue(item.total / 100)
      setEditEndPriceError('')
    }
  }, [focusEndPrice, prevFocusEndPrice, item])

  const toLocal = (price) => priceToLocal(price, booking?.currency_code, true)

  const itemTotal = useMemo(() => {
    let total = item.total
    if (itemRefunds) {
      itemRefunds.forEach((refund) => {
        total = total - refund.amount
      })
    }
    return total
  }, [item.total, itemRefunds])

  const isGreenFeeItem = () => {
    return item?.slot ? true : false
  }

  const handleSaveStartPrice = async (value) => {
    if (value?.includes(',')) {
      value = value.split(',').join('.')
    }
    value = parseFloat(value)

    if (value < 0) {
      setEditStartPriceError(t('errors.valueMustBeThisOrHigher', { this: 0 }))
      return
    }

    setEditStartPrice(false)
    setSavingPriceAdjustment(true)
    const { id } = item

    const [res, err] = await to(setItemPrice(id, Math.round(value * 100)))

    if (err) {
      addToast(t('sentences.couldNotSavePriceAdjustment'), { appearance: 'error' })
      setSavingPriceAdjustment(false)
      return
    }

    if (res) {
      onRequestBookingUpdate(booking.uuid).then(() => {
        setSavingPriceAdjustment(false)
      })
    }
  }

  const handleSaveEndPrice = async (value) => {
    const { id, adjustments } = item

    if (value?.includes(',')) {
      value = value.split(',').join('.')
    }
    value = parseFloat(value)

    if (value < 0) {
      setEditEndPriceError(t('errors.valueMustBeThisOrHigher', { this: 0 }))
      return
    }

    let percentages = 1
    let discounts = 0
    let endPrice = value
    /**
     * x(start price) * discount(percentage) * discount(percentage) - discount(fixed amount) - discount(fixed amount) = value(wanted end-price);
     * Find x:
     * 1. Multiply all the percentage discounts
     * 2. Summarise all the fixed amount discounts
     * 3. Add fixed amount to both sides
     * 4. Divide both sides by multiplied percentages
     */

    adjustments.forEach((x) => {
      if (x?.type === 'order_item_new_price_adjustment') {
        return
      } else if (x?.details?.configuration?.percentage_coefficient >= 0) {
        percentages = percentages * (1 - x.details.configuration.percentage_coefficient)
      } else if (x?.amount >= 0) {
        discounts = discounts + x.amount
      }
    })

    setEditEndPrice(false)
    setSavingPriceAdjustment(true)

    const startPrice = percentages <= 0 ? value : (endPrice + discounts) / percentages

    const [res, err] = await to(setItemPrice(id, Math.round(startPrice * 100)))

    if (err) {
      addToast(t('sentences.couldNotSavePriceAdjustment'), { appearance: 'error' })
      setSavingPriceAdjustment(false)
      return
    }

    if (res) {
      onRequestBookingUpdate(booking.uuid).then(() => {
        setSavingPriceAdjustment(false)
      })
    }
  }

  const handleRemoveAdjustment = async (adjustment) => {
    addRemovingAdjustments(adjustment.id)

    let res, err

    let coupon = item.promotion_coupons.find((x) => adjustment?.details?.code === x.code)

    if (coupon) {
      ;[res, err] = await to(removeCouponFromOrder(booking.uuid, coupon.code))
    } else {
      ;[res, err] = await to(deleteAdjustmentFromOrderItem(item.id, adjustment.id))
    }

    if (res) {
      onRequestBookingUpdate(booking.uuid).then(() => {
        removeRemovingAdjustments(adjustment.id)
      })
    }
    if (!res || err) {
      addToast(t('sentences.couldNotDeleteDiscount'), { appearance: 'error' })
    }
  }

  const getDiscountPromotion = (adjustment) => {
    const { promotions } = booking
    const { origin_code } = adjustment

    let promotion = promotions?.find((el) => el.code === origin_code)
    return promotion || null
  }

  const validateRefundValue = async (val) => {
    if (val <= 0) {
      setRefundInputError(t('errors.valueMustBeHigherThanThis', { this: 0 }))
      return
    }

    const valInCents = val * 100

    if (valInCents > item.total) {
      setRefundInputError(t('errors.valueMustBeThisOrLower', { this: item.total / 100 }))
      return
    }

    setRefundInputError('')
    setRefundInputValue('')
    setShowRefundInput(false)
    setShowConfirmRefund(valInCents)
  }

  const onRefundConfirmed = async (amount) => {
    setAddingAdjustment(true)
    setShowConfirmRefund(false)

    const [res, err] = await to(addRefundToItem(booking.uuid, item.uuid, amount))

    if (err || !res) {
      addToast(t('sentences.failedToAddRefund'), { appearance: 'error' })
    }

    onRequestBookingUpdate(booking.uuid).then(() => {
      onRequestOpenPaymentFold()
      setAddingAdjustment(false)
      addToast(t('sentences.refundAdded'), { appearance: 'success' })
    })
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.row)}>
        <div className={cx(styles.colOne)}>
          {isGreenFeeItem() && <p className={cx(styles.rowName)}>{t('words.greenFee')}</p>}
        </div>
        <div className={cx(styles.colTwo)}>
          {isClubPortal && accessTable.PRICE_ADJUSTMENT && canEdit && !isBookingItemPaid(item) && (
            <EditPen
              onClick={() => {
                setEditStartPrice((prev) => !prev)
                setEditStartPriceValue(item.unit_price / 100)
                setEditStartPriceError('')
              }}
              className={cx(styles.editPen)}
            />
          )}
          {editStartPrice ? (
            <TextInputField
              containerClassName={styles.editPriceInput}
              value={editStartPriceValue}
              error={editStartPriceError}
              feedbackAnchorPosition="right"
              onChange={(val) => setEditStartPriceValue(val)}
              onInputBlur={() => setEditStartPrice(false)}
              onEnter={(val) => handleSaveStartPrice(val)}
              inputProps={{
                autoFocus: true,
              }}
            />
          ) : (
            <p className={cx(styles.rowPrice)}>{toLocal(item.unit_price)}</p>
          )}
        </div>
        <div className={cx(styles.colThree)}></div>
      </div>

      {savingPriceAdjustment && !hasPriceAdjustment && (
        <div className={cx(styles.row)}>
          <div className={cx(styles.colOne)}>
            <Skeleton width="100%" height="22px" />
          </div>
          <div className={cx(styles.colThree)}></div>
        </div>
      )}

      {item.adjustments.map((adjustment) => {
        const label = getAdjustmentLabel(adjustment)
        const promotion = getDiscountPromotion(adjustment)
        const type = adjustment?.type

        if (
          removingAdjustments.includes(adjustment.id) ||
          (type === 'order_item_new_price_adjustment' && savingPriceAdjustment)
        ) {
          return (
            <div key={adjustment.id} className={cx(styles.row, styles.loadingRow)}>
              <div className={cx(styles.colOne)}>
                <Skeleton width="100%" height="22px" />
              </div>
              <div className={cx(styles.colThree)}></div>
            </div>
          )
        }

        const renderRow = () => (
          <div className={cx(styles.row)} key={adjustment.id}>
            <div className={cx(styles.colOne)}>
              <p className={cx(styles.rowName)}>{label}</p>
            </div>
            <div className={cx(styles.colTwo)}>
              <p className={cx(styles.rowPrice)}>{toLocal(adjustment.amount)}</p>
            </div>
            <div className={cx(styles.colThree)}>
              {promotion?.is_partnership_based && canEdit ? (
                <RemoveIcon
                  className={cx(styles.removeIcon)}
                  onClick={() => handleRemoveAdjustment(adjustment)}
                />
              ) : null}
            </div>
          </div>
        )

        if (type === 'order_item_new_price_adjustment') {
          return (
            <React.Fragment key={adjustment.id}>
              {renderRow()}
              <div className={cx(styles.row)}>
                <div className={cx(styles.colOne)}>
                  <p className={cx(styles.rowName, styles.bold)}>{t('sentences.newPrice')}</p>
                </div>
                <div className={cx(styles.colTwo)}>
                  <p className={cx(styles.rowPrice)}>
                    {toLocal(item.unit_price + adjustment.amount)}
                  </p>
                </div>
                <div className={cx(styles.colThree)}></div>
              </div>
            </React.Fragment>
          )
        }
        return renderRow()
      })}

      {itemRefunds &&
        itemRefunds.map((refund) => (
          <div key={refund.id} className={cx(styles.row)}>
            <div className={cx(styles.colOne)}>
              <p className={cx(styles.rowName)}>{t('words.refund')}</p>
            </div>
            <div className={cx(styles.colTwo)}>
              <p className={cx(styles.rowPrice)}>{toLocal(-1 * refund.amount)}</p>
            </div>
            <div className={cx(styles.colThree)}></div>
          </div>
        ))}

      {(addingAdjustment || refundsLoading) && (
        <div className={cx(styles.row)}>
          <div className={cx(styles.colOne)}>
            <Skeleton width="100%" height="22px" />
          </div>
          <div className={cx(styles.colThree)}></div>
        </div>
      )}

      {showRefundInput && (
        <div className={cx(styles.row)}>
          <div className={cx(styles.colOne)}>
            <p className={cx(styles.rowName)}>{t('words.refund')}</p>
          </div>
          <div className={cx(styles.colTwo)}>
            <TextInputField
              inputProps={{
                autoFocus: true,
              }}
              feedbackAnchorPosition="right"
              onEnter={(val) => validateRefundValue(val)}
              onChange={(val) => {
                setRefundInputError('')
                setRefundInputValue(val)
              }}
              onInputBlur={() => {
                setRefundInputError('')
                setRefundInputValue('')
                setShowRefundInput(false)
              }}
              containerClassName={cx(styles.editPriceInput)}
              value={refundInputValue}
              error={refundInputError}
            />
          </div>
          <div className={cx(styles.colThree)}></div>
        </div>
      )}

      <div className={cx(styles.row)}>
        <div className={cx(styles.colOne)}>
          <hr className={cx(styles.separator)} />
        </div>
        <div className={cx(styles.colThree)}></div>
      </div>

      <div className={cx(styles.row)}>
        <div className={cx(styles.colOne)}>
          {(isPaidByCard || isPaidByGooglePay || isPaidByApplePay) &&
            (isBookingPaid(booking) || isBookingPartiallyRefunded(booking)) &&
            (!isBookingItemRefunded(item) || isBookingPartiallyRefunded(booking)) &&
            !isCancelled &&
            itemTotal > 0 && (
              <Button
                width="auto"
                size="default"
                theme="gray"
                text={t('words.refund_verb')}
                onClick={() => setShowRefundInput(true)}
              />
            )}
        </div>
        <div className={cx(styles.colTwo)}>
          {isClubPortal && accessTable.PRICE_ADJUSTMENT && canEdit && !isBookingItemPaid(item) && (
            <EditPen
              onClick={() => {
                setEditEndPrice((prev) => !prev)
                setEditEndPriceValue(item.total / 100)
                setEditEndPriceError('')
              }}
              className={cx(styles.editPen)}
            />
          )}
          {editEndPrice ? (
            <TextInputField
              containerClassName={styles.editPriceInput}
              value={editEndPriceValue}
              error={editEndPriceError}
              onChange={(val) => setEditEndPriceValue(val)}
              onInputBlur={() => setEditEndPrice(false)}
              feedbackAnchorPosition="right"
              onEnter={(val) => handleSaveEndPrice(val)}
              inputProps={{
                autoFocus: true,
              }}
            />
          ) : (
            <p className={cx(styles.rowPrice, styles.bold)}>{toLocal(itemTotal)}</p>
          )}
        </div>
        <div className={cx(styles.colThree)}></div>
      </div>

      <ConfirmPopup
        visible={!!showConfirmRefund}
        title={t('sentences.confirmRefund')}
        titleIcon={DangerRed}
        overrideIconColor={false}
        onClose={() => {
          setShowConfirmRefund(false)
        }}
        onReject={() => {
          setShowConfirmRefund(false)
        }}
        rejectText={t('words.cancel')}
        onAccept={() => onRefundConfirmed(showConfirmRefund)}
        acceptText={t('words.confirm')}
        acceptTheme="default"
      >
        <p>
          {t('sentences.confirmRefundOf')} <strong>{toLocal(showConfirmRefund)}</strong>
        </p>
      </ConfirmPopup>
    </div>
  )
}

BookingItemsTable.propTypes = {
  isCancelled: PropTypes.bool,
  refundsLoading: PropTypes.bool,
  refunds: PropTypes.array,
  items: PropTypes.array,
  item: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    adjustments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        label: PropTypes.string,
        amount: PropTypes.number,
        netural: PropTypes.bool,
        locked: PropTypes.bool,
        origin_code: PropTypes.string,
        id: PropTypes.number,
        uuid: PropTypes.string,
      })
    ),
    promotion_coupons: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
      })
    ),
    unit_price: PropTypes.number,
    total: PropTypes.number,
    units_total: PropTypes.number,
    slot: PropTypes.shape({
      player: PropTypes.shape({
        id: PropTypes.number,
        uuid: PropTypes.string,
      }),
    }),
  }),
  booking: PropTypes.shape({
    items: PropTypes.array,
    promotions: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
      })
    ),
    course: PropTypes.shape({
      id: PropTypes.number,
    }),
    uuid: PropTypes.string,
  }),
  onRequestBookingUpdate: PropTypes.func,
  onRequestOpenPaymentFold: PropTypes.func,
  className: PropTypes.string,
  partnership: PropTypes.object,
  canEdit: PropTypes.bool,
  focusEndPrice: PropTypes.bool,
}

BookingItemsTable.defaultProps = {
  canEdit: true,
  isCancelled: false,
}

export default BookingItemsTable
