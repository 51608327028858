import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Query clubs
 *
 */
export const queryClubs = (
  query = {
    'order[name]': null,
    'admins.id': null,
    'gitId[]': null,
    gitId: null,
    slug: null,
    uuid: null,
    'uuid[]': null,
    search: null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/clubs`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Query club courses
 *
 * @param {string|number} clubId
 */
export const queryClubCourses = (
  clubId,
  query = {
    'order[teeTime.id]': null,
    'order[id]': null,
    'order[lonlat][lat]': null,
    'order[lonlat][lon]': null,
    'order[lonlat][unit]': null,
    'order[lonlat][order]': null,
    'order[name]': null,
    'lonlat[lat]': null,
    'lonlat[lon]': null,
    'lonlat[distance]': null,
    'teeTimes.from[gt]': null,
    'teeTimes.from[gte]': null,
    'teeTimes.from[lt]': null,
    'teeTimes.from[lte]': null,
    'teeTimes.availableSlots[gt]': null,
    'teeTimes.availableSlots[gte]': null,
    'teeTimes.availableSlots[lt]': null,
    'teeTimes.availableSlots[lte]': null,
    is_git_id_required: null,
    'calculateDistance[lat]': null,
    'calculateDistance[lon]': null,
    'name.search': null,
    'club.id': null,
    type: 'course',
    'type[]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/clubs/${clubId}/courses`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get single club
 *
 * @param {number} id
 * @returns
 */
export const getSingleClub = (id) => {
  const url = `${getBaseUrlPlatform()}/clubs/${id}`
  return request(url)
}

/**
 * Update single club
 *
 * @param {number} id
 * @param {Object} payload
 * @returns
 */
export const updateSingleClub = (id, payload) => {
  const url = `${getBaseUrlPlatform()}/clubs/${id}`
  return request(url, { method: 'PUT', body: payload })
}

/**
 * Request to fetch golf club from slug
 *
 * @param {string} slug
 * @returns {promise}
 */
export const fetchGolfClubFromSlug = (slug) => {
  const url = `${getBaseUrlPlatform()}/clubs?slug=${slug}`
  return request(url, {
    method: 'GET',
  })
}

/**
 * Get golf club by id
 *
 * @param {string|number} id
 */
export const getGolfClubById = (id) => {
  const url = `${getBaseUrlPlatform()}/clubs/${id}`
  return request(url)
}

export const _uploadMedia = (token, media) => {
  const URL = `${getBaseUrlPlatform()}/media-objects`

  return request(URL, {
    method: 'POST',
    contentType: 'multipart/form-data',
    body: media,
  })
}

export function _updateUrlSlug(token, clubId, payload) {
  const URL = `${getBaseUrlPlatform()}/clubs/${clubId}/slug`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': payload.lang === 'se' ? 'sv' : payload.lang,
    },
    body: JSON.stringify(payload),
  })
}

/**
 *
 * @param {number|string} clubId
 * @returns {promise}
 */
export const getClubWebBookingSetting = (clubId) => {
  const url = `${getBaseUrlPlatform()}/clubs/${clubId}/web-booking-settings`
  return request(url, {
    method: 'GET',
  })
}

/**
 * Sync tees for golf club from GIT
 *
 * @param {string|number} id
 */
export const syncTeesFromGit = (id) => {
  const url = `${getBaseUrlPlatform()}/clubs/${id}/sync/course-tees`
  return request(url, {
    method: 'PUT',
  })
}
