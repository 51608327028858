import { string, object, array, lazy, number, boolean, mixed } from 'yup'
import mapValues from 'lodash/mapValues'
import m from 'moment'

import { RULE_TYPES, ACTION_TYPES } from '@sweetspot/club-portal-legacy/components/Wizard/constants'
import { parseErrors } from '@sweetspot/club-portal-legacy/helpers/Validation'

const HHmmssREG = /([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/
const HHmmssFormat = 'HH:mm:ss'

// Rules
const rulesSchemas = {
  [RULE_TYPES.numberOfRounds]: object().shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([RULE_TYPES.numberOfRounds]),
    configuration: object()
      .required()
      .shape({
        limitation_value: number().required().min(1),
      }),
  }),
  [RULE_TYPES.daysInWeekAndTime]: object()
    .required()
    .shape({
      id: mixed().notRequired(),
      type: string().required().oneOf([RULE_TYPES.daysInWeekAndTime]),
      configuration: lazy((obj) => {
        if (Object.keys(obj).length >= 1) {
          return object(
            mapValues(obj, () =>
              object().shape({
                from: string().matches(HHmmssREG),
                to: string()
                  .matches(HHmmssREG)
                  .test('must-be-efter-from', 'must-be-after-from', function () {
                    const { from, to } = this.parent
                    if (from && to) {
                      if (m(to, HHmmssFormat).isAfter(m(from, HHmmssFormat))) return true
                      return false
                    }
                    return true
                  }),
              })
            )
          )
        }
        return object().nullable().default({}).notRequired()
      }),
    }),
  [RULE_TYPES.includedCourses]: object()
    .required()
    .shape({
      id: mixed().notRequired(),
      type: string().required().oneOf([RULE_TYPES.includedCourses]),
      configuration: array().required().min(1),
    }),
  [RULE_TYPES.simBookings]: object()
    .required()
    .shape({
      id: mixed().notRequired(),
      type: string().required().oneOf([RULE_TYPES.simBookings]),
      configuration: lazy((value) => {
        if (value.same_day_booking) {
          return object()
            .required()
            .shape({
              number_of_simultaneous_bookings: lazy((value) => {
                if (typeof value === 'number') return number().min(1).nullable()
                return string()
              }),
              same_day_booking: object()
                .shape({ is_enabled: boolean(), from: string() })
                .notRequired(),
            })
        } else {
          return object()
            .required()
            .shape({
              number_of_simultaneous_bookings: number().min(1).nullable(),
            })
        }
      }),
    }),
}

// Action
const actionsSchemas = {
  [ACTION_TYPES.memberDiscount]: object().shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([ACTION_TYPES.memberDiscount]),
    configuration: object()
      .required()
      .shape({
        percentage_coefficient: number().required().min(0).max(1),
      }),
  }),
  [ACTION_TYPES.guestDiscount]: object().shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([ACTION_TYPES.guestDiscount]),
    configuration: object()
      .required()
      .shape({
        percentage_coefficient: number().required().min(0).max(1),
      }),
  }),
  [ACTION_TYPES.playValue]: object().shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([ACTION_TYPES.playValue]),
    configuration: object()
      .required()
      .shape({
        limitation_value: number().required().min(1),
      }),
  }),
}

/**
 * Single promotion
 */
const promotionSchema = object().shape({
  name: string().required().min(3).trim(),
  rules: object()
    .required()
    .shape({
      [RULE_TYPES.numberOfRounds]: rulesSchemas[RULE_TYPES.numberOfRounds]
        .notRequired()
        .default(undefined)
        .nullable(),
      [RULE_TYPES.daysInWeekAndTime]: rulesSchemas[RULE_TYPES.daysInWeekAndTime],
      [RULE_TYPES.includedCourses]: rulesSchemas[RULE_TYPES.includedCourses],
      [RULE_TYPES.simBookings]: rulesSchemas[RULE_TYPES.simBookings]
        .notRequired()
        .default(undefined)
        .nullable(),
    }),
  actions: object()
    .required()
    .shape({
      [ACTION_TYPES.memberDiscount]: actionsSchemas[ACTION_TYPES.memberDiscount]
        .notRequired()
        .default(undefined)
        .nullable(),
      [ACTION_TYPES.guestDiscount]: actionsSchemas[ACTION_TYPES.guestDiscount]
        .notRequired()
        .default(undefined)
        .nullable(),
      [ACTION_TYPES.playValue]: actionsSchemas[ACTION_TYPES.playValue]
        .notRequired()
        .default(undefined)
        .nullable(),
    }),
  id: mixed().required(),
})

/**
 * Multiple promotions
 */
const promotionsSchema = lazy((obj) => {
  if (Object.keys(obj).length >= 1) {
    return object(mapValues(obj, () => promotionSchema))
  } else {
    return object()
      .shape({
        promotions: mixed().notRequired(),
      })
      .required()
  }
})

/**
 * Single Fee
 */
const feeSchema = object().shape({
  fee_unit: mixed(),
  vat: number().min(0).max(100).required(),
  price: number().min(0).required(),
  monthly_price: number().min(0).notRequired(),
  id: mixed().required(),
})

/**
 * Multiple Fees
 */
const feesSchema = array().of(feeSchema).min(1)

const requirementsSchema = object().shape({
  type: string().required(),
  configuration: object()
    .shape({
      setting: boolean(),
    })
    .notRequired(),
})

// Membership
export const AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS = {
  min: 1,
  max: 90,
}
export const AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS = {
  min: 31,
  max: 60,
}

const membershipSchema = object().shape({
  name: string().required().min(3).trim(),
  duration: object().shape({
    options: array().min(1).required(),
    to: string().notRequired().nullable(true),
    from: string().notRequired().nullable(true),
  }),
  description: string().notRequired(),
  is_hidden: boolean().notRequired(),
  play_right_only: boolean().notRequired(),
  is_git_sync: boolean().notRequired(),
  is_active: boolean().notRequired(),
  is_sell_membership_enabled: boolean().notRequired(),
  membership_requirements: array().of(requirementsSchema).notRequired(),
  channels: array()
    .transform((value) => {
      if (value === null) {
        return []
      }
      return value
    })
    .default([]),
  id: number().notRequired(),
  autorenewal: object()
    .shape({
      is_active: boolean().required(),
      days_before_cancellation: number()
        .integer()
        .min(AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS.min)
        .max(AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS.max)
        .nullable(true)
        .when('days_before_email', (days_before_email, schema) => {
          return schema.test({
            test: (days_before_cancellation) => {
              if (days_before_cancellation && days_before_email) {
                return days_before_cancellation <= days_before_email
              }
              return true
            },
          })
        }),
      days_before_email: number()
        .integer()
        .min(AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS.min)
        .max(AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS.max)
        .nullable(true),
    })
    .required(),
})

const membershipFullSchema = membershipSchema.shape({
  fees: feesSchema,
})

/**
 * Validate single fee
 *
 * @param {Object} fee
 * @param {string} lang
 * @return {Promise}
 */
export const validateSingleFee = (fee, lang) => {
  return feeSchema
    .validate(fee, { abortEarly: false, stripUnknown: true })
    .catch((err) => parseErrors(err, lang))
}

/**
 * Validates when creating new membership
 * @param {Object} membership
 * @param {string} lang
 * @returns {Promise}
 */
export const validateMembership = (membership, lang) => {
  return membershipSchema
    .validate(membership, { abortEarly: false, stripUnknown: true })
    .catch((err) => parseErrors(err, lang))
}

export const validateMembershipPrePublish = (membership, currentEntity, lang) => {
  return membershipFullSchema
    .validate(membership, { abortEarly: false, stripUnknown: true })
    .catch((err) => parseErrors(err, lang))
}

/**
 * Validates promotions
 * @param {Object} promotions
 * @param {string} lang
 * @returns {Promise}
 */
export const validatePromotions = (promotions, lang) => {
  return promotionsSchema
    .validate(promotions, { abortEarly: false, stripUnknown: true })
    .catch((err) => parseErrors(err, lang))
}
