import React from 'react'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import { FederationPlayer } from '@sweetspot/shared/types'

type GitInfoProps = {
  gitInfo: FederationPlayer | null
}

const GitInfo: React.FC<GitInfoProps> = ({ gitInfo }) => {
  return (
    <div className={style.container}>
      <div className={style.field}>
        <Text textId="communication.email" />
        {gitInfo?.email || '-'}
      </div>

      <div className={style.field}>
        <Text textId="phone" />
        {gitInfo?.phone || '-'}
      </div>

      <div className={style.field}>
        <Text textId="homeClub" />
        {gitInfo?.homeClub || '-'}
      </div>
    </div>
  )
}

export default GitInfo
