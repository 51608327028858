import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { IUniversalIdentifier } from '@sweetspot/shared/types'

import {
  addPriceOverridePayload,
  createPricePeriodPayload,
  createPriceSegmentPayload,
  updatePriceChartPayload,
  updatePricePeriodPayload,
  updatePriceSegmentPayload,
  getPricingPeriodsResponse,
  updatePriceChartResponse,
} from './types'

export function getPricingPeriods(courseId: string): getPricingPeriodsResponse {
  const URL = `${getBaseUrlPlatform()}/price-periods?course=${courseId}`
  return request(URL)
}

export function createPricePeriod(payload: createPricePeriodPayload): IUniversalIdentifier {
  const { courseId, pricePeriod } = payload

  const URL = `${getBaseUrlPlatform()}/courses/${courseId}/price-period`
  return request(URL, {
    method: 'PUT',
    body: {
      start_date: pricePeriod.start_date,
      end_date: pricePeriod.end_date,
      name: pricePeriod.name,
      is_active: pricePeriod.is_active,
      is_default: pricePeriod.is_default,
    },
  })
}

export function createPriceSegment(payload: createPriceSegmentPayload): IUniversalIdentifier {
  const { periodId, priceSegment } = payload

  const URL = `${getBaseUrlPlatform()}/price-periods/${periodId}/price-segment`
  return request(URL, {
    method: 'PUT',
    body: {
      segment_tag: priceSegment.segment_tag,
      priority: priceSegment.priority,
      configuration: priceSegment.configuration,
    },
  })
}

export function updatePricePeriod(props: updatePricePeriodPayload): IUniversalIdentifier {
  const { pricePeriod } = props

  const URL = `${getBaseUrlPlatform()}/price-periods/${pricePeriod.id}`
  return request(URL, {
    method: 'PUT',
    body: {
      start_date: pricePeriod.start_date,
      end_date: pricePeriod.end_date,
      name: pricePeriod.name,
      is_active: pricePeriod.is_active,
      is_default: pricePeriod.is_default,
    },
  })
}

export function updatePriceSegment(props: updatePriceSegmentPayload): IUniversalIdentifier {
  const { segmentId, priceSegment } = props

  const URL = `${getBaseUrlPlatform()}/price-segments/${segmentId}`
  return request(URL, {
    method: 'PUT',
    body: {
      priority: priceSegment.priority,
      configuration: priceSegment.configuration,
    },
  })
}

export function removePricePeriod(periodId: string): void {
  const URL = `${getBaseUrlPlatform()}/price-periods/${periodId}`
  return request(URL, { method: 'DELETE' })
}

export const getPricingOverrides = (periodId: string): [] => {
  const URL = `${getBaseUrlPlatform()}/price-overrides?price_period=${periodId}`

  return request(URL, { method: 'GET' })
}

export const addPriceOverride = (payload: addPriceOverridePayload): IUniversalIdentifier => {
  const { id, from, to, amount, currency } = payload
  const URL = `${getBaseUrlPlatform()}/price-periods/${id}/price-override`

  return request(URL, {
    method: 'PUT',
    body: {
      from,
      to,
      amount,
      currency,
    },
  })
}

export const removePriceOverride = (overrideId: number): void => {
  const URL = `${getBaseUrlPlatform()}/price-overrides/${overrideId}`

  return request(URL, { method: 'DELETE' })
}

export const updatePriceChart = (
  periodId: number,
  payload: updatePriceChartPayload
): updatePriceChartResponse => {
  const URL = `${getBaseUrlPlatform()}/price-periods/${periodId}/simulate`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}
