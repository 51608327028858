import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import m from 'moment'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'

import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import { RULE_TYPES } from '@sweetspot/club-portal-legacy/components/Wizard/constants'

import styles from './styles.module.scss'

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const REG = /([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/
const RULE_TYPE = RULE_TYPES.daysInWeekAndTime

const DaysAndTimeIncludedRule = ({ promotion, onChange, errors, disabled }) => {
  const { t } = useTranslation()
  const [dayErrors, setDayErrors] = useState({})

  useEffect(() => {
    let errorsObject = {}
    const { id: promotionId } = promotion

    DAYS.forEach((day) => {
      errorsObject[day] = {
        from:
          errors[`promotions.${promotionId}.rules.${RULE_TYPE}.configuration.${day}.from`] || null,
        to: errors[`promotions.${promotionId}.rules.${RULE_TYPE}.configuration.${day}.to`] || null,
      }
    })
    setDayErrors(errorsObject)
  }, [errors, promotion])

  const update = (newConfig) => {
    onChange(RULE_TYPE, newConfig)
  }

  const getCurrentConfig = () => {
    if (promotion?.rules?.[RULE_TYPE]?.configuration) {
      return promotion.rules[RULE_TYPE].configuration
    } else {
      return {}
    }
  }

  const isAllDays = () => {
    const currentConfig = getCurrentConfig()
    return Object.keys(currentConfig).filter((configKey) => DAYS.includes(configKey))?.length === 7
      ? true
      : false
  }

  const isDay = (day) => {
    const currentConfig = getCurrentConfig()
    return currentConfig?.[day] ? true : false
  }

  const isAllDay = (day) => {
    const currentConfig = getCurrentConfig()?.[day]
    if (!currentConfig) return false

    const { from, to } = currentConfig
    if (!from || !to) return false

    if (from === '00:00:00' && to === '23:59:59') return true

    return false
  }

  const getFrom = (day) => {
    if (isAllDay(day)) return '00:00'

    const currentConfig = getCurrentConfig()?.[day]

    if (!currentConfig) return ''
    const { from } = currentConfig

    if (from) {
      if (REG.test(from) && m(from, 'HH:mm:ss').isValid()) {
        return m(from, 'HH:mm:ss').format('HH:mm')
      }
      return from
    } else {
      return ''
    }
  }

  const getTo = (day) => {
    if (isAllDay(day)) return '23:59'

    const currentConfig = getCurrentConfig()?.[day]

    if (!currentConfig) return ''
    const { to } = currentConfig

    if (to) {
      if (REG.test(to) && m(to, 'HH:mm:ss').isValid()) {
        return m(to, 'HH:mm:ss').format('HH:mm')
      }
      return to
    } else {
      return ''
    }
  }

  const setAllDays = (value) => {
    const currentConfig = getCurrentConfig()
    let newConfig = {}

    if (value === true) {
      newConfig = {
        ...currentConfig,
      }
      DAYS.forEach((day) => {
        if (!newConfig[day]) {
          newConfig[day] = { from: '00:00:00', to: '23:59:59' }
        }
      })
    }

    update(newConfig)
  }

  const setOneDay = (value, day) => {
    const currentConfig = getCurrentConfig()
    let newConfig = {
      ...currentConfig,
    }

    if (value === true) {
      if (!newConfig[day]) {
        newConfig[day] = { from: '00:00:00', to: '23:59:59' }
      }
    } else {
      delete newConfig[day]
    }

    update(newConfig)
  }

  const setAllDay = (value, day) => {
    const currentConfig = getCurrentConfig()
    let newConfig = {
      ...currentConfig,
    }

    if (value === true) {
      newConfig[day] = {
        from: '00:00:00',
        to: '23:59:59',
      }
    } else {
      newConfig[day] = { from: null, to: null }
    }

    update(newConfig)
  }

  const setFrom = (value, day) => {
    const currentConfig = getCurrentConfig()
    let newConfig = {
      ...currentConfig,
      [day]: {
        ...currentConfig[day],
      },
    }

    let string = value + ':00'

    if (REG.test(string) && m(string, 'HH:mm:ss').isValid()) {
      newConfig[day].from = string
    } else {
      newConfig[day].from = value
    }
    update(newConfig)
  }

  const setTo = (value, day) => {
    const currentConfig = getCurrentConfig()
    let newConfig = {
      ...currentConfig,
      [day]: {
        ...currentConfig[day],
      },
    }

    if (!newConfig[day]) newConfig[day] = { to: null }

    let string = value + ':00'

    if (REG.test(string) && m(string, 'HH:mm:ss').isValid()) {
      newConfig[day].to = m(string, 'HH:mm:ss').format('HH:mm:ss')
    } else {
      newConfig[day].to = value
    }
    update(newConfig)
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.row, styles.headerRow)}>
        <p className={cx(styles.item, styles.days, styles.header)}>{t('sentences.daysIncluded')}</p>
        <p className={cx(styles.item, styles.allDay, styles.header)}>
          {t('dateTime.words.allDay')}
        </p>
        <p className={cx(styles.item, styles.from, styles.header)}>{t('words.from')}</p>
        <p className={cx(styles.item, styles.to, styles.header)}>{t('words.to')}</p>
      </div>
      <div className={cx(styles.row, styles.allDaysRow)}>
        <Checkbox
          label={t('dateTime.words.allDays')}
          lightLabel={true}
          value={isAllDays()}
          onChange={(val) => setAllDays(val)}
          disabled={disabled}
        />
      </div>
      {DAYS.map((day) => {
        const allDay = isAllDay(day)

        return (
          <div key={day} className={cx(styles.row, styles.itemRow)}>
            <div className={cx(styles.item, styles.days, styles.body)}>
              <Checkbox
                label={t(`dateTime.longDays.${day.toLowerCase()}`)}
                value={isDay(day)}
                onChange={(val) => setOneDay(val, day)}
                lightLabel={true}
                disabled={disabled}
              />
            </div>
            <div className={cx(styles.item, styles.allDay, styles.body)}>
              <Checkbox
                value={allDay}
                onChange={(val) => setAllDay(val, day)}
                disabled={disabled}
              />
            </div>
            <div className={cx(styles.item, styles.from, styles.body)}>
              {allDay ? (
                <p
                  onClick={() => {
                    setAllDay(false, day)
                  }}
                >
                  -
                </p>
              ) : (
                <TextInputField
                  value={getFrom(day)}
                  placeholder="00:00"
                  error={dayErrors[day]?.from}
                  maskOptions={{
                    mask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
                  }}
                  disabled={disabled}
                  onChange={(val) => setFrom(val, day)}
                />
              )}
            </div>
            <div className={cx(styles.item, styles.to, styles.body)}>
              {allDay ? (
                <p
                  onClick={() => {
                    setAllDay(false, day)
                  }}
                >
                  -
                </p>
              ) : (
                <TextInputField
                  value={getTo(day)}
                  placeholder="00:00"
                  error={dayErrors[day]?.to}
                  maskOptions={{
                    mask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
                  }}
                  disabled={disabled}
                  onChange={(val) => setTo(val, day)}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

DaysAndTimeIncludedRule.propTypes = {
  promotion: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
}
DaysAndTimeIncludedRule.defaultProps = {
  promotion: {},
  onChange: () => {},
  errors: {},
  disabled: false,
}

export default DaysAndTimeIncludedRule
