import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export const refundMembershipCard = (id: number): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/membership-cards/${id}/refunds`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const cancelFutureMembershipCardPayments = (id: number): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/membership-cards/${id}/future-payments/cancel`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const membershipCardPayments = (uuid: string | undefined): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/players/membership-cards/${uuid}/payments`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
