export const HEADERS = {
  name: {
    name: '.words.name',
    type: 'label',
    width: '250px',
    selected: true,
    disabled: true,
    isSortable: false,
  },
  email: {
    name: '.words.email',
    type: 'label',
    width: '250px',
    selected: true,
    isSortable: false,
  },
  // TODO: implement after the backend is ready
  //isDone: {
  //  name: '.words.done',
  //  type: 'checkbox',
  //  selected: true,
  //  valueType: 'boolean',
  //},
  birthDate: {
    name: '.settings.playerCard.birthDate',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  membershipName: {
    name: '.words.membership',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
}
