import React from 'react'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { useTranslation } from 'react-i18next'

export const EmptyVenueText = ({ courseType, customStyle }) => {
  const { t } = useTranslation()
  switch (courseType) {
    case COURSE_TYPES.SIMULATOR.value:
      return <p className={customStyle}>{t('sentences.noSimulatorDefaultPage')}</p>
    case COURSE_TYPES.DRIVING_RANGE.value:
      return <p className={customStyle}>{t('sentences.noDivingRangesDefaultPage')}</p>
    case COURSE_TYPES.PRO.value:
      return <p className={customStyle}>{t('sentences.noProDefaultPage')}</p>
    default:
      return <p className={customStyle}>{t('sentences.noOtherDefaultPage')}</p>
  }
}
