import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Gets message information
 *
 * @param {string} messageUuid
 */
export const getDelayedMessage = (messageUuid) => {
  const url = `${getBaseUrlPlatform()}/messages/delays/${messageUuid}`
  return request(url)
}

/**
 * Renews message with delay amount in milliseconds
 *
 * @param {string} messageUuid
 * @param {number} amount in ms / milliseconds
 */
export const renewDelayedMessage = (messageUuid, amount) => {
  const url = `${getBaseUrlPlatform()}/messages/delays/${messageUuid}/renew`
  return request(url, { method: 'PUT', body: { delay: amount } })
}
