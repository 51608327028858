import {
  cancelMembershipAutoRenewal,
  getMembershipCardPayments,
} from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'

import { useQuery, useMutation } from 'react-query'
import {
  getMembershipCard,
  queryMembershipCards,
  cancelMembershipCards,
} from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'
import { CLUB_QUERIES } from '../constants/queries'

const useMembershipCard = (membershipCardId: number) => {
  return useQuery(
    [CLUB_QUERIES.MEMBERSHIP_CARDS, membershipCardId],
    () => getMembershipCard(membershipCardId),
    { enabled: !!membershipCardId }
  )
}

const useMembershipCardPayments = (membershipCardUUID: string) => {
  return useQuery(
    [CLUB_QUERIES.MEMBERSHIP_CARDS_PAYMENT, membershipCardUUID],
    () => getMembershipCardPayments(membershipCardUUID),
    { enabled: !!membershipCardUUID }
  )
}

const useMembershipCards = (query: Record<string, unknown>, settings: Record<string, unknown>) => {
  return useQuery([CLUB_QUERIES.MEMBERSHIP_CARDS, query], () => queryMembershipCards(query), {
    ...settings,
  })
}

const useCancelMembershipCards = (
  membershipCardUuidList: string[],
  args: Record<string, unknown>
) => {
  return useMutation(() => cancelMembershipCards(membershipCardUuidList), {
    ...args,
    retry: false,
  })
}

const useCancelMembershipAutoRenewal = (args: Record<string, unknown>) => {
  return useMutation((membershipId: number) => cancelMembershipAutoRenewal(membershipId), {
    ...args,
    retry: false,
  })
}

export {
  useMembershipCard,
  useMembershipCards,
  useCancelMembershipCards,
  useCancelMembershipAutoRenewal,
  useMembershipCardPayments,
}
