import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import queryString from 'query-string'

/**
 * Query partnerships
 *
 * @param {*} query
 */
export const queryPartnerships = (
  query = {
    'bookers.id': null,
    search: null,
    'order[statusPriority]': null,
    'order[name]': null,
    'order[club.name]': null,
    'club.id[]': null,
    page: null,
    limit: null,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/crm/partnerships`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, { accept: 'application/ld+json' })
}

export const queryPaginatedPartnerships = async (
  query = {
    'bookers.id': null,
    'course.id': null,
    search: null,
    'order[statusPriority]': null,
    'order[name]': null,
    'order[club.name]': null,
    'club.id[]': null,
    page: null,
    limit: null,
  }
) => {
  const [res, err] = await to(queryPartnerships(query))
  if (err) return

  const totalPages = Math.ceil(res['hydra:totalItems'] / res['hydra:itemsPerPage'])

  const promises = []
  for (let page = 1; page <= totalPages; page++) {
    promises.push(to(queryPartnerships({ ...query, page })))
  }

  return Promise.all(promises)
}
