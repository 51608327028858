import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { ImportMembershipResult, PendingInvitesParams, MembershipSignupsQuery } from './types'
import queryString from 'query-string'
import { Hydra, MembershipSignup, PendingInvite, QueryRecord } from '@sweetspot/shared/types'

/**
 * Start import of membership invites
 */
export const importMembershipInvites = (file: FormData): Promise<ImportMembershipResult> => {
  const URL = `${getBaseUrlPlatform()}/membership-player-invites/import`

  return request(URL, {
    method: 'POST',
    contentType: 'multipart/form-data',
    body: file,
  })
}

/**
 * Get pending invites from bulk import
 */
export const getPendingInvites = (query: PendingInvitesParams): Promise<Hydra<PendingInvite>> => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/membership-player-invites`,
      query: query as QueryRecord<typeof query>,
    },
    {
      arrayFormat: 'bracket',
    }
  )

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'Application/ld+json',
    },
  })
}

/**
 * Query membership signups
 * @param query
 * @returns
 */
export const queryMembershipSignups = (
  query: MembershipSignupsQuery
): Promise<Hydra<MembershipSignup>> => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/membership-signups`,
      query: query as QueryRecord<typeof query>,
    },
    {
      arrayFormat: 'bracket',
    }
  )

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
    },
  })
}

/**
 * Get single membership signup by uuid
 * @param uuid
 * @returns
 */
export const getMembershipSignupByUuid = (uuid: string): Promise<MembershipSignup> => {
  const url = `${getBaseUrlPlatform()}/membership-signups/${uuid}`
  return request(url)
}
