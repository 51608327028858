import { ReactComponent as DefaultSimulatorIcon } from '@sweetspot/sweetspot-js/assets/svgs/simulator-default.svg'
import { ReactComponent as DefaultRangesIcon } from '@sweetspot/sweetspot-js/assets/svgs/driving-range-default.svg'
import { ReactComponent as DefaultProIcon } from '@sweetspot/sweetspot-js/assets/svgs/pro-default.svg'
import { ReactComponent as DefaultOtherIcon } from '@sweetspot/sweetspot-js/assets/svgs/other-default.svg'
import { GolfCourseTypes } from '@sweetspot/shared/types'

interface VenuesSelectIconProps {
  courseType: Exclude<GolfCourseTypes, GolfCourseTypes.COURSE>
}

const VenuesSelectIcon = ({ courseType }: VenuesSelectIconProps) => {
  const iconMap = {
    [GolfCourseTypes.SIMULATOR]: DefaultSimulatorIcon,
    [GolfCourseTypes.DRIVING_RANGE]: DefaultRangesIcon,
    [GolfCourseTypes.PRO]: DefaultProIcon,
    [GolfCourseTypes.OTHER]: DefaultOtherIcon,
  }
  const Icon = iconMap[courseType] || null
  return Icon ? <Icon width={16} height={16} className="[&_path]:fill-brand-black" /> : null
}

export default VenuesSelectIcon
