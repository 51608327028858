import React, { useLayoutEffect, useRef, useState } from 'react'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import cx from 'classnames'
import styles from './style.module.scss'
import PropTypes from 'prop-types'

const CalendarBox = ({ visible, onClosePreview, containerRef, CalendarComponent }) => {
  const boxRef = useRef(null)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  useOnClickOutside(boxRef, () => onClosePreview())

  useLayoutEffect(() => {
    if (!containerRef.current || !boxRef.current || !visible) return

    const parentRect = containerRef.current.getBoundingClientRect()
    const boxRect = boxRef.current.getBoundingClientRect()
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    const containerCenterX = parentRect.left + parentRect.width / 2
    let newLeft = containerCenterX - boxRect.width / 2

    newLeft = Math.max(newLeft, 0)
    if (newLeft + boxRect.width > viewportWidth) {
      newLeft = viewportWidth - boxRect.width
    }

    let newTop = parentRect.bottom + window.scrollY
    if (newTop + boxRect.height > viewportHeight + window.scrollY) {
      newTop = parentRect.top + window.scrollY - boxRect.height
    }

    setPosition({ top: newTop, left: newLeft })
  }, [containerRef.current, boxRef.current, visible, window.innerWidth, window.innerHeight])

  return (
    <Portal id={'calendar-box'}>
      <div
        className={cx(styles.container, visible && styles.visible)}
        style={{ position: 'fixed', ...position, zIndex: 100 }}
        onClick={(e) => e.stopPropagation()}
        ref={boxRef}
      >
        {CalendarComponent && <CalendarComponent.component {...CalendarComponent.props} />}
      </div>
    </Portal>
  )
}

export default CalendarBox

CalendarBox.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClosePreview: PropTypes.func.isRequired,
  containerRef: PropTypes.object,
  CalendarComponent: PropTypes.object,
}

CalendarBox.defaultProps = {
  containerRef: null,
  CalendarComponent: null,
}
