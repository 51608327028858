import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { CreateRangePayload, Range } from '@sweetspot/sweetspot-js/features/ranges/services/types'

export const queryRanges = (currentClubId?: string): Promise<{ ranges: Range[] }> => {
  const url = `${getBaseUrlPlatform()}/ranges?organizationId=${currentClubId}`
  return request(url) as Promise<{ ranges: Range[] }>
}

export const createRange = (body: CreateRangePayload): Promise<Range> => {
  const url = `${getBaseUrlPlatform()}/ranges`
  return request(url, { method: 'POST', body }) as Promise<Range>
}
