import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { PlayerBooking, Note, SortOrder } from '@sweetspot/shared/types'

export const createPlayerNote = (note: string, playerId: string): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/players/notes`

  const payload = {
    note,
    player: {
      uuid: playerId,
    },
  }

  return request(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

export const getPlayerNotes = (playerId: string): Promise<Note[]> => {
  const URL = `${getBaseUrlPlatform()}/players/notes?player.uuid=${playerId}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const deletePlayerNote = (noteId: string): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/players/notes/${noteId}`

  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const updatePlayerNote = (noteId: string, note: string): Promise<unknown> => {
  const URL = `${getBaseUrlPlatform()}/players/notes/${noteId}`
  const payload = {
    note,
  }
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

export const getPlayerBookings = (
  playerId?: number,
  clubId?: number,
  sorting?: { name: string; type: SortOrder }[],
  statusFilter?: string | null
): Promise<PlayerBooking[]> => {
  const baseURL = `${getBaseUrlPlatform()}/legacy/bookings/slots?player.id=${playerId}`

  // Initialize an array to hold query parameters
  const queryParams = []

  // Check if clubId is provided and add it to the queryParams array
  if (clubId !== undefined) {
    queryParams.push(`club.id=${clubId}`)
  }

  // Check if statusFilter is provided and not an empty string
  if (statusFilter) {
    queryParams.push(`booking.status=${encodeURIComponent(statusFilter)}`)
  }

  if (sorting?.length) {
    sorting.forEach((sort) => {
      queryParams.push(`${encodeURIComponent(sort.name)}=${sort.type}`)
    })
  }

  // Construct the final URL with query parameters
  const URL = queryParams.length ? `${baseURL}&${queryParams.join('&')}` : baseURL

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
