import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isValid, addDays, isSameDay, format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendarDay } from '@fortawesome/pro-regular-svg-icons'

import { Button } from '@sweetspot/scramble-ds'
// @ts-expect-error InputSelectDateSimple is not in typescript
import InputSelectDateSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectDateSimple'
// @ts-expect-error InputSelectDateSimple is not in typescript
import { changeTimezone } from '@sweetspot/sweetspot-js/common/functions/dateUtils'

interface SheetHeaderDateSelectProps {
  currentDate: Date
  handleSetDate: (date: Date) => void
  timezone: string
  onTodayClick?: () => void
  onPrevClick?: () => void
  onNextClick?: () => void
}

const SheetHeaderDateSelect = ({
  currentDate,
  handleSetDate,
  timezone,
  onTodayClick,
  onPrevClick,
  onNextClick,
}: SheetHeaderDateSelectProps) => {
  const { t } = useTranslation()

  const dateIsDisabled = useMemo(() => !isValid(currentDate), [currentDate])

  const currentDateFormated = useMemo(() => {
    if (isValid(currentDate)) {
      const today = new Date()
      const tomorrow = addDays(today, 1)

      if (isSameDay(currentDate, today)) {
        return `${t('dateTime.words.today')}, ${format(currentDate, 'd MMM')}`
      } else if (isSameDay(currentDate, tomorrow)) {
        return `${t('dateTime.words.tomorrow')}, ${format(currentDate, 'd MMM')}`
      }
      return format(currentDate, 'EEEE, d MMM')
    }
    return ''
  }, [currentDate, t])

  const handleSetDayBack = useCallback(() => {
    onPrevClick?.()
    const prevDay = new Date(currentDate.getTime())
    prevDay.setDate(currentDate.getDate() - 1)
    handleSetDate(prevDay)
  }, [currentDate, handleSetDate, onPrevClick])

  const handleSetDayForward = useCallback(() => {
    onNextClick?.()
    const nextDay = new Date(currentDate.getTime())
    nextDay.setDate(currentDate.getDate() + 1)
    handleSetDate(nextDay)
  }, [currentDate, handleSetDate, onNextClick])

  const handleSetToday = useCallback(() => {
    onTodayClick?.()
    const today = changeTimezone(new Date(), timezone)
    handleSetDate(today)
  }, [handleSetDate, onTodayClick, timezone])

  return (
    <div className="flex gap-6">
      <button onClick={handleSetToday} className="px-3">
        <FontAwesomeIcon size="1x" icon={faCalendarDay} className="px-[1px]" />
      </button>
      <div className="flex items-center">
        <Button
          onClick={handleSetDayBack}
          rounding="pill"
          disabled={dateIsDisabled}
          variant="clear-dark"
          size="small"
          className="hover:text-text-dark p-2 hover:bg-transparent hover:shadow-none focus:!outline-none"
        >
          <FontAwesomeIcon className="px-[1px]" icon={faArrowLeft} />
        </Button>
        <Button
          onClick={handleSetDayForward}
          rounding="pill"
          disabled={dateIsDisabled}
          variant="clear-dark"
          size="small"
          className="hover:text-text-dark p-2 hover:bg-transparent hover:shadow-none focus:!outline-none"
        >
          <FontAwesomeIcon className="px-[1px]" icon={faArrowRight} />
        </Button>
        <InputSelectDateSimple
          className="w-[180px] items-start"
          inputClassName="!w-auto px-4"
          newDesign
          currentDate={currentDate}
          onSetDate={handleSetDate}
          disableBeforeToday={false}
          placeholder={t('sentences.selectDate')}
          value={currentDateFormated}
          disabled={dateIsDisabled}
        />
      </div>
    </div>
  )
}

export default SheetHeaderDateSelect
