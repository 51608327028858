import { ReactNode } from 'react'

type Props = {
  from: string
  to: string
  isLastRow: boolean
  children: ReactNode
}

const TableBodyRow = ({ from, to, isLastRow, children }: Props) => (
  <tr key={`${from}-${to}`}>
    <td className="h-4 !w-[74px]">
      <div
        className={`border-Borders-stroke-clean flex h-full w-full items-center justify-center border-l border-r p-2 ${
          isLastRow ? 'rounded-bl-sm border-b' : ''
        }`}
      >
        <p className="text-content-xs text-text-dark font-bold"> {from}</p>
      </div>
    </td>
    <td className="h-4 !w-[74px]">
      <div
        className={`border-Borders-stroke-clean flex h-full w-full items-center justify-center p-2 ${
          isLastRow ? 'border-b' : ''
        }`}
      >
        <p className="text-content-xs text-text-dark font-bold"> {to}</p>
      </div>
    </td>
    {children}
  </tr>
)

export default TableBodyRow
