import {
  Check,
  cn,
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  Tag,
} from '@sweetspot/scramble-ds'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { TrackingTech } from '@sweetspot/shared/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'

type Props = {
  floor: number
  setFloor?: (value: number) => void
  roof: boolean
  setRoof?: (value: boolean) => void
  trackingTech: string
  setTrackingTech?: (value: TrackingTech) => void
}

const Details = ({ floor, setFloor, roof, setRoof, trackingTech, setTrackingTech }: Props) => {
  const { t } = useTranslation()
  const { baySideBarMode } = useBaysContext()

  const isViewMode = useMemo(() => baySideBarMode === BaySideBarMode.VIEW, [baySideBarMode])

  const fieldLabelClassName = useMemo(() => (isViewMode ? 'px-0' : undefined), [isViewMode])

  return (
    <div className="flex w-full flex-col">
      <p className="text-content-base font-bold">{t('words.details')}</p>
      <div className="pt-md pb-xl flex flex-row flex-wrap gap-4">
        <InputBase className="w-[64px]">
          <InputLabelContainer className={fieldLabelClassName}>
            {t('words.floor')}
          </InputLabelContainer>
          {isViewMode ? (
            <Tag size="large" variant="inverted" className="mt-sm">
              <i className="fa-regular fa-stairs" />
              <span className="px-sm">{floor}</span>
            </Tag>
          ) : (
            <InputContainer>
              <Input
                value={floor}
                onChange={(event) => setFloor && setFloor(+event.target.value)}
                type="number"
                className="pl-lg pr-md border-border-stroke-clean border-[1px]"
              />
            </InputContainer>
          )}
        </InputBase>
        <InputBase className="w-[94px]">
          <InputLabelContainer className={fieldLabelClassName}>
            {t('settings.bays.roof')}
          </InputLabelContainer>
          {isViewMode ? (
            <Tag size="large" variant="inverted" className="mt-sm">
              <i className="fa-regular fa-people-roof" />
              <span className="px-sm"> {roof ? t('words.yes') : t('words.no')}</span>
            </Tag>
          ) : (
            <InputContainer className="bg-background-base-clean border-border-stroke-clean pl-md pr-sm flex items-center justify-between rounded-md border">
              <label htmlFor={'roof'} className={cn('text-base font-medium')}>
                {roof ? t('words.yes') : t('words.no')}
              </label>
              <Check
                onCheckedChange={setRoof}
                id={'roof'}
                label={''}
                checked={roof}
                className="px-md"
              />
            </InputContainer>
          )}
        </InputBase>
        <InputBase className="w-[148px]">
          <InputLabelContainer className={fieldLabelClassName}>
            {t('settings.bays.trackingTech')}
          </InputLabelContainer>
          {isViewMode ? (
            <Tag size="large" variant="inverted" className="mt-sm">
              <span className="px-sm">{capitalize(trackingTech)}</span>
            </Tag>
          ) : (
            <InputSelect onValueChange={setTrackingTech} value={trackingTech}>
              <InputBase>
                <InputSelectTrigger asChild>
                  <InputContainer>
                    <InputSelectStatefulInput
                      className="border-[1px]"
                      value={capitalize(trackingTech)}
                    />
                    <InputSelectTrailingContainer />
                  </InputContainer>
                </InputSelectTrigger>
              </InputBase>

              <InputSelectContent>
                <InputSelectContentView>
                  {Object.values(TrackingTech).map((trackingTech) => (
                    <InputSelectItem key={trackingTech} value={trackingTech}>
                      <ListItem className="ml-2 h-full justify-center gap-2">
                        <ListItemMainContent className="justify-center">
                          <ListItemParagraph className="text-content-base">
                            {capitalize(trackingTech)}
                          </ListItemParagraph>
                        </ListItemMainContent>
                      </ListItem>
                    </InputSelectItem>
                  ))}
                </InputSelectContentView>
              </InputSelectContent>
            </InputSelect>
          )}
        </InputBase>
      </div>
    </div>
  )
}

export default Details
