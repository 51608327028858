import { Divider, Stepper } from '@sweetspot/scramble-ds'

type Props = {
  numberOfBays: number
  setNumberOfBay: (value: number) => void
}

const NumberOfBays = ({ numberOfBays, setNumberOfBay }: Props) => {
  const increment = () => {
    setNumberOfBay(numberOfBays + 1)
  }

  const decrement = () => {
    if (numberOfBays === 1) return
    setNumberOfBay(numberOfBays - 1)
  }

  return (
    <div className="bg-background-sunset-ligher border-background-sunset-light p-md flex flex-col gap-2 rounded-md border">
      <div className="px-md flex gap-2">
        <i className="fa-solid fa-layer-group" />
        <p className="text-content-base font-bold">Bulk Action</p>
      </div>
      <Divider className="bg-background-sunset-light" />
      <div className="flex items-center justify-between gap-2">
        <p className="px-md text-content-sm font-bold"># of bays to create</p>
        <div>
          <Stepper value={String(numberOfBays)} increment={increment} decrement={decrement} />
        </div>
      </div>
    </div>
  )
}

export default NumberOfBays
