import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export function _createGolfClub(payload) {
  const URL = `${getBaseUrlPlatform()}/clubs`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

export function _deleteGolfClub(id) {
  const URL = `${getBaseUrlPlatform()}/clubs/${id}`

  return request(URL, {
    method: 'DELETE',
  })
}

export function _addGolfCourseToGolfClub(golfClubId, payload) {
  const URL = `${getBaseUrlPlatform()}/clubs/${golfClubId}/courses/new`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _getRecipients(query) {
  const URL = `${getBaseUrlPlatform()}/bookings/emails/recipients?${query}`

  return request(URL, {
    method: 'GET',
  })
}

export function _createBookingEmail(payload) {
  const URL = `${getBaseUrlPlatform()}/bookings/emails`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

export function _sendBookingEmail(emailId) {
  const URL = `${getBaseUrlPlatform()}/bookings/emails/${emailId}/send`

  return request(URL, {
    method: 'PUT',
    body: {},
  })
}

export function _updateBookingEmail(payload) {
  const URL = `${getBaseUrlPlatform()}/bookings/emails/${payload.id}`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _removeBookingEmail(id) {
  const URL = `${getBaseUrlPlatform()}/bookings/emails/${id}`

  return request(URL, {
    method: 'DELETE',
  })
}

export function _getMemberships(query) {
  const URL = `${getBaseUrlPlatform()}/memberships${query}`

  return request(URL, {
    method: 'GET',
  })
}

export function _getGitMemberships(query) {
  const URL = `${getBaseUrlPlatform()}/federation-memberships${query}`

  return request(URL, {
    method: 'GET',
  })
}

export function _getMembershipTotalPages(query) {
  const URL = `${getBaseUrlPlatform()}/memberships${query}`

  return request(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/hal+json',
    },
  })
}

export function _getPartnerships(query) {
  const URL = `${getBaseUrlPlatform()}/crm/partnerships${query}`

  return request(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
    },
  })
}

export function _getWebBookingSetting(clubId) {
  const URL = `${getBaseUrlPlatform()}/clubs/${clubId}/web-booking-settings`

  return request(URL, {
    method: 'GET',
  })
}

export function _setWebBookingSetting(clubId, payload) {
  const URL = `${getBaseUrlPlatform()}/clubs/${clubId}/web-booking-settings`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}
