import React, { useCallback, useState } from 'react'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import { PricesTable } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingTabs/PricesTab/PricesTable'

const TABS = {
  Standard: 'Standard',
  Toptracer: 'Toptracer',
}

export const PricesTabs = () => {
  const [activeTab, setActiveTab] = useState(TABS.Standard)

  const renderContent = useCallback(() => {
    if (activeTab === TABS.Standard) {
      return <PricesTable />
    }
    if (activeTab === TABS.Toptracer) {
      return <PricesTable />
    }
    return null
  }, [activeTab])

  return (
    <>
      <SimpleTabs
        currentItem={activeTab}
        onClick={setActiveTab}
        items={[
          { id: TABS.Standard, label: 'Standard', stepNumber: TABS.Standard },
          { id: TABS.Toptracer, label: 'Toptracer', stepNumber: TABS.Toptracer },
        ]}
      />
      {renderContent()}
    </>
  )
}
