import { useTranslation } from 'react-i18next'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import style from '../../style.module.scss'
import { useMemo } from 'react'

const MIN_PRICE = 0
const MAX_PRICE = 99999
const MIN_PRICE_PER_EXTRA_PLAYER = 0
const MAX_PRICE_PER_EXTRA_PLAYER = 9999

const PricingInputs = ({ extraPlayers, register }) => {
  const { t } = useTranslation()
  const [currency] = useClubCurrency()

  const preventedSymbols = useMemo(() => {
    if (['eur', 'gbp'].includes(currency.toLowerCase())) {
      return ['e', 'E', '+', '-']
    }
    return ['e', 'E', '+', '-', '.']
  }, [currency])

  const handleKeyDown = (event) => {
    if (preventedSymbols.includes(event.key)) {
      event.preventDefault()
    }
  }

  const handleOnChange = (event) => {
    const isExtraPlayer = event.target.getAttribute('data-input') === 'price_per_extra_player'
    const minValue = isExtraPlayer ? MIN_PRICE_PER_EXTRA_PLAYER : MIN_PRICE
    const maxValue = isExtraPlayer ? MAX_PRICE_PER_EXTRA_PLAYER : MAX_PRICE

    const validInput = Math.max(
      Number(minValue),
      Math.min(Number(maxValue), Number(event.target.value))
    ).toString()

    event.target.value =
      validInput.indexOf('.') >= 0 ? validInput.slice(0, validInput.indexOf('.') + 3) : validInput
  }

  const renderPricingInput = (translation, name, dataInput, min, max) => (
    <div>
      <span className={style.optionsText}>{t(translation)}</span>
      <div className="ss-input-group">
        <input
          {...register(name)}
          data-input={dataInput}
          className={`ss-input ${style.priceInput}`}
          type="number"
          min={min}
          max={max}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        <div className="ss-prefix-suffix suffix">{currency.toLowerCase()}</div>
      </div>
    </div>
  )

  return (
    <>
      {renderPricingInput('editPeriodsNew.price', 'price', 'price', MIN_PRICE, MAX_PRICE)}
      {extraPlayers &&
        renderPricingInput(
          'editPeriodsNew.pricePerExtraPlayer',
          'price_per_extra_player',
          'price_per_extra_player',
          MIN_PRICE_PER_EXTRA_PLAYER,
          MAX_PRICE_PER_EXTRA_PLAYER
        )}
    </>
  )
}

export default PricingInputs
