import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ToolTip from '@sweetspot/sweetspot-js/ui-kit/components/ToolTip/tooltip'
import styles from './styles.module.scss'

const AddGolfCartButton = ({
  onAddGolfCart,
  className,
  itemWrapperClassName,
  iconClassName,
  loading,
  canEdit,
  theme,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        },
        className
      )}
    >
      {onAddGolfCart && (
        <ToolTip text={t('sentences.thereAreNoAvailableGolfCartsAtThisTime')}>
          <Button
            className={cx(styles.itemWrapper, itemWrapperClassName)}
            size="default"
            theme="gray"
            onClick={canEdit ? onAddGolfCart : () => {}}
            loading={loading}
            loaderStyle="pulse"
            disabled={!canEdit}
          >
            <AddIcon className={cx(styles.icon, styles.addIcon, iconClassName)} />
            {t('words.golfCart')}
          </Button>
        </ToolTip>
      )}
    </div>
  )
}

AddGolfCartButton.propTypes = {
  onAddGolfCart: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  itemWrapperClassName: PropTypes.string,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
  availableSlots: PropTypes.number,
}

AddGolfCartButton.defaultProps = {
  onAddGolfCart: undefined,
  loading: false,
  canEdit: true,
  theme: 'dark',
  availableSlots: 0,
}

export default AddGolfCartButton
