import React, { useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import InputSelectOptionSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectOptionSimple'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import {
  assignCouponToPlayer,
  queryPromotionCoupons,
  getEligibleBookingCoupons,
} from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import { assignCouponToOrder } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useToasts } from 'react-toast-notifications'

import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'
import Button from '@sweetspot/sweetspot-js/common/components/Button'

const defaultSelectedVoucher = { id: 0, label: '' }

const defaultErrors = {
  general: '',
}

const AddVoucherButton = ({
  className,
  itemWrapperClassName,
  iconClassName,
  popoverClassName,
  booking,
  onUpdateBooking,
  canEdit,
  theme,
  buttonIcon,
  buttonText,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const boxRef = useRef(null)
  const [showBox, setShowBox] = useState(false)
  const [selectedVoucher, setSelectedVoucher] = useState(defaultSelectedVoucher)

  const [inputValue, setInputValue] = useState('')

  const [errors, setErrors] = useMergeState(defaultErrors)

  const [loading, setLoading] = useState(false)

  useOnClickOutside(boxRef, () => setShowBox(false))

  const customer = useMemo(() => {
    return booking?.customer || null
  }, [booking])

  const hasCode = useMemo(() => {
    return selectedVoucher?.code || inputValue?.length ? true : false
  }, [inputValue, selectedVoucher])

  const {
    data: customerVouchers,
    isFetching: isFetchingCustomerVouchers,
    isError: isErrorCustomerVouchers,
    refetch: refetchCustomerVouchers,
  } = useQuery(
    [CLUB_QUERIES.PLAYER_VOUCHERS, { player: customer?.id, booking: booking?.uuid }],
    () =>
      Promise.all([
        queryPromotionCoupons({ player: customer?.id, 'promotion.club': booking?.club?.id }),
        queryPromotionCoupons({ 'orders.uuid': booking?.uuid }),
        getEligibleBookingCoupons(booking?.uuid),
      ]),
    {
      enabled: !!customer?.id && !!booking?.uuid,
      select: ([playerVouchers, assignedPromotionCoupons, bookingCoupons]) => {
        if (playerVouchers && bookingCoupons) {
          // sort voucher coupons into eligible and noneligible grouped options
          const groupedVouchers = playerVouchers.reduce(
            (store, voucher) => {
              // filter already assigned coupons
              if (assignedPromotionCoupons.some(({ uuid }) => voucher.uuid === uuid)) {
                return store
              }
              // formatted voucher option to pass for dropdown object
              const voucherOption = {
                id: voucher.id,
                label: voucher.promotion.name,
                code: voucher.code,
              }
              const bookingCoupon = bookingCoupons.find(({ id }) => voucher.id === id)
              if (bookingCoupon) {
                if (bookingCoupon.eligible) {
                  store[0].options.push(voucherOption)
                } else {
                  store[1].options.push({ ...voucherOption, disabled: true })
                }
              }
              return store
            },
            [
              {
                groupName: '',
                order: 0,
                options: [],
              },
              {
                groupName: t('vouchers.sentence_notValidForThisBooking'),
                order: 1,
                options: [],
              },
            ]
          )
          if (groupedVouchers[1].options.length === 0) {
            groupedVouchers.pop()
          }
          return groupedVouchers
        }
        return []
      },
    }
  )

  const cancel = () => {
    setInputValue('')
    setSelectedVoucher(defaultSelectedVoucher)
    setLoading(false)
    setShowBox(false)
    setErrors(defaultErrors)
  }

  const applyVoucher = async () => {
    const codeFromList = selectedVoucher?.code || null
    const codeFromInput = inputValue || null
    const code = codeFromList || codeFromInput || null

    if (!code || !customer) return
    setLoading(true)

    if (codeFromInput) {
      const [, errFirst] = await to(assignCouponToPlayer(customer.id, code))
      if (errFirst) {
        refetchCustomerVouchers()
      }
    }

    // Test on one first to see if code is valid
    const [, errTwo] = await to(assignCouponToOrder(booking.uuid, code))

    if (errTwo) {
      if (errTwo?.detail === 'Promotion coupon is out of limit') {
        addToast(t('sentences.voucherHasRanOutOfUses'), {
          appearance: 'warning',
        })
        cancel()
        refetchCustomerVouchers()
        return
      }
      setErrors({ general: t('sentences.couldNotApplyVoucher') })
      setLoading(false)
      refetchCustomerVouchers()
      return
    }

    addToast(t('sentences.voucherApplied'), { appearance: 'success' })
    cancel()
    onUpdateBooking()
    refetchCustomerVouchers()
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        },
        className
      )}
    >
      {loading ? (
        <PulseLoader showIf={true} dotStyles={{ width: 10, height: 10 }} />
      ) : (
        <div className={cx({ [styles.buttonContainer]: !canEdit })}>
          <Button
            className={cx(styles.itemWrapper, !canEdit && styles.disabled, itemWrapperClassName)}
            size="default"
            theme="gray"
            onClick={() => canEdit && setShowBox((showBox) => !showBox)}
            loading={loading}
            loaderStyle="pulse"
            disabled={!canEdit}
          >
            {buttonIcon ? (
              buttonIcon
            ) : (
              <AddIcon
                className={cx(styles.icon, !canEdit && styles.disabledIcon, iconClassName)}
              />
            )}
            {buttonText}
          </Button>
        </div>
      )}

      <div ref={boxRef} className={cx(popoverClassName, styles.box, showBox && styles.visible)}>
        <InputSelectOptionSimple
          value={selectedVoucher?.label}
          options={customerVouchers || []}
          disabled={
            !customerVouchers || isFetchingCustomerVouchers || isErrorCustomerVouchers || loading
          }
          onOptionSelect={(option) => {
            setSelectedVoucher(option)
            setInputValue('')
            setErrors(defaultErrors)
          }}
          className={cx(styles.dropdown)}
          groupTitleClassName={cx(styles.groupTitle)}
          disabledOptionsClassName={cx(styles.disabledOptions)}
          label={t('sentences.userVouchers')}
          placeholder={t('sentences.userVouchers')}
        />
        <div className="ss-form-wrapper">
          <label htmlFor="voucher-code" className="ss-label">
            {t('sentences.customCode')}
          </label>
          <input
            disabled={loading}
            name="voucher-code"
            className={cx('ss-input')}
            style={{ width: '100%' }}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
              setSelectedVoucher(defaultSelectedVoucher)
              setErrors(defaultErrors)
            }}
            placeholder={t('words.code')}
          />
        </div>

        <p className={cx(styles.error, errors.general && styles.visible)}>{errors.general}</p>

        <div className={cx(styles.buttonRow)}>
          <button
            disabled={loading}
            onClick={cancel}
            className="system-button primary-outline md-32"
          >
            {t('words.cancel')}
          </button>
          <button
            disabled={loading || !hasCode}
            onClick={applyVoucher}
            className="system-button primary md-32"
          >
            {loading ? (
              <PulseLoader showIf={true} color="#FFFFFF" dotStyles={{ width: 10, height: 10 }} />
            ) : (
              t('words.apply')
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

AddVoucherButton.propTypes = {
  booking: PropTypes.object.isRequired,
  onUpdateBooking: PropTypes.func.isRequired,
  className: PropTypes.string,
  itemWrapperClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  canEdit: PropTypes.bool,
  buttonIcon: PropTypes.element,
  buttonText: PropTypes.string.isRequired,
}

AddVoucherButton.defaultProps = {
  canEdit: true,
  className: '',
  itemWrapperClassName: '',
  iconClassName: '',
  popoverClassName: '',
}

export default AddVoucherButton
