import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Query courses
 *
 */
export const queryCourses = (
  query = {
    'order[teeTime.id]': null,
    'order[id]': null,
    'order[lonlat][lat]': null,
    'order[lonlat][lon]': null,
    'order[lonlat][unit]': null,
    'order[lonlat][order]': null,
    'order[name]': null,
    'lonlat[lat]': null,
    'lonlat[lon]': null,
    'lonlat[distance]': null,
    'teeTimes.from[gt]': null,
    'teeTimes.from[gte]': null,
    'teeTimes.from[lt]': null,
    'teeTimes.from[lte]': null,
    'teeTimes.availableSlots[gt]': null,
    'teeTimes.availableSlots[gte]': null,
    'teeTimes.availableSlots[lt]': null,
    'teeTimes.availableSlots[lte]': null,
    is_git_id_required: null,
    'calculateDistance[lat]': null,
    'calculateDistance[lon]': null,
    'name.search': null,
    'club.id': null,
    type: 'course',
    'type[]': null,
    offset: 0,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/courses`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get course by id
 *
 * @param {number} courseId
 * @returns
 */
export const getCourseById = (courseId) => {
  const url = `${getBaseUrlPlatform()}/courses/${courseId}`
  return request(url)
}

/**
 * Fetch golf course images
 *
 * @param {number} courseId
 * @returns
 */
export const getGolfCourseImages = (courseId) => {
  const url = `${getBaseUrlPlatform()}/courses/images?course=${courseId}`
  return request(url)
}

/**
 * Add access to course for admin by id
 *
 * @param {string|number} courseId
 * @returns
 */
export const addAdminToCourse = (courseId, payload = { admin: '' }) => {
  const url = `${getBaseUrlPlatform()}/courses/${courseId}/admin/add`
  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * remove access to course for admin by uuid
 *
 * @param {string|number} courseId
 * @returns
 */
export const removeAdminFromCourse = (courseId, payload = { admin: '' }) => {
  const url = `${getBaseUrlPlatform()}/courses/${courseId}/admin/remove`
  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Fetch course guide
 *
 * @param {number} courseId
 * @returns
 */
export const getCourseGuide = (courseId) => {
  const url = `${getBaseUrlPlatform()}/courses/${courseId}/guide`
  return request(url)
}
