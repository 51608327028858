import React, { FC, useMemo } from 'react'

import styles from './style.module.scss'
import { Facts, Player } from '@sweetspot/shared/types'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { formatCurrency } from '@sweetspot/shared/util/number'
import { useTranslation } from 'react-i18next'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import { currencyCodeInCurrentLanguage } from '@sweetspot/sweetspot-js/common/functions/utils'

type HeaderProps = {
  player: Player | null
}

const HeaderInformation: FC<HeaderProps> = ({ player }) => {
  const selectedGolfClubId = useSelector((state: RootState) => state.golfClub?.selectedId)

  const { t } = useTranslation()
  const [clubCurrency] = useClubCurrency()

  const playerFacts: Facts | undefined = useMemo(
    () => player?.facts?.find((fact) => fact?.club?.id === selectedGolfClubId),
    [player, selectedGolfClubId]
  )

  const currencyCode: string | null | undefined = useMemo(
    () => clubCurrency && currencyCodeInCurrentLanguage(clubCurrency as string),
    [clubCurrency]
  )

  const playValue = useMemo(() => {
    if (playerFacts?.play_value) {
      return formatCurrency(Math.round(playerFacts.play_value / 100), { currencyCode })
    }
    return '-'
  }, [playerFacts, currencyCode])

  const paidValue = useMemo(() => {
    if (playerFacts?.paid_value) {
      return formatCurrency(Math.round(playerFacts.paid_value / 100), { currencyCode })
    }
    return '-'
  }, [playerFacts, currencyCode])

  const bookingDiscount = useMemo(() => {
    if (playerFacts?.paid_value && playerFacts?.paid_value) {
      return formatCurrency(Math.round((playerFacts.play_value - playerFacts.paid_value) / 100), {
        currencyCode,
      })
    }
    return '-'
  }, [playerFacts, currencyCode])

  if (!player) return null

  return (
    <div className={styles.container}>
      <hr className={'mb-2 w-full border-t-gray-200'} />
      <div className={'flex gap-10'}>
        <div>
          <h5>{t('settings.playerCard.bookings')}</h5>
          <p>{playerFacts?.number_of_rounds || '-'}</p>
        </div>
        <div>
          <h5>{t('settings.playerCard.usedPlayValue')}</h5>
          <p>{playValue}</p>
        </div>
        <div>
          <h5>{t('settings.playerCard.paidAmount')}</h5>
          <p>{paidValue}</p>
        </div>
        <div>
          <h5>{t('settings.playerCard.bookingDiscount')}</h5>
          <p>{bookingDiscount}</p>
        </div>
      </div>
    </div>
  )
}

export default HeaderInformation
