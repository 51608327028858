import { getHoursMinsFromISOString } from '@sweetspot/sweetspot-js/common/functions/dateUtils'
const MONDAY = 1
const FULL_WEEK_LENGTH = 7

const getWeekPeriodOptions = (timePeriod, t) => {
  if (!timePeriod?.id) return []

  const [startHours, startMinutes] = getHoursMinsFromISOString(timePeriod.start_time_from)
  const [endHours, endMinutes] = getHoursMinsFromISOString(timePeriod.start_time_to)
  const { start_date, end_date } = timePeriod
  const startDate = new Date(start_date.substr(0, 10))
  const endDate = new Date(end_date.substr(0, 10))

  let weekPeriodOptions = []

  let currentDate = new Date(startDate)
  while (currentDate.getDay() !== MONDAY) {
    currentDate.setDate(currentDate.getDate() - 1)
  }

  const allPeriodToDate = new Date(currentDate)
  allPeriodToDate.setDate(allPeriodToDate.getDate() + FULL_WEEK_LENGTH)

  const allPeriod = {
    id: -1,
    name: t('editPeriodsNew.allPeriod'),
    fromDate: startDate.toISOString(),
    toDate: endDate.toISOString(),
  }

  while (currentDate <= endDate) {
    const sd = new Date(currentDate)
    currentDate.setDate(currentDate.getDate() + (FULL_WEEK_LENGTH - 1))
    const ed = new Date(currentDate)
    weekPeriodOptions.push({
      startDate: sd.toISOString().substring(0, 10),
      endDate: ed.toISOString().substring(0, 10),
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return !timePeriod.period_price_status && weekPeriodOptions.length > 1
    ? [allPeriod]
    : weekPeriodOptions.map((opt, idx) => {
        const splitStartDate = opt.startDate.split('-')
        const splitEndDate = opt.endDate.split('-')

        let fromDate = new Date(splitStartDate[0], splitStartDate[1] - 1, splitStartDate[2])
        let toDate = new Date(splitEndDate[0], splitEndDate[1] - 1, splitEndDate[2])
        fromDate.setHours(startHours)
        fromDate.setMinutes(startMinutes)
        toDate.setHours(endHours)
        toDate.setMinutes(endMinutes)

        if (idx === 0) {
          fromDate = startDate
          fromDate.setHours(startHours)
          fromDate.setMinutes(startMinutes)
        }

        if (idx === weekPeriodOptions.length - 1) {
          toDate = endDate
          toDate.setHours(endHours)
          toDate.setMinutes(endMinutes)
        }

        return {
          id: idx + 1,
          name: `W${idx + 1} ${opt.startDate} - ${opt.endDate}`,
          fromDate,
          toDate,
        }
      })
}

export default getWeekPeriodOptions
