import { ChangeEvent, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { useToasts } from 'react-toast-notifications'
import { t } from 'i18next'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import {
  Input,
  InputBase,
  InputLeadingContainer,
  InputContainer,
  Button,
} from '@sweetspot/scramble-ds'
import CopyLinkIcon from '@sweetspot/club-portal-legacy/resources/images/copy_link_icon.svg'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

import BookingsTable from './components/BookingsTable'
import {
  searchForPlayer,
  searchForPlayerRelatedBookings,
  filterBookings,
  getShareLink,
} from './utils'
import { GolfCourse, TeeTime } from '@sweetspot/shared/types'

interface SearchBookingsProps {
  lang: string
  teeTimes: TeeTime[]
  currentDate: Date
  selectedGolfCourse: GolfCourse
  relatedGolfClubId: number
  handleSearchResultClick: (teeTime: TeeTime) => void
}

interface FilteredBookingsProps {
  id: number
  name: string
  golfId: string
  day: string
  time: string
  startTime: Date
  courseUuid: string
  course: string
}

export const SearchBookings = ({
  lang,
  teeTimes,
  currentDate,
  selectedGolfCourse,
  relatedGolfClubId,
  handleSearchResultClick,
}: SearchBookingsProps): JSX.Element => {
  const { addToast } = useToasts()
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [bookings, setBookings] = useState<FilteredBookingsProps[]>([])
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [shareLink, setShareLink] = useState<string>('')

  useEffect(() => {
    if (searchValue) setSearchValue('')
    if (selectedGolfCourse) {
      const assignShareLink = async () => {
        const link = await getShareLink(selectedGolfCourse, currentDate, lang)
        if (link) {
          const baseUrl = window.location.origin
          setShareLink(`${baseUrl}/tee-sheet-links/${link}`)
        }
      }

      assignShareLink()
    }
  }, [selectedGolfCourse?.uuid, currentDate])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value
    setSearchValue(inputValue)
  }

  const handleSearchBtnClick = async () => {
    if (!searchValue || searchValue.length < 3) return
    setLoading(true)
    let searchProps = null
    let bookingsToDisplay: FilteredBookingsProps[] = []

    const golfIdCheckPattern = /^\d{6}-\d{3}$/
    const isValidGolfId = golfIdCheckPattern.test(searchValue)

    const value = searchValue.replace(/\s/g, '')

    if (Utilities.isAPhoneNumber(value)) {
      searchProps = { phone: value, relatedGolfClub: relatedGolfClubId }
    } else {
      searchProps = { golfId: value, relatedGolfClub: relatedGolfClubId }
    }

    // Unexpected search value entered
    if (!searchProps) {
      setLoading(false)
      setSearchValue('')
      addToast('Invalid search value', { appearance: 'error' })
      return
    }
    // Search for player
    const playerResponse = await searchForPlayer(searchValue, searchProps)
    if (!playerResponse) {
      setSearchValue('')
      addToast('Player not found', { appearance: 'error' })
      setLoading(false)
      return
    }
    // Search for player related bookings
    const bookings = await searchForPlayerRelatedBookings(playerResponse[0])
    if (!bookings) {
      setSearchValue('')
      setLoading(false)
      addToast('No bookings found', { appearance: 'error' })
      return
    }

    if (playerResponse.length > 0 && bookings?.length > 0) {
      const filteredBookings = filterBookings(bookings, playerResponse[0])
      if (filteredBookings.length > 0) {
        bookingsToDisplay = filteredBookings
      } else {
        setSearchValue('')
        addToast('No bookings found', { appearance: 'error' })
      }
    }

    setSearchValue('')
    setLoading(false)
    setBookings(bookingsToDisplay)
  }

  const handleTableRowClick = (rowData: FilteredBookingsProps) => {
    if (!rowData || !rowData.startTime || !rowData.golfId || !rowData?.courseUuid) return

    const timestamp = moment.utc(rowData.startTime).unix()
    const teeTime = teeTimes.find((time) => moment.utc(time.from).unix() === timestamp)

    if (!teeTime) return
    setBookings([])
    handleSearchResultClick(teeTime)
  }

  const handleCopy = () => {
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 5000)
  }

  const renderSearchResults = () => {
    return (
      <div className="rounded-lg">
        <BookingsTable bookings={bookings} onRowClick={handleTableRowClick} />
      </div>
    )
  }

  return (
    <div onClick={() => setBookings([])}>
      <div className="flex items-center">
        <InputBase className="w-[300px]">
          <InputContainer>
            <InputLeadingContainer>
              <i className="fa-solid fa-magnifying-glass" />
            </InputLeadingContainer>
            <Input
              onChange={handleInputChange}
              value={searchValue}
              className="h-[40px] w-[300px] border-none bg-[#e4e4e459] px-10 py-1"
              rounding="pill"
              placeholder={t('searchByPhoneOrGolfId')}
            />
          </InputContainer>
        </InputBase>
        <Button
          onClick={handleSearchBtnClick}
          disabled={loading}
          size="small"
          variant="primary"
          className="system-button info-outline md-32 ml-2"
        >
          {loading ? <i className="fas fa-spinner fa-pulse" /> : <span>{t('search')}</span>}
        </Button>
        <div
          className="ml-[25px] cursor-pointer"
          onClick={() => track(AMPLITUDE_EVENTS.COPY_LINK_TO_TEE_SHEET_TAPPED)}
        >
          <CopyToClipboard text={shareLink} onCopy={handleCopy}>
            <div className="w-[20px]">
              <img
                data-tip
                data-tooltip-id="clipboard_copy"
                alt="Clipboard-icon"
                src={CopyLinkIcon}
              />
            </div>
          </CopyToClipboard>
          <Tooltip className="z-[100]" id="clipboard_copy" place="right" effect="solid">
            <Text textId={isCopied ? 'teeSheet.copied' : 'teeSheet.copyLink'} />
          </Tooltip>
        </div>
      </div>
      {bookings.length > 0 && renderSearchResults()}
    </div>
  )
}
