import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'

/**
 * Gets bookings for courseUuid, and filters from startTime to endTime
 *
 * @param {*} params
 */
export const fetchBookings = (
  query = {
    'course.uuid': null,
    'course.uuid[]': null,
    state: null,
    'state[]': null,
    'spaces.uuid': null,
    'customer.uuid': null,
    'customer.uuid[]': null,
    'booking.start_time[before]': null,
    'booking.start_time[strictly_before]': null,
    'booking.start_time[after]': null,
    'booking.start_time[strictly_after]': null,
    'booking.status': null,
    'order[booking.start_time]': null,
    'groups[]': null,
    page: 1,
    limit: 100,
  },
  ...args
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/bookings`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}

/**
 * Gets single booking by uuid
 *
 * @param {string} bookingUuid
 */
export const fetchBooking = (bookingUuid, query = { 'groups[]': null }) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/bookings/${bookingUuid}`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get single booking items
 *
 * @param {string} bookingUuid
 * @returns
 */
export const fetchBookingItems = (bookingUuid, query = { 'groups[]': null }) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/bookings/${bookingUuid}/items`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get single booking payments
 *
 * @param {string} bookingUuid
 * @returns
 */
export const fetchBookingPayments = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/payments`
  return request(url)
}

/**
 * Get single booking refunds
 *
 * @param {string} bookingUuid
 * @returns
 */
export const fetchBookingRefunds = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/refunds`
  return request(url)
}

/**
 * Get single booking promotions
 *
 * @param {string} bookingUuid
 * @returns
 */
export const fetchBookingPromotions = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/promotions`
  return request(url)
}

/**
 * Get single booking spaces
 *
 * @param {string} bookingUuid
 * @returns
 */
export const fetchBookingSpaces = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/spaces`
  return request(url)
}

/**
 * Reserves booking on tee-time
 *
 * @param {string} teeTimeUuid
 * @param {function} delayHeadersHandler
 */
export const reserveBooking = (
  teeTimeUuid,
  payload = { slots_number: null, partnership: null },
  delayHeadersHandler
) => {
  const url = `${getBaseUrlPlatform()}/tee-times/${teeTimeUuid}/reserve`

  if (!payload.partnership) {
    delete payload.partnership
  }

  return request(url, { method: 'PUT', body: payload, retryConflict: false }, (headers, data) => {
    if (delayHeadersHandler) delayHeadersHandler(headers, data)
  })
}

/**
 * Reserves booking with spaces
 *
 * @param {Object} payload - Payload
 * @param {string} payload.from - From date string
 * @param {string} payload.to - To date string
 * @param {string} payload.partnership - Partnership uuid
 * @param {Object[]} payload.data - Spaces and tee times
 * @param {string} payload.data[].space_uuid - Space uuid
 * @param {Object[]} payload.data[].tee_times - Array of tee times
 * @param {string} payload.data[].tee_times[].uuid - Tee time uuid
 * @param {number} payload.data[].tee_times[].price - Price of tee time
 * @param {Function} delayHeadersHandler
 * @returns
 */
export const reserveBookingWithSpaces = (payload, delayHeadersHandler) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings`

  return request(
    url,
    {
      method: 'POST',
      body: payload,
    },
    (headers, data) => {
      if (delayHeadersHandler) delayHeadersHandler(headers, data)
    }
  )
}

export const addSpacesToBooking = (payload, bookingId, delayHeadersHandler) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/${bookingId}/add-spaces`

  return request(
    url,
    {
      method: 'PUT',
      body: payload,
    },
    (headers, data) => {
      if (delayHeadersHandler) delayHeadersHandler(headers, data)
    }
  )
}

export const changeTime = (payload, bookingId, delayHeadersHandler) => {
  const url = `${getBaseUrlPlatform()}/orders/${bookingId}/change-time`

  return request(
    url,
    {
      method: 'PUT',
      body: payload,
    },
    (headers, data) => {
      if (delayHeadersHandler) delayHeadersHandler(headers, data)
    }
  )
}

export const removeSpaceFromBooking = (payload, bookingId, delayHeadersHandler) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/${bookingId}/remove-spaces`

  return request(
    url,
    {
      method: 'PUT',
      body: payload,
    },
    (headers, data) => {
      if (delayHeadersHandler) delayHeadersHandler(headers, data)
    }
  )
}

/**
 * Assigns player to a slot
 *
 * @param {string} slotUuid
 * @param {string} playerUuid
 */
export const assignPlayerToSlot = (
  slotUuid,
  playerUuid,
  overrideSimBooking = false,
  muteNotification = false,
  overridePolicyViolations = null
) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/add-player`
  let headers = {}
  if (muteNotification) headers = { 'X-Mute-Notification': 1 }
  return request(url, {
    method: 'PUT',
    body: {
      player_id: playerUuid,
      override_simultaneous_booking: overrideSimBooking,
      override_violations: overridePolicyViolations,
    },
    headers,
  })
}

/**
 * Assigns stub player to a slot
 *
 * @param {string} slotUuid
 * @param {string} playerName
 */
export const assignStubPlayerToSlot = (
  slotUuid,
  payload = {
    name: '',
    email: '',
    phone: '',
  },
  muteNotification = false
) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/add-stub-player`
  let headers = {}
  if (muteNotification) headers = { 'X-Mute-Notification': 1 }
  return request(url, {
    method: 'PUT',
    body: { ...payload },
    headers,
  })
}

/**
 * Sets player as owner of slot
 *
 * @param {string} slotUuid
 * @param {string} playerUuid
 */
export const setSlotOwner = (slotUuid) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/set-ownership`
  return request(url, { method: 'PUT' })
}

/**
 * Set golf cart status
 * *LEGACY*
 *
 * @param {string} slotUuid
 * @param {string} status
 * @returns
 */
export const setGolfCartStatus = (slotUuid, status) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/golf-cart-status`
  return request(url, { method: 'PUT', body: { status } })
}

/**
 * Sets booking owner
 *
 * @param {number} bookingId
 * @param {string} ownerUuid
 * @param {boolean} overrideSimBooking
 * @param {boolean} overridePolicyViolations
 * @returns
 */
export const setBookingOwner = (
  bookingId,
  ownerUuid,
  overrideSimBooking = false,
  overridePolicyViolations = null
) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/${bookingId}/owner`
  return request(url, {
    method: 'PUT',
    body: {
      owner: ownerUuid,
      override_simultaneous_booking: overrideSimBooking,
      override_violations: overridePolicyViolations,
    },
  })
}

/**
 * Add stub player to booking
 *
 * @param {number} bookingUuid
 * @param {Object} stubPlayer
 * @param {string} stubPlayer.name
 * @param {string} stubPlayer.phone
 * @param {string} stubPlayer.email
 * @returns
 */
export const addStubPlayerToOrder = (bookingUuid, stubPlayer) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/add-stub-player`
  return request(url, { method: 'PUT', body: stubPlayer })
}

/**
 * Confirms booking
 *
 * @param {string} bookingUuid
 */
export const confirmOrderBooking = (bookingUuid, delayHeadersHandler = null, muteNotification) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/confirm`
  let headers = {}
  if (muteNotification) headers = { 'X-Mute-Notification': 1 }
  return request(url, { method: 'PUT', headers }, delayHeadersHandler)
}

/**
 * Cancels order-booking
 *
 * @param {string} bookingUuid
 */
export const cancelOrderBooking = (
  bookingUuid,
  muteNotification,
  overridePolicyViolations = undefined
) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/cancel`
  let headers = {}
  if (muteNotification) headers = { 'X-Mute-Notification': 1 }
  return request(url, {
    method: 'PUT',
    headers,
    body: {
      override_violations: overridePolicyViolations,
    },
  })
}

/**
 * Removes slot from booking
 *
 * @param {string} slotUuid
 */
export const removeSlot = (slotUuid, muteNotification = false) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}`
  let headers = {}
  if (muteNotification) headers = { 'X-Mute-Notification': 1 }
  return request(url, { method: 'DELETE', headers })
}

/**
 * Reserved extra slots on order booking
 *
 * @param {string} bookingUuid
 * @param {number} numberOfSlots
 * @param {number} teeTimePrice
 */
export const reserveExtraSlots = (bookingUuid, numberOfSlots, teeTimePrice) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/reserve`
  return request(url, {
    method: 'PUT',
    body: { slots_number: numberOfSlots, tee_time_price: teeTimePrice },
  })
}

/**
 * Updates slot arrival registration status
 *
 * @param {string} slotUuid
 * @param {boolean} newStatus
 */
export const updateArrivalRegistration = (slotUuid, newStatus) => {
  let url = ''

  if (newStatus === true) {
    url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/mark-arrival-registration`
  } else if (newStatus === false) {
    url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/unmark-arrival-registration`
  }

  if (!url) throw new Error('Invalid new status')

  return request(url, { method: 'PUT' })
}

/**
 * Adds manual adjustment to order item
 *
 * @param {number|string} orderItemId
 * @param {string} label
 * @param {number} amount
 * @param {boolean} neutral
 * @param {boolean} locked
 * @param {string} origin_code
 * @param {{}} details
 */
export const addAdjustmentToOrderItem = (
  orderItemId,
  label,
  amount,
  neutral = false,
  locked = true,
  origin_code = null,
  details = null
) => {
  const url = `${getBaseUrlPlatform()}/bookings/items/${orderItemId}/adjustments`
  return request(url, {
    method: 'PUT',
    body: {
      type: 'order_item_promotion',
      label,
      amount,
      neutral,
      locked,
      origin_code,
      details,
    },
  })
}

/**
 * Add manual adjustment to order
 *
 * @param {string} bookingUuid
 * @param {string} label
 * @param {number} amount
 * @param {boolean} neutral
 * @param {boolean} locked
 * @param {string} origin_code
 * @param {{}} details
 */
export const addAdjustmentToOrder = (
  bookingUuid,
  label,
  amount,
  neutral = false,
  locked = true,
  origin_code = null,
  details = null
) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/adjustments`
  return request(url, {
    method: 'PUT',
    body: {
      type: 'order_promotion',
      label,
      amount,
      neutral,
      locked,
      origin_code,
      details,
    },
  })
}

/**
 * Delete adjustment from order-item
 *
 * @param {string|number} orderItemId
 * @param {string|number} adjustmentId
 */
export const deleteAdjustmentFromOrderItem = (orderItemId, adjustmentId) => {
  const url = `${getBaseUrlPlatform()}/bookings/items/${orderItemId}/adjustments/remove`
  return request(url, { method: 'PUT', body: { adjustment: adjustmentId } })
}

/**
 * Delete adjustment from order
 *
 * @param {string} bookingUuid
 * @param {string|number} adjustmentId
 */
export const deleteAdjustmentFromOrder = (bookingUuid, adjustmentId) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/remove-adjustments`
  return request(url, { method: 'PUT', body: { adjustment: adjustmentId } })
}

/**
 * Assign coupon/voucher to order
 *
 * @param {string} bookingUuid
 * @param {string} couponCode
 */
export const assignCouponToOrder = (bookingUuid, couponCode) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/assign-coupons`
  return request(url, { method: 'PUT', body: { code: couponCode } })
}

/**
 * Unassign coupon/voucher from order
 *
 * @param {string} bookingUuid
 * @param {string} couponCode
 */
export const removeCouponFromOrder = (bookingUuid, couponCode) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/remove-coupons`
  return request(url, { method: 'PUT', body: { code: couponCode } })
}

/**
 * Assign coupon/voucher to order item
 * Deprecated API, instead use `assignCouponToOrder`
 *
 * @param {string|number} itemId
 * @param {string} couponCode
 */
export const assignCouponToOrderItem = (itemId, couponCode) => {
  const url = `${getBaseUrlPlatform()}/order-items/${itemId}/assign-coupons`
  return request(url, { method: 'PUT', body: { code: couponCode } })
}

/**
 * Unassign coupon/voucher from order item
 * Deprecated API, instead use `removeCouponFromOrder`
 *
 * @param {string|number} itemId
 * @param {string} couponCode
 */
export const removeCouponFromOrderItem = (itemId, couponCode) => {
  const url = `${getBaseUrlPlatform()}/order-items/${itemId}/remove-coupons`
  return request(url, { method: 'PUT', body: { code: couponCode } })
}

/**
 * Updates booking pay on site status
 *
 * @param {string} bookingUuid
 * @param {bool} payOnSiteValue
 */
export const updateBookingPayOnSiteStatus = (bookingUuid, payOnSiteValue = true) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/pay-on-site`
  return request(url, { method: 'PUT', body: { is_pay_on_site: payOnSiteValue } })
}

/**
 * Marks booking as paid
 *
 * @param {string} bookingUuid
 */
export const markBookingAsPaid = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/mark-as-paid`
  return request(url, { method: 'PUT' })
}

/**
 * Moves booking to specified tee time
 *
 * @param {string} bookingUuid
 * @param {string} teeTimeUuid
 */
export const moveBookingToTeeTime = (bookingUuid, teeTimeUuid, muteNotification) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/move`
  let headers = {}
  if (muteNotification) headers = { 'X-Mute-Notification': 1 }
  return request(url, { method: 'PUT', body: { to_tee_time: teeTimeUuid }, headers })
}

/**
 * Assigns partnership to booking
 *
 * @param {string} bookingUuid
 * @param {string} partnershipUuid
 */
export const assignPartnershipToBooking = (bookingUuid, partnershipUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/assign-partnerships`
  return request(url, { method: 'PUT', body: { partnership: partnershipUuid } })
}

/**
 * Unassigns partnership from booking
 *
 * @param {string} bookingUuid
 */
export const removePartnershipFromBooking = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/remove-partnerships`
  return request(url, { method: 'PUT' })
}

/**
 * Set slot partner type
 *
 * @param {string} slotUuid
 * @param {string} partnerTypeUuid
 */
export const setSlotPartnerType = (slotUuid, partnerTypeUuid) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/set-partner-type`
  return request(url, { method: 'PUT', body: { partner_type: partnerTypeUuid } })
}

/**
 * Remove slot partner type
 *
 * @param {string} slotUuid
 */
export const removeSlotPartnerType = (slotUuid) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots/${slotUuid}/remove-partner-type`
  return request(url, { method: 'PUT' })
}

/**
 * Validate simultaneous booking of a player
 *
 * @param {string} slotUuid
 * @param {string} playerUuid
 */
export const validateSimultaneousBookings = (slotUuid, playerUuid) => {
  const url = `${getBaseUrlPlatform()}/crm/slots/validate-simultaneous-bookings`
  return request(url, { method: 'POST', body: { player: playerUuid, slot: slotUuid } })
}

/**
 * Creates adjustment for in order item to match new sent item price
 *
 * @param {string} orderItemUuid
 * @param {number} newPrice
 */
export const setItemPrice = (orderItemUuid, newPrice) => {
  const url = `${getBaseUrlPlatform()}/bookings/items/${orderItemUuid}/adjustments/price`
  return request(url, { method: 'PUT', body: { new_price: newPrice } })
}

/**
 * Download receipt as PDF from 360 on paid by card bookings
 *
 * @param {number|string} id (payment id)
 * @param {string} type (payment type)
 * @param {string} token
 */
export const downloadReceiptAsPDF = (id, type) => {
  const url = `${getBaseUrlPlatform()}/${type}/${id}/receipt`

  // return fetch(url, { headers: { Authorization: `Bearer ${token}` } });
  return request(url, { rawResponse: true })
}

/**
 * Gets client token
 *
 * @param {string} bookingUuid
 * @returns {Promise}
 */
export const getClientToken = (bookingUuid) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/client-token`
  return request(url, { method: 'PUT' })
}

/**
 *
 * @param {string} bookingUuid
 * @param {Object} payload
 * @param {Object} payload.parameters
 * @param {string} payload.parameters.paymentMethodNonce
 * @param {string} payload.method
 * @returns {Promise}
 */
export const payForOrder = (bookingUuid, payload) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/pay`
  return request(url, { method: 'PUT', body: payload })
}

/**
 * Add refund to order item
 *
 * @param {string} bookingUuid
 * @param {string} orderItemUuid
 * @param {number} amount
 */
export const addRefundToItem = (bookingUuid, orderItemUuid, amount) => {
  const url = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/refund`
  return request(url, { method: 'PUT', body: { data: [{ item_id: orderItemUuid, amount }] } })
}

/**
 * Query refunds
 *
 */
export const queryRefunds = (
  query = {
    order: null,
    'order[]': null,
    refund_payment: null,
    'refund_payment[]': null,
    page: null,
    limit: null,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/refunds`,
      query,
    },
    {
      skipNull: true,
    }
  )

  return request(url)
}

/**
 * Get periods by golf course id
 *
 * @param {string|number} id
 */
export const getBookingPeriodsByGolfCourseId = (id) => {
  const url = `${getBaseUrlPlatform()}/periods?course.id=${id}&order[start_date]=DESC`
  return request(url)
}

/**
 * Add booking period override
 *
 * @param {string} periodUuid
 * @param {Object} payload
 */
export const addBookingPeriodOverride = (periodUuid, payload) => {
  const url = `${getBaseUrlPlatform()}/crm/periods/${periodUuid}/overrides`

  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Query no show slots for club
 */
export const getNoShowSlots = (club, query) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/slots${query}`

  return request(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/ld+json',
    },
  })
}

/**
 * Reserves booking with spaces
 *
 * @param {number} bookingId - Booking ID
 * @param {Object} payload - Payload
 * @param {Object[]} payload.data - Spaces and tee times
 * @param {string} payload.data[].space_uuid - Space uuid
 * @returns
 */

export const removeSpacesFromBooking = (bookingId, payload) => {
  const url = `${getBaseUrlPlatform()}/legacy/bookings/${bookingId}/remove-spaces`

  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Query Booking Notes
 *
 * @returns
 */

export const getBookingNotes = (
  query = {
    'booking.uuid': null,
    'booking.uuid[]': null,
    'order[created_at]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/bookings/notes`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, {
    method: 'GET',
  })
}

/**
 * Create Booking Note
 *
 * @param {Object} payload
 * @param {string} payload.note
 * @param {Object} payload.author
 * @param {string} payload.author.uuid
 * @param {Object} payload.booking
 * @param {string} payload.booking.uuid
 * @returns
 */
export const createBookingNote = (payload) => {
  const URL = `${getBaseUrlPlatform()}/bookings/notes`
  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

/**
 * Update Booking Note
 *
 * @returns
 */
export const updateBookingNote = (noteID, text) => {
  const URL = `${getBaseUrlPlatform()}/bookings/notes/${noteID}`
  return request(URL, {
    method: 'PUT',
    body: {
      note: text,
    },
  })
}

/**
 * Remove Booking Note
 *
 * @returns
 */
export const removeBookingNote = (noteID) => {
  const URL = `${getBaseUrlPlatform()}/bookings/notes/${noteID}`
  return request(URL, {
    method: 'DELETE',
  })
}

/**
 * Makes a simulated price calculation for a booking (does not update the actual booking data)
 * @param bookingUuid
 * @param payload
 * @returns @see {CalculatePriceResponse}
 */
export const calculatePrice = (bookingUuid, payload) => {
  const URL = `${getBaseUrlPlatform()}/bookings/${bookingUuid}/calculate-price`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

/**
 * Gets web tee sheet bookings for courseUuid, and filters from startTime to endTime
 *
 * @param {*} params
 */
export const getWebTeeSheetBookings = (
  query = {
    'course.uuid': null,
    'course.uuid[]': null,
    state: null,
    'spaces.uuid': null,
    'customer.uuid': null,
    'customer.uuid[]': null,
    'booking.start_time[before]': null,
    'booking.start_time[strictly_before]': null,
    'booking.start_time[after]': null,
    'booking.start_time[strictly_after]': null,
    'booking.status': null,
    'order[booking.start_time]': null,
    'groups[]': null,
    page: 1,
    limit: 100,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/tee-sheet/web/bookings`,
      query,
    },
    {
      skipNull: true,
    }
  )

  return request(url)
}
