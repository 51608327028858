import { useCallback, useState } from 'react'
import {
  OPENING_HOURS,
  BUCKET_DATA,
  BUCKET_SIZES,
  BALL_PRICE,
  BALL_PRICE_DATA,
  PRICE_ROUNDING,
  PRICE_ROUNDING_DATA,
} from '../mockData'
import { BallPrice, BucketSize, OpeningHours } from '../types'

const useBucketConfigurationSettings = () => {
  const [openingHours, setOpeningHours] = useState<OpeningHours>(OPENING_HOURS)
  const [bucketSizes, setBucketSizes] = useState<BucketSize[]>(BUCKET_SIZES)
  const [ballPrice, setBallPrice] = useState<BallPrice>(BALL_PRICE)
  const [priceRounding, setPriceRounding] = useState<number>(PRICE_ROUNDING)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const handleSetOpeningHours = useCallback((newData: OpeningHours) => {
    setOpeningHours((prev) => ({
      ...prev,
      ...Object.entries(newData).reduce(
        (acc, [day, values]) => ({
          ...acc,
          [day]: { ...prev[day], ...values },
        }),
        {}
      ),
    }))
  }, [])

  const handleAddBucketSize = useCallback(() => {
    setBucketSizes((prev) => [
      ...prev,
      {
        nrOfBalls: 25,
        discount: 0,
      },
    ])
  }, [])

  const handleRemoveBucketSize = useCallback((index: number) => {
    setBucketSizes((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const handleSetBucketSizes = useCallback((newData: BucketSize, index: number) => {
    setBucketSizes((prev) => {
      const newSizes = [...prev]
      newSizes[index] = { ...newSizes[index], ...newData }
      return newSizes
    })
  }, [])

  const handleSetBallPrice = useCallback((newData: BallPrice) => {
    setBallPrice((prev) => ({
      ...prev,
      ...Object.entries(newData).reduce(
        (acc, [tech, prices]) => ({
          ...acc,
          [tech]: { ...prev[tech], ...prices },
        }),
        {}
      ),
    }))
  }, [])

  const handleOnSave = useCallback(async () => {
    setIsUpdating(true)
    // TODO: add price update logic
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setIsUpdating(false)
        resolve()
      }, 2000)
    })
  }, [])

  const handleGetBucketConfiguration = useCallback(async () => {
    setIsFetching(true)
    // TODO: add price init logic
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setIsFetching(false)
        resolve()
      }, 2000)
    })
  }, [])

  return {
    openingHours,
    setOpeningHours: handleSetOpeningHours,
    bucketSizes,
    setBucketSizes: handleSetBucketSizes,
    addBucketSize: handleAddBucketSize,
    removeBucketSize: handleRemoveBucketSize,
    nrOfBallsOptions: BUCKET_DATA.nrOfBalls,
    discountsOptions: BUCKET_DATA.discount,
    ballPrice,
    setBallPrice: handleSetBallPrice,
    ballPriceData: BALL_PRICE_DATA,
    priceRounding,
    setPriceRounding,
    priceRoundingData: PRICE_ROUNDING_DATA,
    onSave: handleOnSave,
    getBucketConfiguration: handleGetBucketConfiguration,
    isUpdating,
    isFetching,
  }
}

export default useBucketConfigurationSettings
