import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import useVoucher from '@sweetspot/sweetspot-js/features/vouchers/hooks/useVoucher'
import cx from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { VoucherPromotionProps } from '../../types'
import RulesTable from '../RulesTable'
import TableCell from '../TableCell/TableCell'
import styles from './styles.module.scss'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'

const VoucherPromotion: React.FC<VoucherPromotionProps> = ({
  getVoucherConfig,
  voucher,
  selectedGolfClubId,
}) => {
  const shouldShowPromotion = !!voucher.promotion
  const shouldShowActions = !!voucher.promotion.actions
  const [isOpen, setIsOpen] = useState(false)
  const { voucher: voucherData } = useVoucher(voucher.promotion.id, selectedGolfClubId)
  const [currency] = useClubCurrency()
  const { t } = useTranslation()

  const appliesTo = useMemo(() => {
    if (voucher && voucher?.promotion?.actions?.length > 0) {
      const resultArr =
        voucher?.promotion?.actions[0]?.configuration?.filter?.slot_filter.slot_type_query.map(
          (type: string) => {
            return t(`settings.playerCard.voucher.applies_to_options.${type}`)
          }
        )
      if (voucher?.promotion?.actions[0]?.configuration?.filter?.golf_cart_filter) {
        resultArr.push(t('settings.playerCard.voucher.applies_to_options.golf_cart_filter'))
      }
      return resultArr.join(', ')
    }
    return ''
  }, [voucher])

  const shouldDisplayRules = voucherData && voucherData.rules.length > 0

  const voucherConfig = getVoucherConfig(voucher)
  const isDiscountedTypeUses = voucherConfig && Object.keys(voucherConfig).includes('uses_to')

  const formatVoucherDiscount = () => {
    return voucherConfig && typeof voucherConfig.percentage_coefficient === 'number'
      ? `${voucherConfig.percentage_coefficient * 100} %`
      : '-'
  }

  const formatVoucherConfig = () => {
    const usedVoucherValue =
      voucher?.promotion?.actions && voucher?.promotion?.actions?.length > 0
        ? voucher?.promotion?.actions[0]?.used
        : 0

    if (isDiscountedTypeUses && voucherConfig?.uses_to) {
      return `${voucherConfig.uses_to - usedVoucherValue} / ${voucherConfig.uses_to} ${t(
        'settings.playerCard.voucher.uses'
      )}`
    }

    const discountedValue = voucherConfig?.discounted_value_to
      ? voucherConfig.discounted_value_to
      : 0

    return `${priceToLocal(discountedValue - usedVoucherValue, currency || '')} / ${priceToLocal(
      discountedValue,
      currency || ''
    )}`
  }

  return (
    <div className="min-w-[470px] rounded-md border border-[solid] bg-gray-50">
      <h3 className="font-roboto  border-b-2 border-b-[solid] px-3.5 py-1 text-left text-sm font-bold">
        {voucher?.promotion?.name}
      </h3>
      <div className="pr-3.5">
        <table className="my-2 w-full">
          <tr>
            {shouldShowPromotion && (
              <TableCell cellType="th">
                {t('settings.playerCard.voucher.percentageDiscount')}
              </TableCell>
            )}
            {shouldShowActions && (
              <>
                <TableCell cellType="th">{t('settings.playerCard.voucher.remaining')}</TableCell>
                <TableCell cellType="th">{t('settings.playerCard.voucher.minPrice')}</TableCell>
              </>
            )}
            <TableCell cellType="th">{t('settings.playerCard.voucher.appliesTo')}</TableCell>
          </tr>
          <tr>
            <TableCell>{formatVoucherDiscount()}</TableCell>
            <TableCell>{formatVoucherConfig()}</TableCell>
            <TableCell>
              {priceToLocal(voucherConfig?.minimum_fee_total_amount || 0, currency || '')}
            </TableCell>
            <TableCell className={cx('relative w-full', styles.playerCell)} rowSpan={1}>
              <div className="bg-gray-100 px-2 py-1">{appliesTo}</div>
            </TableCell>
          </tr>
        </table>
      </div>
      {shouldDisplayRules && (
        <div className="flex flex-row border-t-2 ">
          <div className={cx(styles.container, isOpen && styles.openContainer)}>
            <div className={cx(styles.header)} onClick={() => setIsOpen(!isOpen)}>
              <div className={cx(styles.leftSide)}>
                <div className={cx(styles.caretContainer)}>
                  <CaretDown className={cx(styles.caretDown, isOpen && styles.isOpen)} />
                </div>
                <h3 className="font-roboto px-3.5 py-1 text-left text-sm font-bold">
                  {t('settings.playerCard.voucher.rules')}
                </h3>
              </div>
              <div className={cx(styles.rightSide)}></div>
            </div>
            <div className={cx(styles.foldContainer, isOpen && styles.isOpen)}>
              {isOpen && (
                <div className={cx(styles.topRow, styles.extraMargin)}>
                  <RulesTable voucher={voucher} selectedGolfClubId={selectedGolfClubId} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VoucherPromotion
