import React from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

import styles from './styles.module.scss'

const Payments = () => {
  const { t } = useTranslation()

  return (
    <Container className={styles.container}>
      <EmptyState>
        <EmptyStateTitle>{t('payments.paymentLinks')}</EmptyStateTitle>
        <EmptyStateDescription>{t('payments.paymentEmptyPageInformation')}</EmptyStateDescription>
        <EmptyStateButton>
          <a href="mailto:hello@sweetspot.io">{t('payments.contactUs')}</a>
        </EmptyStateButton>
      </EmptyState>
    </Container>
  )
}

export default Payments
