import i18next from 'i18next'
import moment from 'moment'

export const isSlotObject = (x) => {
  return !!x?.player || !!x?.stub_player
}

export const isItemObject = (x) => {
  return !!x?.slot
}

export const isSlotItem = (x) => {
  if (isItemObject(x) || isSlotObject(x)) return true
  return false
}

export const isCartItem = (x) => {
  if (x?.product_variant?.product?.code === 'golf_cart') return true
  return false
}

export const getBookingSlotItems = (booking) => {
  if (booking?.items?.length > 0) {
    return booking.items.filter((x) => isSlotItem(x))
  }
  return []
}

export const getBookingCartItems = (booking) => {
  if (booking?.items?.length > 0 && booking?.booking?.inventory_course_schedules) {
    // Get inventory item that has the same booking uuid as the booking, otherwise get the first ones
    const product = booking.booking.inventory_course_schedules[0]
    const hold_to = product?.hold_to
    const hold_from = product?.hold_from
    return booking.items
      .filter((x) => isCartItem(x))
      .map((y) => {
        return { ...y, hold_from, hold_to }
      })
  }
  return []
}

export const isItemPaid = (item) => {
  return item?.payment?.state === 'new' ? false : true
}

export const bookingItemCanBeRemoved = (orderBooking, item) => {
  if (isBookingReOpened(orderBooking) && isItemPaid(item)) return false
  if (isBookingCancelled(orderBooking)) return false
  return true
}

export const isBookingItemPartialRefunded = (item) => {
  return item?.payment_state === 'partially_refunded'
}

export const isBookingItemRefunded = (item) => {
  return item?.state === 'refunded'
}

export const isBookingConfirmed = (booking) => {
  return booking?.state === 'new' && booking?.payment_state === 'new' ? false : true
}

export const isBookingReOpened = (booking) => {
  return booking?.state === 'reopened' ? true : false
}

export const isBookingCancelled = (booking, isOrderBooking = true) => {
  if (isOrderBooking) {
    return booking?.state === 'cancelled' || booking?.state === 'canceled' ? true : false
  } else {
    return booking?.status === 'canceled' ? true : false
  }
}

export const isBookingWaitingForPayment = (booking) => {
  return booking?.payment_state === 'awaiting_payment' ? true : false
}

export const isBookingPaid = (booking) => {
  return booking?.payment_state === 'paid' ? true : false
}

export const isBookingPartiallyPaid = (orderBooking) => {
  return orderBooking?.payment_state === 'partially_paid' ? true : false
}

export const isBookingPartiallyRefunded = (booking) => {
  return booking?.payment_state === 'partially_refunded' ? true : false
}

export const isBookingPayOnSite = (booking) => {
  return booking?.payments?.some((payment) => payment?.method?.code === 'on_site')
}

export const isAllPaymentsInProcessing = (payments) => {
  return payments?.every((payment) => payment?.state === 'processing')
}

export const isAnyPaymentProcessingState = (payments) => {
  return payments?.some((payment) => payment?.state === 'processing')
}

export const isBookingConsideredPaid = (booking) => {
  return isBookingPaid(booking) || isBookingPartiallyRefunded(booking) || isBookingRefunded(booking)
    ? true
    : false
}

export const isBookingRefunded = (booking) => {
  return booking?.payment_state === 'refunded' ? true : false
}

export const isBookingPaidByCard = (booking) => {
  let paidByCard = false
  if (booking?.payments) {
    booking.payments.forEach((payment) => {
      if (
        (payment?.method?.code === 'braintree_card_sandbox' ||
          payment?.method?.code === 'braintree_card') &&
        payment?.state === 'completed'
      ) {
        paidByCard = true
      }
    })
  }
  return paidByCard
}

export const isBookingPaidByRefundableMethod = (booking) => {
  const { payments } = booking
  const refunableMethods = {
    card: false,
    googlePay: false,
    applePay: false,
  }
  // NOTE: Move payment method codes to constants
  const cardMethodTitles = [
    'braintree_card_sandbox',
    'braintree_card',
    'adyen_card_sandbox',
    'adyen_card',
  ]
  const googlePayMethodTitles = [
    'braintree_google_pay_sandbox',
    'braintree_google_pay',
    'google_pay',
  ]
  const applePayMethodTitles = ['braintree_apple_pay_sandbox', 'braintree_apple_pay', 'apple_pay']

  if (payments) {
    payments.forEach((payment) => {
      if (payment?.state === 'completed') {
        if (cardMethodTitles.includes(payment?.method?.code)) refunableMethods.card = true
        if (googlePayMethodTitles.includes(payment?.method?.code)) refunableMethods.googlePay = true
        if (applePayMethodTitles.includes(payment?.method?.code)) refunableMethods.applePay = true
      }
    })
  }

  return refunableMethods
}

export const isBookingPostPayPartnership = (booking) => {
  return booking?.partnership?.type === 'postpay' ? true : false
}

export const itemIsAssigned = (item) => {
  let slot
  if (isItemObject(item)) {
    slot = item.slot
  } else if (isSlotObject(item)) {
    slot = item
  } else {
    return false
  }
  return isSlotPlayer(slot) || isSlotStub(slot) || isSlotExtra(slot) ? true : false
}

export const isSlotPlayer = (slot) => {
  return slot?.player?.id ? true : false
}

export const isSlotStub = (slot) => {
  return slot?.type === 'stub_player' ? true : false
}

export const isSlotExtra = (slot) => {
  return slot?.type === 'extra_player' ? true : false
}

export const isBookingFilled = (booking) => {
  if (!booking) return false

  let filtered = booking?.items
    ?.filter((x) => isSlotItem(x))
    ?.map((item) => itemIsAssigned(item)) || [false]

  return filtered.includes(false) ? false : true
}

export const getPlayerName = (player) => {
  return `${player?.first_name || ''} ${player?.last_name || ''}`
}

export const bookingHasAtleastOneAssigned = (booking) => {
  if (!booking || !booking?.items) return null

  const bookingSlotItems = getBookingSlotItems(booking)

  const unassignedItems = bookingSlotItems.filter((x) => !itemIsAssigned(x))
  if (unassignedItems.length >= bookingSlotItems.length) return false
  return true
}

export const bookingHasOwner = (booking) => {
  let hasOwner = false

  const bookingSlotItems = getBookingSlotItems(booking)

  if (bookingSlotItems?.map) {
    bookingSlotItems.forEach((item) => {
      if (item?.slot?.is_owner === true) hasOwner = true
    })
  }
  return hasOwner
}

export const getNumberOfSlots = (booking) => {
  let count = 0

  const bookingSlotItems = getBookingSlotItems(booking)

  if (!bookingSlotItems?.forEach) return count

  bookingSlotItems.forEach((i) => {
    if (isSlotObject(i) || isItemObject(i)) count++
  })

  return count
}

export const isPartner = (booking) => {
  if (booking?.partnership) return true
  return false
}

export const getMembershipName = (item) => {
  return item?.slot?.membership?.name || item?.membership?.name || ''
}

export const hasAR = (item) => {
  if (item?.slot?.is_arrival_registration === true || item?.is_arrival_registration === true)
    return true
  return false
}

export const isStub = (item) => {
  if (item?.slot?.type === 'stub_player' || item?.type === 'stub_player') return true
  return false
}

export const isMember = (item) => {
  if (item?.is_member === true || item?.slot?.is_member === true) return true
  return false
}

export const isOwner = (item) => {
  if (item?.is_owner === true || item?.slot?.is_owner === true) return true
  return false
}

export const isSweetspot = (item) => {
  if (item?.slot?.player?.is_registered === true || item?.player?.is_registered === true)
    return true
  return false
}

export const getItemGender = (item) => {
  if (item?.slot?.player?.gender === 'man' || item?.player?.gender === 'man')
    return i18next.t('words.man')
  if (item?.slot?.player?.gender === 'woman' || item?.player?.gender === 'woman')
    return i18next.t('words.woman')
  return i18next.t('words.other')
}

export const getItemTotal = (item, refunds = null) => {
  let total = item?.total >= 0 ? item.total : '-'

  if (Number.isInteger(total)) {
    if (refunds && refunds?.length > 0) {
      refunds
        .filter((refund) => refund?.order_item?.id === item.id)
        .forEach((refund) => {
          total = total - refund.amount
        })
    }
  }

  return total
}

export const getBookingTotal = (booking, includeRefunds = false) => {
  let total = booking?.total >= 0 ? booking.total : '-'

  if (Number.isInteger(total)) {
    if (includeRefunds && booking?.refund_payments?.length > 0) {
      booking.refund_payments.forEach((refund) => {
        total = total - refund.amount
      })
    }
  }

  return total
}

export const getUnpaidBookingTotal = (booking) => {
  const total = booking.items?.reduce(
    (sum, item) =>
      item.state === 'new' || item.state === 'awaiting_payment' ? sum + item.total : sum,
    0
  )

  return total >= 0 ? total : '-'
}

export const getLatestUnpaidPayment = (orderBooking) => {
  return orderBooking?.payments?.find((p) => p?.state === 'new' || p?.state === 'failed') || null
}

export const isBookingItemPaid = (item) => {
  return item?.payment?.state === 'completed' ? true : false
}

export const bookingStartHasPassed = (orderBooking) => {
  const startTime = orderBooking?.booking?.start_time

  if (startTime) {
    return moment().isAfter(moment.utc(startTime))
  } else {
    return false
  }
}

export const bookingEndHasPassed = (orderBooking) => {
  const endTime = orderBooking?.booking?.end_time

  if (endTime) {
    return moment().isAfter(moment.utc(endTime))
  } else {
    return false
  }
}

export const getOwnerName = (orderBooking) => {
  const ownerItem = orderBooking?.items?.find((x) => isOwner(x))
  if (!ownerItem) return ''

  const { player } = ownerItem.slot

  if (player?.first_name || player?.last_name) {
    return `${player?.first_name || ''} ${player?.last_name || ''}`
  }
  return ''
}

export const isPlayerIdBookingOwner = (playerId, orderBooking) => {
  const ownerItem = orderBooking?.items?.find((x) => isOwner(x))
  if (ownerItem) {
    return ownerItem?.slot?.player?.id === playerId
  }
  return false
}

export const isPlayerIdBookingParticipant = (playerId, orderBooking) => {
  const playerItem = orderBooking?.items?.find((x) => x?.slot?.player?.id === playerId)
  if (playerItem) return true
  return false
}

export const isParticipantIdBookingParticipant = (participantId, orderBooking) => {
  const slotItem = orderBooking?.items?.find((x) => x?.slot?.id === participantId)
  if (slotItem) return true
  return false
}

export const getAdjustmentPercentage = (adjustment) => {
  if (adjustment?.details?.type && adjustment?.details?.configuration) {
    const { type, configuration } = adjustment.details
    if (type === 'greenfee_member_percentage_discount') {
      return (configuration.percentage_coefficient * 100).toFixed()
    }
    if (type === 'greenfee_guest_percentage_discount') {
      return (configuration.percentage_coefficient * 100).toFixed()
    }
  }
  return ''
}

export const getAdjustmentLabel = (adjustment) => {
  if (adjustment?.type === 'order_item_new_price_adjustment') {
    return i18next.t('sentences.priceAdjustment')
  }

  return adjustment.label
}

export const getVoucherPercentage = (voucher) => {
  const percentage = voucher?.promotion?.actions?.find(
    (x) => x?.type === 'item_percentage_discount_with_uses_limitation'
  )?.configuration?.percentage_with_uses?.[0]?.percentage_coefficient

  if (percentage) {
    return (percentage * 100).toFixed()
  }
  return null
}

export const getItemByPlayerId = (bookingItems, playerId) => {
  return bookingItems.find((x) => x?.slot?.player?.id === playerId)
}

export const getTeeTimePriceCurrency = (teeTimePrice) => {
  return teeTimePrice?.price?.amount !== undefined && teeTimePrice?.price?.amount !== null
    ? teeTimePrice?.price?.currency
    : teeTimePrice?.dynamic_price?.currency
}

export const getTeeTimePriceCurrencyV2 = (teeTimePrice) => {
  if (!teeTimePrice) return null

  return teeTimePrice?.price ? teeTimePrice?.currency : undefined
}

export const timeAllowsCancelBooking = (orderBooking) => {
  const now = new Date().getTime()
  const startTime = new Date(orderBooking?.booking?.start_time)
  startTime.setHours(startTime.getHours() - 3)
  const latestCancelTime = startTime.getTime()
  return now <= latestCancelTime
}

export const isCourseTypeCourse = (course) => {
  return course?.type === 'course'
}

export const isVoucherTypePlayValue = (voucher) => {
  if (voucher.promotion?.actions) {
    return (
      voucher.promotion.actions[0]?.type ===
        'item_percentage_discount_with_discounted_value_limitation' ?? false
    )
  }
  return false
}

/**
 * Find order booking from array that contains tee time id
 *
 * @param {*} orderBookings
 * @param {number} teeTimeId
 * @returns
 */
export const findBookingThatContainsTeeTimeId = (orderBookings, teeTimeId) => {
  if (!orderBookings || !teeTimeId) return null

  return orderBookings.find((orderBooking) => {
    return orderBooking?.items?.some((item) => item?.slot?.tee_time?.id === teeTimeId)
  })
}

/**
 * Checks if tee time matches booking
 *
 * @param {{}} booking
 * @param {{}} teeTime
 * @returns
 */
export const bookingStartEndIncludesTeeTime = (booking, teeTime) => {
  const bookingStart = new Date(booking.booking?.start_time),
    bookingEnd = new Date(booking.booking?.end_time),
    teeTimeStart = new Date(teeTime.from),
    teeTimeEnd = new Date(teeTime.to)

  return (
    bookingStart.getTime() === teeTimeStart.getTime() ||
    bookingEnd.getTime() === teeTimeEnd.getTime() ||
    (teeTimeStart.getTime() > bookingStart.getTime() && teeTimeEnd.getTime() < bookingEnd.getTime())
  )
}

/**
 * Find first booking that matches tee time
 *
 * @param {Array} bookings
 * @param {{}} teeTime
 * @returns
 */
export const findBookingThatContainsTeeTime = (bookings, teeTime) => {
  return bookings.find((booking) => bookingStartEndIncludesTeeTime(booking, teeTime))
}

/**
 * Find bookings that matches tee time
 *
 * @param {Array} bookings
 * @param {{}} teeTime
 * @returns
 */
export const findBookingsThatContainsTeeTime = (bookings, teeTime) => {
  return bookings.filter((booking) => bookingStartEndIncludesTeeTime(booking, teeTime))
}

/**
 * Find first tee time id in booking
 *
 * @param {*} orderBooking
 * @returns
 */
export const findFirstTeeTimeId = (orderBooking) => {
  if (!orderBooking) return null
  return orderBooking?.items?.find((item) => item?.slot?.tee_time?.id)?.slot?.tee_time?.id
}

/**
 * Does order booking contain slots on more than one tee time id, if yes then probably
 * indication of booking being sim/ranges booking
 *
 * @param {*} orderBooking
 * @returns
 */
export const containsMultipleTeeTimes = (orderBooking) => {
  if (!orderBooking?.items) return null
  let teeTimes = []
  orderBooking.items.forEach((item) => {
    if (item?.slot?.tee_time?.id) teeTimes.push(item.slot.tee_time.id)
  })

  return [...new Set(teeTimes)]?.length > 1
}

export const areTeeTimesWithPPPModified = (isConfirmed, booking, calculatedPriceResultTeeTimes) => {
  if (!isConfirmed || !booking || !calculatedPriceResultTeeTimes) return false

  const teeTimesModified = calculatedPriceResultTeeTimes.filter((teeTime) => teeTime.is_modified)
  return teeTimesModified?.some(
    (teeTime) =>
      !!booking?.items?.find((item) => item.slot?.tee_time?.uuid === teeTime.uuid)?.slot.tee_time
        .price_per_extra_player
  )
}

export const hasPromotionApplied = (calculatedOrderItems) => {
  if (!calculatedOrderItems?.length) return false

  return calculatedOrderItems.some((item) =>
    item?.adjustments.some((adj) => adj?.type === 'order_item_promotion')
  )
}
