import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import PromotionReservationWrapper from '../PromotionReservationWrapper'
import MembershipCardSingleAction from '../MembershipCardSingleAction'
import MembershipCardRules from '../MembershipCardRules'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { getIncompatiblePromotions } from '@sweetspot/sweetspot-js/features/promotions/services/api-platform'
import IncompatiblePromotions from '../IncompatiblePromotions'

const MembershipCardSinglePromotion = ({ promotion }) => {
  const { t } = useTranslation()

  const actions = useMemo(() => {
    if (!promotion?.actions) return null
    return promotion.actions
  }, [promotion.actions])

  const rules = useMemo(() => {
    if (!promotion?.rules) return null
    return promotion.rules
  }, [promotion.rules])

  const { data: incompatiblePromotionsData } = useQuery(
    [CLUB_QUERIES.INCOMPATIBLE_PROMOTIONS, promotion.id, { limit: 999, 'state[]': ['published'] }],
    () => getIncompatiblePromotions(promotion.id, { limit: 999, 'state[]': ['published'] }),
    {
      enabled: !!promotion?.id,
    }
  )

  return (
    <PromotionReservationWrapper className={cx(styles.wrapper)} title={promotion?.name}>
      {!actions && (
        <div className={cx(styles.emptyPromotion)}>
          <p className={cx(styles.emptyText)}>{t('sentences.noActions')}</p>
        </div>
      )}

      {actions &&
        actions?.map((action) => <MembershipCardSingleAction key={action.id} action={action} />)}

      {rules?.length ? <MembershipCardRules rules={rules} /> : null}

      {incompatiblePromotionsData?.length ? (
        <IncompatiblePromotions incompatiblePromotions={incompatiblePromotionsData} />
      ) : null}
    </PromotionReservationWrapper>
  )
}

MembershipCardSinglePromotion.propTypes = {
  promotion: PropTypes.object,
}

MembershipCardSinglePromotion.defaultProps = {}

export default MembershipCardSinglePromotion
