import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import {
  InputBase,
  InputContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { Venue } from '@sweetspot/shared/types'
import { useQueryParams } from '@sweetspot/shared/util/hooks'
import { GolfCourseTypes } from '@sweetspot/shared/types'

import { VenuesSelectIcon } from './components'

interface VenuesSelectProps {
  venues: Venue[]
  venuesIsLoading: boolean
  setVenue: (venue: Venue | undefined) => void
  currentVenueName?: string
  courseType: Exclude<GolfCourseTypes, GolfCourseTypes.COURSE>
}

const VenuesSelect = ({
  venuesIsLoading,
  setVenue,
  venues,
  currentVenueName,
  courseType,
}: VenuesSelectProps) => {
  const { t } = useTranslation()

  const query = useQueryParams()
  const history = useHistory()

  const currentVenueDisabled = useMemo(
    () => venuesIsLoading || !venues?.length,
    [venues, venuesIsLoading]
  )

  const currentVenueValue = useMemo(
    () => (!venuesIsLoading && currentVenueName ? currentVenueName : ''),
    [currentVenueName, venuesIsLoading]
  )

  const handleSetVenue = (value: string) => {
    const venue = venues?.find((venue) => venue.id === parseInt(value))
    query.set('venue', `${venue?.id}`)
    history.replace(`${history?.location?.pathname}?${query.toString()}`)
    setVenue(venue)
  }

  return (
    <InputSelect
      disabled={currentVenueDisabled}
      value={currentVenueValue || ''}
      onValueChange={handleSetVenue}
    >
      <InputBase className="w-auto">
        <InputSelectTrigger asChild>
          <InputContainer className="ring-border-stroke-subtle">
            <InputLeadingContainer>
              <VenuesSelectIcon courseType={courseType} />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              placeholder={t('sentences.selectCourse')}
              className="h-10 w-[288px] text-ellipsis border py-1 pe-8 pl-12"
              value={currentVenueValue || ''}
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>
      <InputSelectContent className="max-h-56">
        <InputSelectContentView>
          {venues?.map((venue) => (
            <InputSelectItem value={`${venue.id}`}>
              <ListItem className="ml-2 h-full justify-center gap-2">
                <ListItemMainContent className="justify-center py-1">
                  <ListItemParagraph className="text-content-base">{venue?.name}</ListItemParagraph>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default VenuesSelect
