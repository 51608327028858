import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import {
  _getPeriodOverrides,
  _addPeriodOverride,
  _deletePeriodOverride,
} from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import TimePeriodsContext from '../context'
import getDaysOptions from './daysOptions'
import { useTranslation } from 'react-i18next'

const usePeriodOverrides = (period = {}) => {
  const { t } = useTranslation()
  const [globalState] = useContext(TimePeriodsContext)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { lang } = globalState
  const daysOptions = getDaysOptions(t)

  const onMutationSuccess = () => queryClient.invalidateQueries(['PERIOD_OVERRIDES', period.id])

  const { data: periodOverrides = [], isFetching: isLoading } = useQuery(
    ['PERIOD_OVERRIDES', period.id],
    () => _getPeriodOverrides(period.id, 'limit=1000&order[created_at]=desc'),
    { enabled: !!period.id }
  )
  const addOverrideMutation = useMutation((payload) => _addPeriodOverride(period.uuid, payload), {
    onSuccess: onMutationSuccess,
    onError: ({ detail }) => {
      dispatch(addToast(detail, 'error'))
    },
  })

  const addOverride = (formState) => {
    const payload = mapPayload(formState, daysOptions)
    const { days, start_date, end_date } = formState
    if (Utilities.isValidDateRange(days, new Date(start_date), new Date(end_date))) {
      addOverrideMutation.mutate(payload)
    } else {
      const dayString = GetTranslatedString(lang, `date.days.${payload.repeat_on}`)
      const message = GetTranslatedString(lang, 'toast.invalidPeriod')
      dispatch(addToast(dayString + message, 'error'))
    }
  }

  const deleteOverrideMutation = useMutation((id) => _deletePeriodOverride(id), {
    onSuccess: onMutationSuccess,
  })

  const deleteOverride = (id) => {
    deleteOverrideMutation.mutate(id)
  }

  return { isLoading, periodOverrides, addOverride, daysOptions, deleteOverride }
}

export default usePeriodOverrides

const mapPayload = (formState, daysOptions) => {
  const { days, start_date, end_date, space } = formState
  const { start_time_from, start_time_to, category_id } = formState
  const { slots, scheduledDate, scheduledTime } = formState
  const { is_golf_id_required, is_prime_time, isScheduled = true } = formState

  return {
    days,
    space: space === -1 ? null : { uuid: space },
    repeat_on: daysOptions.find((opt) => opt.id === days)?.value,
    start_date: start_date,
    end_date: end_date,
    start_time_from: `${start_time_from}:00`,
    start_time_to: `${start_time_to}:00`,
    category_id,
    slots: slots || null,
    is_golf_id_required,
    is_prime_time,
    schedule_to: isScheduled
      ? DateHelpers.getUTCDateString(new Date(`${scheduledDate} ${scheduledTime}`))
      : null,
  }
}
