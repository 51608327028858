import { useCallback } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router'
import { getUnixTime, isValid } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { Venue } from '@sweetspot/shared/types'
import { useQueryParams } from '@sweetspot/shared/util/hooks'
import { GolfCourseTypes } from '@sweetspot/shared/types'

import VenuesSelect from '../VenuesSelect/VenuesSelect'
import SheetHeaderDateSelect from '../SheetHeaderDateSelect/SheetHeaderDateSelect'
import HeaderSettings from '../HeaderSettings/HeaderSettings'
import FilterButton from '../FilterButton/FilterButton'
import { useRangeFilter } from '../../hooks'
import { RangeFilter } from '../RangeFilter'
import RefreshButton from '../RefreshButton/RefreshButton'

interface SimRangeHeaderProps {
  venues: Venue[]
  venuesIsLoading: boolean
  currentVenue: Venue
  setVenue: (venue: Venue | undefined) => void
  currentDate: Date
  setDate: (date: Date) => void
  isVisible: boolean
  setHeader: (value: number) => void
  courseType: Exclude<GolfCourseTypes, GolfCourseTypes.COURSE>
  hasTeeTimes: boolean
  teeTimesIsLoading: boolean
  onRefreshTeeTimes: () => void
}

const SimRangeHeader = ({
  isVisible,
  venuesIsLoading,
  currentVenue,
  setVenue,
  currentDate,
  setDate,
  venues,
  setHeader,
  courseType,
  hasTeeTimes,
  teeTimesIsLoading,
  onRefreshTeeTimes,
}: SimRangeHeaderProps) => {
  const query = useQueryParams()
  const history = useHistory()
  const { isFilterBtnVisible } = useRangeFilter()

  const handleSetDate = useCallback(
    (date: Date) => {
      if (!date) return
      const localizedDate = utcToZonedTime(date, currentVenue?.timezone)
      if (!isValid(localizedDate)) return

      query.set('date', `${getUnixTime(localizedDate)}`)
      history.replace(`${history?.location?.pathname}?${query.toString()}`)
      setDate(localizedDate)
    },
    [currentVenue?.timezone, history, query, setDate]
  )

  return (
    <div
      className={cx('flex items-center justify-between p-4 pb-2 pl-6', {
        'max-h-0 scale-y-0 pb-0 pt-0': !isVisible,
      })}
    >
      <div className="flex gap-6">
        <VenuesSelect
          venues={venues}
          venuesIsLoading={venuesIsLoading}
          setVenue={setVenue}
          currentVenueName={currentVenue?.name}
          courseType={courseType}
        />
        <SheetHeaderDateSelect
          currentDate={currentDate}
          handleSetDate={handleSetDate}
          timezone={currentVenue?.timezone}
        />
      </div>
      <div className="flex items-center gap-2">
        <RefreshButton isLoading={teeTimesIsLoading} handleRefresh={onRefreshTeeTimes} />
        {isFilterBtnVisible && <FilterButton disabled={!hasTeeTimes} />}
        <HeaderSettings setHeader={setHeader} timezone={currentVenue?.timezone} />
      </div>

      <RangeFilter />
    </div>
  )
}

export default SimRangeHeader
