import * as React from 'react'
import { tv, type VariantProps } from 'tailwind-variants'
import { cn } from '../../../utils'

const TagVariantKeys = {
  Primary: 'primary',
  Inverted: 'inverted',
  Info: 'info',
  InfoFilled: 'info-filled',
  Success: 'success',
  SuccessFilled: 'success-filled',
  Warning: 'warning',
  WarningFilled: 'warning-filled',
  Danger: 'danger',
  DangerFilled: 'danger-filled',
} as const

const tagVariants = tv({
  base: 'rounded-pill text-content-xs gap-2 focus:ring-ring inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
  variants: {
    variant: {
      [TagVariantKeys.Primary]: 'bg-surface-primary text-text-light',
      [TagVariantKeys.Inverted]: 'bg-surface-inverted-primary text-text-dark',
      [TagVariantKeys.Info]: 'text-text-info border border-border-stroke-info',
      [TagVariantKeys.InfoFilled]: 'bg-surface-info text-text-light',
      [TagVariantKeys.Success]: 'text-text-success border border-border-stroke-success',
      [TagVariantKeys.SuccessFilled]: 'bg-surface-success text-text-light',
      [TagVariantKeys.Warning]: 'text-text-warning border border-border-stroke-warning',
      [TagVariantKeys.WarningFilled]: 'bg-surface-warning text-text-light',
      [TagVariantKeys.Danger]: 'text-text-danger border border-border-stroke-danger',
      [TagVariantKeys.DangerFilled]: 'bg-surface-danger text-text-light',
    },
    size: {
      medium: 'px-sm py-xs',
      large: 'px-1.5 py-sm',
    },
  },
  defaultVariants: {
    variant: TagVariantKeys.Primary,
    size: 'medium',
  },
})

export interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tagVariants> {
  onRemove?: () => void
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  ({ className, children, variant, size, onRemove = undefined, ...props }, ref) => {
    return (
      <div
        className={cn(tagVariants({ variant, size }), className)}
        ref={ref}
        {...props}
        data-testid="scramble-tag"
      >
        {children}
        {onRemove && (
          <button type="button" onClick={onRemove} data-testid="scramble-tag-close">
            <i className="fa-regular fa-close" />
          </button>
        )}
      </div>
    )
  }
)
Tag.displayName = 'Tag'

export { Tag, tagVariants }
