import { vsprintf } from 'sprintf-js'

const EVERY_DAY = 0
const WEEKENDS = 1
const WEEKDAYS = 2

export default class Utilities {
  static copyValue(value) {
    return Object.assign({}, { value }).value
  }

  static deepClone(object) {
    return JSON.parse(JSON.stringify(object))
  }

  static arrayIncludesObject(array, object) {
    for (let obj of array) {
      if (this.objectsAreEqual(obj, object)) return true
    }
    return false
  }

  static objectValueFromDotNotation(object, key) {
    try {
      if (key && key.toString().substring(0, 1) === '.') {
        key = key.toString().substring(1)
      }
      return key.split('.').reduce((o, i) => o[i], object)
    } catch {
      return null
    }
  }

  static objectsAreEqual(a, b) {
    try {
      let keys = Object.keys(a)
      for (let key of keys) {
        if (a[key] !== b[key]) return false
      }
      return true
    } catch (e) {
      return false
    }
  }

  static objectAreEqualAdvanced(x, y) {
    if (x === y) return true
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!Object.prototype.hasOwnProperty.call(x, p)) continue
      // other properties were tested using x.constructor === y.constructor

      if (!Object.prototype.hasOwnProperty.call(y, p)) return false
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue
      // if they have the same strict value or identity then they are equal

      if (typeof x[p] !== 'object') return false
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!this.objectAreEqualAdvanced(x[p], y[p])) return false
      // Objects and Arrays must be tested recursively
    }

    for (p in y) {
      if (Object.prototype.hasOwnProperty.call(y, p) && !Object.prototype.hasOwnProperty.call(x, p))
        return false
      // allows x[ p ] to be set to undefined
    }
    return true
  }

  static arraysAreEqual(a, b) {
    if ((a === null && b === null) || (a === undefined && b === undefined)) return true
    if (
      (Utilities.isSet(a) && !Utilities.isSet(b)) ||
      (!Utilities.isSet(a) && Utilities.isSet(b))
    ) {
      return false
    }
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false
      }
    }
    return true
  }

  static arrayIncludesObjectWithProperty(array, propertyName, propertyValue) {
    for (let obj of array) {
      if (
        Object.prototype.hasOwnProperty.call(obj, propertyName) &&
        obj[propertyName] === propertyValue
      )
        return true
    }
    return false
  }

  static numbersAscending(a, b) {
    return a - b
  }

  static isSet(variable) {
    return !(variable === null || variable === undefined || variable === '')
  }

  static hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  static formatPhoneNumber(phone) {
    let raw = phone.replace(/\s/g, '')

    if (raw.includes('-')) raw = raw.replace('-', '')
    if (/^\d{9}$/.test(raw)) {
      let numbers = raw.split('')
      numbers.splice(0, 1)
      return vsprintf('+46 %s%s %s%s %s%s %s%s', numbers)
    } else if (/^\d{10}$/.test(raw)) {
      let numbers = raw.split('')
      numbers.splice(0, 1)
      return vsprintf('+46 %s%s %s%s%s %s%s %s%s', numbers)
    } else if (/^[+]\d{11}$/.test(raw)) {
      let numbers = raw.split('')
      numbers.splice(0, 1)
      return vsprintf('+%s%s %s%s %s%s%s %s%s %s%s', numbers)
    }

    return phone
  }

  static autoFormatNumber(event) {
    let value = event.target.value
    value = Utilities.formatPhoneNumber(value)
    event.target.value = value
  }

  static autoFormatGolfId(golfId) {
    if (golfId.length > 6 && !golfId.includes('-') && !isNaN(golfId)) {
      return golfId.slice(0, 6) + '-' + golfId.slice(6)
    }
    return golfId
  }

  static validateEmail(email) {
    if (!email) return true
    else {
      // eslint-disable-next-line
      let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(String(email).toLowerCase())
    }
  }

  static uniqueID() {
    return '_' + Math.random().toString(36).substr(2, 9)
  }

  static findObjectsArrIndexByID(arr, obj) {
    return arr.findIndex((x) => x.id === obj.id)
  }

  static numberArraySequence(limit) {
    return Array.from(Array(limit).keys())
  }

  static isAPhoneNumber(string) {
    if (!string) return true
    else {
      let re = /^\+(?:[0-9] ?){6,14}[0-9]$/

      return re.test(String(string))
    }
  }

  static isValidPhoneNo(string) {
    const regExp = /^\+?([0-9]{2})\)?[ ][(]?([0-9]{3})[)][ ]?([0-9]{3})[-]?([0-9]{3})$/
    return !!string.match(regExp)
  }

  static isAGolfId(string) {
    if (!string) return true
    else {
      let re = /^\d{6,8}[-|(\s)]{0,1}\d{3}$/
      return re.test(String(string))
    }
  }

  static parseHCP(hcp) {
    if (!hcp) return null
    if (hcp.substring(0, 1) === '+') {
      return parseFloat(hcp.substring(1))
    } else {
      return -parseFloat(hcp)
    }
  }

  static displayHCP(hcp) {
    if (!hcp) return '-'
    else if (hcp <= 0) {
      return Math.abs(hcp).toFixed(1)
    } else {
      return '+' + hcp.toFixed(1)
    }
  }

  /**
   * Converts and array of objects to object using passed property as key for each object.
   *
   * @static
   * @memberof Utilities
   * @param {Array} array
   * @param {string} useAsProperty
   * @returns {Object}
   */
  static arrayToObject = (array, useAsProperty = 'id') => {
    let object = {}
    array.map((item) => (object[item[useAsProperty]] = item))
    return object
  }

  /**
   * Converts object to array
   *
   * @static
   * @memberof Utilities
   * @param {Object} object
   * @returns {Array}
   */
  static objectToArray = (object) => {
    if (Object.keys(object).length === 0) {
      return []
    }
    return Object.keys(object).map((key) => object[key])
  }

  /**
   * Converts a number to decimal percent.
   * Ex: 98 -> 0.98
   *
   * @static
   * @memberof Utilities
   * @param {string|number} number
   * @returns {number}
   */
  static numberToDecimalPercent = (number) => {
    const parsedNumber = parseInt(number)
    let percentage = (parsedNumber / 100).toFixed(2)
    return parseFloat(percentage)
  }

  /**
   * Converts a decimal percent to number.
   * Ex: 0.75 -> 75
   *
   * @static
   * @memberof Utilities
   * @param {string|number} percent
   * @returns {number}
   */
  static decimalPercentToNumber = (percent) => {
    const parsedPercent = parseFloat(percent)
    let number = (parsedPercent * 100).toFixed(0)
    return parseInt(number)
  }

  /**
   * Converts float to cents value
   *
   * @static
   * @memberof Utilities
   * @param {number} float
   * @returns {number}
   */
  static floatToCents = (float) => {
    return parseInt(parseFloat(float * 100).toFixed(0))
  }

  /**
   * Converts cents to float
   *
   * @static
   * @memberof Utilities
   * @param {number} cents
   * @returns {number}
   */
  static centsToFloat = (cents) => {
    return parseFloat(cents / 100)
  }

  /**
   * Does basic search in array or object of items for searchTerm matching against searchKeys.
   *
   * @static
   * @memberof Utilities
   * @param {string} searchTerm
   * @param {Array|Object} items
   * @param {Array} searchKeys
   * @param {boolean} hideZeroRelevance
   * @returns {Array|Object}
   */
  static basicStringSearch = (searchTerm, items, searchKeys, hideZeroRelevance = false) => {
    let type = null
    let results
    searchTerm = searchTerm.toLowerCase()

    if (Array.isArray(items)) {
      results = []
      type = 'array'
    } else if (typeof items === 'object' && items !== null) {
      results = {}
      type = 'object'
    }

    if (!type) return null

    const createResults = (itemReadOnly, objectKey = null) => {
      let item = { ...itemReadOnly }
      let relevance = 0
      searchKeys.forEach((key) => {
        if (item && item[key] && item[key].toString().toLowerCase().includes(searchTerm)) {
          relevance = relevance + 1
        }
      })
      if (hideZeroRelevance && relevance <= 0) return
      item['relevance'] = relevance
      if (type === 'array') results.push(item)
      if (type === 'object') results[objectKey] = item
    }

    if (type === 'array') {
      items.forEach((item) => createResults(item))
      results = results.sort((a, b) => {
        if (a.relevance < b.relevance) return -1
        if (a.relevance > b.relevance) return 1
        return 0
      })
    }
    if (type === 'object') {
      Object.keys(items).forEach((key) => createResults(items[key], key))
    }

    return results
  }

  /**
   * Checks if nested property exists in object
   * Pass params like: checkNestedProp(object, 'key', 'key2', 'key3') etc
   *
   * @static
   * @memberof Utilities
   * @param {Object} obj
   * @param {string} property
   * @returns {bool}
   */
  static checkNestedProp = (obj, property, ...rest) => {
    if (obj === undefined) return false
    if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, property)) return true
    return this.checkNestedProp(obj[property], ...rest)
  }

  /**
   * Checks if date range includes Days : Monday, Tuesday, Wendesday, Thursday, Friday, Saturday, Sunday, Weekend, Weekday
   *
   * @static
   * @memberof Utilities
   * @param {Number} days
   * @param {Date} fromDate
   * @param {Date} toDate
   * @returns {bool}
   */
  static isValidDateRange = (days, fromDate, toDate) => {
    if (days === EVERY_DAY) return true

    if ((toDate - fromDate) / (60 * 60 * 24 * 1000) > 6) return true

    for (let date = fromDate; date <= toDate; date.setDate(date.getDate() + 1)) {
      const day = date.getDay()

      if (days !== WEEKDAYS && days - 2 === day) return true

      if (days === WEEKENDS) {
        if (day === 6 || day === 0) return true
      } else if (days === WEEKDAYS) {
        if (day !== 6 && !!day) return true
      }
    }

    return false
  }
}
