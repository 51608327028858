import { useMemo, useState } from 'react'
import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import { HEADERS } from './headers'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useMembershipSignups } from '@sweetspot/club-portal/feature/memberships'
import MembershipSignupsModal from '@sweetspot/club-portal-legacy/modals/MembershipSignupsModal'
import { GridFilter } from '@sweetspot/club-portal-legacy/components/Grid/types'

export interface MembershipSignupsGridProps {
  pageLimit?: number
}

enum Filter {
  ALL = 'ALL',
  PENDING = 'PENDING',
  DONE = 'DONE',
}

const filters: GridFilter[] = [
  {
    name: '.status',
    options: [
      { name: '.words.all', filter: Filter.ALL },
      { name: '.words.pending', filter: Filter.PENDING },
      { name: '.words.done', filter: Filter.DONE },
    ],
  },
]

export const MembershipSignupsGrid: React.FC<MembershipSignupsGridProps> = ({ pageLimit = 25 }) => {
  const history = useHistory()
  const match = useRouteMatch()
  /* Use mutually exclusive filters. Only one can be active at a time.
   * Radios would suit better, but we use checkboxes everywhere. */
  const [activeFilter, setActiveFilter] = useState<Filter | null>(null)
  const { path } = match
  const [currentPage, setCurrentPage] = useState(1)

  const isDone = useMemo(() => {
    if (!activeFilter || activeFilter === Filter.ALL) {
      return
    }

    return Number(activeFilter === Filter.DONE)
  }, [activeFilter])

  const { data, isFetching } = useMembershipSignups({
    query: { page: currentPage, limit: pageLimit, isDone },
    queryOptions: {
      keepPreviousData: true,
      retry: false,
    },
  })

  // TODO: implement after the backend is ready
  //const toggleIsDone = (id: number) => (isDone: boolean) => {
  //}

  const gridData = useMemo(() => {
    const result = []
    const dataForGrid = data?.['hydra:member'] || []
    dataForGrid.forEach((item) => {
      const row = {
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
        // TODO: implement after the backend is ready
        //isDone: {
        //  checked: false,
        //  onChange: toggleIsDone(item.id)
        //},
        email: item.email_address,
        birthDate: item.birth_date,
        membershipName: item.membership_name,
      }
      result.push(row)
    })
    return result
  }, [data])

  const totalPages = useMemo(() => {
    const { 'hydra:member': members, 'hydra:totalItems': totalItems } = data ?? {}
    if (members?.length && totalItems) {
      return Math.ceil(totalItems / members?.length)
    }
    return 0
  }, [data])

  const rowsPerPage = useMemo(() => {
    const { 'hydra:member': members, 'hydra:totalItems': totalItems } = data ?? {}
    if (members?.length && totalItems) {
      return members?.length > totalItems ? totalItems : members?.length
    }
    return 0
  }, [data])

  const handleRowClick = (row) => {
    history.push(`${path}/${row.id}`)
  }

  return (
    <div className="relative grid h-full gap-8 p-[10px] text-base">
      <Grid
        filtersEnabled
        activeFilters={[activeFilter]}
        handleFilters={setActiveFilter}
        filters={filters}
        filtersTitle={'memberships.pendingInvites.invitation_filters'}
        allowFunctionValues
        values={gridData}
        pageTitle={'memberships.pendingInvites.heading_pending'}
        headers={HEADERS}
        isLoading={isFetching}
        pagination
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onRowClick={handleRowClick}
        totalRows={data?.['hydra:totalItems'] ?? 0}
        displayRangeOfRows
        setCurrentPage={(val: number) => setCurrentPage(val)}
      />
      <Route path={`${path}/:id`} component={MembershipSignupsModal} />
    </div>
  )
}
