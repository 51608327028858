import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { RangeFilters, BayType, RangeFilterType } from '@sweetspot/shared/types'

interface RangeFilterContext {
  floors: number[]
  trackingTechnologies: string[]
  filters: RangeFilters
  setFilters: (filters: RangeFilters) => void
  resetFilters: () => void
  isFiltersModalOpen: boolean
  setIsFiltersModalOpen: (isFiltersModalOpen: boolean) => void
  filtersCount: number
  isFilterBtnVisible: boolean
}

interface RangeFilterProviderProps {
  bays: BayType[]
  children: ReactNode
  filters: RangeFilters
  setFilters: (filters: RangeFilters) => void
}

export const RangeFilterContext = createContext<RangeFilterContext>({} as RangeFilterContext)

export const RangeFilterProvider = ({
  bays,
  children,
  filters,
  setFilters,
}: RangeFilterProviderProps) => {
  const [floors, setFloors] = useState<number[]>([])
  const [trackingTechnologies, setTrackingTechnologies] = useState<string[]>([])
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false)

  const isFilterBtnVisible = useMemo(
    () =>
      Object.keys(filters[RangeFilterType.FLOORS]).length > 1 ||
      Object.keys(filters[RangeFilterType.TECHNOLOGIES]).length > 1,
    [filters]
  )

  useEffect(() => {
    const bayFloors: number[] = Array.from(new Set(bays?.map((bay) => bay.floor)))
    setFloors(bayFloors)

    const bayTechnologies: string[] = Array.from(
      new Set(bays?.map((bay) => bay.tracking_technology))
    )
    setTrackingTechnologies(bayTechnologies)
  }, [bays])

  const resetFilters = useCallback(() => {
    const initialFiltersState: RangeFilters = {
      [RangeFilterType.TECHNOLOGIES]: trackingTechnologies.reduce((acc, key) => {
        // if there is only one tracking technology, set it to true
        if (trackingTechnologies.length === 1) return { [key]: true }
        return { ...acc, [key]: false }
      }, {}),
      [RangeFilterType.FLOORS]: floors.reduce((acc, key) => {
        // if there is only one floor, set it to true
        if (floors.length === 1) return { [key]: true }
        return { ...acc, [key]: false }
      }, {}),
    }
    setFilters(initialFiltersState)
  }, [floors, trackingTechnologies, setFilters])

  useEffect(() => {
    resetFilters()
  }, [floors, trackingTechnologies, resetFilters, bays])

  // calculate the number of enabled filters
  const filtersCount = useMemo(
    () =>
      Object.values(filters).reduce((count, filterGroup) => {
        const filterGroupOptionsLength = Object.keys(filterGroup).length
        const countInFilterGroup =
          // if there is only one trackingTech or floor option, dont count it
          filterGroupOptionsLength <= 1
            ? 0
            : Object.values(filterGroup).reduce(
                (count: number, filter) => count + (filter ? 1 : 0),
                0
              )
        return count + countInFilterGroup
      }, 0),
    [filters]
  )

  return (
    <RangeFilterContext.Provider
      value={{
        floors,
        trackingTechnologies,
        filters,
        setFilters,
        resetFilters,
        isFiltersModalOpen,
        setIsFiltersModalOpen,
        filtersCount,
        isFilterBtnVisible,
      }}
    >
      {children}
    </RangeFilterContext.Provider>
  )
}
