import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Tag } from '@sweetspot/scramble-ds'
import PlayerName from '@sweetspot/club-portal-legacy/components/PlayerName'

import ItemBox from '../ItemBox'
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCrown,
  faAddressCard,
  faHandWave,
  faHandshake,
  faAt,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons'
import { ReactComponent as SweetspotIcon } from '@sweetspot/sweetspot-js/assets/svgs/ss-symbol.svg'
import { Booking } from '@sweetspot/shared/types'

interface ManageBookingOwnerBoxProps {
  className?: string
  isOpen: boolean
  shouldRender: boolean
  orderBooking: Booking
}

const ManageBookingOwnerBox = ({
  orderBooking,
  shouldRender,
  className,
  isOpen,
}: ManageBookingOwnerBoxProps) => {
  const { t } = useTranslation()

  const customer = useMemo(() => {
    return orderBooking?.customer || null
  }, [orderBooking])

  const isMember = useMemo(() => {
    return orderBooking?.items?.some((item) => item.slot?.is_member) || false
  }, [orderBooking?.items])

  const { playerEmail, playerPhone } = useMemo(() => {
    if (customer) {
      return { playerEmail: customer.email, playerPhone: customer.phone }
    }

    if (orderBooking?.stub_player) {
      return {
        playerEmail: orderBooking.stub_player.email,
        playerPhone: orderBooking.stub_player.phone,
      }
    }

    return { playerEmail: '-', playerPhone: '-' }
  }, [customer, orderBooking.stub_player])

  if (shouldRender) {
    return (
      <ItemBox className={cx(styles.container, className, isOpen && styles.open)}>
        <div className={cx(styles.header)}>
          <h2 className={cx(styles.title)}>{t('sentences.bookingOwner')}</h2>
        </div>
        <div className={cx(styles.ownerInformationContainer)}>
          <div className={cx(styles.left, customer && 'cursor-pointer underline')}>
            <div className={styles.ownerName}>
              <FontAwesomeIcon icon={faUser} size="1x" />
              <PlayerName slot={orderBooking} />
            </div>
          </div>
          <div className={cx(styles.right)}>
            <Tag size="large" variant="inverted">
              <FontAwesomeIcon icon={faCrown} />
              <p>{t('sentences.bookingOwner')}</p>
            </Tag>

            {isMember ? (
              <Tag size="large" variant="inverted">
                <FontAwesomeIcon icon={faAddressCard} />
                <p>{t('words.member')}</p>
              </Tag>
            ) : (
              <Tag size="large" variant="inverted">
                <FontAwesomeIcon icon={faHandWave} />
                <p>{t('words.guest')}</p>
              </Tag>
            )}

            {orderBooking?.partnership && (
              <Tag size="large" variant="inverted">
                <FontAwesomeIcon icon={faHandshake} />
                <p>{t('words.partner')}</p>
              </Tag>
            )}

            {customer?.is_registered && (
              <Tag size="large" variant="inverted">
                <SweetspotIcon />
                <p>{t('sentences.sweetspotUser')}</p>
              </Tag>
            )}

            {customer?.hcp && (
              <Tag size="large" variant="inverted">
                <p className="lowercase">
                  {t('words.hcp')} - {customer.hcp}
                </p>
              </Tag>
            )}
          </div>
        </div>

        <div className={cx(styles.ownerInformationContainer, styles.ownerDataContainer)}>
          <div className={cx(styles.left)}>
            <div className={styles.ownerData}>
              <FontAwesomeIcon icon={faAt} size="1x" />
              <span>{playerEmail || '-'}</span>
            </div>
            <div className={styles.ownerData}>
              <FontAwesomeIcon icon={faPhone} size="1x" />
              <span>{playerPhone || '-'}</span>
            </div>
          </div>
        </div>
      </ItemBox>
    )
  } else {
    return null
  }
}

ManageBookingOwnerBox.propTypes = {
  orderBooking: PropTypes.object.isRequired,
  shouldRender: PropTypes.bool,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
}

ManageBookingOwnerBox.defaultProps = {
  orderBooking: null,
  shouldRender: false,
  isOpen: false,
  className: '',
}

export default ManageBookingOwnerBox
