import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export const setLanguage = (language: string): Promise<void> => {
  const URL = `${getBaseUrlPlatform()}/settings/language`

  return request(URL, {
    method: 'POST',
    body: { language },
  })
}
