import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'

import { Button } from '@sweetspot/scramble-ds'
import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'

import BucketConfigurationSelect from '../BucketConfigurationSelect/BucketConfigurationSelect'

const BucketSizes = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: {
      bucketSizes,
      setBucketSizes,
      addBucketSize,
      removeBucketSize,
      nrOfBallsOptions,
      discountsOptions,
    },
  } = useBallPricingContext()

  return (
    <div className="text-content-base text-text-dark font-bold">
      <span>{t('sentences.bucketSizes')}</span>
      <div className="text-text-dark text-content-sm mt-2 flex justify-between font-bold">
        <span>{t('words.option')}</span>
        <div className="flex gap-4">
          <div className="w-16">{t('sentences.nrOfBalls')}</div>
          <div className="mr-11 w-16">{t('words.discount')}</div>
        </div>
      </div>
      {bucketSizes?.map((bucket, index) => (
        <div className="mt-1 flex justify-between">
          <span>{index + 1}</span>
          <div className="flex items-center gap-1">
            <BucketConfigurationSelect
              value={bucket?.nrOfBalls}
              index={index}
              options={nrOfBallsOptions}
              className="w-20"
              onChange={(value, index) =>
                setBucketSizes?.({ nrOfBalls: parseInt(value) }, index as number)
              }
            />
            <BucketConfigurationSelect
              value={bucket?.discount}
              index={index}
              options={discountsOptions}
              className="w-20"
              renderValue={(value) => `${value}%`}
              onChange={(value, index) =>
                setBucketSizes?.({ discount: parseInt(value) }, index as number)
              }
            />
            <FontAwesomeIcon
              icon={faMinus}
              className={cx('cursor-pointer p-2', {
                'text-text-pale !cursor-not-allowed': bucketSizes?.length === 1,
              })}
              onClick={() => bucketSizes?.length > 1 && removeBucketSize?.(index)}
            />
          </div>
        </div>
      ))}
      {bucketSizes?.length < nrOfBallsOptions.length && (
        <div className="mt-[12px] flex justify-center">
          <Button rounding="pill" variant="inverted" size="small" onClick={addBucketSize}>
            <FontAwesomeIcon icon={faPlus} />
            {t('sentences.newSize')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default BucketSizes
