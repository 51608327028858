import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getPlayerBookings as _getPlayerBookings } from '@sweetspot/shared/data-access/api-platform'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import { SortOrder } from '@sweetspot/shared/types'

type Props = {
  playerId?: number
  statusFilter?: string | null
  sorting: { name: string; type: SortOrder }[]
}

const usePlayerBookings = ({ playerId, statusFilter, sorting }: Props) => {
  const selectedGolfClubId = useSelector((state: RootState) => state.golfClub?.selectedId)

  const {
    data: bookingsData,
    isLoading,
    error,
  } = useQuery(
    ['playerBookings', playerId, selectedGolfClubId, statusFilter, sorting],
    () => _getPlayerBookings(playerId, selectedGolfClubId, sorting, statusFilter),
    {
      enabled: !!playerId,
    }
  )

  const [currencyCode] = useClubCurrency()

  const golfCourses = useSelector((state: RootState) =>
    state.golfCourse.list.reduce((acc, { id, timezone }) => {
      acc[id] = timezone
      return acc
    }, {} as Record<string, string>)
  )
  const courses = useSelector((state) => state.golfCourse.list)

  const bookings = useMemo(
    () =>
      bookingsData?.map((bookingData) => {
        const booking = bookingData?.booking
        const dateString = DateHelpers.toDateStringWithTimezone(
          booking.start_time,
          golfCourses[booking.course?.id],
          'YYYY-MM-DD HH:mm'
        )
        const [, startTime] = dateString.split(' ')

        const course = courses?.find((course) => course?.id === booking.course?.id)
        const isCancelledBooking = course?.type !== 'course' && booking?.status === 'canceled'

        const bookingPath =
          course?.type === 'course'
            ? `/booking/${booking?.order?.uuid}`
            : `/simulators-ranges/orders/${booking?.order?.uuid}`

        return {
          bookingId: [booking?.id, bookingPath, isCancelledBooking],
          course: booking.course?.name,
          courseId: booking.course?.id,
          courseUuid: booking.course?.uuid,
          date: dateString,
          status: booking.status.charAt(0).toUpperCase() + booking.status.slice(1),
          price: priceToLocal(booking?.total_price || 0, currencyCode || undefined, true),
          noOfPlayers: booking.booking_participants?.length || 0,
          startTime,
        }
      }) || [],
    [bookingsData, currencyCode, golfCourses]
  )

  return {
    bookings,
    isLoading,
    error,
  }
}

export default usePlayerBookings
