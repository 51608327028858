import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Utilities from '../../../../helpers/Utilities'
import DOMHelpers from '../../../../helpers/DOMHelpers'

export default class InputCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      value: Utilities.isSet(this.props.initialValue) ? this.props.initialValue : '...',
    }

    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.checkValue = this.checkValue.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
    DOMHelpers.onEnter(this._input, this.toggleOpen)
    this._input.addEventListener('blur', this.checkValue)
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: Utilities.isSet(this.props.initialValue) ? this.props.initialValue : '...',
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
    DOMHelpers.clearOnEnter(this._input, this.toggleOpen)
    this._input.removeEventListener('blur', this.checkValue)
  }

  checkValue() {
    let value = this._input.value

    if (this.props.type === 'number') {
      if (value === '' || isNaN(value) || (this.props.min && parseFloat(value) < this.props.min)) {
        if (this.props.min) {
          value = this.props.min.toString()
        } else {
          value = '0'
        }
      }
    }

    if (this.props.onChange) {
      this.props.onChange(value)
    }

    this.setState({
      value: value,
    })
  }

  handleClick(event) {
    if (!DOMHelpers.didClickInside(this._container, event)) {
      this.setState({
        isOpen: false,
      })
    }
  }

  handleChange(event) {
    let value = event.target.value

    if (this.props.onChange) {
      this.props.onChange(value)
    }

    this.setState({
      value: value,
    })
  }

  toggleOpen() {
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => {
        if (!this.state.isOpen) {
          this._input.blur()
        }
      }
    )
  }

  render() {
    let containerClassNames = [style.container]
    if (this.state.isOpen) containerClassNames.push(style.open)

    return (
      <div
        className={containerClassNames.join(' ')}
        onClick={this.toggleOpen}
        ref={(container) => (this._container = container)}
      >
        <input
          className={style.textContainer}
          value={this.state.value}
          onChange={this.handleChange}
          type={this.props.type}
          ref={(input) => (this._input = input)}
        />
      </div>
    )
  }
}

InputCell.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  min: PropTypes.number,
}
