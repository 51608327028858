import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Get club partner types
 * Automatically filters by current club in auth token
 *
 * @returns
 */
export const getPartnerTypes = () => {
  const url = `${getBaseUrlPlatform()}/club-partner-types?limit=200`
  return request(url)
}

/**
 * Create partner type
 *
 * @param {{}} payload
 * @param {string} payload.club - Should be formated as "/api/clubs/{clubId}"
 * @param {string} payload.name - Name of partner type
 * @returns
 */
export const createPartnerType = (payload = { club: '', name: '' }) => {
  const url = `${getBaseUrlPlatform()}/club-partner-types`
  return request(url, { method: 'post', body: payload })
}

/**
 * Update partner type
 *
 * @param {number} id
 * @param {{}} payload
 * @param {string} payload.name - New name of partner type
 * @returns
 */
export const updatePartnerType = (id, payload = { name: '' }) => {
  const url = `${getBaseUrlPlatform()}/club-partner-types/${id}`
  return request(url, { method: 'put', body: payload })
}

/**
 * Delete partner type
 *
 * @param {number} id
 * @returns
 */
export const deletePartnerType = (id) => {
  const url = `${getBaseUrlPlatform()}/club-partner-types/${id}`
  return request(url, { method: 'delete' })
}
