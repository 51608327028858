import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import {
  createProductVariant,
  getProductVariants,
  removeProductVariant,
} from '../services/api-platform'

const defaultCreatePayload = {
  on_hold: 1,
  on_hand: 1,
  hold_strategy: 'deferred',
  is_tracked: true,
  is_postponable: true,
  state: 'active',
  variant_code: 'golf_cart',
  name: 'name',
  warehouse: null,
}

const useProductVariants = (warehouseUuid, productId) => {
  const queryClient = useQueryClient()
  const queryKey = [CLUB_QUERIES.PRODUCT_VARIANTS, warehouseUuid, productId]

  const {
    data: productVariants,
    isFetching: isFetchingVariants,
    refetch: refetchProductVariants,
  } = useQuery(
    queryKey,
    async () => {
      return await getProductVariants(warehouseUuid)
    },
    {
      enabled: !!warehouseUuid && !!productId,
    }
  )

  const createVariant = useMutation(
    (
      newVariant = {
        state: 'not_active',
        name: 'product variant',
      }
    ) => {
      const variantPayload = {
        ...defaultCreatePayload,
        ...newVariant,
        warehouse: warehouseUuid,
      }

      return createProductVariant(productId, variantPayload)
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async (newVariant) => {
        await queryClient.cancelQueries(queryKey)
        const previousVariants = queryClient.getQueryData(queryKey)
        queryClient.setQueryData(queryKey, (oldCarts) => [...(oldCarts ?? []), { ...newVariant }])

        return { previousVariants }
      },
      // On failure, roll back to the previous value
      onError: async (err, newVariant, context) => {
        queryClient.setQueryData(queryKey, context.previousVariants)
      },
      // After success or failure, refetch the variants
      onSettled: async () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )

  const removeVariant = useMutation(
    (variantToRemove) => {
      return removeProductVariant(variantToRemove.uuid)
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async (cartToRemove) => {
        await queryClient.cancelQueries(queryKey)
        const previousVariants = queryClient.getQueryData(queryKey)
        const updatedVariants = previousVariants.filter((cart) => {
          return cart.uuid !== cartToRemove.uuid
        })
        queryClient.setQueryData(queryKey, updatedVariants)

        return { previousVariants }
      },
      // On failure, roll back to the previous value
      onError: (err, variantToRemove, context) => {
        queryClient.setQueryData(queryKey, context.previousVariants)
      },
      // After success or failure, refetch the variants
      onSettled: (variantToRemove, err, previousVariants, context) => {
        // Not needed
      },
    }
  )

  return {
    createVariant,
    removeVariant,
    productVariants,
    isFetchingVariants,
    refetchProductVariants,
  }
}

export { useProductVariants }
