import React, { useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { useSelector } from 'react-redux'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { createSpace } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { useToasts } from 'react-toast-notifications'

const initialForm = {
  name: '',
  description: '',
  floor: '',
}

const defaultErrors = {
  course: null,
  name: null,
  discription: null,
  floor: null,
  general: null,
}

const CreateSpace = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [selectedClub, setSelectedClub] = useState(null)
  const [activeGolfCourse, setActiveGolfCourse] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useMergeState(defaultErrors)
  const [errorText, setErrorText] = useState('')
  const [form, setForm] = useState(initialForm)

  const golfClubList = useSelector((state) => state.golfClub.list)

  const { data: golfCourseList, isFetching: golfCoursesIsFetching } = useQuery(
    [
      CLUB_QUERIES.COURSES,
      {
        'club.id': selectedClub?.id,
        'order[name]': 'asc',
        'type[]': [
          COURSE_TYPES.DRIVING_RANGE.value,
          COURSE_TYPES.SIMULATOR.value,
          COURSE_TYPES.PRO.value,
          COURSE_TYPES.OTHER.value,
        ],
      },
    ],
    () =>
      queryCourses({
        'club.id': selectedClub?.id,
        'order[name]': 'asc',
        'type[]': [
          COURSE_TYPES.DRIVING_RANGE.value,
          COURSE_TYPES.SIMULATOR.value,
          COURSE_TYPES.PRO.value,
          COURSE_TYPES.OTHER.value,
        ],
      }),
    {
      enabled: !!selectedClub?.id,
      select: (data) => {
        return data.map((course) => ({ ...course, label: course.name }))
      },
    }
  )

  const checkForErrors = ({ courseUuid, name, description, floor }) => {
    let errors = {
      course: null,
      name: null,
      description: null,
      floor: null,
    }
    let hasErrors = false

    if (!courseUuid) {
      errors.course = t('sentences.courseMustBeSelected')
      hasErrors = true
    }

    if (!name || typeof name !== 'string' || name.length < 2 || name.length > 150) {
      errors.name = t('errors.stringBetween', { min: 2, max: 150 })
      hasErrors = true
    }

    if (description && (typeof description !== 'string' || description.length > 150)) {
      errors.description = t('errors.maxString_plural', { count: 150 })
      hasErrors = true
    }

    if (floor && (!parseInt(floor) || typeof parseInt(floor) !== 'number')) {
      errors.floor = t('errors.fieldMustBeANumber')
      hasErrors = true
    }

    if (hasErrors) {
      setErrors(errors)
      return false
    }

    return true
  }

  const resetForm = () => {
    setForm(initialForm)
  }

  const handleChange = (name, value) => {
    let newValue = value

    if (name === 'floor') {
      newValue = newValue.replace(/[^0-9]*/g, '')
    }
    setErrors({ [name]: '' })
    setForm({ ...form, [name]: newValue })
  }

  const saveSettings = async () => {
    const { name, description, floor } = form
    const courseUuid = activeGolfCourse?.uuid || null
    const clubId = selectedClub.id

    if (checkForErrors({ courseUuid, name, description, floor })) {
      setLoading(true)

      const [res, err] = await to(
        createSpace(clubId, {
          name,
          course: courseUuid,
          ...(description ? { description } : {}),
          ...(floor ? { floor: parseInt(floor) } : {}),
        })
      )

      setLoading(false)

      if (res) {
        resetForm()
        addToast(t('sentences.spaceCreated'), { appearance: 'success' })
      } else if (err?.violations) {
        err.violations.forEach((violation) => {
          const prop = violation.propertyPath
          if (prop === 'course') {
            setErrors({ course: t('sentences.courseMustBeSelected') })
          }
          if (prop === 'name') {
            setErrors({ name: t('errors.stringBetween', { min: 2, max: 150 }) })
          }
          if (prop === 'description') {
            setErrors({ description: t('errors.maxString_plural', { count: 150 }) })
          }
          if (prop === 'floor') {
            setErrors({ floor: t('errors.valueMustBeBetweenThese', { this: 0, that: 255 }) })
          }
        })
      } else {
        setErrorText(t('errors.somethingWentWrong', { id: 2525 }))
        setLoading(false)
      }
    }
  }

  return (
    <div className={cx(styles.container)}>
      <div className={styles.title}>
        <Text textId="sentences.createSpace" />
        <div>
          <PulseLoader fillHeight showIf={isLoading || golfCoursesIsFetching} />
        </div>
      </div>
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="selects">
            <div>
              <div className="ss-form-group">
                <label className="ss-label">{t('words.golfClub')}</label>
                <DropdownSelect
                  showBorder
                  width="100%"
                  values={golfClubList}
                  onSelect={(id) => {
                    const club = golfClubList.find((item) => item.id === id)
                    if (club) setSelectedClub({ id, name: club.name })
                  }}
                />
              </div>
              <div className="ss-form-group">
                <label className="ss-label">{t('words.golfCourse')}</label>
                <DropdownSelect
                  error={errors?.course}
                  showBorder
                  width="100%"
                  values={golfCourseList || []}
                  onSelect={(id) => {
                    const course = golfCourseList.find((item) => item.id === id)
                    if (course) setActiveGolfCourse(course)
                  }}
                />
              </div>
            </div>
          </div>
          <TextInputField
            error={errors.name}
            label={t('words.name')}
            disabled={isLoading || golfCoursesIsFetching || !golfCourseList?.length}
            value={form.name}
            onChange={(value) => handleChange('name', value)}
          />
          <TextInputField
            error={errors.description}
            label={t('words.description')}
            type="text-area"
            rows={4}
            disabled={isLoading || golfCoursesIsFetching || !golfCourseList?.length}
            value={form.description}
            onChange={(value) => handleChange('description', value)}
          />
          <TextInputField
            error={errors.floor}
            label={t('words.floor')}
            disabled={isLoading || golfCoursesIsFetching || !golfCourseList?.length}
            value={form.floor}
            onChange={(value) => handleChange('floor', value)}
            type="number"
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
          <div className={styles.formButtons}>
            <button
              className="system-button primary-outline md-32"
              disabled={isLoading || golfCoursesIsFetching}
              onClick={resetForm}
            >
              {t('words.cancel')}
            </button>
            <button
              className="system-button info-outline md-32"
              disabled={isLoading || golfCoursesIsFetching}
              onClick={saveSettings}
            >
              {t('words.save')}
            </button>
          </div>
        </form>
        <Alert showIf={!!errorText} onClick={() => setErrorText('')}>
          <p>{errorText}</p>
        </Alert>
      </div>
    </div>
  )
}

CreateSpace.propTypes = {}

CreateSpace.defaultProps = {}

export default CreateSpace
