import { useContext, useEffect, useId, useMemo, useState } from 'react'
import { SecondLevelItemKeyContext, TopLevelItemKeyContext } from '../contexts'
import { useSideSystemNavStore } from '../state'
import { cn } from '../../../../utils'
import { Button } from '../../../atoms'

interface SecondLevelItemProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  text: string
  disabled?: boolean
  isActive?: boolean
  isPending?: boolean
  onClick?: () => void
}
const SecondLevelItem = ({
  isActive = false,
  isPending = false,
  disabled = false,
  children = null,
  onClick = undefined,
  text,
}: SecondLevelItemProps) => {
  const key = useId()
  const parentKey = useContext(TopLevelItemKeyContext)

  const [isHover, setIsHover] = useState(false)

  const {
    setActiveKey,
    setActiveKeyByIsActiveProp,
    activeKeys: { 2: currentSecondLevelActiveItem },
    activeKeysByIsActiveProp: { 2: currentSecondLevelActiveItemByIsActiveProp },
  } = useSideSystemNavStore()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    if (currentSecondLevelActiveItem === key) {
      setActiveKey(2, '')
    } else {
      setActiveKey(2, key)
    }
    onClick?.()
  }

  useEffect(() => {
    if (isActive && parentKey) {
      setActiveKeyByIsActiveProp(1, parentKey)
      setActiveKeyByIsActiveProp(2, key)
    }
  }, [isActive, key, parentKey, setActiveKeyByIsActiveProp])

  const calculatedActive = useMemo(
    () =>
      isActive ||
      currentSecondLevelActiveItem === key ||
      currentSecondLevelActiveItemByIsActiveProp === key,
    [isActive, currentSecondLevelActiveItem, currentSecondLevelActiveItemByIsActiveProp, key]
  )

  const isActiveOrHovered = calculatedActive || isHover

  return (
    <>
      <div
        className={cn(
          'bg-sand-100 relative ml-auto flex h-12 w-[232px] cursor-pointer items-center pr-3.5 transition-colors hover:will-change-[color]',
          {
            'bg-sand-300': calculatedActive,
            'cursor-default opacity-40': disabled,
            'fa-fade': isPending,
          }
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
        data-testid="second-level-item"
        role="presentation"
      >
        <div
          className={cn('!bg-sand-300 absolute left-0 top-0 h-full w-[1px]', {
            '!bg-sand-800': isActiveOrHovered,
          })}
        />
        <i
          className={cn('fa-regular fa-angle-right text-content-xl ml-5 transition-transform', {
            hidden: !children,
            'rotate-90': calculatedActive,
          })}
        />
        <Button
          variant="clear-dark"
          size={isActiveOrHovered ? 'medium' : 'small'}
          className={cn(
            'text-content-base hover:text-text-dark !text-text-dark border border-transparent !bg-transparent !shadow-none transition-none focus:ring-0',
            {
              'pl-[50px]': !children,
              'truncate pr-[2px] font-bold': isActiveOrHovered,
              'pr-1 font-normal': !isActiveOrHovered,
              '!cursor-default': disabled,
            }
          )}
        >
          <span className="truncate">{text ?? ''}</span>
        </Button>
        {!children && (
          <i
            className={cn(
              'fa-regular fa-angle-right !ml-auto text-xl opacity-0 transition-opacity',
              {
                'opacity-100': calculatedActive,
              }
            )}
          />
        )}
      </div>
      <SecondLevelItemKeyContext.Provider key={key} value={key}>
        <div
          className={cn('max-h-0 overflow-hidden transition-[max-height] duration-300', {
            'max-h-screen': calculatedActive,
          })}
        >
          {children}
        </div>
      </SecondLevelItemKeyContext.Provider>
    </>
  )
}
SecondLevelItem.displayName = 'SecondLevelItem'

export { SecondLevelItem }
