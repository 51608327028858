import * as React from 'react'
import { tv } from 'tailwind-variants'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from '../../../utils'

const checkVariants = tv({
  base: 'flex justify-center gap-4 h-touch-height-lg min-h-touch-height-sm px-4 flex items-center',
  variants: {
    disabled: {
      true: 'text-text-pale',
      false:
        'hover:text-text-subtle active:text-text-medium focus-visible:ring-border-stroke-focused focus-visible:ring-2 cursor-pointer focus-visible:ring-inset outline-none',
    },
    error: {
      true: 'text-text-danger hover:text-text-danger active:text-text-danger focus-visible:ring-none focus-visible:ring-0',
      false: '',
    },
  },
  defaultVariants: {
    disabled: false,
    error: false,
  },
})

const getCheckboxIcon = (
  checked: CheckboxPrimitive.CheckedState | undefined,
  indeterminate: boolean | undefined
) => {
  if (indeterminate) {
    return <span className="fa-regular fa-square-minus" />
  }
  if (checked) {
    return <span className="fa-solid fa-square-check" />
  }

  return <i className="fa-regular fa-square" />
}

const getAriaCheckedState = (
  checked: CheckboxPrimitive.CheckedState | undefined,
  indeterminate: boolean | undefined
) => {
  if (indeterminate) {
    return 'mixed'
  }
  return checked ? 'true' : 'false'
}

interface CheckProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  checked: boolean
  label?: string
  error?: boolean
  indeterminate?: boolean
  labelClassName?: string
}

const Check = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckProps>(
  (
    { className, labelClassName, label, error, checked, indeterminate, id, disabled, ...props },
    ref
  ) => {
    return (
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(checkVariants({ disabled, error }), className)}
        disabled={disabled || error}
        checked={indeterminate ? 'indeterminate' : checked}
        id={id}
        aria-checked={getAriaCheckedState(checked, indeterminate)}
        data-testid="check-component"
        {...props}
      >
        <div>{getCheckboxIcon(checked, indeterminate)}</div>
        {label && (
          <label
            htmlFor={id}
            className={cn(
              'text-base font-medium',
              {
                'cursor-pointer': !disabled,
              },
              labelClassName
            )}
          >
            {label}
          </label>
        )}
      </CheckboxPrimitive.Root>
    )
  }
)
Check.displayName = 'Check'

export { Check }
