import { string, object, array, number, mixed, lazy } from 'yup'
import m from 'moment'
import mapValues from 'lodash/mapValues'

import { parseErrors } from '@sweetspot/club-portal-legacy/helpers/Validation'

import { RULE_TYPES, ACTION_TYPES } from '@sweetspot/club-portal-legacy/components/Wizard/constants'

const dateReg =
  /(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31) ([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/
const HHmmssREG = /([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/
const HHmmssFormat = 'HH:mm:ss'

// Rules
const daysInWeekAndTime = object()
  .required()
  .shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([RULE_TYPES.daysInWeekAndTime]),
    configuration: lazy((obj) => {
      if (typeof obj === 'object' && obj !== null && Object.keys(obj).length >= 1) {
        return object(
          mapValues(obj, () =>
            object().shape({
              from: string().matches(HHmmssREG),
              to: string()
                .matches(HHmmssREG)
                .test('must-be-efter-from', 'must-be-after-from', function () {
                  const { from, to } = this.parent
                  if (from && to) {
                    if (m(to, HHmmssFormat).isAfter(m(from, HHmmssFormat))) return true
                    return false
                  }
                  return true
                }),
            })
          )
        )
      }
      return object().nullable().default(null).notRequired()
    }),
  })

const includedCourses = object()
  .required()
  .shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([RULE_TYPES.includedCourses]),
    configuration: array().required().min(1),
  })

const partnerNumberOfRounds = object()
  .required()
  .shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([RULE_TYPES.partnerNumberOfRounds]),
    configuration: object()
      .required()
      .shape({
        limitation_value: number().required().min(0),
      }),
  })

// Action
const partnerDiscount = object()
  .required()
  .shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([ACTION_TYPES.partnerDiscount]),
    configuration: object()
      .required()
      .shape({
        percentage_coefficient: number().required().min(0).max(1),
      }),
  })

const partnerFixedPrice = object()
  .required()
  .shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([ACTION_TYPES.partnerFixedPrice]),
    configuration: object()
      .required()
      .shape({
        fixed_price: number().required().min(0),
      }),
  })

const partnerPlayValue = object()
  .required()
  .shape({
    id: mixed().notRequired(),
    type: string().required().oneOf([ACTION_TYPES.partnerPlayValue]),
    configuration: object()
      .required()
      .shape({
        limitation_value: number().required().min(0),
      }),
  })

/**
 * Single promotion
 */

const promotionSchema = object().shape({
  name: string().required().min(3).trim(),
  rules: object().shape({
    [RULE_TYPES.partnerNumberOfRounds]: partnerNumberOfRounds
      .notRequired()
      .default(undefined)
      .nullable(),
    [RULE_TYPES.daysInWeekAndTime]: daysInWeekAndTime,
    [RULE_TYPES.includedCourses]: includedCourses,
  }),
  actions: object()
    .required()
    .shape({
      [ACTION_TYPES.partnerDiscount]: partnerDiscount.notRequired().default(undefined).nullable(),
      [ACTION_TYPES.partnerFixedPrice]: partnerFixedPrice
        .notRequired()
        .default(undefined)
        .nullable(),
      [ACTION_TYPES.partnerPlayValue]: partnerPlayValue.notRequired().default(undefined).nullable(),
    }),
  id: mixed().required(),
})

const promotionSchemaRequired = promotionSchema
  .shape({
    id: mixed()
      .required()
      .test('promotions-must-be-saved', 'promotionsMustBeSaved', (value) => {
        if (typeof value === 'string' && value.includes('tempId_')) return false
        return true
      }),
  })
  .when('$partnership', (partnership, schema) => {
    const { type } = partnership
    if (type === 'postpay') {
      return schema.test(
        'invalid-rules-for-partnership-type',
        'invalid-rules-for-partnership-type',
        (value) => {
          const { rules, actions } = partnership.promotions[value.id]
          if (
            !rules ||
            !actions ||
            (!actions[ACTION_TYPES.partnerFixedPrice] && !actions[ACTION_TYPES.partnerDiscount]) ||
            rules[RULE_TYPES.partnerNumberOfRounds] ||
            actions[ACTION_TYPES.partnerPlayValue]
          ) {
            return false
          }
          return true
        }
      )
    }
    if (type === 'prepay') {
      return schema.test(
        'invalid-rules-for-partnership-type',
        'invalid-rules-for-partnership-type',
        (value) => {
          const { rules, actions } = partnership.promotions[value.id]
          if (
            !rules ||
            !actions ||
            (!rules[RULE_TYPES.partnerNumberOfRounds] && !actions[ACTION_TYPES.partnerPlayValue]) ||
            actions[ACTION_TYPES.partnerFixedPrice]
          ) {
            return false
          }
          return true
        }
      )
    }
    return schema
  })

/**
 * Multiple promotions
 */
const promotionsSchema = lazy((obj) => {
  if (Object.keys(obj).length >= 1) {
    return object(mapValues(obj, () => promotionSchema))
  } else {
    return object()
      .shape({
        promotions: mixed().required(),
      })
      .required()
  }
})
const promotionsSchemaRequired = lazy((obj) => {
  if (Object.keys(obj).length >= 1) {
    return object(mapValues(obj, () => promotionSchemaRequired))
  } else {
    return object()
      .shape({
        promotions: mixed().required(),
      })
      .required()
  }
})

/**
 * Partnership
 */

const partnershipSchema = object().shape({
  name: string().required().min(3).trim(),
  id: number().notRequired(),
  type: string().required().oneOf(['prepay', 'postpay']),
  price_included_vat: number().when('type', {
    is: (val) => val === 'prepay',
    then: number().min(0).required(),
    otherwise: number().notRequired().strip(),
  }),
  price_excluded_vat: number().when('type', {
    is: (val) => val === 'prepay',
    then: number()
      .min(0)
      .test(
        'must-be-lower-than-including-vat',
        'must-be-lower-than-including-vat',
        function (value) {
          const { price_included_vat } = this.parent
          if (value <= price_included_vat) {
            return true
          }
          return false
        }
      )
      .required(),
    otherwise: number().notRequired().strip(),
  }),
  starts_at: string()
    .test('required', 'required', (value) => {
      if (dateReg.test(value)) return true
      return false
    })
    // .test('must-be-today-or-later', 'must-be-today-or-later', value => {
    //   if (
    //     m
    //       .utc(value)
    //       .local()
    //       .isAfter(m(), 'day') ||
    //     m
    //       .utc(value)
    //       .local()
    //       .isSame(m(), 'day')
    //   ) {
    //     return true;
    //   }
    //   return false;
    // })
    .required(),
  expires_at: string()
    .test('required', 'required', (value) => {
      if (dateReg.test(value)) return true
      return false
    })
    .test('must-be-higher-than-start-date', 'must-be-higher-than-start-date', function (value) {
      const { starts_at } = this.parent
      if (m(value).isAfter(starts_at)) {
        return true
      }
      return false
    })
    .required(),
})

const partnershipSchemaComplete = partnershipSchema.shape({
  promotions: promotionsSchemaRequired,
})

/**
 * Validates partnership
 *
 * @param {object} partnership
 * @param {string} lang
 */
export const validatePartnership = (partnership, lang) => {
  return partnershipSchema
    .validate(partnership, { abortEarly: false, stripUnknown: true })
    .catch((err) => parseErrors(err, lang))
}

/**
 * Validates complete partnership including promotions
 *
 * @param {object} partnership
 * @param {string} lang
 */
export const validateCompletePartnership = (partnership, currentPartnership, lang) => {
  return partnershipSchemaComplete
    .validate(partnership, {
      abortEarly: false,
      stripUnknown: true,
      context: { partnership: currentPartnership },
    })
    .catch((err) => parseErrors(err, lang))
}

/**
 * Validates promotions
 *
 * @param {object} promotions
 * @param {string} lang
 */
export const validatePromotions = (promotions, lang) => {
  return promotionsSchema
    .validate(promotions, { abortEarly: false, stripUnknown: true })
    .catch((err) => parseErrors(err, lang))
}
