export const RULE_TYPES = {
  numberOfRounds: 'number_of_rounds_limitation_checker',
  partnerNumberOfRounds: 'partnership_number_of_rounds_limitation_checker',
  noLimit: 'local_no_limit',
  daysInWeek: 'days_in_week_checker',
  daysInWeekAndTime: 'days_and_time_in_week_checker',
  includedCourses: 'included_courses_checker',
  simBookings: 'number_of_simultaneous_bookings_checker',
}

export const ACTION_TYPES = {
  playValue: 'play_value_limitation',
  partnerPlayValue: 'partnership_play_value_limitation',
  memberDiscount: 'greenfee_member_percentage_discount',
  guestDiscount: 'greenfee_guest_percentage_discount',
  partnerDiscount: 'partnership_greenfee_percentage_discount',
  partnerFixedPrice: 'partnership_greenfee_fixed_price',
}

export const GOLFSTAR_CLUB_ID = 'eff6c9a5-46b8-452c-83bb-e5bc3872c7a8'
