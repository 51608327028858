import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import SchemeTab from './SchemeTab'
import PricesTab from './PricesTab'
import { Toggle, Divider } from '@sweetspot/scramble-ds'
import BucketConfiguration from './BucketConfiguration/BucketConfiguration'
import { useBallPricingContext } from '../BallPricingProvider'

const TABS = {
  Scheme: 'Scheme',
  Prices: 'Prices',
}

const BallPricingTabsContainer = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(TABS.Scheme)
  const [isSchemeEditMode, setIsSchemeEditMode] = useState(false)
  const [openBucketConfigurationSideBar, setOpenBucketConfigurationSideBar] = useState(false)
  const {
    bucketConfiguration: { getBucketConfiguration },
  } = useBallPricingContext()

  const renderContent = useCallback(() => {
    if (activeTab === TABS.Scheme) {
      return <SchemeTab isSchemeEditMode={isSchemeEditMode} />
    }
    if (activeTab === TABS.Prices) {
      return <PricesTab />
    }
    return null
  }, [activeTab, isSchemeEditMode])

  const toggleBucketConfigurationSideBar = useCallback(() => {
    setOpenBucketConfigurationSideBar((prev) => !prev)
  }, [])

  const handleOnOpenBucketConfiguration = useCallback(() => {
    toggleBucketConfigurationSideBar()
    getBucketConfiguration?.()
  }, [getBucketConfiguration, toggleBucketConfigurationSideBar])

  return (
    <div className="flex h-full flex-1 flex-col gap-4">
      <div className="flex items-center">
        <SimpleTabs
          currentItem={activeTab}
          onClick={setActiveTab}
          items={[
            { id: TABS.Scheme, label: t('words.scheme'), stepNumber: TABS.Scheme },
            { id: TABS.Prices, label: t('words.prices'), stepNumber: TABS.Prices },
          ]}
        />
        <div className="flex items-center gap-2">
          {activeTab === TABS.Scheme && (
            <>
              <div className="px-md flex items-center gap-2">
                <p className="text-content-sm text-text-dark">
                  {isSchemeEditMode ? `${t('words.editing')}...` : t('words.edit')}
                </p>
                <Toggle
                  checked={isSchemeEditMode}
                  onCheckedChange={() => setIsSchemeEditMode(!isSchemeEditMode)}
                />
              </div>
              <Divider orientation={'vertical'} className="h-4" />
            </>
          )}
          <div
            className="flex cursor-pointer items-center gap-2 px-4 py-1"
            onClick={handleOnOpenBucketConfiguration}
          >
            <i className="fa-regular fa-sliders" />
            <p className="text-content-sm text-text-dark whitespace-nowrap">
              {t('sentences.bucketConfiguration')}
            </p>
          </div>
        </div>
      </div>
      {renderContent()}
      <BucketConfiguration
        open={openBucketConfigurationSideBar}
        onOpenChange={toggleBucketConfigurationSideBar}
      />
    </div>
  )
}

export default BallPricingTabsContainer
