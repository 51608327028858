import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import BlueTitle from '../BlueTitle'
import {
  ACTION_TYPES_OBJ,
  ACTION_TYPES_OBJ_VALUES,
} from '@sweetspot/sweetspot-js/features/promotions/constants/actions'
import ValueText from '../ValueText'
import {
  decimalPercentToNumber,
  priceToLocal,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import SlotFilterPresentation from '../SlotFilterPresentation'
import GolfCartFilterPresentation from '../GolfCartFilterPresentation'
import { useClubCurrency } from '../../../../common/hooks/useClubCurrency'

const MembershipCardSingleAction = ({ action }) => {
  const { t } = useTranslation()

  const [currencyCode] = useClubCurrency()

  const currentActionType = action.type
  const currentActionTypeObject = ACTION_TYPES_OBJ_VALUES[currentActionType]

  const configRows = useMemo(() => {
    if (
      [ACTION_TYPES_OBJ.PERCENTAGE, ACTION_TYPES_OBJ.FIXED_DISCOUNT].includes(currentActionType)
    ) {
      return [action.configuration]
    } else {
      return action.configuration[currentActionTypeObject.configurationKey]
    }
  }, [action])

  const usedCount = useMemo(() => {
    return action?.used || 0
  }, [action])

  const playValueUsage = useMemo(() => {
    return action?.used || 0
  }, [action])

  const getActionTitle = () => {
    if (
      [
        ACTION_TYPES_OBJ.PERCENTAGE,
        ACTION_TYPES_OBJ.PERCENTAGE_USES,
        ACTION_TYPES_OBJ.PERCENTAGE_VALUE,
      ].includes(currentActionType)
    ) {
      return t('sentences.percentageDiscount')
    }

    if (
      [
        ACTION_TYPES_OBJ.FIXED_DISCOUNT,
        ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES,
        ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE,
      ].includes(currentActionType)
    ) {
      return t('sentences.fixedDiscount')
    }

    return t('words.action')
  }

  const getUsesTitle = () => {
    if (
      [ACTION_TYPES_OBJ.PERCENTAGE_VALUE, ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE].includes(
        currentActionType
      ) ||
      [ACTION_TYPES_OBJ.PERCENTAGE_USES, ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES].includes(
        currentActionType
      )
    ) {
      return t('membership.settings.remaining')
    }
    return ''
  }

  const getPercentageString = (row) => {
    let string = '-'

    if (!row) return string

    let config = {
      percentage_coefficient: row.percentage_coefficient,
    }

    string = decimalPercentToNumber(config.percentage_coefficient) + '%'

    return string
  }

  const getMinFeeString = (row) => {
    let string = ''

    if (!row) return string

    if (row?.minimum_fee_total_amount) {
      string = `(${t('sentences.minimumFee')} ${priceToLocal(
        row.minimum_fee_total_amount,
        currencyCode,
        true
      )})`
    }

    return string
  }

  const getDiscountAmountString = (row) => {
    let string = ''

    if (!row) return string

    if (row?.discount_amount) {
      string = `${priceToLocal(row?.discount_amount, currencyCode)}`
    }

    return string
  }

  const getUsesString = (row, rowIndex) => {
    let string = '-'

    if (!row) return string

    if (
      [ACTION_TYPES_OBJ.PERCENTAGE_USES, ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES].includes(
        currentActionType
      )
    ) {
      if (rowIndex > 0) {
        const previousRow = configRows[rowIndex - 1]
        string = `${previousRow?.uses_to} +`
      } else {
        string = `${row?.uses_to - usedCount} / ${row?.uses_to} ${t('membership.settings.uses')}`
      }
    }

    if (
      [ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE, ACTION_TYPES_OBJ.PERCENTAGE_VALUE].includes(
        currentActionType
      )
    ) {
      if (rowIndex > 0) {
        const previousRow = configRows[rowIndex - 1]
        string = `${priceToLocal(previousRow?.discounted_value_to, currencyCode)} +`
      } else {
        string = `${priceToLocal(row?.discounted_value_to - playValueUsage, null)} / ${priceToLocal(
          row?.discounted_value_to,
          currencyCode
        )}`
      }
    }

    return string
  }

  const rowIsUsedUp = (row) => {
    if (row?.uses_to && usedCount >= row?.uses_to) return true
    if (row?.discounted_value_to && playValueUsage >= row?.discounted_value_to) return true
    return false
  }

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.leftColumn)}>
        <div className={cx(styles.row, styles.firstRow)}>
          <BlueTitle>{getActionTitle()}</BlueTitle>
          <BlueTitle className={cx(styles.lastItem)}>{getUsesTitle()}</BlueTitle>
        </div>

        {configRows.map((row, index) => (
          <div
            className={cx(styles.row, {
              [styles.gray]: index === 0 && rowIsUsedUp(row),
            })}
            key={index}
          >
            <div className={cx(styles.innerRow)}>
              {row?.percentage_coefficient && (
                <ValueText fontWeight="medium">{getPercentageString(row)}</ValueText>
              )}
              {row?.discount_amount && (
                <ValueText fontWeight="medium">{getDiscountAmountString(row)}</ValueText>
              )}

              <ValueText fontSize="12">&nbsp;{getMinFeeString(row)}</ValueText>
            </div>

            {(row?.uses_to || row?.discounted_value_to) && (
              <div className={cx(styles.innerRow)}>
                {index === 0 && !rowIsUsedUp(row) && <div className={cx(styles.greenDot)}></div>}
                {index === 1 && rowIsUsedUp(configRows[index - 1]) && (
                  <div className={cx(styles.greenDot)}></div>
                )}
                <ValueText className={cx(styles.lastItem)}>{getUsesString(row, index)}</ValueText>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={cx(styles.rightColumn)}>
        {(action?.configuration?.filter?.slot_filter ||
          action?.configuration?.filter?.golf_cart_filter) && (
          <React.Fragment>
            <div className={cx(styles.row, styles.firstRow)}>
              <BlueTitle>{t('sentences.appliesTo')}</BlueTitle>
            </div>

            {action?.configuration?.filter?.slot_filter && (
              <SlotFilterPresentation filter={action.configuration.filter.slot_filter} />
            )}

            {action?.configuration?.filter?.golf_cart_filter && (
              <GolfCartFilterPresentation filter={action?.configuration.filter.golf_cart_filter} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

MembershipCardSingleAction.propTypes = {
  action: PropTypes.object,
  usedCount: PropTypes.number,
  playValueUsage: PropTypes.number,
}

MembershipCardSingleAction.defaultProps = {
  usedCount: 0,
  playValueUsage: 0,
}

export default MembershipCardSingleAction
